import { Component, useState } from 'react';
import { View, Text, Pressable } from 'react-native';
import React from 'react';
import { DefaultRootState } from '@reduxLocal/persistState';
import { SET_SELECTED_PLAN } from '@reduxLocal/actions/types';
import { useDispatch, useSelector } from 'react-redux';
import LottieView from 'lottie-react-native';

const RenderSubscriptionItems = ({ item, index }) => {
  const [selectedItem, setSelectedItem] = useState(false);
  const selectedPlan = useSelector((state: DefaultRootState) => state.modals.registerModal.selectedPlan);
const dispatch = useDispatch();
  const handlePlan = () => {
    try {
      if (selectedPlan.plan === index) {
        return true
      } else return false
    }
    catch (e) {
      return false
    }
  }


  const HandleIcon = () => {
    if (index === 0) {
      return <LottieView source={require('@assets/animations/LordIcons/63-home-gradient-edited.json')} autoPlay loop />
    } else if (index === 1) {
      return <LottieView source={require('@assets/animations/LordIcons/neighbourhood.json')} autoPlay loop />
    } else if (index === 2) {
      return <LottieView source={require('@assets/animations/LordIcons/building.json')} autoPlay loop />
    } else if (index === 3) {
      return <LottieView source={require('@assets/animations/LordIcons/manyBuildings.json')} autoPlay loop />
    } else if (index === 4) {
      return <LottieView source={require('@assets/animations/LordIcons/beta-test.json')} autoPlay loop />
    }
  }

  return (
    <View style={{ flex: 1, marginHorizontal: 2.5, padding: 15, minWidth: 200 }}>
      <Pressable style={{ padding: 20, borderRadius: 20, backgroundColor: handlePlan() ? '#ECECEC' : 'grey', flex: 1, elevation: 1, shadowRadius: handlePlan() ? 15 : 10, shadowColor: handlePlan() ? 'blue' : 'black' }}
        onPress={() => { dispatch({ type: SET_SELECTED_PLAN, data: { plan: index, id: item.id, name: item.name, details: item } }) }}>
        {HandleIcon()}
      </Pressable>
      <Text adjustsFontSizeToFit style={{ textAlign: 'center', fontSize: 20, fontWeight: '600' }}>{item.name}</Text>
    </View>
  )
}
export default RenderSubscriptionItems;