import undoable from 'redux-undo';
import { updateObject } from './utils/globalStateFunctions';
import {
	ADD_ACTION,
	CLEAR_ACTION_HANDLER_HISTORY,
	REMOVE_ACTION,
} from '@reduxLocal/actions/types';

const initialState = {
	actions: [],
	mods: {},
};

const actionHandler = (state = initialState, action) => {
	switch (action.type) {
		case ADD_ACTION:
			{
				const { actionId, actionData } = action.payload;
				return updateObject(state, {
					actions: state.actions.push(actionData),
				});
			}
			case REMOVE_ACTION: {
				const { actionId } = action.payload;
				return updateObject(state, {
					actions: state.actions.filter(
						(action) => action.id !== actionId
					),
				});
			}
		default:
			return state;
	}
};

const undoablePreviewer = undoable(actionHandler, {
	limit: 50,
	clearHistoryType: CLEAR_ACTION_HANDLER_HISTORY,
});

export default undoablePreviewer;
