import { View, Pressable, Platform, ScrollView } from 'react-native';
import { Text } from '@constants/Themed';
import LottieView from 'lottie-react-native';
import React, { useCallback, useState } from 'react';
import { CHANGE_MENU, SECOND_MENU_CONTEXT } from '@reduxLocal/actions/types';
import { useDispatch, useSelector } from 'react-redux';
import DropDownPicker from 'react-native-dropdown-picker';
import styles from '@stylesheet';
import { useLottieAnim } from '@hooks/lottieHook';
import menuStates from './menuStates';
import { DefaultRootState, store } from '@reduxLocal/persistState';
import { Foundation, FontAwesome, Entypo, AntDesign } from '@expo/vector-icons';
import { NavigationHelpers, useNavigation } from "@react-navigation/native";
import * as Animatable from 'react-native-animatable';
import { DashboardNavigatorParamList, StackParamList } from '@navigation/types';
import { Badge, BadgeProps, styled } from '@mui/material';
import { XStack } from 'tamagui';

const StaticMenuHeader = ({ messageCount, theme, callBack, invitationCount }) => {
  const menuWarper = useSelector((state: DefaultRootState) => state.UI.menuWarper);
  const animation1 = useLottieAnim();
  const animation2 = useLottieAnim();
  const [items, setItems] = useState(menuStates);
  const [accessState, setAccessState] = useState(null);
  const [selectState, setSelectState] = useState(0);
  const [open, setOpen] = useState(true);
  const navigation = useNavigation<NavigationHelpers<StackParamList>>();
  const menuOpen = useSelector((state: DefaultRootState) => state.UI.menuOpen);
  
  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -8,
      top: -2,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  const StyledBadge2 = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      top: 16,
      right: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  return (
    <View style={{ top: menuOpen ? -20 : undefined, flexDirection: 'row', flexWrap: "wrap", gap: menuOpen ? 5 : 5, paddingHorizontal: 10, flex: .3 }}>
      <Pressable
        onPress={() => { navigation.navigate('MailLoginScreen') }}
        onHoverIn={() => { animation1.current?.reset(), animation1.current?.play() }}
        style={[styles.centerEverything, { flex: 2, minWidth: 50, minHeight: 20, padding: 5, borderRadius: 150 }]}>
        <StyledBadge color="error" overlap='circular' showZero={true} max={999} badgeContent={messageCount} >
        <FontAwesome name="inbox" size={25} color="white" style={{ textAlign: 'center', overflow: 'hidden', }} />
        </StyledBadge>
        {
          menuOpen &&
          <Text style={{ color: "white", fontSize: 10, textAlign: "center" }}>Emails</Text>
        }
      </Pressable>
      <Pressable
        onPress={() => { navigation.replace('Store', { screen: 'StoreLoading' }) }}
        onHoverIn={() => { animation2.current?.reset(), animation2.current?.play() }}
        style={[styles.centerEverything, { marginTop: -15, flex: 2, minHeight: 20, minWidth: 50, borderRadius: 150, overflow: 'visible' }]}>
        <LottieView key={2} autoPlay ref={animation2} loop={false} style={[{ top: 7, width: 75, alignSelf: 'center' }]} source={require('@assets/animations/78018-store-icon.json')} ></LottieView>
        {
          menuOpen &&
          <Text style={{ color: "white", fontSize: 10, top: -17, textAlign: "center" }}>Store</Text>
        }
      </Pressable>
      <Pressable
        onPress={() => { navigation.replace('Invitations') }}
        onHoverIn={() => { animation2.current?.reset(), animation2.current?.play() }}
        style={[styles.centerEverything, { flex: 2, top: menuOpen ? undefined : -10, minWidth: 50, minHeight: 20, padding: 5, borderRadius: 150 }]}>
        <Badge overlap='circular' color="error" showZero={true} max={999} badgeContent={invitationCount} >
        <AntDesign name="mail" size={25} color="white" style={{ textAlign: 'center', overflow: 'hidden', }} />
        </Badge>
        {
          menuOpen &&
          <Text style={{ color: "white", fontSize: 10, textAlign: "center" }}>Invites</Text>
        }
      </Pressable>
    </View>
  )
}
export default StaticMenuHeader;