export const SubscriptionInfo = [
  {
    key: 0,
    plan: 'Beta',
    users: 10,
    price: { monthly: 'Free', Annual: 'Free' },
    VAT: 0,
    storage: 2,
    features: [true, true, true, true, true, true, false, false],
    description: 'For those subscribing to our beta, please select this plan',
    active: true,
  },
  {
    key: 1,
    plan: 'Basic',
    users: 2,
    price: { monthly: 'Beta', annual: 'Beta' },
    VAT: 0,
    storage: 2,
    features: [true, true, true, false, false, false, false, false],
    description:
      'Up to 2 user licenses for both Mobile and Desktop, including Machine Learning and Artificial Intelligence features and Basic Tech Support.',
    active: false,
  },

  {
    key: 2,
    plan: 'Advanced',
    users: 10,
    price: { monthly: 'Beta', annual: 'Beta' },
    VAT: 0,
    storage: 10,
    features: [true, true, true, true, true, true, false, false],
    description:
      'Up to 10 user licenses for both Mobile and Desktop, including Machine Learning and Artificial Intelligence features and Expert Tech Support.',
    active: false,
  },
  {
    key: 3,
    plan: 'Enterprise',
    users: '> 10',
    price: { monthly: 'Quote', annual: 'Quote' },
    VAT: 0,
    storage: '>50',
    features: [true, true, true, true, true, true, true, true],
    description:
      'Unlimited user licenses for both Mobile and Desktop, including Machine Learning and Artificial Intelligence features and 24/7 Tech Support.',
    active: false,
  },
];
