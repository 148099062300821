import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 18.33 12.08"
    width={150}
    height={25}
    preserveAspectRatio="xMinYMin meet"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={6.38}
        x2={18.33}
        y2={6.38}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.01} stopColor="#49b2f8" />
        <stop offset={0.99} stopColor="#b37aff" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.51}
        y1={3.43}
        x2={18.26}
        y2={3.43}
        xlinkHref="#a"
      />
    </defs>
    <path
      style={{
        fill: "url(#a)",
      }}
      d="M9.55 7.95 0 .69v11.39h18.33V1.03L9.55 7.95z"
    />
    <path
      style={{
        fill: "url(#b)",
      }}
      d="M18.26 0H.51l9.03 6.87L18.26 0z"
    />
  </svg>
)

export default SvgComponent
