import { useSelector } from 'react-redux';
import { DefaultRootState } from '../persistState';
import { createSelector } from '@reduxjs/toolkit';


export const selectUser = () => {
	const user = useSelector((state: DefaultRootState) => state.promiseData.data.userData);
  return user
};

export interface GoogleAuthType {
	accessToken: string;
	refreshToken: string;
	expiry_date: number;
	googleId: string;
  email: string;
  scopes: string[];
}

export const googleAuths = () => {
	const googleAuth = useSelector(
		(state: DefaultRootState) =>
			state.promiseData.data.userData.authentications?.google
	);
	return googleAuth;
};


const userSelect = createSelector(selectUser);