const PreviewArr = [
  {
    headers: 'FIRST FIELD',
    data: '"First Sample"',
    dataTypes: 'STRING',
    key: '1',
    dataCategory: '',
  },
  {
    headers: 'SECOND FIELD',
    data: '"TRUE"',
    dataTypes: 'BOOLEAN',
    key: '2',
    dataCategory: '',
  },
  {
    headers: 'THIRD FIELD',
    data: '"01/01/2030"',
    dataTypes: 'DATE/TIME',
    key: '3',
    dataCategory: '',
  },
  {
    headers: 'nTH FIELD',
    data: '"nTh Sample"',
    dataTypes: 'UNDEFINED',
    key: '0',
    dataCategory: '',
  },
];

export default PreviewArr;
