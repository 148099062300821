import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={24}
    height={24}
    {...props}
  >
    <Path
      d="m118.16 386.28 39.95-23.05c5.89 4.77 12.22 9.15 18.99 13.06s13.72 7.2 20.8 9.91v46.12c.18 14.29 11.7 26 26.07 26.11l51.99-.05c7-.05 13.6-2.73 18.56-7.57 4.88-5.12 7.7-11.67 7.55-18.49v-46.17a146.007 146.007 0 0 0 39.86-22.94l39.9 23.04c12.45 7.19 28.38 2.92 35.57-9.53l26.04-45.1c7.19-12.45 2.92-28.38-9.53-35.57l-39.9-23.04c2.44-15.36 2.36-30.89-.07-46l39.99-23.08c6.09-3.46 10.25-9 12.24-15.79 1.71-6.71.73-13.77-2.73-19.86l-25.95-45.05c-7.28-12.38-23.36-16.62-35.64-9.51l-39.94 23.05c-5.89-4.77-12.22-9.15-18.99-13.06s-13.72-7.2-20.8-9.91V67.68c0-14.19-11.7-25.99-26.07-26.11l-51.99.05c-7 .05-13.6 2.73-18.56 7.57-1.51 1.78-3.01 3.55-4.16 5.54-2.29 3.97-3.4 8.38-3.39 12.96v46.17a146.007 146.007 0 0 0-39.86 22.94l-39.9-23.04c-12.45-7.19-28.38-2.92-35.57 9.53l-26.04 45.1c-7.19 12.45-2.92 28.38 9.53 35.57l39.9 23.04c-2.44 15.36-2.36 30.89.07 46l-39.99 23.08c-3.96 2.28-7.23 5.45-9.53 9.41-1.15 1.98-1.93 4.18-2.72 6.37-1.71 6.71-.73 13.77 2.73 19.86l25.95 45.05c7.28 12.38 23.18 16.51 35.64 9.51Zm68.7-172.74c20.1-34.82 64.78-46.79 99.6-26.69s46.79 64.78 26.69 99.6c-20.1 34.82-64.78 46.79-99.6 26.69s-46.79-64.78-26.69-99.6Z"
      style={{
        fill: "#fff",
      }}
    />
  </Svg>
)

export default SvgComponent
