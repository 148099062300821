import { Text, View, Platform, useWindowDimensions } from 'react-native';
import React, { useEffect, useState, useRef } from 'react';
import LottieView from 'lottie-react-native';
import { ProgressBar } from 'react-native-paper';
import styles from '@stylesheet';
import { SET_STORE_ID, UPDATE_PRODUCT_LIST } from '@reduxLocal/actions/types';
import apis from '@api/api';
import useGlobalUser from '@hooks/getGlobalUser';
import { useDispatch } from 'react-redux';

const StoreLoading = ({ navigation }) => {
  const [loaded, setLoaded] = useState(true);
  const [loadingText, setLoadingText] = useState('');
  const globalId = useGlobalUser();
  const [progress, setProgress] = useState(0);
  const ProgressBarRef = useRef(0);
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const makeAPICall = async (apiFunction, stepText, step) => {
    setLoadingText(stepText);
    const data = await apiFunction(globalId);
    return data;
  };

  const getStore = async () => {
    try {
      const steps = 2;
      const stepDuration = 2000; // Adjust the duration as needed
      const contribution = 1 / steps;
      const updateFrequency = 50; // Adjust the frequency of ProgressBar updates
      const maxTimeout = 5000; // Adjust the maximum timeout as needed

      // Initialize ProgressBar based on steps
      let currentStep = 0;

      // Update ProgressBar every 50ms
      const interval = setInterval(() => {
        console.log('interval count')
        setProgress(progress => progress + 0.05);
        currentStep += 1;

        // Stop updating ProgressBar after maxTimeout
        if (currentStep >= steps) {
          clearInterval(interval);
        }
      }, updateFrequency);
      // Make API calls
      const [productData, getStripeId] = await Promise.all([
        makeAPICall(apis.getProductList, 'Loading Product List', 1).then((res) => {
          setProgress(progress => progress + contribution);
          console.log('product data', res);
          const listOfProducts = res.map(item => item.data || []) || [];
          console.log('list of products', listOfProducts);
          dispatch({ type: UPDATE_PRODUCT_LIST, payload: res });
        }),
        makeAPICall(apis.getStripeId, 'Loading Store Information', 2).then((res) => {
          console.log('stripe id', res)
          const stripeId = res;
          setProgress(progress => progress + contribution);
          dispatch({ type: SET_STORE_ID, payload: stripeId });
        }),
      ]);

      // Navigate after all API calls have completed
      navigation.replace('StoreList');
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  const ProgressBarCalculator = ({ progress }) => {
    return (
      <ProgressBar progress={progress} color='rgb(90,192,234)' style={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }} />
    )
  };

  useEffect(() => {
    (async () => {
      await getStore();
    })();
  }, []);

  return (
    <View style={{ flex: 1 }}>
      {Platform.OS === 'web' ? (
        <LottieView style={{ zIndex: -1, position: 'absolute', minWidth: '350%', alignSelf: 'center', top: -2500 }} speed={0.2} source={require('@assets/animations/140777-liquid-loading.json')} autoPlay />
      ) : (
          <LottieView style={{ flex: 1, position: 'absolute', alignSelf: 'center', justifyContent: 'center', paddingVertical: '25%', minWidth: width, minHeight: height }} speed={1} source={require('@assets/animations/140777-liquid-loading.json')} loop={false} autoPlay />
      )}

      {loaded && (
        <View style={[styles.centerEverything, { flex: 1, zIndex: 1, alignSelf: 'center', alignContent: 'center', position: 'absolute', top: Platform.OS === 'web' ? undefined : '27%' }]}>
          <LottieView source={require('@assets/animations/78018-store-icon.json')} autoPlay style={Platform.OS !== 'web' && { height: 900, width: 900 }} loop={false} />
          <View style={{ width: '50%', margin: 5, flex: 3, minWidth: '50%', top: '-15%' }}>
            <Text style={{ textAlign: 'center', marginVertical: 10, fontWeight: 'bold' }}>{loadingText}</Text>
            <ProgressBarCalculator progress={progress} />
          </View>
        </View>
      )}
    </View>
  );
};

export default StoreLoading;
