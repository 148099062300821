import { io } from "socket.io-client";
import { HOST } from '@api/constant';

class SocketManager {
  constructor(namespace, params = { autoconnect: false }) {
    // Build the dynamic URL with the provided namespace and params
    const url = `${HOST}/${namespace}`;

    this.socket = io.connect(url, {
      autoconnect: params.autoconnect || false,
      query: params, // Pass the params as query parameters
    });

    // Bind internal functions to the class instance
    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.getSocket = this.getSocket.bind(this);
  }

  connect() {
    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
  }

  getSocket(user) {
    this.socket.auth = { user }
    return this.socket; // Return the socket for use elsewhere
  }
}

export default SocketManager;
