import React, { useState, useEffect, useCallback } from 'react';
import { Pressable, TextInput, Switch, StatusBar, Platform } from 'react-native';
import * as ScreenOrientation from 'expo-screen-orientation';
import { DefaultRootState } from '@reduxLocal/persistState';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, View, Text, Title } from '@constants/Themed';
import AnimatedLottieView from 'lottie-react-native';
import styles from '@stylesheet';
import { FlatList } from 'react-native-gesture-handler';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import { CHANGE_THEME, UPDATE_NOTIFICATIONS } from '@reduxLocal/actions/types';
import { useNavigation } from '@react-navigation/native';
import AnalogClock from './Components/AnalogueClock';
import { Ionicons, AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import * as Animatable from 'react-native-animatable';
import parseByDelimiter from '@utils/parseByDelimiter';
import moment from 'moment';
import toTitleCase from '@hooks/toTitleCase';
import { Audio } from 'expo-av';
import { Sound } from 'expo-av/build/Audio';
import SocketManager from '@utils/socket';
import Button from '@components/UI/Button/Button';

const socketManager = new SocketManager('notifications');

const TurnScreenModal = ({ navigation }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleOrientationChange = async () => {
      const orientation = await ScreenOrientation.getOrientationAsync();

      if (
        orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
        orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
      ) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    // Initial check
    handleOrientationChange();

    // Add orientation change listener
    const subscription = ScreenOrientation.addOrientationChangeListener(handleOrientationChange);

    // Clean up when component unmounts
    return () => {
      subscription.remove();
    };
  }, []);



  return (
    <Modal isVisible={isVisible} style={[styles.centerEverything, { flex: 1 }]} coverScreen={false}>
      <View style={[styles.centerEverything, { paddingVertical: 120, margin: 40, flex: 1, justifyContent: 'center', alignItems: 'center', alignSelf: 'center', alignContent: 'center', gap: 30 }]}>
        <AnimatedLottieView style={{ flex: 1, maxHeight: 150, backgroundColor: 'black', borderRadius: 20 }} source={require('@assets/animations/rotatedevice.json')} autoPlay />
        <Text>Turn your screen to landscape</Text>
        <Button onPress={() => navigation.goBack()} buttonText={"Back"} />
      </View>
    </Modal>
  )
}

const LiveReports = ({ navigation }) => {
  const dispatch = useDispatch();
  const user = useSelector((state: DefaultRootState) => state.promiseData.data.userData);
  const notificationList = useSelector((state: DefaultRootState) => state.notifications.notificationList);
  const notifications = useSelector((state: DefaultRootState) => state.notifications);
  const userId = useSelector((state: DefaultRootState) => state.promiseData.data.userData.id);
  const socket = socketManager.getSocket(userId);
  const theme = themeSelector();
  const [settingsModal, showSettingsModal] = useState(false);
  const [showClock, setShowClock] = useState(false);
  const [sound, setSound] = React.useState<Sound>();

  //TODO change orientation to landscape
  useEffect(() => {
    // TODO change orientation to landscape
    if (!user) {
      ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.DEFAULT);
      navigation.navigate('Login')
    };

    (async () => {
      console.log('unlocked screen');
      await ScreenOrientation.unlockAsync();
    })();
    return () => {
      //TODO change orientation to DEFAULT
      ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.DEFAULT);
    }
  }, []);

  async function playSound() {
    const { sound } = await Audio.Sound.createAsync(require('@assets/sound/smartsaasping.wav')
    );
    setSound(sound);
    await sound.playAsync();
  }

  useEffect(() => {
    return sound ? () => {
      sound.unloadAsync();
    } : undefined
  }, [sound])

  const _handleMute = useCallback(() => {

  }, []);

  const _handleExitReport = useCallback(async () => {
    console.log('locking to portrait');
    await ScreenOrientation.unlockAsync().then(async (res) => {
      console.log(res);
      navigation.navigate('Login')
    })
  }, [])


  const eventHandler = useCallback(async (event) => {
    console.log('new event', event.postedBy);
    console.log('notifications are', notifications);
    const oldList = notificationList;
    console.log('old list', oldList);
    const newList = [...oldList, event];
    dispatch({ type: UPDATE_NOTIFICATIONS, payload: newList });
    playSound();
  }, [userId, notifications, notificationList]);

  useEffect(() => {
    socket.connect();
    socket.emit('connection');
    return () => socketManager.disconnect();
  }, []);

  useEffect(() => {
    socket.on('event', eventHandler);
  }, [socket]);



  const renderListItem = ({ item }) => {
    return (
      <View style={{ flex: 1, backgroundColor: theme ? '#ececec' : '#333333', minWidth: 200, minHeight: 150, borderRadius: 20, padding: 20, flexDirection: showClock ? 'row' : "column", margin: 5 }}>
        {
          item.postedBy &&
          <View style={[styles.centerEverything, { backgroundColor: 'transparent', margin: 10 }]}>
            <Animatable.Image animation={'slideInLeft'} source={{ uri: item.postedBy.profile_information.avatar.img }} style={{ left: -5, top: -2, width: 60, height: 60, borderRadius: 40 }} />
          </View>
        }
        <View style={{ backgroundColor: 'transparent' }}>
          <Title >{'Detected an ' + item?.operationType + ' in ' + parseByDelimiter(item?.ns.coll)}</Title>
          <Text>{item.postedBy ? `${toTitleCase(item.postedBy.profile_information.name)} ${toTitleCase(item.postedBy.profile_information.surname)} ${item?.operationType}d ${parseByDelimiter(item.title ? item.title : "")}` : 'Detected an ' + item?.operationType + ' in ' + parseByDelimiter(item?.ns.coll)}</Text>
          {
            item.updateDescription &&
            <Text>{moment(item.updateDescription.updatedFields.updatedAt).format('Do MMM YYYY HH:MM a').toString()}</Text>
          }
        </View>
      </View>
    )
  }

  return (
    <View style={{ flex: 1, paddingVertical: 2, paddingRight: 20 }} >
      <TurnScreenModal navigation={navigation} />
      <View style={{ flexDirection: 'row', flex: 1, alignSelf: 'stretch' }}>
        <View style={{ minWidth: StatusBar.currentHeight, width: 60, paddingVertical: 20 }}>
          <Pressable style={[styles.centerEverything, { flex: 1, justifyContent: 'center' }]} onPress={() => dispatch({ type: UPDATE_NOTIFICATIONS, payload: { id: 33443, text: "hi" } })}>
            <Text>TEST</Text>
          </Pressable>
          <Pressable style={[styles.centerEverything, { flex: 1, justifyContent: 'center' }]} onPress={() => setShowClock(!showClock)}>
            <AntDesign name="clockcircle" size={24} color={theme ? "black" : "white"} />
          </Pressable>
          <View style={{ flex: 1.8 }} />
          <Pressable style={[styles.centerEverything, { flex: 1, justifyContent: 'center' }]} onPress={() => dispatch({ type: CHANGE_THEME, payload: { lightTheme: !theme, themeColour: theme ? 'light' : 'dark' } })}>
            <MaterialCommunityIcons name="theme-light-dark" size={24} color={theme ? "black" : "white"} />
          </Pressable>
          <Pressable style={[styles.centerEverything, { flex: 1, justifyContent: 'center' }]} onPress={_handleExitReport}>
            <Ionicons name="exit" size={24} color={theme ? "black" : "white"} />
          </Pressable>
        </View>
        <View style={{ flex: 1, flexDirection: 'row', paddingVertical: 20 }}>
          <Animatable.View transition={'flex'} style={{ flex: showClock ? .35 : 0.001 }}>
            {
              showClock &&
              <Animatable.View animation={'slideInDown'}>
                <AnalogClock />
              </Animatable.View>
            }
          </Animatable.View>
          <FlatList style={{ flex: 1 }} contentContainerStyle={{ padding: 10, gap: 20 }} horizontal={showClock ? false : (Platform.OS != 'web' ? true : false)} data={notificationList} renderItem={renderListItem} />
        </View>
      </View>
    </View>
  )

}

export default LiveReports;