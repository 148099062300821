import { countryList } from '@utils/countryList';
const genCountryList = () => {
	//Use papaparse to extract first and third columns from csv file
  const countryListArray = countryList.map((item) => {
    return { label: item.name, value: item['alpha-3'] };
  });
  return countryListArray;

};
export default genCountryList;
