import * as React from "react"
import Svg, { SvgProps, Defs, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props: SvgProps) => (
  <Svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={24}
    height={24}
    {...props}
  >
    <Defs></Defs>
    <Path
      className="cls-1"
      d="M416.64 333.32h-41.66v-41.66c0-11.5-9.31-20.83-20.83-20.83s-20.83 9.33-20.83 20.83v41.66h-41.66c-11.52 0-20.83 9.33-20.83 20.83s9.31 20.83 20.83 20.83h41.66v41.66c0 11.5 9.31 20.83 20.83 20.83s20.83-9.33 20.83-20.83v-41.66h41.66c11.52 0 20.83-9.33 20.83-20.83s-9.31-20.83-20.83-20.83ZM230.95 97.45v95.04c0 20.31-16.46 36.77-36.77 36.77H99.14c-20.31 0-36.77-16.46-36.77-36.77V97.45c0-20.31 16.46-36.77 36.77-36.77h95.04c20.31 0 36.77 16.46 36.77 36.77ZM230.95 307.88v95.04c0 20.31-16.46 36.77-36.77 36.77H99.14c-20.31 0-36.77-16.46-36.77-36.77v-95.04c0-20.31 16.46-36.77 36.77-36.77h95.04c20.31 0 36.77 16.46 36.77 36.77ZM437.99 98.58v95.04c0 20.31-16.46 36.77-36.77 36.77h-95.04c-20.31 0-36.77-16.46-36.77-36.77V98.58c0-20.31 16.46-36.77 36.77-36.77h95.04c20.31 0 36.77 16.46 36.77 36.77Z"
      style={{fill:'#fff'}}
    />
  </Svg>
)

export default SvgComponent
