import { Animated, View, Pressable, Text } from 'react-native';
import React, { useEffect, useState } from 'react';
import useForm from '@hooks/useForm';
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import { Entypo, Ionicons } from "@expo/vector-icons";

import otpStyles, {
  ACTIVE_CELL_BG_COLOR,
  CELL_BORDER_RADIUS,
  CELL_SIZE,
  DEFAULT_CELL_BG_COLOR,
  NOT_EMPTY_CELL_BG_COLOR,
} from './Styles';
import { useDispatch, useSelector } from 'react-redux';
import LottieView from 'lottie-react-native';
import { DefaultRootState, store } from "@reduxLocal/persistState";
import { HIDE_OTP, SUCCESS, TOGGLE_OTP } from '@reduxLocal/actions/types';
import styles from '@stylesheet';
import { loginUser } from '@api';
import Button from '@components/UI/Button/Button';
import apis from '@api/api';
import { useNavigation } from '@react-navigation/native';

const { Value, Text: AnimatedText } = Animated;

const CELL_COUNT = 6;

const animationsColor = [...new Array(CELL_COUNT)].map(() => new Value(0));
const animationsScale = [...new Array(CELL_COUNT)].map(() => new Value(1));
const animateCell = ({ hasValue, index, isFocused }) => {
  Animated.parallel([
    Animated.timing(animationsColor[index], {
      useNativeDriver: false,
      toValue: isFocused ? 1 : 0,
      duration: 250,
    }),
    Animated.spring(animationsScale[index], {
      useNativeDriver: false,
      toValue: hasValue ? 0 : 1,
      duration: hasValue ? 300 : 250,
    }),
  ]).start();
};

const OTPInput = ({ }) => {
  const dispatch = useDispatch();
  const [isPinReady, setIsPinReady] = useState(false);
  const [value, setValue] = useState('');
  const storedCredentials = useSelector((state:DefaultRootState) => state.UI.heldCredentials);
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();

  const _handleSubmit = async () => {
    await apis.validateOTP({userId: storedCredentials.userId, token:value}).then((res) => {
      store.dispatch({ type: HIDE_OTP });
      console.log(res);
      store.dispatch({ type: SUCCESS, data: res.data.data })
      navigation.navigate('Dashboard')
    }).catch(()=>{
      setValue("")
    })
  }

  useEffect(() => {
    if (value.length === CELL_COUNT) {
      setIsPinReady(true);
      _handleSubmit()
    }
  }, [value])

  const renderCell = ({ index, symbol, isFocused }) => {
    const hasValue = Boolean(symbol);
    const animatedCellStyle = {
      backgroundColor: hasValue
        ? animationsScale[index].interpolate({
          inputRange: [0, 1],
          outputRange: [NOT_EMPTY_CELL_BG_COLOR, ACTIVE_CELL_BG_COLOR],
        })
        : animationsColor[index].interpolate({
          inputRange: [0, 1],
          outputRange: [DEFAULT_CELL_BG_COLOR, ACTIVE_CELL_BG_COLOR],
        }),
      borderRadius: animationsScale[index].interpolate({
        inputRange: [0, 1],
        outputRange: [CELL_SIZE, CELL_BORDER_RADIUS],
      }),
      transform: [
        {
          scale: animationsScale[index].interpolate({
            inputRange: [0, 1],
            outputRange: [0.2, 1],
          }),
        },
      ],
    };

    // Run animation on next event loop tik
    // Because we need first return new style prop and then animate this value
    setTimeout(() => {
      animateCell({ hasValue, index, isFocused });
    }, 0);

    return (
      <AnimatedText
        key={index}
        style={[otpStyles.cell, animatedCellStyle]}
        onLayout={getCellOnLayoutHandler(index)}>
        {symbol || (isFocused ? <Cursor /> : null)}
      </AnimatedText>
    );
  };

  return (
    <View style={[otpStyles.root, { alignContent: 'center' }]}>
      {/* <Image style={otpStyles.icon} source={source} /> */}
      <View style={{ flex: .6, alignContent: 'center', justifyContent: 'center', width: '210%', alignItems: 'center', alignSelf: 'center' }}>
        {
          loading ?
            <LottieView style={{ width: '100%', alignSelf: 'center', justifyContent: 'center' }} source={require('@assets/animations/custom_Loader.json')} autoPlay loop />
            :
            <CodeField
              ref={ref}
              value={value}
              onChangeText={(value) => { setValue(value) }}
              cellCount={CELL_COUNT}
              rootStyle={otpStyles.codeFiledRoot}
              textContentType="oneTimeCode"
              renderCell={renderCell}
              selectTextOnFocus={true}
            />
        }
      </View>
      <View style={{ flex:1, flexDirection: 'row', gap: 30 }}>
        <Button
          onPress={() => {
            store.dispatch({ type: HIDE_OTP })
          }
          }
          icon={<Ionicons name={"arrow-back-outline"} size={20} color={'white'} ></Ionicons>}
          buttonText='Back'
          customStyle={{ maxWidth: 150, flex: 1 }}
        />
        <Button
          disabled={!isPinReady || loading}
          onPress={() => {
            _handleSubmit
          }
          }
          icon={<Ionicons name={"arrow-forward-outline"} size={20} color={'white'} ></Ionicons>}
          buttonText='Validate'
          customStyle={{ maxWidth: 150, flex: 1 }}
        />
      </View>
    </View>
  );
};

export default OTPInput;