import * as React from 'react';
import { View, Text, ScrollView } from 'react-native';
import styles from '../../../stylesheet';
import * as WebBrowser from 'expo-web-browser';
import { LinearGradient } from 'expo-linear-gradient';
import FlipCard from 'react-native-flip-card';
import DynamicPlanInfo from './DynamicPlanInfo';
import DynamicContentInfo from './components/DynamicContentInfo';
import { FlatList } from 'react-native-gesture-handler';
import { useQuery, useQueryClient } from 'react-query';
import BlogItem from './components/BlogItem';
import LottieView from 'lottie-react-native';
import MarkDown from 'react-native-markdown-display';
import { rules } from '@utils/stringUtils/markDownRules';
import * as Animatable from 'react-native-animatable';
import { Separator, SizableText, Tabs, TabsContentProps, YStack } from 'tamagui';
import { uniqueId } from 'lodash';

function learnMorePress() {
  WebBrowser.openBrowserAsync(
    'https://www.smartsaas.co.uk'
  )
}
function privacyPolicy() {
  WebBrowser.openBrowserAsync(
    'https://www.smartsaas.co.uk/privacy-policy'
  )
}
function termsOfService() {
  WebBrowser.openBrowserAsync(
    'https://www.smartsaas.co.uk/terms-and-conditions/'
  )
}

function handleGoToBlog() {
  WebBrowser.openBrowserAsync(
    'https://www.smartsaas.co.uk/posts'
  )
}

const TabsContent = (props: TabsContentProps) => {
  return (
    <Tabs.Content
      backgroundColor="$transparent"
      key="tab3"
      padding="$2"
      alignItems="center"
      justifyContent="center"
      flex={1}
      borderColor="$transparent"
      borderRadius={0}
      borderTopLeftRadius={0}
      borderTopRightRadius={0}
      borderWidth={0}
      minHeight={"100%"}
      {...props}
    >
      {props.children}
    </Tabs.Content>
  )
}

const _renderItem = ({ item, index }) => {
  return (
    <BlogItem item={item} index={index} />
  )
}

const DynamicContent = ({ registerModalState, selectedPlanState }) => {
  const [postData, setPostData] = React.useState([]);
  const [journal, setJournal] = React.useState(false);
  const queryClient = useQueryClient();
  const [changelogContent, setChangelogContent] = React.useState('');
  const changeLogData = queryClient.getQueryData('changeLogData');

  React.useEffect(() => {
    if(changeLogData) {
      setChangelogContent(changeLogData.toString());
    } else {
      queryClient.fetchQuery('changeLogData');
    }
  }, [changeLogData]);

  const { status, data, error, isFetching, isLoading, refetch } = useQuery({
    queryKey: ['postData'],
    queryFn: async () => {
      const data = await fetch('https://smartsaas.co.uk/wp-json/wp/v2/posts', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(async response => {
          if (response.status === 200) {
            const data = await response.json();
            return data;
          }
        }
        );
      setJournal(true);
      return data
    },
    retry: 20,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })

  return (
    <View style={{ flex: 1.5 }}>
      <View style={{ flexDirection: 'column', flex: 1 }}>
        <DynamicContentInfo />
        <Separator />
          <FlipCard
          style={{ flex: 1, marginTop: 10, flexDirection: 'column' }}
            flipVertical={true}
            flip={registerModalState}
            clickable={false}
          >
          <View style={[styles.face, { flex: 1, gap: 20 }]}>
            <YStack style={{ flex: 10 }}>
              <Tabs
                defaultValue={'changelog'}
                value={journal ? 'journal' : 'changelog'}
                orientation="horizontal"
                borderBlockColor={'$colorTransparent'}
                borderWidth={0}
                flexDirection="column"
                height={43}
                flex={1}
              >
                <Tabs.List
                  borderWidth={0}
                  paddingHorizontal={30}
                  borderColor={"$colorTransparent"}
                  disablePassBorderRadius="bottom"
                  aria-label="Switch between our journal and log"
                >
                  <Tabs.Tab borderBottomStartRadius={0} borderWidth={0} onPress={() => setJournal(false)} flex={1} value="changelog">
                    <SizableText fontFamily="$body">Changelog</SizableText>
                  </Tabs.Tab>
                  <Tabs.Tab flex={1} borderWidth={0} borderBottomEndRadius={0} onPress={() => setJournal(true)} value="journal">
                    <SizableText fontFamily="$body">Journal</SizableText>
                  </Tabs.Tab>
                </Tabs.List>
                <TabsContent borderRadius={20} flex={1} value="journal">
                  <FlatList showsVerticalScrollIndicator={false} scrollEnabled keyExtractor={item => item.id || 'journal' + uniqueId()} data={data} maxToRenderPerBatch={3} initialNumToRender={3} contentContainerStyle={{ padding: 20, paddingBottom: 200 }} nestedScrollEnabled renderItem={_renderItem} ItemSeparatorComponent={() => (<View style={{ minHeight: 20 }}></View>)}
                    ListEmptyComponent={() => (<View style={{ flexDirection: 'row' }}>
                      <LottieView autoPlay style={{ width: 80, margin: 20 }} source={require('../../../assets/animations/errorIcon.json')} loop={true} speed={1} />
                      <Text style={{ verticalAlign: 'middle', paddingVertical: 40 }}>Could not download recent posts, please check your network connection</Text>
                    </View>)}
                  />
                </TabsContent>
                <TabsContent borderRadius={20} flex={1} value="changelog">
                  <View style={{ flex: 1 }}>
                    {
                      changeLogData ?
                        <ScrollView style={{ flex: 1, marginBottom: 50 }} contentContainerStyle={{ padding: 30 }}>
                          <MarkDown rules={rules}>{changelogContent}</MarkDown>
                        </ScrollView>
                        :
                        <View style={{ flexDirection: 'row' }}>
                          <LottieView autoPlay style={{ width: 80, margin: 20 }} source={require('../../../assets/animations/errorIcon.json')} loop={true} speed={1} />
                          <Text style={{ verticalAlign: 'middle', paddingVertical: 40 }}>Could not download content, please check your network connection</Text>
                        </View>
                    }
                  </View>
                </TabsContent>
              </Tabs>
            </YStack>
            </View>
            {/*Card Flips Here!*/}
            <LinearGradient
              colors={['rgba(255,255,255,0.8)', 'transparent']}
              style={[styles.back, { flex: 1, alignSelf: 'stretch', paddingVertical: 20, paddingHorizontal: 20, borderRadius: 20 }]}
            >
              {
                selectedPlanState ?
                <DynamicPlanInfo />
                :
                  <Text adjustsFontSizeToFit >Please select a plan to get more details</Text>
              }
              <Text adjustsFontSizeToFit >Sign up for our free 14 day trial today and experience what SmartSaaS can do for your business</Text>
              <Text adjustsFontSizeToFit >No Credit Card Required!</Text>
            </LinearGradient>
          </FlipCard>
      </View>
    </View >
  );
};

export default DynamicContent;