/*
 * This is the source code of Moon Meet CrossPlatform.
 * It is licensed under GNU GPL v. 3.
 * You should have received a copy of the license in this archive (see LICENSE).
 *
 * Copyright Rayen sbai, 2021-2022.
 */

const FONTS = {
	bold: 'Product-Sans-Bold',
	bold_italic: 'Product-Sans-Bold-Italic',
	italic: 'Product-Sans-Italic',
	regular: 'Product-Sans-Regular',
};

const COLORS = {
	primaryLight: '#fffefe',
	primaryDark: '#000000',
	accentLight: 'rgb(90,192,234)',
	accentDark: '#429AFF',
	controlNormal: '#DADADA',
	controlHighlight: '#ADADAD',
	darkGrey: '#999999',
	lightGrey: '#757575',
	rippleColor: '#EEEEEE',
	redLightError: '#B00020',
	redDarkError: '#CF6679',
	yellowLightWarning: '#ffff00',
	yellowDarkWarning: '#ffca28',
	dimmed: '#F0F0F0',
	black: '#2f3042',
	white: '#fffefe',
	transparent: '#00000000',
	purple: '#9B59B6',
	blue: '#2471A3',
	blue_second: '#6495ED',
	amazingPurple: '#AB9FE2',
	green: '#229954',
	yellow: '#DFFF00',
	maroon: '#800000',
	orange: '#FF7F50',
	pink: '#DE3163',
	grey: '#838383',
	grey1: '#C0C0C0',
	grey2: 'A0A0A0',
	chats: {
		rightBubble: '#566193',
		leftBubble: '#e4e6eb',
	},
};

export { COLORS, FONTS };
