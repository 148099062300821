import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Modal, Text, View, Title } from '@constants/Themed';
import { AntDesign } from '@expo/vector-icons';
import { ScrollView, Pressable } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import apis from '@api/api';
import * as Animatable from 'react-native-animatable';
import Button from '../../UI/Button/Button';
import { FormikProps } from 'formik';
import LottieView from 'lottie-react-native';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultRootState } from '@reduxLocal/persistState';
import { FEEDBACK_MODAL_HIDDEN, SHOW_AUTH_MODAL } from '@reduxLocal/actions/types';
import { store } from '@reduxLocal/persistState';
import AuthModal from '../ErrorModal/AuthModal';
import GenerateSchemaForm from '@hooks/generateSchemaForm';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';

export type FeedbackModalHandle = {
  openModal: () => void;
};


const FeedbackModal = forwardRef((props, ref) => {
  const feedbackVisible = useSelector((state: DefaultRootState) => state.modals.feedbackModal.modalDisplayed);
  const [visible, setVisible] = useState(false);
  const currentScreen = useSelector((state: DefaultRootState) => state.navigation.page);
  const [feedbackTypeSelected, setFeedbackTypeSelected] = useState(undefined);
  const formRef = useRef<FormikProps<any>>(null);
  const scrollRef = useRef<ScrollView>(null);
  const queryClient = useQueryClient();
  const theme = themeSelector();

const dispatch = useDispatch();
  useEffect(() => {
    if (feedbackVisible) {
      setVisible(true);
    }
  }, [feedbackVisible])

  useImperativeHandle(ref, () => ({
    openModal: () => {
      setVisible(true);
    }
  }));

  const feedbackTypes = [
    {
      label: 'Sad',
      value: 'sad',
      icon: <LottieView source={require('@assets/animations/emojis/sad-emoji.json')} autoPlay style={{ flex: 1 }} loop />,
      message: 'We are sorry to hear that you are sad. Please tell us what happened.'
      // icon: <></>
    },
    {
      label: 'Meh!',
      value: 'meh',
      icon: <LottieView source={require('@assets/animations/emojis/unhappy-emoji.json')} style={{ flex: 1 }} loop autoPlay />,
      message: 'We are sorry to hear that you are unhappy. Please tell us what happened.'
    },
    {
      label: 'Neutral',
      value: 'neutral',
      icon: <LottieView source={require('@assets/animations/emojis/nuetral-emoji.json')} autoPlay loop style={{ flex: 1 }} />,
      message: 'We are sorry to hear that you are neutral. Please tell us what happened.'
    },
    {
      label: 'Happy',
      value: 'happy',
      icon: <LottieView source={require('@assets/animations/emojis/happy-emoji.json')} autoPlay loop style={{ flex: 1 }} />,
      message: 'We are happy to hear that you are happy. Please tell us what happened.'
    },
    {
      label: 'Amazed',
      value: 'amazed',
      icon: <LottieView source={require('@assets/animations/emojis/amazed-emoji.json')} autoPlay style={{ flex: 1 }} loop />,
      message: 'We are happy to hear that you are amazed. Please tell us what happened.'
    },
    {
      label: 'Other',
      value: 'other',
      icon: <LottieView source={require('@assets/animations/emojis/other-emoji.json')} autoPlay style={{ flex: 1 }} loop />,
      message: 'Unsure how you feel? No worries, let us know what happened.'
    }
  ]

  const { status, data, error, isFetching, isLoading, refetch } = useQuery({
    queryKey: ['feedbackSchema'],
    queryFn: async () => {
      const data = await apis.getFeedbackSchema()
        .then(res => {
          console.log('feed back schema', res)
          return res
        });
      return data
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })

  useImperativeHandle(ref, () => ({
    openModal: () => {
      setVisible(true);
    }
  }));

  useEffect(() => {
    if (feedbackTypeSelected) {
      scrollRef.current?.scrollTo({ x: feedbackTypes.findIndex(item => item.value === feedbackTypeSelected) * 60 + 20, y: 0, animated: true })
    }
  }, [feedbackTypeSelected, scrollRef])


  const handleSubmitFeedback = async (values) => {
    formRef.current?.setSubmitting(true);
    values.feedback_type = feedbackTypeSelected;
    values.page_details = currentScreen;
    await apis.postFeedback(values).then((res) => {
      if (res.status == 200) {
        setVisible(false);
        formRef.current?.resetForm();
        setFeedbackTypeSelected(undefined);
        setTimeout(() => dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Feedback submitted successfully', type: 'success' } }), 200);
      }
    })

  }

  return (
    <Modal
      onModalHide={() => { setFeedbackTypeSelected(undefined); dispatch({ type: FEEDBACK_MODAL_HIDDEN }) }}
      containerStyle={{ top: 40, backgroundColor: theme ? "white" : "#333333", minHeight: 800 }}
      isVisible={visible}
      extraChildren={(<Button buttonText={'Submit Feedback'} onPress={() => formRef.current?.handleSubmit()}
        customStyle={{ flex: 1, top: 8, minWidth: 200, alignSelf: 'center', jusifyContent: 'center' }}></Button>)}
    >
      <AuthModal />
      <Pressable hitSlop={30} onPress={() => setVisible(false)} style={{ minHeight: 40, minWidth: 40, position: 'absolute', top: -15, right: -15, borderRadius: 40, zIndex: 100 }}>
        <AntDesign name="closecircle" size={40} color="red" />
      </Pressable>
      <View style={{ flex: 1, paddingVertical: 20, backgroundColor: "transparent", paddingHorizontal: 20, minHeight: 800 }}>
        <Title style={{ marginBottom: 10, textAlign: "center" }}>Something to say?</Title>
        <Text>Looks like you may be having some trouble? Even if you aren't, nothing makes us happier than hearing from you!</Text>
        <View style={{ gap: 30, flex: 1, marginVertical: 20 }}>
          <Animatable.View transition={'flex'} style={{ flex: feedbackTypeSelected ? 1 : 3 }}>
            <ScrollView ref={scrollRef} centerContent style={{ flex: 1 }} horizontal={feedbackTypeSelected ? true : false}>
              {
                feedbackTypes.map((item, index) => {
                  return (
                    <Pressable key={index} onPress={() => setFeedbackTypeSelected(item.value)} style={{ height: 100, width: feedbackTypeSelected ? 80 : '92%', margin: 10, borderRadius: 20, backgroundColor: feedbackTypeSelected === item.value ? 'rgb(90,192,234)' : 'white', padding: 10 }}>
                      <View style={{ flex: 3, backgroundColor: 'transparent' }}>
                        {item.icon}
                      </View>
                      <Animatable.Text animation={'slideInUp'} style={{ flex: 1, color: feedbackTypeSelected === item.value ? 'white' : undefined, textAlign: 'center', overflow: 'visible' }}>{item.label}</Animatable.Text>
                    </Pressable>
                  )
                })
              }
            </ScrollView>
          </Animatable.View>
          <Text style={{ textAlign: "center", top: -20 }}>Leave us some feedback and we will get back to you as soon as possible</Text>
          {
            feedbackTypeSelected &&
            <Animatable.View animation={'fadeIn'} style={{ paddingTop: 0, paddingHorizontal: 20, shadowRadius: 20, shadowOpacity: .2, borderRadius: 20, flex: feedbackTypeSelected ? 4 : 0 }}>
              <GenerateSchemaForm ref={formRef} schema={data.schema} nullify={['userId', 'feedback_type', 'page_details']} removeSubmit submitFunction={handleSubmitFeedback} />
            </Animatable.View>
          }
        </View>
      </View>
    </Modal>
  )
});
export default FeedbackModal;