
import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';

const handleImageUpload = async (local: boolean) => {
	const { status } = await ImagePicker.requestCameraPermissionsAsync();

	if (status !== 'granted') {
		alert('Sorry, we need camera roll permissions to make this work!');
		return;
	}

	const result = await ImagePicker.launchImageLibraryAsync({
		mediaTypes: ImagePicker.MediaTypeOptions.Images,
		allowsEditing: true,
		aspect: [4, 3],
	});
	if (result.canceled) {
		alert('No image selected');
		return;
	}
	const manipResult = await manipulateAsync(
		result.assets[0].uri,
		[{ resize: { width: 1500 } }],
		{ compress: 1, format: SaveFormat.PNG, base64: true }
	);
	manipResult.fileName = result.assets[0].fileName;
	manipResult.mimeType = result.assets[0].mimeType;
	return local ? manipResult : manipResult.base64;
};
export default handleImageUpload;
