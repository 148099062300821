import * as React from 'react';
import { Text, TouchableOpacity, View, Image } from 'react-native';
import styles from '@stylesheet';

const NotFoundScreen = ({ navigation }) => {
  return (
    <View style={styles.welcomeContainer}>
      <Image
        style={styles.welcomeImage}
        source={require('@assets/images/logos/spoiled-logo.png')}
      />
      <View style={styles.gap} /><View />
      <Text adjustsFontSizeToFit style={styles.title}>This page doesn't exist.</Text>
      <Text adjustsFontSizeToFit style={styles.title}>{'\n'}If you are having any issues with SmartSaaS, please follow the link for rapid support</Text>
      <TouchableOpacity onPress={() => navigation.replace('SmartSaaS')} style={styles.link}>
        <Text adjustsFontSizeToFit style={styles.linkText}>Back to SmartSaaS?</Text>
      </TouchableOpacity>
    </View>
  );
}

export default NotFoundScreen;