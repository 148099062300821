import { useState } from 'react';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from "react-i18next";
import { Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_LANGUAGE } from '@reduxLocal/actions/types';
import { GB, DE, ES, GR, JP, TR, AR, CN, IT, UAE } from "@assets/--native/index";
import { View, Text } from 'react-native';
import FlagHandler from './FlagHandler';
import { DefaultRootState } from '@reduxLocal/persistState';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en-GB',
    label: 'English',
    icon: <GB />,
  },
  {
    value: 'ar',
    label: 'Arabic',
    icon: <AR />,
  },
  {
    value: 'zh',
    label: 'Chinese',
    icon: <CN />,
  },
  {
    value: 'de',
    label: 'German',
    icon: <DE />,
  },
  {
    value: 'el',
    label: 'Greek',
    icon: <GR />,
  },
  {
    value: 'es',
    label: 'Spanish',
    icon: <ES />,
  },
  {
    value: 'it',
    label: 'Italian',
    icon: <IT />,
  },
  {
    value: 'ja',
    label: 'Japanese',
    icon: <JP />,
  },
  {
    value: 'tk',
    label: 'Turkish',
    icon: <TR />,
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const [open, setOpen] = useState(null);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const activeLanguage = useSelector((state: DefaultRootState) => state.app.language.value)
  const theme = themeSelector();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <FlagHandler />
      </IconButton>
      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 180,
          },
        }}
      >
        {LANGS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === activeLanguage}
            onClick={() => { handleClose(); i18n.changeLanguage(option.value); dispatch({ type: CHANGE_LANGUAGE, payload: option.value }); }}
            sx={{ typography: 'body2', py: 1, backgroundColor: theme ? "white" : "#333333"}}
          >
            <View style={{ flex: 0.5 }}>
              {option.icon}
            </View>
            <View style={{ flex: 3 }}>
              <Text style={{ textAlign: "right", color: theme ? "black" : "white" }}>{option.label}</Text>
            </View>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
