import React, { useState } from "react";
import { Pressable } from 'react-native';
import { Animated, Text, View, Image } from 'react-native';
import { useTranslation } from "react-i18next";
import { CHANGE_LANGUAGE, HIDE_LANGUAGE_MODAL } from "@reduxLocal/actions/types";
import { useDispatch } from "react-redux";

const LanguagePressable = (lang) => {
  const [hovered, setHovered] = useState(false)
  const { i18n } = useTranslation()
const dispatch = useDispatch()
  return (
    <View style={{ flexDirection: 'row', flex: 1 }} key={lang.languageCode} >
      <Pressable onPress={() => { i18n.changeLanguage(lang.languageCode); dispatch({ type: CHANGE_LANGUAGE, payload: lang.language }); dispatch({ type: HIDE_LANGUAGE_MODAL }) }} style={{ flexDirection: 'row', flex: 1, margin: 10, backgroundColor: hovered ? '#95959599' : null, flexWrap: 'nowrap', padding: 20 }}
        onHoverIn={() => setHovered(true)}
        onHoverOut={() => setHovered(false)}>
        <View style={{ flex: 1, maxHeight: 40 }} >
          {
            lang.source
          }
        </View>
        <View style={{ flex: 3 }} />
        <Animated.Text style={{ fontSize: 14, flex: 2 }}>{lang.language}</Animated.Text>
      </Pressable>
    </View>
  )
}

export default LanguagePressable;