import { useQuery } from 'react-query';

const fetchChangeLogData = async () => {
  const response = await fetch('https://raw.githubusercontent.com/smartsaas/smartsaas-builds/main/CHANGELOG.md', {
    method: 'GET',
    headers: {
      Accept: 'application/vnd.github.html+json'
    }
  });

  if (response.status === 200) {
    const data = await response.text();
    return data;
  }
  throw new Error('Failed to fetch changelog');
};

const useChangeLogQuery = () => {
  return useQuery('changeLogData', fetchChangeLogData, { retry: 3, refetchOnWindowFocus: false, refetchOnMount: false });
};

export default useChangeLogQuery;
