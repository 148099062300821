import React from 'react';
import { Pressable, Platform } from 'react-native';
import { Text, View } from '@constants/Themed';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import styles from '@stylesheet';
import * as Animatable from 'react-native-animatable';
import returnIcon from '../utils/returnIcon';
import toTitleCase from '@hooks/toTitleCase';
import parseByDelimiter from '@utils/parseByDelimiter';
import getDaysAgo from '@utils/getDaysAgo';

const NoticeItem = ({ item }) => {
  const theme = themeSelector();
  //Convert this to a React Native component
  return (
    <View style={[styles.noticeCardContainer, { borderRadius: 10, overflow:"hidden", backgroundColor: theme ? undefined : "black" }]} >
      <Animatable.View animation={'slideInLeft'} style={{ flex: 1, maxWidth: 60, minWidth: 60, left: Platform.OS === 'web' ? 20 : -3 }}>
        {
          item.postedBy ?
            <Animatable.Image animation={'slideInLeft'} source={{ uri: item.postedBy.profile_information.avatar.img }} style={{ left: -5, top: -2, width: 30, height: 30, borderRadius: 20 }} />
            :
            <>
              {returnIcon(item.operationType)}
            </>
        }
      </Animatable.View>
      <View style={{ flex: 15 }}>
        <Animatable.Text numberOfLines={1} animation={'slideInDown'} style={{ userSelect: "none", textAlignVertical: "center", flex: 15, minWidth: '90%', paddingVertical: 15, color: theme ? "black" : "white" }}>
          {
            item.postedBy ?
              `${toTitleCase(item.postedBy.profile_information.name)} ${toTitleCase(item.postedBy.profile_information.surname)} ${item.operationType === 'delete' ? 'deleted' : item.operationType} ${parseByDelimiter(item.title ? item.title : "")}` :
              `Detected a${item.operationType === 'delete' ? '' : 'n'} ${item.operationType} event in ${parseByDelimiter(item?.ns?.coll)}`
          }
        </Animatable.Text>
        <Text>{getDaysAgo(item.wallTime)}</Text>
      </View>
    </View>
  );
}
export default NoticeItem;