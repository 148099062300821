import {updateObject} from './utils/globalStateFunctions';
import {
  ACTIVE_PROJECT,
  PROJECT_TEAM_LIST,
} from '../actions/types';

const initialState = {
  activeProject: null,
  projectTeamList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTIVE_PROJECT:
      return updateObject(state, {
        activeProject: action.payload,
      });
    case PROJECT_TEAM_LIST:
      return updateObject(state, {
        projectTeamList: action.payload,
      });
    default:
      return state;
  }
}

