import FontAwesome from '@expo/vector-icons/FontAwesome';
import { useEffect, useState } from 'react';
import { View, Pressable, Text } from 'react-native';
import * as WebBrowser from "expo-web-browser";
import * as Google from "expo-auth-session/providers/google";
import { UPDATE_GOOGLE_ACCOUNT, MAIL_AUTHENTICATED, SHOW_AUTH_MODAL, SUCCESS } from '@reduxLocal/actions/types';
import { store } from '@reduxLocal/persistState';
import { createURL } from 'expo-linking';
import { GoogleIcon } from '@assets/--native';
import { navigationRef } from '../../../../rootNavigation';
import apis from '@api/api';
import getGlobalUser from '@hooks/getGlobalUser';
import React from 'react';
import { googleAuths } from '@reduxLocal/selectors/userSelectors';
import { useDispatch } from 'react-redux';

WebBrowser.maybeCompleteAuthSession();

type GoogleAuth = {
  callback?: Function,
  loading?: Function,
  scopes: string[],
  setVisible?: Function
}

const GoogleButton = ({ callback, loading, scopes: passedScopes }: GoogleAuth) => {
  //Google Authentication Vars
  const userId = getGlobalUser();
  const previousScopes = googleAuths()?.scopes;
const dispatch = useDispatch();

  //If passed scopes already exist in array, then simply return the previous scopes
  const compareScopes = (scopes: string[], passedScopes: string[]) => {
    return scopes?.filter((scope) => passedScopes.includes(scope)) || [];
  }
  console.log(compareScopes(previousScopes, passedScopes), 'compare scopes');
  
  const scopes = previousScopes?.length ? [...passedScopes, ...previousScopes] : passedScopes;
  console.log(scopes, 'scope array');

  const [request, response, promptAsync] = Google.useAuthRequest({
    androidClientId: "530616870665-mt8uulmtk3r41m5ga22sfuc66j632gik.apps.googleusercontent.com",
    iosClientId: "530616870665-sn6qm81jg6eh4pr6dm2ov2n6guovrgna.apps.googleusercontent.com",
    expoClientId: "530616870665-sn6qm81jg6eh4pr6dm2ov2n6guovrgna.apps.googleusercontent.com",
    webClientId: '530616870665-5e22kgtr0e146vutn8veh5shoqnkis5j.apps.googleusercontent.com',
    clientSecret: "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",
    redirectUri: createURL(''),
    extraParams: {
      access_type: 'offline'
    },
    responseType: "code",
    scopes: scopes
  });


  useEffect(() => {
    console.log('response', response)
    if (response?.type === "success") {
      console.log(response, 'gresponse');
      const token = response.authentication.accessToken;
      const refreshToken = response.authentication.refreshToken
      if (refreshToken) {
      getMailToken(token, refreshToken);
      } else {
        googleAuthCallback(response);
      }
    }
  }, [response]);

  const googleAuthCallback = async (auth) => {

  }

  const getMailToken = async (token, refreshToken) => {
    console.log('started process', token)
    try {
      const response = await fetch(
        "https://www.googleapis.com/userinfo/v2/me",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const user = await response.json();
      let newOAuthUser = {
        googleId: user.id,
        email: user.email,
        accessToken: token,
        userId: userId,
        type: 'google',
        refreshToken: refreshToken,
        scopes: scopes
      }
      if (token && refreshToken) {
        if (loading) { loading?.(true) }
        await apis.authoriseGMail(newOAuthUser)
          .then((res) => {
            console.log(res, 'from our server');
            console.log(token);
            dispatch({ type: UPDATE_GOOGLE_ACCOUNT, payload: res.data });
            if (callback) {
              callback();
            }
          }).finally(() => {
            if (loading) { loading?.(false) }
          })
      } else {
        console.log('no token in request!');
        throw new Error('No token in request');
      }
    } catch (error) {
      dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Failed to log in with google account', type: 'error' } })
    }
  };

  return (
    <Pressable onPress={() => { promptAsync() }} style={{ elevation: 1, shadowRadius: 10, shadowOpacity: .2, marginVertical: 10, backgroundColor: 'white', borderRadius: 30, minWidth: 270, padding: 10, flexDirection: 'row', alignContent: 'space-around', justifyContent: "space-around", alignItems: 'stretch', }}>
      <GoogleIcon />
      <View style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, justifyContent: 'center', alignItems: 'center', minWidth: 220 }}><Text adjustsFontSizeToFit style={{ color: 'black', fontWeight: '600' }}>Sign in with Google</Text></View>
    </Pressable>
  );
}

export default GoogleButton;