import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={24}
    height={24}
    {...props}
  >
    <Path
      d="M317.7 83.36H182.31c-77.49 0-140.6 63.11-140.6 140.6v177.05c0 8.54 7.08 15.62 15.62 15.62H317.7c77.49 0 140.6-63.11 140.6-140.6v-52.07c0-77.49-63.11-140.6-140.6-140.6ZM358.94 255c-.42 1.67-.83 3.33-1.67 5-.62 1.46-1.46 2.92-2.29 4.37a30.214 30.214 0 0 1-3.33 3.96c-1.04 1.25-2.5 2.29-3.96 3.33-1.25.83-2.92 1.67-4.37 2.29-1.67.62-3.33 1.25-5 1.46-1.67.42-3.33.62-5 .62-6.87 0-13.54-2.92-18.33-7.71-1.25-1.25-2.29-2.5-3.33-3.96-.83-1.46-1.67-2.92-2.29-4.37-.83-1.67-1.25-3.33-1.67-5-.21-1.67-.42-3.33-.42-5 0-6.87 2.71-13.54 7.71-18.54 1.04-1.04 2.5-2.29 3.96-3.12 1.25-1.04 2.92-1.87 4.37-2.5 1.67-.62 3.33-1.04 5-1.46 8.33-1.67 17.5 1.04 23.33 7.08 5 5 7.71 11.66 7.71 18.54 0 1.67-.21 3.33-.42 5Zm-82.9-5c0 6.87-2.71 13.54-7.71 18.33-1.04 1.25-2.5 2.29-3.96 3.33-1.25.83-2.92 1.67-4.37 2.29-1.67.62-3.33 1.25-5 1.46-1.67.42-3.33.62-5 .62s-3.33-.21-5-.62c-1.67-.21-3.33-.83-5-1.46-1.46-.62-3.12-1.46-4.37-2.29-1.46-1.04-2.92-2.08-3.96-3.33-5-4.79-7.71-11.46-7.71-18.33s2.71-13.54 7.71-18.54c1.04-1.04 2.5-2.29 3.96-3.12 1.25-1.04 2.92-1.67 4.37-2.5 1.67-.62 3.33-1.04 5-1.46 3.33-.62 6.67-.62 10 0 1.67.42 3.33.83 5 1.46 1.46.83 3.12 1.46 4.37 2.5 1.46.83 2.92 2.08 3.96 3.12 5 5 7.71 11.66 7.71 18.54Zm-127.69-18.33c1.04-1.25 2.5-2.5 3.96-3.33 1.25-1.04 2.92-1.67 4.37-2.5 1.67-.62 3.33-1.04 5-1.46 3.33-.62 6.67-.62 10 0 1.67.42 3.33.83 5 1.46 1.46.83 3.12 1.46 4.37 2.5 1.46.83 2.92 2.08 3.96 3.33 5 4.79 7.71 11.46 7.71 18.33s-2.71 13.54-7.71 18.33c-1.04 1.25-2.5 2.29-3.96 3.33-1.25.83-2.92 1.67-4.37 2.29-1.67.62-3.33 1.25-5 1.46-1.67.42-3.33.62-5 .62s-3.33-.21-5-.62c-1.67-.21-3.33-.83-5-1.46-1.46-.62-3.12-1.46-4.37-2.29-1.46-1.04-2.92-2.08-3.96-3.33-5-4.79-7.71-11.66-7.71-18.33s2.71-13.54 7.71-18.33Z"
      style={{
        fill: "#fff",
      }}
    />
  </Svg>
)

export default SvgComponent
