import { TextInput, TextInputProps } from "react-native";
import styles from "../../../stylesheet";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { themeSelector } from "@reduxLocal/selectors/themeSelector";

export type StyledTextHandle = {
  /**
   * 
   * @property focus - Focuses the input field
   */
  focus: () => void;
};


const InputFieldStyled = forwardRef<StyledTextHandle, TextInputProps>((props, ref) => {
  const { multiline, maxLength, placeholder, onFocus, onBlur, style, ...rest } = props;
  const theme = themeSelector();
  const minHeight = multiline ? 60 : undefined;
  const inputRef = useRef<TextInput>(null);

  useEffect(() => {
    return () => {
      // Cleanup
      inputRef.current = null;
    }
  }, []);

  useImperativeHandle(ref, () => ({
    focus: () => {
      console.log('focusing')
      // Expose the focus method
      inputRef.current?.focus();
    },
  }));

  return (
    <TextInput
    key={"inputOfText"}
      ref={inputRef}  // Applying the ref separately
      maxLength={maxLength || undefined}
      placeholder={placeholder || ''}
      onFocus={onFocus}
      onBlur={onBlur}
      style={[styles.inputOutline, { flex: 1, alignSelf: 'stretch', minWidth: '93%', color: theme ? 'black' : 'white', borderWidth: 0, borderBottomWidth: 2 }, { ...style }]}
      placeholderTextColor={theme ? 'black' : 'white'}
      multiline={multiline}
      {...rest}
    />
  );
});

export default InputFieldStyled;
