import React from 'react';
import { Platform, View } from "react-native";
import styles from "../../../stylesheet";
import { FormProps } from './types';
import DropDownPicker from 'react-native-dropdown-picker';

type DropSelectProps = FormProps & {
  [key: string]: any;
  formContext?: any
};

const DropSelect = ({ formContext, name, form, ...props }: DropSelectProps) => {
  if (formContext) {
    const { handleChangeBySelect } = formContext;

    return (
      <View style={{ flex: 1, minWidth: 250, maxWidth: '100%' }}>
        <DropDownPicker
          onSelectItem={handleChangeBySelect(name)}
          style={styles.dropdownInput}
          dropDownDirection='BOTTOM'
          listItemContainerStyle={{ flex: 1 }}
          textStyle={{ marginBottom: 3, margin: 2 }}
          zIndex={2}
          bottomOffset={100}
          closeOnBackPressed
          {...props}
        />
      </View>
    );
  } else return (<View style={{ flex: 1, minWidth: 250, maxWidth: '100%' }}>
    <DropDownPicker
      style={styles.dropdownInput}
      dropDownDirection='BOTTOM'
      listItemContainerStyle={{ flex: 1 }}
      textStyle={{ marginBottom: 3, margin: 2 }}
      zIndex={2}
      bottomOffset={100}
      closeOnBackPressed
      {...props}
    />
  </View>)
};

export default DropSelect;
