import { SELECT_FIELD, SET_REPORT } from '../actions/types';
import { updateObject } from './utils/globalStateFunctions';

const initialState = {
	selectedField: null,
	selectedId: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_REPORT:
			return updateObject(state, {
				activeItem: action.payload,
			});
		case SELECT_FIELD:
			return updateObject(state, {
				selectedField: action.payload,
			});
		default:
			return state;
	}
};
