import * as React from "react"
import Svg, { Defs, LinearGradient, Stop, Path } from "react-native-svg"
import { uniqueId } from "lodash"
const SvgComponent = (props) => {
  return (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    data-name="a"
    viewBox="0 0 5204.51 246.57"
    {...props}
    pointerEvents="none"
  >
    <Defs>
      <LinearGradient
        id="a"
        x1={2751.32}
        x2={3030.18}
        y1={337.86}
        y2={337.86}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#ecf5d5" />
        <Stop offset={1} stopColor="#d4dbcb" />
      </LinearGradient>
      <LinearGradient
        id="c"
        x1={2032.8}
        x2={2178.37}
        y1={1280.72}
        y2={1280.72}
        gradientTransform="rotate(-180 2417.48 755.07)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#edd29d" />
        <Stop offset={0.99} stopColor="#f7ffb0" />
      </LinearGradient>
      <LinearGradient
        id="q"
        x1={1087.49}
        x2={1176.54}
        y1={290.04}
        y2={290.04}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#506425" />
        <Stop offset={0.22} stopColor="#566c25" />
        <Stop offset={0.57} stopColor="#688227" />
        <Stop offset={0.99} stopColor="#86a529" />
        <Stop offset={1} stopColor="#87a72a" />
      </LinearGradient>
      <LinearGradient
        id="r"
        x1={1087.49}
        x2={1163.69}
        y1={290.04}
        y2={290.04}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#3d4d1a" />
        <Stop offset={0.23} stopColor="#43551a" />
        <Stop offset={0.59} stopColor="#556b1d" />
        <Stop offset={1} stopColor="#718d21" />
      </LinearGradient>
      <LinearGradient
        id="e"
        x1={-261.22}
        x2={1833.26}
        y1={233.1}
        y2={233.1}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#567c23" />
        <Stop offset={0.45} stopColor="#96b234" />
        <Stop offset={1} stopColor="#b0d459" />
      </LinearGradient>
      <LinearGradient
        id="d"
        x1={2828.01}
        x2={1944.12}
        y1={258.11}
        y2={258.11}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#66873a" />
        <Stop offset={0.32} stopColor="#accc3e" />
        <Stop offset={0.71} stopColor="#e3ff56" />
        <Stop offset={1} stopColor="#e3ff56" />
      </LinearGradient>
      <LinearGradient
        id="s"
        x1={1087.66}
        x2={1520.53}
        y1={372.97}
        y2={372.97}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#f0f0dc" />
        <Stop offset={1} stopColor="#d4dbcb" />
      </LinearGradient>
      <LinearGradient
        xlinkHref="#a"
        id="W"
        x1={1361.69}
        x2={1640.55}
        y1={395.95}
        y2={395.95}
      />
      <LinearGradient
        xlinkHref="#a"
        id="X"
        x1={1030.71}
        x2={1309.57}
        y1={319.26}
        y2={319.26}
      />
      <LinearGradient
        id="t"
        x1={1193.29}
        x2={1401.8}
        y1={358.62}
        y2={358.62}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#e2fda0" />
        <Stop offset={1} stopColor="#91a377" />
      </LinearGradient>
      <LinearGradient
        id="b"
        x1={1334.69}
        x2={1652.4}
        y1={379.59}
        y2={379.59}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#bdcc9c" />
        <Stop offset={1} stopColor="#7c925b" />
      </LinearGradient>
      <LinearGradient
        xlinkHref="#b"
        id="Y"
        x1={1024.96}
        x2={1342.66}
        y1={305.08}
        y2={305.08}
      />
      <LinearGradient
        xlinkHref="#c"
        id="Z"
        x1={3445.48}
        x2={3591.04}
        y1={1344.77}
        y2={1344.77}
      />
      <LinearGradient
        xlinkHref="#c"
        id="aa"
        x1={3539.09}
        x2={3684.65}
        y1={1290.33}
        y2={1290.33}
      />
      <LinearGradient
        id="u"
        x1={1150.31}
        x2={1239.36}
        y1={294.18}
        y2={294.18}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#506425" />
        <Stop offset={0.22} stopColor="#566c25" />
        <Stop offset={0.57} stopColor="#688227" />
        <Stop offset={0.99} stopColor="#86a529" />
        <Stop offset={1} stopColor="#87a72a" />
      </LinearGradient>
      <LinearGradient
        id="v"
        x1={1150.31}
        x2={1226.51}
        y1={294.19}
        y2={294.19}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#3d4d1a" />
        <Stop offset={0.23} stopColor="#43551a" />
        <Stop offset={0.59} stopColor="#556b1d" />
        <Stop offset={1} stopColor="#718d21" />
      </LinearGradient>
      <LinearGradient
        id="w"
        x1={1234.93}
        x2={1234.93}
        y1={-11.66}
        y2={353.26}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#6e923b" />
        <Stop offset={0.33} stopColor="#6e923b" />
        <Stop offset={0.74} stopColor="#9bb535" />
        <Stop offset={1} stopColor="#cefd60" />
      </LinearGradient>
      <LinearGradient
        id="x"
        x1={1919.49}
        x2={2264.24}
        y1={61.86}
        y2={61.86}
        gradientTransform="matrix(-.79861 .13536 -.15207 -.8972 2856.22 84.17)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#8faf3c" />
        <Stop offset={0.96} stopColor="#69701a" />
        <Stop offset={0.99} stopColor="#80891d" />
      </LinearGradient>
      <LinearGradient
        id="y"
        x1={5902.1}
        x2={5067.6}
        y1={333.64}
        y2={333.64}
        gradientTransform="rotate(-180 3343.325 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#66873a" />
        <Stop offset={0.32} stopColor="#accc3e" />
        <Stop offset={0.71} stopColor="#e3ff56" />
        <Stop offset={1} stopColor="#e3ff56" />
      </LinearGradient>
      <LinearGradient
        id="z"
        x1={1044.66}
        x2={1331.78}
        y1={326.68}
        y2={326.68}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#b5e154" />
        <Stop offset={0.16} stopColor="#afd851" />
        <Stop offset={1} stopColor="#93ac47" />
      </LinearGradient>
      <LinearGradient
        xlinkHref="#d"
        id="ab"
        x1={1685.53}
        x2={801.64}
        y1={314.11}
        y2={314.11}
      />
      <LinearGradient
        id="A"
        x1={1237.37}
        x2={1934.15}
        y1={350.33}
        y2={350.33}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#e0ff78" />
        <Stop offset={0.16} stopColor="#c8fb4e" />
        <Stop offset={0.67} stopColor="#8ead3c" />
        <Stop offset={1} stopColor="#6e8133" />
      </LinearGradient>
      <LinearGradient
        id="B"
        x1={1496.13}
        x2={1549.97}
        y1={299.86}
        y2={299.86}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#aed53a" />
        <Stop offset={0.25} stopColor="#92b335" />
        <Stop offset={0.57} stopColor="#759030" />
        <Stop offset={0.83} stopColor="#637a2d" />
        <Stop offset={1} stopColor="#5d722c" />
      </LinearGradient>
      <LinearGradient
        id="C"
        x1={1408.97}
        x2={1446.35}
        y1={281.28}
        y2={281.28}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#8faf3c" />
        <Stop offset={0.96} stopColor="#69701a" />
        <Stop offset={0.99} stopColor="#80891d" />
      </LinearGradient>
      <LinearGradient
        id="D"
        x1={1072.4}
        x2={1109.78}
        y1={268.56}
        y2={268.56}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#8faf3c" />
        <Stop offset={0.96} stopColor="#69701a" />
        <Stop offset={0.99} stopColor="#80891d" />
      </LinearGradient>
      <LinearGradient
        id="E"
        x1={-1040.86}
        x2={2024.07}
        y1={420.34}
        y2={420.34}
        gradientTransform="scale(1 -1) rotate(6.24 8420.7 -179.863)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#96b83f" />
        <Stop offset={1} stopColor="#5f781c" />
      </LinearGradient>
      <LinearGradient
        xlinkHref="#d"
        id="ac"
        x1={1018.24}
        x2={-666.02}
        y1={229.17}
        y2={229.17}
      />
      <LinearGradient
        id="F"
        x1={1037.3}
        x2={1037.3}
        y1={252.33}
        y2={296.98}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#6e923b" />
        <Stop offset={0.33} stopColor="#6e923b" />
        <Stop offset={0.74} stopColor="#9bb535" />
        <Stop offset={1} stopColor="#cefd60" />
      </LinearGradient>
      <LinearGradient
        id="G"
        x1={1037.3}
        x2={1037.3}
        y1={252.33}
        y2={296.98}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#6e923b" />
        <Stop offset={0.33} stopColor="#6e923b" />
        <Stop offset={0.74} stopColor="#9bb535" />
        <Stop offset={1} stopColor="#cefd60" />
      </LinearGradient>
      <LinearGradient
        id="H"
        x1={1697.46}
        x2={1968.53}
        y1={283.47}
        y2={283.47}
        gradientTransform="matrix(1.17997 .00824 .10696 -.91928 -652.61 518.84)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#96b83f" />
        <Stop offset={1} stopColor="#34440a" />
      </LinearGradient>
      <LinearGradient
        xlinkHref="#e"
        id="ad"
        x1={353.99}
        x2={2448.47}
        y1={238.32}
        y2={238.32}
      />
      <LinearGradient
        id="f"
        x1={2490.53}
        x2={2579.6}
        y1={295.26}
        y2={295.26}
        gradientTransform="rotate(-180 1798.73 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#506425" />
        <Stop offset={0.22} stopColor="#566c25" />
        <Stop offset={0.57} stopColor="#688227" />
        <Stop offset={0.99} stopColor="#86a529" />
        <Stop offset={1} stopColor="#87a72a" />
      </LinearGradient>
      <LinearGradient
        id="g"
        x1={2490.54}
        x2={2566.75}
        y1={295.26}
        y2={295.26}
        gradientTransform="rotate(-180 1798.73 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#3d4d1a" />
        <Stop offset={0.23} stopColor="#43551a" />
        <Stop offset={0.59} stopColor="#556b1d" />
        <Stop offset={1} stopColor="#718d21" />
      </LinearGradient>
      <LinearGradient
        id="h"
        x1={2553.35}
        x2={2642.42}
        y1={299.4}
        y2={299.4}
        gradientTransform="rotate(-180 1798.73 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#506425" />
        <Stop offset={0.22} stopColor="#566c25" />
        <Stop offset={0.57} stopColor="#688227" />
        <Stop offset={0.99} stopColor="#86a529" />
        <Stop offset={1} stopColor="#87a72a" />
      </LinearGradient>
      <LinearGradient
        id="i"
        x1={2553.36}
        x2={2629.57}
        y1={299.41}
        y2={299.41}
        gradientTransform="rotate(-180 1798.73 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#3d4d1a" />
        <Stop offset={0.23} stopColor="#43551a" />
        <Stop offset={0.59} stopColor="#556b1d" />
        <Stop offset={1} stopColor="#718d21" />
      </LinearGradient>
      <LinearGradient
        id="j"
        x1={2530.01}
        x2={2530.01}
        y1={-6.44}
        y2={358.48}
        gradientTransform="rotate(-180 1798.73 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#6e923b" />
        <Stop offset={0.33} stopColor="#6e923b" />
        <Stop offset={0.74} stopColor="#9bb535" />
        <Stop offset={1} stopColor="#cefd60" />
      </LinearGradient>
      <LinearGradient
        id="k"
        x1={206.03}
        x2={550.78}
        y1={-197.52}
        y2={-197.52}
        gradientTransform="matrix(.79861 .13536 .15207 -.8972 741.24 84.17)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#8faf3c" />
        <Stop offset={0.96} stopColor="#69701a" />
        <Stop offset={0.99} stopColor="#80891d" />
      </LinearGradient>
      <LinearGradient
        id="l"
        x1={4499.03}
        x2={3664.54}
        y1={338.86}
        y2={338.86}
        gradientTransform="matrix(1 0 0 -1 -3089.19 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#66873a" />
        <Stop offset={0.32} stopColor="#accc3e" />
        <Stop offset={0.71} stopColor="#e3ff56" />
        <Stop offset={1} stopColor="#e3ff56" />
      </LinearGradient>
      <LinearGradient
        id="m"
        x1={2447.71}
        x2={2734.83}
        y1={331.9}
        y2={331.9}
        gradientTransform="rotate(-180 1798.73 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#b5e154" />
        <Stop offset={0.16} stopColor="#afd851" />
        <Stop offset={1} stopColor="#93ac47" />
      </LinearGradient>
      <LinearGradient
        id="n"
        x1={2640.42}
        x2={3337.21}
        y1={348.08}
        y2={348.08}
        gradientTransform="rotate(-180 1798.73 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#e0ff78" />
        <Stop offset={0.16} stopColor="#c8fb4e" />
        <Stop offset={0.67} stopColor="#8ead3c" />
        <Stop offset={1} stopColor="#6e8133" />
      </LinearGradient>
      <LinearGradient
        id="o"
        x1={2475.45}
        x2={2512.84}
        y1={275.28}
        y2={275.28}
        gradientTransform="rotate(-180 1798.73 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#8faf3c" />
        <Stop offset={0.96} stopColor="#69701a" />
        <Stop offset={0.99} stopColor="#80891d" />
      </LinearGradient>
      <LinearGradient
        id="p"
        x1={1129.74}
        x2={1129.74}
        y1={257.55}
        y2={302.2}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#6e923b" />
        <Stop offset={0.33} stopColor="#6e923b" />
        <Stop offset={0.74} stopColor="#9bb535" />
        <Stop offset={1} stopColor="#cefd60" />
      </LinearGradient>
      <LinearGradient
        xlinkHref="#f"
        id="ae"
        x1={1391.03}
        x2={1480.09}
        y1={290.15}
        y2={290.15}
      />
      <LinearGradient
        xlinkHref="#g"
        id="af"
        x1={1391.04}
        x2={1467.24}
        y1={290.15}
        y2={290.15}
      />
      <LinearGradient
        xlinkHref="#h"
        id="ag"
        x1={1453.85}
        x2={1542.91}
        y1={294.29}
        y2={294.29}
      />
      <LinearGradient
        xlinkHref="#i"
        id="ah"
        x1={1453.86}
        x2={1530.06}
        y1={294.3}
        y2={294.3}
      />
      <LinearGradient
        xlinkHref="#j"
        id="ai"
        x1={1538.48}
        x2={1538.48}
        y1={-11.55}
        y2={353.37}
      />
      <LinearGradient
        xlinkHref="#k"
        id="aj"
        x1={1545.41}
        x2={1890.16}
        y1={-1.15}
        y2={-1.15}
      />
      <LinearGradient
        xlinkHref="#l"
        id="ak"
        x1={5598.54}
        x2={4764.05}
        y1={333.75}
        y2={333.75}
      />
      <LinearGradient
        xlinkHref="#m"
        id="al"
        x1={1348.2}
        x2={1635.32}
        y1={326.79}
        y2={326.79}
      />
      <LinearGradient
        id="S"
        x1={1989.07}
        x2={1105.65}
        y1={314.22}
        y2={314.22}
        gradientTransform="rotate(-180 1798.73 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#66873a" />
        <Stop offset={0.32} stopColor="#accc3e" />
        <Stop offset={0.71} stopColor="#e3ff56" />
        <Stop offset={1} stopColor="#e3ff56" />
      </LinearGradient>
      <LinearGradient
        xlinkHref="#n"
        id="am"
        x1={1540.91}
        x2={2237.7}
        y1={353.46}
        y2={353.46}
      />
      <LinearGradient
        id="I"
        x1={1756.6}
        x2={2062.98}
        y1={362.43}
        y2={362.43}
        gradientTransform="rotate(-180 1798.73 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0.64} stopColor="#798e4a" />
        <Stop offset={1} stopColor="#a3c440" />
      </LinearGradient>
      <LinearGradient
        id="J"
        x1={1853.54}
        x2={1970.1}
        y1={147.97}
        y2={147.97}
        gradientTransform="rotate(176.83 1803.157 199.549)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#718d21" />
        <Stop offset={0.41} stopColor="#556b1d" />
        <Stop offset={0.77} stopColor="#43551a" />
        <Stop offset={0.99} stopColor="#3d4d1a" />
      </LinearGradient>
      <LinearGradient
        id="K"
        x1={1884.9}
        x2={1969.41}
        y1={-16.1}
        y2={-16.1}
        gradientTransform="rotate(173.02 1807.822 113.356)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#87a72a" />
        <Stop offset={0} stopColor="#86a529" />
        <Stop offset={0.43} stopColor="#688227" />
        <Stop offset={0.78} stopColor="#566c25" />
        <Stop offset={1} stopColor="#506425" />
      </LinearGradient>
      <LinearGradient
        id="L"
        x1={1889.13}
        x2={1926.21}
        y1={379.01}
        y2={379.01}
        gradientTransform="rotate(-180 1798.73 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#a3c935" />
        <Stop offset={0.42} stopColor="#a8c357" />
        <Stop offset={0.64} stopColor="#8ca747" />
        <Stop offset={0.86} stopColor="#77913b" />
        <Stop offset={1} stopColor="#708937" />
      </LinearGradient>
      <LinearGradient
        id="M"
        x1={1799.68}
        x2={1853.52}
        y1={299.96}
        y2={299.96}
        gradientTransform="rotate(-180 1798.73 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#aed53a" />
        <Stop offset={0.25} stopColor="#92b335" />
        <Stop offset={0.57} stopColor="#759030" />
        <Stop offset={0.83} stopColor="#637a2d" />
        <Stop offset={1} stopColor="#5d722c" />
      </LinearGradient>
      <LinearGradient
        id="N"
        x1={1712.52}
        x2={1749.9}
        y1={281.39}
        y2={281.39}
        gradientTransform="rotate(-180 1798.73 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#8faf3c" />
        <Stop offset={0.96} stopColor="#69701a" />
        <Stop offset={0.99} stopColor="#80891d" />
      </LinearGradient>
      <LinearGradient
        xlinkHref="#o"
        id="an"
        x1={1375.95}
        x2={1413.33}
        y1={270.16}
        y2={270.16}
      />
      <LinearGradient
        id="O"
        x1={1609.75}
        x2={2054.66}
        y1={402.59}
        y2={402.59}
        gradientTransform="rotate(-173.76 1808.534 360.951)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#96b83f" />
        <Stop offset={1} stopColor="#5f781c" />
      </LinearGradient>
      <LinearGradient
        xlinkHref="#p"
        id="ao"
        x1={2229.25}
        x2={2229.25}
        y1={252.44}
        y2={297.09}
      />
      <LinearGradient
        id="P"
        x1={1953.79}
        x2={2258.72}
        y1={286.41}
        y2={286.41}
        gradientTransform="matrix(-1.17997 .00824 -.10696 -.91928 4250.07 518.84)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#96b83f" />
        <Stop offset={1} stopColor="#34440a" />
      </LinearGradient>
      <LinearGradient
        xlinkHref="#e"
        id="ap"
        x1={1667.23}
        x2={2789.95}
        y1={316.03}
        y2={316.03}
      />
      <LinearGradient xlinkHref="#q" id="aq" x1={3923.16} x2={4012.21} />
      <LinearGradient xlinkHref="#r" id="ar" x1={3923.15} x2={3999.35} />
      <LinearGradient xlinkHref="#e" id="as" x1={2574.44} x2={4668.92} />
      <LinearGradient xlinkHref="#s" id="at" x1={3923.32} x2={4356.19} />
      <LinearGradient
        xlinkHref="#a"
        id="au"
        x1={4197.36}
        x2={4476.22}
        y1={395.95}
        y2={395.95}
      />
      <LinearGradient
        xlinkHref="#a"
        id="av"
        x1={3866.38}
        x2={4145.24}
        y1={319.26}
        y2={319.26}
      />
      <LinearGradient xlinkHref="#t" id="aw" x1={4028.95} x2={4237.46} />
      <LinearGradient
        xlinkHref="#b"
        id="ax"
        x1={4170.36}
        x2={4486}
        y1={168.3}
        y2={168.3}
      />
      <LinearGradient
        xlinkHref="#b"
        id="ay"
        x1={4194.24}
        x2={4194.25}
        y1={188.99}
        y2={188.99}
      />
      <LinearGradient
        xlinkHref="#b"
        id="az"
        x1={3860.62}
        x2={4178.33}
        y1={305.08}
        y2={305.08}
      />
      <LinearGradient
        xlinkHref="#c"
        id="aA"
        x1={609.81}
        x2={755.37}
        y1={1344.77}
        y2={1344.77}
      />
      <LinearGradient
        xlinkHref="#c"
        id="aB"
        x1={703.43}
        x2={848.99}
        y1={1290.33}
        y2={1290.33}
      />
      <LinearGradient xlinkHref="#u" id="aC" x1={3985.98} x2={4075.03} />
      <LinearGradient xlinkHref="#v" id="aD" x1={3985.97} x2={4062.17} />
      <LinearGradient
        xlinkHref="#w"
        id="aE"
        x1={4043.66}
        x2={4043.66}
        y1={-16.68}
        y2={348.24}
      />
      <LinearGradient
        xlinkHref="#x"
        id="aF"
        x1={-1532.1}
        x2={-1187.35}
        y1={-458.88}
        y2={-458.88}
      />
      <LinearGradient xlinkHref="#y" id="aG" x1={3066.43} x2={2231.93} />
      <LinearGradient xlinkHref="#z" id="aH" x1={3880.32} x2={4167.44} />
      <LinearGradient
        xlinkHref="#d"
        id="aI"
        x1={4497.45}
        x2={3655.39}
        y1={232.92}
        y2={232.92}
      />
      <LinearGradient
        xlinkHref="#A"
        id="aJ"
        x1={4073.03}
        x2={4727.42}
        y1={197.56}
        y2={197.56}
      />
      <LinearGradient xlinkHref="#B" id="aK" x1={4331.8} x2={4385.64} />
      <LinearGradient xlinkHref="#C" id="aL" x1={4244.64} x2={4282.02} />
      <LinearGradient xlinkHref="#D" id="aM" x1={3908.07} x2={3945.45} />
      <LinearGradient
        xlinkHref="#E"
        id="aN"
        x1={242.42}
        x2={5038.17}
        y1={331.66}
        y2={331.66}
      />
      <LinearGradient
        xlinkHref="#d"
        id="aO"
        x1={3853.91}
        x2={2169.65}
        y1={231.17}
        y2={231.17}
      />
      <LinearGradient xlinkHref="#F" id="aP" x1={3872.97} x2={3872.97} />
      <LinearGradient xlinkHref="#G" id="aQ" x1={3872.97} x2={3872.97} />
      <LinearGradient
        xlinkHref="#H"
        id="aR"
        x1={4215.64}
        x2={4510.66}
        y1={273.58}
        y2={273.58}
      />
      <LinearGradient
        xlinkHref="#e"
        id="aS"
        x1={3202.94}
        x2={5237.06}
        y1={309.54}
        y2={309.54}
      />
      <LinearGradient xlinkHref="#f" id="aT" x1={-345.14} x2={-256.07} />
      <LinearGradient xlinkHref="#g" id="aU" x1={-345.13} x2={-268.92} />
      <LinearGradient xlinkHref="#h" id="aV" x1={-282.32} x2={-193.25} />
      <LinearGradient xlinkHref="#i" id="aW" x1={-282.31} x2={-206.1} />
      <LinearGradient
        xlinkHref="#j"
        id="aX"
        x1={-197.68}
        x2={-197.68}
        y1={-6.44}
        y2={358.48}
      />
      <LinearGradient
        xlinkHref="#k"
        id="aY"
        x1={3657.63}
        x2={4002.38}
        y1={323.22}
        y2={323.22}
      />
      <LinearGradient xlinkHref="#l" id="aZ" x1={7334.7} x2={6500.21} />
      <LinearGradient xlinkHref="#m" id="ba" x1={-387.96} x2={-100.84} />
      <LinearGradient
        xlinkHref="#n"
        id="bb"
        x1={-195.25}
        x2={501.54}
        y1={355.55}
        y2={355.55}
      />
      <LinearGradient
        xlinkHref="#I"
        id="bc"
        x1={20.44}
        x2={326.82}
        y1={367.55}
        y2={367.55}
      />
      <LinearGradient
        xlinkHref="#J"
        id="bd"
        x1={119.75}
        x2={236.31}
        y1={57.07}
        y2={57.07}
      />
      <LinearGradient
        xlinkHref="#K"
        id="be"
        x1={160.99}
        x2={245.49}
        y1={-222.01}
        y2={-222.01}
      />
      <LinearGradient
        xlinkHref="#L"
        id="bf"
        x1={152.96}
        x2={190.05}
        y1={384.12}
        y2={384.12}
      />
      <LinearGradient
        xlinkHref="#M"
        id="bg"
        x1={63.52}
        x2={117.36}
        y1={305.08}
        y2={305.08}
      />
      <LinearGradient
        xlinkHref="#N"
        id="bh"
        x1={-23.65}
        x2={13.74}
        y1={286.5}
        y2={286.5}
      />
      <LinearGradient xlinkHref="#o" id="bi" x1={-360.22} x2={-322.83} />
      <LinearGradient
        xlinkHref="#O"
        id="bj"
        x1={-115.57}
        x2={329.34}
        y1={596.39}
        y2={596.39}
      />
      <LinearGradient
        id="U"
        x1={-115.57}
        x2={329.34}
        y1={571.58}
        y2={571.58}
        gradientTransform="rotate(-173.76 1808.534 360.951)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#96b83f" />
        <Stop offset={1} stopColor="#5f781c" />
      </LinearGradient>
      <LinearGradient xlinkHref="#p" id="bk" x1={3965.41} x2={3965.41} />
      <LinearGradient
        xlinkHref="#P"
        id="bl"
        x1={483.01}
        x2={754.08}
        y1={278.27}
        y2={278.27}
      />
      <LinearGradient xlinkHref="#q" id="bm" x1={2801.56} x2={2890.62} />
      <LinearGradient xlinkHref="#r" id="bn" x1={2801.56} x2={2877.76} />
      <LinearGradient xlinkHref="#e" id="bo" x1={1452.85} x2={3547.33} />
      <LinearGradient xlinkHref="#s" id="bp" x1={2801.73} x2={3234.6} />
      <LinearGradient
        xlinkHref="#a"
        id="bq"
        x1={3075.76}
        x2={3354.63}
        y1={395.95}
        y2={395.95}
      />
      <LinearGradient xlinkHref="#t" id="br" x1={2907.36} x2={3115.87} />
      <LinearGradient
        id="bs"
        x1={3334.75}
        x2={3392.78}
        y1={374.33}
        y2={374.33}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#acc955" />
        <Stop offset={0.14} stopColor="#9fb951" />
        <Stop offset={0.51} stopColor="#80964a" />
        <Stop offset={0.81} stopColor="#6d8045" />
        <Stop offset={1} stopColor="#677844" />
      </LinearGradient>
      <LinearGradient xlinkHref="#b" id="bt" x1={3048.77} x2={3366.47} />
      <LinearGradient
        xlinkHref="#c"
        id="bu"
        x1={1731.4}
        x2={1876.96}
        y1={1344.77}
        y2={1344.77}
      />
      <LinearGradient
        id="bv"
        x1={-4583.59}
        x2={-4583.59}
        y1={483.16}
        y2={366.24}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#95cce9" />
        <Stop offset={0.33} stopColor="#92cae6" />
        <Stop offset={0.67} stopColor="#8bc2de" />
        <Stop offset={1} stopColor="#80b7d0" />
      </LinearGradient>
      <LinearGradient
        id="bw"
        x1={-4591.57}
        x2={-4591.57}
        y1={478.08}
        y2={366.23}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="bx"
        x1={-4588.91}
        x2={-4588.91}
        y1={478.08}
        y2={366.23}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="by"
        x1={-4586.25}
        x2={-4586.25}
        y1={478.08}
        y2={366.23}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="bz"
        x1={-4583.58}
        x2={-4583.58}
        y1={478.08}
        y2={366.23}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="bA"
        x1={-4580.92}
        x2={-4580.92}
        y1={478.08}
        y2={366.23}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="bB"
        x1={-4578.25}
        x2={-4578.25}
        y1={478.08}
        y2={366.23}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="bC"
        x1={-4575.59}
        x2={-4575.59}
        y1={478.08}
        y2={366.23}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="bD"
        x1={7597.04}
        x2={7597.04}
        y1={366.24}
        y2={487.67}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.18} stopColor="#435870" />
        <Stop offset={0.37} stopColor="#4d647c" />
        <Stop offset={0.56} stopColor="#5a7a91" />
        <Stop offset={0.75} stopColor="#6e98ae" />
        <Stop offset={0.94} stopColor="#81b7cc" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bE"
        x1={7614.21}
        x2={7614.21}
        y1={366.24}
        y2={492.31}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#88c1d5" />
        <Stop offset={0.14} stopColor="#90c5d7" />
        <Stop offset={0.37} stopColor="#a8d0df" />
        <Stop offset={0.66} stopColor="#cbe1ec" />
        <Stop offset={0.99} stopColor="#fbf9fe" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="bF"
        x1={7612.76}
        x2={7612.76}
        y1={366.24}
        y2={487.67}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.11} stopColor="#475970" />
        <Stop offset={0.25} stopColor="#5a697f" />
        <Stop offset={0.41} stopColor="#788396" />
        <Stop offset={0.59} stopColor="#a1a8b6" />
        <Stop offset={0.77} stopColor="#c7cbd5" />
        <Stop offset={0.96} stopColor="#f4f2f7" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="bG"
        x1={7612.76}
        x2={7612.76}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bH"
        x1={7612.76}
        x2={7612.76}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bI"
        x1={7612.76}
        x2={7612.76}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bJ"
        x1={7612.76}
        x2={7612.76}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bK"
        x1={7612.76}
        x2={7612.76}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bL"
        x1={7612.76}
        x2={7612.76}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bM"
        x1={7612.76}
        x2={7612.76}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bN"
        x1={7612.76}
        x2={7612.76}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bO"
        x1={7612.76}
        x2={7612.76}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bP"
        x1={7612.76}
        x2={7612.76}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bQ"
        x1={7612.76}
        x2={7612.76}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bR"
        x1={7612.76}
        x2={7612.76}
        y1={374.55}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bS"
        x1={7604.81}
        x2={7604.81}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bT"
        x1={7616.44}
        x2={7616.44}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bU"
        x1={7610.52}
        x2={7610.52}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bV"
        x1={7620.61}
        x2={7620.61}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bW"
        x1={7604.69}
        x2={7604.69}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bX"
        x1={7615}
        x2={7615}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bY"
        x1={7609.22}
        x2={7609.22}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="bZ"
        x1={7619.52}
        x2={7619.52}
        y1={374.54}
        y2={485.61}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="ca"
        x1={7612.76}
        x2={7612.76}
        y1={361.66}
        y2={472.72}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="cb"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cc"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cd"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="ce"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cf"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cg"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="ch"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="ci"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cj"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="ck"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cl"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cm"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cn"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="co"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cp"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cq"
        x1={7597.04}
        x2={7597.04}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cr"
        x1={7597.04}
        x2={7597.04}
        y1={365.61}
        y2={471}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cs"
        x1={7621.99}
        x2={7603.15}
        y1={457.24}
        y2={469.46}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fff" />
        <Stop offset={1} stopColor="#fff" />
      </LinearGradient>
      <LinearGradient
        id="ct"
        x1={7621}
        x2={7604.18}
        y1={446.96}
        y2={457.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fff" />
        <Stop offset={1} stopColor="#fff" />
      </LinearGradient>
      <LinearGradient
        id="cu"
        x1={7555.7}
        x2={7555.7}
        y1={366.24}
        y2={486.17}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.18} stopColor="#435870" />
        <Stop offset={0.37} stopColor="#4d647c" />
        <Stop offset={0.56} stopColor="#5a7a91" />
        <Stop offset={0.75} stopColor="#6e98ae" />
        <Stop offset={0.94} stopColor="#81b7cc" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="cv"
        x1={7572.87}
        x2={7572.87}
        y1={366.24}
        y2={502.78}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#88c1d5" />
        <Stop offset={0.17} stopColor="#8cc3d5" />
        <Stop offset={0.35} stopColor="#97c8d9" />
        <Stop offset={0.53} stopColor="#abd2e0" />
        <Stop offset={0.71} stopColor="#c6dfea" />
        <Stop offset={0.89} stopColor="#e7eff6" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="cw"
        x1={7571.42}
        x2={7571.42}
        y1={366.24}
        y2={499.58}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.1} stopColor="#4b5c73" />
        <Stop offset={0.26} stopColor="#677488" />
        <Stop offset={0.47} stopColor="#929aaa" />
        <Stop offset={0.71} stopColor="#c2c5d0" />
        <Stop offset={0.97} stopColor="#f6f4f9" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="cx"
        x1={7562.94}
        x2={7569.23}
        y1={504.92}
        y2={490.48}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="cy"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cz"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cA"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cB"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cC"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cD"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cE"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cF"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cG"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cH"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cI"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cJ"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.89}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cK"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cL"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cM"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cN"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cO"
        x1={7555.7}
        x2={7555.7}
        y1={378.49}
        y2={483.88}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cP"
        x1={7555.7}
        x2={7555.7}
        y1={365.61}
        y2={471}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#44596c" />
        <Stop offset={0.33} stopColor="#465c70" />
        <Stop offset={0.68} stopColor="#4d677c" />
        <Stop offset={1} stopColor="#59778f" />
      </LinearGradient>
      <LinearGradient
        id="cQ"
        x1={-1806.49}
        x2={-1806.49}
        y1={373.06}
        y2={485.12}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="cR"
        x1={-1806.49}
        x2={-1806.49}
        y1={373.06}
        y2={485.12}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="cS"
        x1={-1806.49}
        x2={-1806.49}
        y1={373.06}
        y2={485.12}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="cT"
        x1={-1806.49}
        x2={-1806.49}
        y1={360.18}
        y2={472.23}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="cU"
        x1={-1806.49}
        x2={-1806.49}
        y1={373.06}
        y2={485.12}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="cV"
        x1={-1806.49}
        x2={-1806.49}
        y1={373.06}
        y2={485.12}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="cW"
        x1={-1806.49}
        x2={-1806.49}
        y1={373.06}
        y2={485.11}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="cX"
        x1={-1806.49}
        x2={-1806.49}
        y1={373.06}
        y2={485.11}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="cY"
        x1={-1806.49}
        x2={-1806.49}
        y1={373.06}
        y2={485.11}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="cZ"
        x1={-1806.49}
        x2={-1806.49}
        y1={373.06}
        y2={485.11}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="da"
        x1={-1806.49}
        x2={-1806.49}
        y1={373.06}
        y2={485.11}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="db"
        x1={-1806.49}
        x2={-1806.49}
        y1={373.06}
        y2={485.11}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="dc"
        x1={-1806.49}
        x2={-1806.49}
        y1={373.06}
        y2={485.11}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="dd"
        x1={-1806.49}
        x2={-1806.49}
        y1={373.06}
        y2={485.11}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="de"
        x1={-1806.49}
        x2={-1806.49}
        y1={373.06}
        y2={485.11}
        gradientTransform="matrix(-1.67 0 0 -1 128.87 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="df"
        x1={7568.11}
        x2={7568.11}
        y1={373.07}
        y2={485.11}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="dg"
        x1={7578.6}
        x2={7578.6}
        y1={373.07}
        y2={485.11}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="dh"
        x1={7564.28}
        x2={7564.28}
        y1={373.06}
        y2={485.11}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="di"
        x1={7574.6}
        x2={7574.6}
        y1={373.06}
        y2={485.11}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="dj"
        x1={7567}
        x2={7567}
        y1={373.06}
        y2={485.11}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="dk"
        x1={7577.29}
        x2={7577.29}
        y1={373.06}
        y2={485.11}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#41536c" />
        <Stop offset={0.15} stopColor="#465c74" />
        <Stop offset={0.4} stopColor="#58768d" />
        <Stop offset={0.71} stopColor="#729fb4" />
        <Stop offset={1} stopColor="#88c1d5" />
      </LinearGradient>
      <LinearGradient
        id="dl"
        x1={7580.91}
        x2={7564.9}
        y1={477.47}
        y2={487.86}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fff" />
        <Stop offset={1} stopColor="#fff" />
      </LinearGradient>
      <LinearGradient
        id="dm"
        x1={7579.66}
        x2={7562.84}
        y1={467.37}
        y2={478.28}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fff" />
        <Stop offset={1} stopColor="#fff" />
      </LinearGradient>
      <LinearGradient
        id="dn"
        x1={-4722.59}
        x2={-4722.59}
        y1={366.24}
        y2={454.32}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="do"
        x1={-4706.19}
        x2={-4706.19}
        y1={366.24}
        y2={454.32}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#80bfd5" />
      </LinearGradient>
      <LinearGradient
        id="dp"
        x1={-4722.59}
        x2={-4722.59}
        y1={501.65}
        y2={429.56}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="dq"
        x1={-4722.59}
        x2={-4722.59}
        y1={487.99}
        y2={415.91}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="dr"
        x1={-4722.59}
        x2={-4722.59}
        y1={474.34}
        y2={402.25}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="ds"
        x1={-4722.59}
        x2={-4722.59}
        y1={460.68}
        y2={388.59}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="dt"
        x1={-4722.59}
        x2={-4722.59}
        y1={447.03}
        y2={374.94}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="du"
        x1={-4722.59}
        x2={-4722.59}
        y1={433.37}
        y2={361.28}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="dv"
        x1={-4722.59}
        x2={-4722.59}
        y1={419.71}
        y2={347.62}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="dw"
        x1={-4722.59}
        x2={-4722.59}
        y1={406.06}
        y2={333.97}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="dx"
        x1={-4722.59}
        x2={-4722.59}
        y1={392.4}
        y2={320.31}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="dy"
        x1={-4722.59}
        x2={-4722.59}
        y1={378.74}
        y2={306.65}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="dz"
        x1={-4722.59}
        x2={-4722.59}
        y1={515.31}
        y2={443.22}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="dA"
        x1={-4722.59}
        x2={-4722.59}
        y1={378.74}
        y2={306.65}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="dB"
        x1={-4706.19}
        x2={-4706.19}
        y1={377.28}
        y2={445.23}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dC"
        x1={-4706.19}
        x2={-4706.19}
        y1={377.28}
        y2={445.23}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dD"
        x1={-4706.19}
        x2={-4706.19}
        y1={377.28}
        y2={445.23}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dE"
        x1={-4706.19}
        x2={-4706.19}
        y1={377.28}
        y2={445.23}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dF"
        x1={-4706.19}
        x2={-4706.19}
        y1={377.28}
        y2={445.23}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dG"
        x1={-4706.19}
        x2={-4706.19}
        y1={377.28}
        y2={445.23}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dH"
        x1={-4706.19}
        x2={-4706.19}
        y1={377.28}
        y2={445.23}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dI"
        x1={-4706.19}
        x2={-4706.19}
        y1={377.28}
        y2={445.23}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dJ"
        x1={-4706.19}
        x2={-4706.19}
        y1={377.28}
        y2={445.23}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dK"
        x1={-4706.19}
        x2={-4706.19}
        y1={377.28}
        y2={445.23}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dL"
        x1={-4706.19}
        x2={-4706.19}
        y1={377.28}
        y2={445.23}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dM"
        x1={-4706.19}
        x2={-4706.19}
        y1={377.28}
        y2={445.23}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dN"
        x1={-4730.54}
        x2={-4714.64}
        y1={457.51}
        y2={457.51}
        gradientTransform="rotate(-180 -801.285 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="dO"
        x1={-4410.3}
        x2={-4410.3}
        y1={370.04}
        y2={443.91}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dP"
        x1={10956.04}
        x2={10956.04}
        y1={375.84}
        y2={449.14}
        gradientTransform="matrix(.94 0 0 -1 -7178.65 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dQ"
        x1={10956.04}
        x2={10956.04}
        y1={375.84}
        y2={449.14}
        gradientTransform="matrix(.94 0 0 -1 -7178.65 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dR"
        x1={10956.04}
        x2={10956.04}
        y1={375.84}
        y2={449.14}
        gradientTransform="matrix(.94 0 0 -1 -7178.65 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dS"
        x1={10956.04}
        x2={10956.04}
        y1={375.84}
        y2={449.14}
        gradientTransform="matrix(.94 0 0 -1 -7178.65 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dT"
        x1={10956.04}
        x2={10956.04}
        y1={375.84}
        y2={449.14}
        gradientTransform="matrix(.94 0 0 -1 -7178.65 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dU"
        x1={10956.04}
        x2={10956.04}
        y1={375.84}
        y2={449.14}
        gradientTransform="matrix(.94 0 0 -1 -7178.65 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dV"
        x1={10956.04}
        x2={10956.04}
        y1={375.84}
        y2={449.14}
        gradientTransform="matrix(.94 0 0 -1 -7178.65 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dW"
        x1={10956.04}
        x2={10956.04}
        y1={375.84}
        y2={449.14}
        gradientTransform="matrix(.94 0 0 -1 -7178.65 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dX"
        x1={10956.04}
        x2={10956.04}
        y1={375.84}
        y2={449.14}
        gradientTransform="matrix(.94 0 0 -1 -7178.65 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dY"
        x1={10956.04}
        x2={10956.04}
        y1={375.84}
        y2={449.14}
        gradientTransform="matrix(.94 0 0 -1 -7178.65 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="dZ"
        x1={10956.04}
        x2={10956.04}
        y1={375.84}
        y2={449.14}
        gradientTransform="matrix(.94 0 0 -1 -7178.65 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="ea"
        x1={10956.04}
        x2={10956.04}
        y1={375.84}
        y2={449.14}
        gradientTransform="matrix(.94 0 0 -1 -7178.65 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eb"
        x1={-4524.61}
        x2={-4524.61}
        y1={431.14}
        y2={366.24}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#8bd0e7" />
        <Stop offset={0.34} stopColor="#89cce3" />
        <Stop offset={0.7} stopColor="#85c3db" />
        <Stop offset={1} stopColor="#80b7d0" />
      </LinearGradient>
      <LinearGradient
        id="ec"
        x1={14356.99}
        x2={14356.99}
        y1={366.24}
        y2={431.14}
        gradientTransform="matrix(.52 0 0 -1 -4262.24 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#70a2b4" />
        <Stop offset={0.32} stopColor="#72a5b8" />
        <Stop offset={0.65} stopColor="#77acc1" />
        <Stop offset={0.99} stopColor="#7fb6d0" />
        <Stop offset={1} stopColor="#80b7d0" />
      </LinearGradient>
      <LinearGradient
        id="ed"
        x1={14455.75}
        x2={14455.75}
        y1={366.24}
        y2={483.16}
        gradientTransform="matrix(.52 0 0 -1 -4262.24 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#70a2b4" />
        <Stop offset={0.32} stopColor="#72a4b8" />
        <Stop offset={0.65} stopColor="#78aac1" />
        <Stop offset={0.99} stopColor="#81b3d0" />
        <Stop offset={1} stopColor="#82b4d0" />
      </LinearGradient>
      <LinearGradient
        id="ee"
        x1={-4612.73}
        x2={-4612.73}
        y1={457.39}
        y2={366.24}
        gradientTransform="rotate(-180 -655.74 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#95cce9" />
        <Stop offset={0.33} stopColor="#92cae6" />
        <Stop offset={0.67} stopColor="#8bc2de" />
        <Stop offset={1} stopColor="#80b7d0" />
      </LinearGradient>
      <LinearGradient
        id="ef"
        x1={14511.79}
        x2={14511.79}
        y1={366.23}
        y2={457.38}
        gradientTransform="matrix(.52 0 0 -1 -4262.24 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#70a2b4" />
        <Stop offset={0.32} stopColor="#72a4b8" />
        <Stop offset={0.65} stopColor="#78aac1" />
        <Stop offset={0.99} stopColor="#81b3d0" />
        <Stop offset={1} stopColor="#82b4d0" />
      </LinearGradient>
      <LinearGradient
        id="eg"
        x1={7668.37}
        x2={7668.37}
        y1={366.24}
        y2={462.36}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="eh"
        x1={7668.37}
        x2={7668.37}
        y1={375.44}
        y2={457.08}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="ei"
        x1={7668.37}
        x2={7668.37}
        y1={375.44}
        y2={457.08}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="ej"
        x1={7668.37}
        x2={7668.37}
        y1={375.44}
        y2={457.08}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="ek"
        x1={7668.37}
        x2={7668.37}
        y1={375.44}
        y2={457.08}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="el"
        x1={7668.37}
        x2={7668.37}
        y1={375.44}
        y2={457.08}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="em"
        x1={7668.37}
        x2={7668.37}
        y1={375.44}
        y2={457.08}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="en"
        x1={7668.37}
        x2={7668.37}
        y1={375.44}
        y2={457.08}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="eo"
        x1={7668.37}
        x2={7668.37}
        y1={375.44}
        y2={457.08}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="ep"
        x1={7668.37}
        x2={7668.37}
        y1={375.44}
        y2={457.08}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="eq"
        x1={7668.37}
        x2={7668.37}
        y1={375.44}
        y2={457.08}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="er"
        x1={7668.37}
        x2={7668.37}
        y1={375.44}
        y2={457.08}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="es"
        x1={7668.37}
        x2={7668.37}
        y1={375.44}
        y2={457.08}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="et"
        x1={7668.37}
        x2={7668.37}
        y1={375.44}
        y2={457.08}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="eu"
        x1={7668.37}
        x2={7668.37}
        y1={361.78}
        y2={443.42}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#73a8c5" />
      </LinearGradient>
      <LinearGradient
        id="ev"
        x1={7671.48}
        x2={7658.61}
        y1={459.79}
        y2={470.43}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="ew"
        x1={7651.97}
        x2={7651.97}
        y1={366.24}
        y2={462.36}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#54758f" />
        <Stop offset={1} stopColor="#80bfd5" />
      </LinearGradient>
      <LinearGradient
        id="ex"
        x1={7668.37}
        x2={7668.37}
        y1={371}
        y2={454.87}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="ey"
        x1={7668.37}
        x2={7668.37}
        y1={371}
        y2={454.87}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="ez"
        x1={7668.37}
        x2={7668.37}
        y1={371}
        y2={454.87}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eA"
        x1={7668.37}
        x2={7668.37}
        y1={371}
        y2={454.87}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eB"
        x1={7668.37}
        x2={7668.37}
        y1={371}
        y2={454.87}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eC"
        x1={7668.37}
        x2={7668.37}
        y1={371}
        y2={454.87}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eD"
        x1={7668.37}
        x2={7668.37}
        y1={371}
        y2={454.87}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eE"
        x1={7668.37}
        x2={7668.37}
        y1={371}
        y2={454.87}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eF"
        x1={7668.37}
        x2={7668.37}
        y1={371}
        y2={454.87}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eG"
        x1={7668.37}
        x2={7668.37}
        y1={371}
        y2={454.87}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eH"
        x1={7668.37}
        x2={7668.37}
        y1={371}
        y2={454.87}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eI"
        x1={7668.37}
        x2={7668.37}
        y1={371}
        y2={454.87}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eJ"
        x1={7668.37}
        x2={7668.37}
        y1={357.34}
        y2={441.21}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eK"
        x1={7651.97}
        x2={7651.97}
        y1={370.69}
        y2={456.37}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eL"
        x1={7651.97}
        x2={7651.97}
        y1={370.69}
        y2={456.37}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eM"
        x1={7651.97}
        x2={7651.97}
        y1={370.69}
        y2={456.37}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eN"
        x1={7651.97}
        x2={7651.97}
        y1={370.69}
        y2={456.37}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eO"
        x1={7651.97}
        x2={7651.97}
        y1={370.69}
        y2={456.37}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eP"
        x1={7651.97}
        x2={7651.97}
        y1={370.69}
        y2={456.37}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eQ"
        x1={7651.97}
        x2={7651.97}
        y1={370.69}
        y2={456.37}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eR"
        x1={7651.97}
        x2={7651.97}
        y1={370.69}
        y2={456.37}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eS"
        x1={7651.97}
        x2={7651.97}
        y1={370.69}
        y2={456.37}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eT"
        x1={7651.97}
        x2={7651.97}
        y1={370.69}
        y2={456.37}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eU"
        x1={7651.97}
        x2={7651.97}
        y1={370.69}
        y2={456.37}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eV"
        x1={7651.97}
        x2={7651.97}
        y1={370.69}
        y2={456.37}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eW"
        x1={7651.97}
        x2={7651.97}
        y1={370.69}
        y2={456.37}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eX"
        x1={7651.97}
        x2={7651.97}
        y1={357.03}
        y2={442.72}
        gradientTransform="matrix(1 0 0 -1 -4425.72 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#40576c" />
        <Stop offset={0.33} stopColor="#425a70" />
        <Stop offset={0.68} stopColor="#49647c" />
        <Stop offset={1} stopColor="#54758f" />
      </LinearGradient>
      <LinearGradient
        id="eY"
        x1={-4575.76}
        x2={-4575.76}
        y1={489.04}
        y2={476.75}
        gradientTransform="rotate(-180 -655.74 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#95cce9" />
        <Stop offset={0.33} stopColor="#92cae6" />
        <Stop offset={0.67} stopColor="#8bc2de" />
        <Stop offset={1} stopColor="#80b7d0" />
      </LinearGradient>
      <LinearGradient
        id="eZ"
        x1={10997.18}
        x2={10997.18}
        y1={489.04}
        y2={476.75}
        gradientTransform="matrix(.8 0 0 -1 -5541.58 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#71a2bf" />
        <Stop offset={1} stopColor="#85b7d6" />
      </LinearGradient>
      <LinearGradient
        id="fa"
        x1={-4604.9}
        x2={-4604.9}
        y1={463.26}
        y2={450.98}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#95cce9" />
        <Stop offset={0.33} stopColor="#92cae6" />
        <Stop offset={0.67} stopColor="#8bc2de" />
        <Stop offset={1} stopColor="#80b7d0" />
      </LinearGradient>
      <LinearGradient
        id="fb"
        x1={11033.6}
        x2={11033.6}
        y1={463.26}
        y2={450.98}
        gradientTransform="matrix(.8 0 0 -1 -5541.58 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#71a2bf" />
        <Stop offset={1} stopColor="#85b7d6" />
      </LinearGradient>
      <LinearGradient
        id="fc"
        x1={-4620.72}
        x2={-4620.72}
        y1={452.31}
        y2={366.24}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="fd"
        x1={-4618.05}
        x2={-4618.05}
        y1={452.31}
        y2={366.24}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="fe"
        x1={-4615.39}
        x2={-4615.39}
        y1={452.31}
        y2={366.24}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="ff"
        x1={-4612.72}
        x2={-4612.72}
        y1={452.31}
        y2={366.24}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="fg"
        x1={-4610.06}
        x2={-4610.06}
        y1={452.31}
        y2={366.24}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="fh"
        x1={-4607.4}
        x2={-4607.4}
        y1={452.31}
        y2={366.24}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="fi"
        x1={-4604.73}
        x2={-4604.73}
        y1={452.31}
        y2={366.24}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="fj"
        x1={-4528.18}
        x2={-4528.18}
        y1={426.14}
        y2={366.24}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="fk"
        x1={-4524.72}
        x2={-4524.72}
        y1={426.14}
        y2={366.24}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="fl"
        x1={-4521.26}
        x2={-4521.26}
        y1={426.14}
        y2={366.24}
        gradientTransform="rotate(-180 -655.735 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fdfaff" />
        <Stop offset={1} stopColor="#fdfaff" />
      </LinearGradient>
      <LinearGradient
        id="fm"
        x1={-250.3}
        x2={-250.3}
        y1={460.02}
        y2={394.78}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#e49355" />
        <Stop offset={0.44} stopColor="#de8c4d" />
        <Stop offset={1} stopColor="#d57e3b" />
      </LinearGradient>
      <LinearGradient
        id="fn"
        x1={-236.67}
        x2={-236.67}
        y1={455.44}
        y2={445.25}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#e49355" />
        <Stop offset={0.44} stopColor="#de8c4d" />
        <Stop offset={1} stopColor="#d57e3b" />
      </LinearGradient>
      <LinearGradient
        id="fo"
        x1={-10.92}
        x2={10.4}
        y1={406.45}
        y2={347.79}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#e49355" />
        <Stop offset={0.44} stopColor="#de8c4d" />
        <Stop offset={1} stopColor="#d57e3b" />
      </LinearGradient>
      <LinearGradient
        id="fp"
        x1={-0.38}
        x2={20.93}
        y1={410.27}
        y2={351.62}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#e49355" />
        <Stop offset={0.44} stopColor="#de8c4d" />
        <Stop offset={1} stopColor="#d57e3b" />
      </LinearGradient>
      <LinearGradient
        id="fq"
        x1={-63.66}
        x2={-63.66}
        y1={433.78}
        y2={357.62}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#e49355" />
        <Stop offset={0.44} stopColor="#de8c4d" />
        <Stop offset={1} stopColor="#d57e3b" />
      </LinearGradient>
      <LinearGradient
        id="fr"
        x1={-84.76}
        x2={-72.1}
        y1={424.35}
        y2={424.35}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fff" />
        <Stop offset={0.32} stopColor="#fcfcfc" />
        <Stop offset={0.64} stopColor="#f3f3f3" />
        <Stop offset={0.98} stopColor="#e5e5e5" />
        <Stop offset={1} stopColor="#e4e4e4" />
      </LinearGradient>
      <LinearGradient
        id="fs"
        x1={-30.23}
        x2={-30.23}
        y1={350.88}
        y2={409.08}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#e58944" />
        <Stop offset={0.25} stopColor="#e58d46" />
        <Stop offset={0.5} stopColor="#e69950" />
        <Stop offset={0.76} stopColor="#e7af5f" />
        <Stop offset={1} stopColor="#e9c973" />
      </LinearGradient>
      <LinearGradient
        id="ft"
        x1={-169.74}
        x2={-169.74}
        y1={378.75}
        y2={432.97}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#eebf6e" />
        <Stop offset={1} stopColor="#faeb9d" />
      </LinearGradient>
      <LinearGradient
        id="fu"
        x1={-190.13}
        x2={-190.13}
        y1={436.79}
        y2={376.14}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#e9ba58" />
        <Stop offset={1} stopColor="#d69642" />
      </LinearGradient>
      <LinearGradient
        id="fv"
        x1={-172.79}
        x2={-172.79}
        y1={436.79}
        y2={376.14}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#e9ba58" />
        <Stop offset={1} stopColor="#d69642" />
      </LinearGradient>
      <LinearGradient
        id="fw"
        x1={-178.98}
        x2={-178.98}
        y1={436.79}
        y2={376.15}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#e9ba58" />
        <Stop offset={1} stopColor="#d69642" />
      </LinearGradient>
      <LinearGradient
        id="fx"
        x1={-169.87}
        x2={-169.87}
        y1={378.75}
        y2={432.97}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#eebf6e" />
        <Stop offset={1} stopColor="#faeb9d" />
      </LinearGradient>
      <LinearGradient
        id="fy"
        x1={-180.39}
        x2={-180.39}
        y1={378.75}
        y2={432.97}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#eebf6e" />
        <Stop offset={1} stopColor="#faeb9d" />
      </LinearGradient>
      <LinearGradient
        id="fz"
        x1={-182.53}
        x2={-182.53}
        y1={436.79}
        y2={376.14}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#e9ba58" />
        <Stop offset={1} stopColor="#d69642" />
      </LinearGradient>
      <LinearGradient
        id="fA"
        x1={-277.33}
        x2={-269.4}
        y1={398.75}
        y2={413.18}
        gradientTransform="rotate(-180 1540.405 273.945)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fff" />
        <Stop offset={0.32} stopColor="#fcfcfc" />
        <Stop offset={0.64} stopColor="#f3f3f3" />
        <Stop offset={0.98} stopColor="#e5e5e5" />
        <Stop offset={1} stopColor="#e4e4e4" />
      </LinearGradient>
      <LinearGradient xlinkHref="#u" id="fB" x1={2864.38} x2={2953.44} />
      <LinearGradient xlinkHref="#v" id="fC" x1={2864.38} x2={2940.58} />
      <LinearGradient xlinkHref="#w" id="fD" x1={2949.01} x2={2949.01} />
      <LinearGradient
        xlinkHref="#x"
        id="fE"
        x1={-166.89}
        x2={177.86}
        y1={-252.91}
        y2={-252.91}
      />
      <LinearGradient xlinkHref="#y" id="fF" x1={4188.02} x2={3353.52} />
      <LinearGradient xlinkHref="#z" id="fG" x1={2758.73} x2={3045.85} />
      <LinearGradient xlinkHref="#A" id="fH" x1={2951.44} x2={3649.98} />
      <LinearGradient
        id="T"
        x1={3134.89}
        x2={3513.84}
        y1={365.07}
        y2={365.07}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0.64} stopColor="#798e4a" />
        <Stop offset={1} stopColor="#a3c440" />
      </LinearGradient>
      <LinearGradient
        id="Q"
        x1={3261.91}
        x2={3378.47}
        y1={225.86}
        y2={225.86}
        gradientTransform="scale(1 -1) rotate(-3.17 -5412.871 159.995)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#718d21" />
        <Stop offset={0.41} stopColor="#556b1d" />
        <Stop offset={0.77} stopColor="#43551a" />
        <Stop offset={0.99} stopColor="#3d4d1a" />
      </LinearGradient>
      <LinearGradient
        id="R"
        x1={3284.99}
        x2={3369.49}
        y1={155.21}
        y2={155.21}
        gradientTransform="scale(1 -1) rotate(-6.98 -59.946 149.072)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#87a72a" />
        <Stop offset={0} stopColor="#86a529" />
        <Stop offset={0.43} stopColor="#688227" />
        <Stop offset={0.78} stopColor="#566c25" />
        <Stop offset={1} stopColor="#506425" />
      </LinearGradient>
      <LinearGradient
        xlinkHref="#Q"
        id="fI"
        x1={3327.73}
        x2={3444.29}
        y1={229.51}
        y2={229.51}
      />
      <LinearGradient
        xlinkHref="#R"
        id="fJ"
        x1={3350.42}
        x2={3434.93}
        y1={163.22}
        y2={163.22}
      />
      <LinearGradient
        id="V"
        x1={3299.65}
        x2={3336.74}
        y1={378.9}
        y2={378.9}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#a3c935" />
        <Stop offset={0.42} stopColor="#a8c357" />
        <Stop offset={0.64} stopColor="#8ca747" />
        <Stop offset={0.86} stopColor="#77913b" />
        <Stop offset={1} stopColor="#708937" />
      </LinearGradient>
      <LinearGradient xlinkHref="#B" id="fK" x1={3210.21} x2={3264.05} />
      <LinearGradient xlinkHref="#C" id="fL" x1={3123.04} x2={3160.43} />
      <LinearGradient xlinkHref="#D" id="fM" x1={2786.47} x2={2823.86} />
      <LinearGradient
        xlinkHref="#E"
        id="fN"
        x1={663.06}
        x2={3727.99}
        y1={234.03}
        y2={234.03}
      />
      <LinearGradient xlinkHref="#F" id="fO" x1={2751.38} x2={2751.38} />
      <LinearGradient xlinkHref="#G" id="fP" x1={2751.38} x2={2751.38} />
      <LinearGradient
        xlinkHref="#H"
        id="fQ"
        x1={3148.92}
        x2={3419.99}
        y1={296.48}
        y2={296.48}
      />
      <LinearGradient
        xlinkHref="#f"
        id="fR"
        x1={1391.67}
        x2={1480.74}
        y1={290.04}
        y2={290.04}
      />
      <LinearGradient
        xlinkHref="#g"
        id="fS"
        x1={1391.68}
        x2={1467.89}
        y1={290.04}
        y2={290.04}
      />
      <LinearGradient
        xlinkHref="#h"
        id="fT"
        x1={1454.49}
        x2={1543.56}
        y1={294.18}
        y2={294.18}
      />
      <LinearGradient
        xlinkHref="#i"
        id="fU"
        x1={1454.5}
        x2={1530.71}
        y1={294.19}
        y2={294.19}
      />
      <LinearGradient
        xlinkHref="#j"
        id="fV"
        x1={1539.13}
        x2={1539.13}
        y1={-11.66}
        y2={353.26}
      />
      <LinearGradient
        xlinkHref="#k"
        id="fW"
        x1={1544.65}
        x2={1889.4}
        y1={-1.38}
        y2={-1.38}
      />
      <LinearGradient
        xlinkHref="#l"
        id="fX"
        x1={5597.89}
        x2={4763.4}
        y1={333.64}
        y2={333.64}
      />
      <LinearGradient
        xlinkHref="#m"
        id="fY"
        x1={1348.85}
        x2={1635.97}
        y1={326.68}
        y2={326.68}
      />
      <LinearGradient
        xlinkHref="#S"
        id="fZ"
        x1={1989.72}
        x2={1106.3}
        y1={314.11}
        y2={314.11}
      />
      <LinearGradient
        xlinkHref="#n"
        id="ga"
        x1={1541.56}
        x2={2250.53}
        y1={350.33}
        y2={350.33}
      />
      <LinearGradient
        xlinkHref="#I"
        id="gb"
        x1={1757.25}
        x2={2063.63}
        y1={362.33}
        y2={362.33}
      />
      <LinearGradient
        xlinkHref="#T"
        id="gc"
        x1={1453.06}
        x2={1759.44}
        y1={362.38}
        y2={362.38}
      />
      <LinearGradient
        xlinkHref="#J"
        id="gd"
        x1={1854.19}
        x2={1970.75}
        y1={147.9}
        y2={147.9}
      />
      <LinearGradient
        xlinkHref="#K"
        id="ge"
        x1={1885.56}
        x2={1970.06}
        y1={-16.13}
        y2={-16.13}
      />
      <LinearGradient
        xlinkHref="#L"
        id="gf"
        x1={1889.77}
        x2={1926.86}
        y1={378.9}
        y2={378.9}
      />
      <LinearGradient
        xlinkHref="#M"
        id="gg"
        x1={1800.33}
        x2={1854.17}
        y1={299.86}
        y2={299.86}
      />
      <LinearGradient
        xlinkHref="#N"
        id="gh"
        x1={1713.16}
        x2={1750.55}
        y1={281.28}
        y2={281.28}
      />
      <LinearGradient
        xlinkHref="#o"
        id="gi"
        x1={1376.59}
        x2={1413.98}
        y1={270.06}
        y2={270.06}
      />
      <LinearGradient
        xlinkHref="#O"
        id="gj"
        x1={1610.38}
        x2={2055.29}
        y1={402.42}
        y2={402.42}
      />
      <LinearGradient
        xlinkHref="#U"
        id="gk"
        x1={1607.68}
        x2={2203.32}
        y1={374.27}
        y2={374.27}
      />
      <LinearGradient
        xlinkHref="#p"
        id="gl"
        x1={2228.6}
        x2={2228.6}
        y1={252.33}
        y2={296.98}
      />
      <LinearGradient
        xlinkHref="#P"
        id="gm"
        x1={1954.24}
        x2={2225.23}
        y1={285.78}
        y2={285.78}
      />
      <LinearGradient
        xlinkHref="#w"
        id="gn"
        x1={283.31}
        x2={283.31}
        y1={-9.23}
        y2={344.99}
      />
      <LinearGradient
        xlinkHref="#d"
        id="go"
        x1={571.64}
        x2={-312.25}
        y1={315.86}
        y2={315.86}
      />
      <LinearGradient
        xlinkHref="#A"
        id="gp"
        x1={123.48}
        x2={820.26}
        y1={352.07}
        y2={352.07}
      />
      <LinearGradient
        xlinkHref="#E"
        id="gq"
        x1={-2011.69}
        x2={865.29}
        y1={331.17}
        y2={331.17}
      />
      <LinearGradient
        xlinkHref="#H"
        id="gr"
        x1={754.06}
        x2={1025.13}
        y1={276.92}
        y2={276.92}
      />
      <LinearGradient
        xlinkHref="#e"
        id="gs"
        x1={-335.73}
        x2={1186.71}
        y1={310.82}
        y2={310.82}
      />
      <LinearGradient
        xlinkHref="#f"
        id="gt"
        x1={2504.92}
        x2={2593.98}
        y1={291.89}
        y2={291.89}
      />
      <LinearGradient
        xlinkHref="#g"
        id="gu"
        x1={2504.92}
        x2={2581.13}
        y1={291.89}
        y2={291.89}
      />
      <LinearGradient
        xlinkHref="#h"
        id="gv"
        x1={2567.74}
        x2={2656.8}
        y1={296.03}
        y2={296.03}
      />
      <LinearGradient
        xlinkHref="#i"
        id="gw"
        x1={2567.74}
        x2={2643.95}
        y1={296.04}
        y2={296.04}
      />
      <LinearGradient
        xlinkHref="#j"
        id="gx"
        x1={2652.37}
        x2={2652.37}
        y1={-9.81}
        y2={355.11}
      />
      <LinearGradient
        xlinkHref="#k"
        id="gy"
        x1={189.22}
        x2={533.97}
        y1={-203.81}
        y2={-203.81}
      />
      <LinearGradient
        xlinkHref="#l"
        id="gz"
        x1={4484.65}
        x2={3650.16}
        y1={335.49}
        y2={335.49}
      />
      <LinearGradient
        xlinkHref="#m"
        id="gA"
        x1={2462.09}
        x2={2749.21}
        y1={328.53}
        y2={328.53}
      />
      <LinearGradient
        xlinkHref="#S"
        id="gB"
        x1={3102.96}
        x2={2219.54}
        y1={315.96}
        y2={315.96}
      />
      <LinearGradient
        xlinkHref="#n"
        id="gC"
        x1={2654.8}
        x2={3351.59}
        y1={355.2}
        y2={355.2}
      />
      <LinearGradient
        xlinkHref="#I"
        id="gD"
        x1={2870.49}
        x2={3176.87}
        y1={364.18}
        y2={364.18}
      />
      <LinearGradient
        xlinkHref="#J"
        id="gE"
        x1={2965.63}
        x2={3082.19}
        y1={211.31}
        y2={211.31}
      />
      <LinearGradient
        xlinkHref="#K"
        id="gF"
        x1={2990.33}
        x2={3074.83}
        y1={121}
        y2={121}
      />
      <LinearGradient
        xlinkHref="#L"
        id="gG"
        x1={3003.02}
        x2={3040.1}
        y1={380.75}
        y2={380.75}
      />
      <LinearGradient
        xlinkHref="#M"
        id="gH"
        x1={2913.57}
        x2={2967.41}
        y1={301.71}
        y2={301.71}
      />
      <LinearGradient
        xlinkHref="#N"
        id="gI"
        x1={2826.41}
        x2={2863.79}
        y1={283.13}
        y2={283.13}
      />
      <LinearGradient
        xlinkHref="#o"
        id="gJ"
        x1={2489.83}
        x2={2527.22}
        y1={271.91}
        y2={271.91}
      />
      <LinearGradient
        xlinkHref="#O"
        id="gK"
        x1={2717.23}
        x2={3162.14}
        y1={283.26}
        y2={283.26}
      />
      <LinearGradient
        xlinkHref="#p"
        id="gL"
        x1={1115.36}
        x2={1115.36}
        y1={254.18}
        y2={298.83}
      />
      <LinearGradient
        xlinkHref="#P"
        id="gM"
        x1={2896.85}
        x2={3201.77}
        y1={296.76}
        y2={296.76}
      />
      <LinearGradient
        xlinkHref="#e"
        id="gN"
        x1={553.34}
        x2={1676.06}
        y1={314.29}
        y2={314.29}
      />
      <LinearGradient
        xlinkHref="#e"
        id="gO"
        x1={338.96}
        x2={2433.44}
        y1={234.84}
        y2={234.84}
      />
      <LinearGradient
        xlinkHref="#f"
        id="gP"
        x1={2505.56}
        x2={2594.63}
        y1={291.78}
        y2={291.78}
      />
      <LinearGradient
        xlinkHref="#g"
        id="gQ"
        x1={2505.57}
        x2={2581.78}
        y1={291.79}
        y2={291.79}
      />
      <LinearGradient
        xlinkHref="#h"
        id="gR"
        x1={2568.38}
        x2={2657.45}
        y1={295.92}
        y2={295.92}
      />
      <LinearGradient
        xlinkHref="#i"
        id="gS"
        x1={2568.39}
        x2={2644.6}
        y1={295.94}
        y2={295.94}
      />
      <LinearGradient
        xlinkHref="#j"
        id="gT"
        x1={2653.02}
        x2={2653.02}
        y1={-9.92}
        y2={355}
      />
      <LinearGradient
        xlinkHref="#k"
        id="gU"
        x1={188.45}
        x2={533.2}
        y1={-204.05}
        y2={-204.05}
      />
      <LinearGradient
        xlinkHref="#l"
        id="gV"
        x1={4484}
        x2={3649.51}
        y1={335.38}
        y2={335.38}
      />
      <LinearGradient
        xlinkHref="#m"
        id="gW"
        x1={2462.74}
        x2={2749.86}
        y1={328.42}
        y2={328.42}
      />
      <LinearGradient
        xlinkHref="#S"
        id="gX"
        x1={3103.61}
        x2={2220.19}
        y1={315.86}
        y2={315.86}
      />
      <LinearGradient
        xlinkHref="#n"
        id="gY"
        x1={2655.45}
        x2={3364.42}
        y1={352.07}
        y2={352.07}
      />
      <LinearGradient
        xlinkHref="#I"
        id="gZ"
        x1={2871.14}
        x2={3177.52}
        y1={364.07}
        y2={364.07}
      />
      <LinearGradient
        xlinkHref="#T"
        id="ha"
        x1={339.17}
        x2={645.55}
        y1={364.12}
        y2={364.12}
      />
      <LinearGradient
        xlinkHref="#J"
        id="hb"
        x1={2966.28}
        x2={3082.84}
        y1={211.24}
        y2={211.24}
      />
      <LinearGradient
        xlinkHref="#K"
        id="hc"
        x1={2990.98}
        x2={3075.49}
        y1={120.97}
        y2={120.97}
      />
      <LinearGradient
        xlinkHref="#L"
        id="hd"
        x1={3003.66}
        x2={3040.75}
        y1={380.65}
        y2={380.65}
      />
      <LinearGradient
        xlinkHref="#M"
        id="he"
        x1={2914.22}
        x2={2968.06}
        y1={301.6}
        y2={301.6}
      />
      <LinearGradient
        xlinkHref="#N"
        id="hf"
        x1={2827.05}
        x2={2864.44}
        y1={283.03}
        y2={283.03}
      />
      <LinearGradient
        xlinkHref="#o"
        id="hg"
        x1={2490.48}
        x2={2527.87}
        y1={271.8}
        y2={271.8}
      />
      <LinearGradient
        xlinkHref="#O"
        id="hh"
        x1={2717.86}
        x2={3162.77}
        y1={283.08}
        y2={283.08}
      />
      <LinearGradient
        xlinkHref="#U"
        id="hi"
        x1={2715.16}
        x2={3310.8}
        y1={254.93}
        y2={254.93}
      />
      <LinearGradient
        xlinkHref="#p"
        id="hj"
        x1={1114.71}
        x2={1114.71}
        y1={254.07}
        y2={298.72}
      />
      <LinearGradient
        xlinkHref="#P"
        id="hk"
        x1={2897.3}
        x2={3168.29}
        y1={296.13}
        y2={296.13}
      />
      <LinearGradient
        xlinkHref="#j"
        id="hl"
        x1={4455.68}
        x2={4455.68}
        y1={-12.61}
        y2={352.21}
      />
      <LinearGradient
        xlinkHref="#S"
        id="hm"
        x1={4495.9}
        x2={3982}
        y1={234.37}
        y2={234.37}
      />
      <LinearGradient
        xlinkHref="#n"
        id="hn"
        x1={4225.32}
        x2={4637.08}
        y1={188.28}
        y2={188.28}
      />
      <LinearGradient
        xlinkHref="#I"
        id="ho"
        x1={4355.98}
        x2={4528.99}
        y1={186.11}
        y2={186.11}
      />
      <LinearGradient
        xlinkHref="#P"
        id="hp"
        x1={4243.08}
        x2={4508.72}
        y1={290.09}
        y2={290.09}
      />
      <LinearGradient
        xlinkHref="#T"
        id="hq"
        x1={4329.42}
        x2={4541.73}
        y1={185.51}
        y2={185.51}
      />
      <LinearGradient xlinkHref="#V" id="hr" x1={4421.25} x2={4458.33} />
      <LinearGradient
        xlinkHref="#Q"
        id="hs"
        x1={4372.39}
        x2={4489.02}
        y1={256.29}
        y2={256.29}
      />
      <LinearGradient
        xlinkHref="#R"
        id="ht"
        x1={4398.27}
        x2={4482.78}
        y1={291.51}
        y2={291.51}
      />
      <LinearGradient
        xlinkHref="#Q"
        id="hu"
        x1={1550.46}
        x2={1667.02}
        y1={131.08}
        y2={131.08}
      />
      <LinearGradient
        xlinkHref="#R"
        id="hv"
        x1={1583.62}
        x2={1668.12}
        y1={-53.09}
        y2={-53.09}
      />
      <LinearGradient xlinkHref="#V" id="hw" x1={1585.58} x2={1622.66} />
      <LinearGradient
        xlinkHref="#w"
        id="hx"
        x1={4119.79}
        x2={4119.79}
        y1={-11.61}
        y2={353.31}
      />
      <LinearGradient
        xlinkHref="#y"
        id="hy"
        x1={4570.38}
        x2={3686.49}
        y1={314.16}
        y2={314.16}
        gradientTransform="matrix(1 0 0 -1 0 547.89)"
      />
      <LinearGradient
        xlinkHref="#A"
        id="hz"
        x1={4122.22}
        x2={4819}
        y1={350.38}
        y2={350.38}
      />
      <LinearGradient
        xlinkHref="#E"
        id="hA"
        x1={1826.91}
        x2={4891.84}
        y1={106.83}
        y2={106.83}
      />
      <LinearGradient
        xlinkHref="#H"
        id="hB"
        x1={4140.32}
        x2={4411.39}
        y1={305.42}
        y2={305.42}
      />
      <LinearGradient
        xlinkHref="#e"
        id="hC"
        x1={3238.85}
        x2={5333.33}
        y1={238.37}
        y2={238.37}
      />
      <LinearGradient
        xlinkHref="#q"
        id="hD"
        x1={-1493.83}
        x2={-1404.76}
        y1={290.2}
        y2={290.2}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#r"
        id="hE"
        x1={-1493.82}
        x2={-1417.61}
        y1={290.2}
        y2={290.2}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#q"
        id="hF"
        x1={-1431.01}
        x2={-1341.94}
        y1={294.34}
        y2={294.34}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#r"
        id="hG"
        x1={-1431}
        x2={-1354.79}
        y1={294.35}
        y2={294.35}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#w"
        id="hH"
        x1={-1346.37}
        x2={-1346.37}
        y1={-11.5}
        y2={353.42}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#x"
        id="hI"
        x1={5056.87}
        x2={5401.62}
        y1={528.69}
        y2={528.69}
        gradientTransform="matrix(.79861 .13536 .15207 -.8972 741.24 84.17)"
      />
      <LinearGradient
        xlinkHref="#y"
        id="hJ"
        x1={8483.39}
        x2={7648.9}
        y1={333.8}
        y2={333.8}
        gradientTransform="matrix(1 0 0 -1 -3089.19 547.89)"
      />
      <LinearGradient
        xlinkHref="#z"
        id="hK"
        x1={-1536.65}
        x2={-1249.53}
        y1={326.84}
        y2={326.84}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#y"
        id="hL"
        x1={-895.78}
        x2={-1779.2}
        y1={314.27}
        y2={314.27}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#A"
        id="hM"
        x1={-1343.94}
        x2={-647.15}
        y1={353.51}
        y2={353.51}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#T"
        id="hN"
        x1={-1128.25}
        x2={-821.87}
        y1={362.49}
        y2={362.49}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#Q"
        id="hO"
        x1={-1026.9}
        x2={-910.34}
        y1={-11.51}
        y2={-11.51}
        gradientTransform="rotate(176.83 1803.157 199.549)"
      />
      <LinearGradient
        xlinkHref="#R"
        id="hP"
        x1={-978.58}
        x2={-894.07}
        y1={-366.62}
        y2={-366.62}
        gradientTransform="rotate(173.02 1807.822 113.356)"
      />
      <LinearGradient
        xlinkHref="#V"
        id="hQ"
        x1={-995.73}
        x2={-958.64}
        y1={379.06}
        y2={379.06}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#B"
        id="hR"
        x1={-1085.17}
        x2={-1031.33}
        y1={300.02}
        y2={300.02}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#x"
        id="hS"
        x1={-1172.34}
        x2={-1134.95}
        y1={281.44}
        y2={281.44}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#x"
        id="hT"
        x1={-1508.91}
        x2={-1471.52}
        y1={270.22}
        y2={270.22}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#E"
        id="hU"
        x1={-1258.01}
        x2={-813.1}
        y1={716.21}
        y2={716.21}
        gradientTransform="rotate(-173.76 1808.534 360.951)"
      />
      <LinearGradient
        xlinkHref="#w"
        id="hV"
        x1={5114.1}
        x2={5114.1}
        y1={252.49}
        y2={297.14}
      />
      <LinearGradient
        xlinkHref="#H"
        id="hW"
        x1={-489.08}
        x2={-184.16}
        y1={264.58}
        y2={264.58}
        gradientTransform="matrix(-1.17997 .00824 -.10696 -.91928 4250.07 518.84)"
      />
      <LinearGradient
        xlinkHref="#e"
        id="hX"
        x1={4552.09}
        x2={5674.81}
        y1={315.98}
        y2={315.98}
      />
      <LinearGradient
        xlinkHref="#q"
        id="hY"
        x1={-1493.18}
        x2={-1404.12}
        y1={290.09}
        y2={290.09}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#r"
        id="hZ"
        x1={-1493.17}
        x2={-1416.96}
        y1={290.1}
        y2={290.1}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#q"
        id="ia"
        x1={-1430.36}
        x2={-1341.3}
        y1={294.23}
        y2={294.23}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#r"
        id="ib"
        x1={-1430.35}
        x2={-1354.14}
        y1={294.25}
        y2={294.25}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#w"
        id="ic"
        x1={-1345.72}
        x2={-1345.72}
        y1={-11.61}
        y2={353.31}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#x"
        id="id"
        x1={5056.1}
        x2={5400.85}
        y1={528.45}
        y2={528.45}
        gradientTransform="matrix(.79861 .13536 .15207 -.8972 741.24 84.17)"
      />
      <LinearGradient
        xlinkHref="#y"
        id="ie"
        x1={8482.75}
        x2={7648.26}
        y1={333.69}
        y2={333.69}
        gradientTransform="matrix(1 0 0 -1 -3089.19 547.89)"
      />
      <LinearGradient
        xlinkHref="#z"
        id="if"
        x1={-1536}
        x2={-1248.88}
        y1={326.73}
        y2={326.73}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#y"
        id="ig"
        x1={-895.13}
        x2={-1778.55}
        y1={314.16}
        y2={314.16}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#A"
        id="ih"
        x1={-1343.29}
        x2={-634.33}
        y1={350.38}
        y2={350.38}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#T"
        id="ii"
        x1={-1127.6}
        x2={-821.22}
        y1={362.38}
        y2={362.38}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#T"
        id="ij"
        x1={4337.91}
        x2={4644.29}
        y1={362.43}
        y2={362.43}
      />
      <LinearGradient
        xlinkHref="#Q"
        id="ik"
        x1={-1026.25}
        x2={-909.69}
        y1={-11.58}
        y2={-11.58}
        gradientTransform="rotate(176.83 1803.157 199.549)"
      />
      <LinearGradient
        xlinkHref="#R"
        id="il"
        x1={-977.92}
        x2={-893.42}
        y1={-366.65}
        y2={-366.65}
        gradientTransform="rotate(173.02 1807.822 113.356)"
      />
      <LinearGradient
        xlinkHref="#V"
        id="im"
        x1={-995.08}
        x2={-958}
        y1={378.96}
        y2={378.96}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#B"
        id="in"
        x1={-1084.53}
        x2={-1030.69}
        y1={299.91}
        y2={299.91}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#x"
        id="io"
        x1={-1171.69}
        x2={-1134.31}
        y1={281.34}
        y2={281.34}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#x"
        id="ip"
        x1={-1508.26}
        x2={-1470.88}
        y1={270.11}
        y2={270.11}
        gradientTransform="rotate(-180 1798.73 273.945)"
      />
      <LinearGradient
        xlinkHref="#E"
        id="iq"
        x1={-1257.38}
        x2={-812.47}
        y1={716.04}
        y2={716.04}
        gradientTransform="rotate(-173.76 1808.534 360.951)"
      />
      <LinearGradient
        xlinkHref="#E"
        id="ir"
        x1={-1260.08}
        x2={-664.43}
        y1={687.89}
        y2={687.89}
        gradientTransform="rotate(-173.76 1808.534 360.951)"
      />
      <LinearGradient
        xlinkHref="#w"
        id="is"
        x1={5113.46}
        x2={5113.46}
        y1={252.38}
        y2={297.03}
      />
      <LinearGradient
        xlinkHref="#H"
        id="it"
        x1={-488.64}
        x2={-217.64}
        y1={263.95}
        y2={263.95}
        gradientTransform="matrix(-1.17997 .00824 -.10696 -.91928 4250.07 518.84)"
      />
    </Defs>
    <Path
      d="M3030.14 229.15c-2.05-.07-4.07 0-6.05.28-3.33.46-6.75-.76-7.71-4.38-.07-.25-.11-.51-.14-.78-2.35-2.56-2.18-7.16.54-9.4-3.85-6.54-7.46-14.27-12.17-19.27-4.4-4.67-16.32-5.94-21.62-5.02-4.02.7-10.09 4.34-12.46 10.3-1.5-1.76-3.09-3.3-4.73-4.49-5.8-9.62-11.11-21.35-18.16-28.83-6.31-6.7-23.43-8.52-31.03-7.21-5.77 1-14.48 6.23-17.88 14.78-7.2-8.45-15.9-13.53-24.04-7.07.43-.34.88-.6 1.28-.77-14.71 16.75-40.82 21.85-48.62 31.34-2.55 3.1-1.08 7.77-4.82 9.63-9.03 4.47-9.49-26.05-38.64 8.11-5.72 6.71-7.56 7.3-15.33 8.13-5.01.54-10.93 1.59-14.9 5.37-8.31 27.32 7.43 29.38 16.41 29.53 56.65.94 58.73 1.45 101.26-2.39 3.13 2.48 5.83 3.39 46.93 3.02 22.99-.21 45.81-4.31 68.9-4.15 13.32.09 29.17 1.7 41.94-5.6-.36-7.17 1.24-14.04 1.06-21.15l-.02.02Z"
      style={{
        fill: "url(#a)",
      }}
    />
    <Path
      d="M2667.24 234.75c7.5-2.32 15.09-4.5 20.88-9.61 3.02-2.67 6.14-6.31 10.15-5.79-1.35-1.68-.27-4.33 1.55-5.5s4.08-1.32 6.22-1.69c7.03-1.24 13.46-5.18 20.58-5.72 9.25-.69 17.87 4.42 27.02 5.97 1.97.33 4.06.53 5.7 1.66s2.58 3.58 1.33 5.12c4.32-.54 7.65 3.39 10.96 6.19 7.55 6.36 18.02 7.7 27.23 11.32 1.02.4 2.07.87 2.72 1.74 3.85 5.2-12.62 8.04-15.41 7.9-4.27-.21-8.51-1.62-12.74-.98-3.68.56-6.91 2.62-10.34 4.04-11.1 4.6-23.68 2.44-35.71 2.57-9.85.11-20.1 1.75-29.29-1.78-4.04-1.55-7.77-4.07-12.05-4.63-3.68-.48-7.36.53-11.06.84-5.72.48-11.57-.79-16.56-3.58-.66-.37-1.33-.8-1.64-1.48-.84-1.82 1.41-3.5 3.31-4.21 2.34-.88 4.75-1.64 7.16-2.38Z"
      style={{
        fill: "url(#c)",
      }}
    />
    <Path
      d="M1173.49 264.62c-4.49-3.18-2.29-7.76-3.34-13.93-1.17-6.89-2.08-7.6-9.04-7.91h-.01c-2.23-10.3-1.3-14.72-14.19-24.75-8.41-6.54-11.13.66-14.78-.39-10.89-3.13-10.4 8.21-11.63 17.14-2.34-1.31-4.46 2.35-7.54-.22-4.34-3.62-7.53-3.47-10.07-1.22.7 1.97 1.04 4.05.9 6.16-.85 12.83-6.88 24.16-15.7 32.94l.03.38c.35 4.12 3.39 6.05 6.88 6.94 1.96.5 2.59 1.28 2.97 3.12.96 4.62 3.25 7.96 8.66 7.88.58 0 1.24.84 1.77 1.39 7.5 7.76 12.44-.37 15.71.5 16.66 4.43 19.27 9.26 30.83 7.38 9.71-1.58 10.8-11.52 15.05-12.96 3.74-1.27 5.47-3.85 5.97-7.37.7-4.87 1.54-12.24-2.47-15.08Z"
      style={{
        fill: "url(#q)",
      }}
    />
    <Path
      d="M1146.72 288.62c-3.27-.87-8.21 7.26-15.71-.5-.53-.55-1.19-1.39-1.77-1.39-5.41.08-7.7-3.26-8.66-7.88-.38-1.84-1.01-2.62-2.97-3.12-3.49-.89-6.53-2.82-6.88-6.94-.66-7.8-2.33-19.6 5.27-18.43 4.57-12.45 8.04-29.46 19.57-19.83 3.08 2.57 5.2-1.09 7.54.22.66-4.84.98-10.77 2.39-13.73-7.25-4.74-9.93 1.61-13.37.62-10.88-3.13-10.4 8.2-11.63 17.14-2.34-1.31-4.46 2.35-7.54-.22-4.33-3.62-7.53-3.47-10.08-1.22.7 1.97 1.04 4.06.9 6.16-.85 12.83-6.88 24.16-15.7 32.94l.03.39c.35 4.12 3.38 6.05 6.88 6.94 1.96.5 2.59 1.28 2.97 3.12.96 4.62 3.25 7.96 8.66 7.88.59 0 1.25.84 1.77 1.39 7.46 7.73 12.46-.37 15.71.5 4.56 1.21 9.22 2.44 13.41 4.54 7.68 3.84 19.34 5.69 26.17-2.52-5.35-1.91-3.42-2.44-16.97-6.05Z"
      style={{
        fill: "url(#r)",
      }}
    />
    <Path
      d="M1214.1 318.74c-56.36-13-107.08-22.89-152.39-30.36-8.57 2.41-17.79 1.39-26.54.81-10.8-.72-21.62-1.13-32.43-1.53-38.92-1.46-77.94-1.86-116.74-5.49-3.17-.3-6.34-.55-9.51-.82-14.24 1.7-28.61 2.89-42.94 3.34-14.24.45-28.47-.37-42.71-.47-19.64-.13-39.28.15-58.92.31-.04 9.31-.22 18.63-.37 27.96-.13 8.51.19 16.98.39 25.49.06 2.8.01 5.59-.07 8.39 93.98.28 187.96.89 281.94 1.88 92.7-3.84 185.35-8.84 278.01-13.52-25.11-4.54-51.04-9.83-77.72-15.98Z"
      style={{
        fill: "url(#e)",
      }}
    />
    <Path
      d="M2807.75 232.56c-.6-.19-1.21-.39-1.83-.58-38.15.47-77.15-.67-115.01.25-68.41 1.67-112.43 18.21-175.2 26.76-59.76 8.13-73.95 19.39-135.87 22.28-2.53.12-8.85.77-17.37 1.79.68 2.86-9.39 3.02-12.3 4.25-8.4 6.71-20 7.41-23.56 17.11-1.19 1.75 1.46 3.91-.05 5.28 31.03 1.59 64.26 3.27 86.83 6.09 74 9.24 148.29 20.24 223.99 25.77 61.78 4.52 124.43 3.24 186.48 6.14V238.58c-5.26-2.31-10.68-4.28-16.12-6.02h.01Z"
      style={{
        fill: "url(#d)",
      }}
    />
    <Path
      d="M1515.26 202.89c-7.21-1.07-14.02-2.3-19.58-8.66-1.23-1.4-5.67-.94-7.53.51-23.04 17.91-25.92-21.16-51.39-12.27-6.6 2.3-10.66-10.78-19.07-9.19-.43.08-1.41-1.11-1.55-1.85-1.23-6.81-5.28-9.15-9.69-8.82-2.7-6.36-4.62-12.12-7.43-17.25-6.15-11.22-15.83-12.14-21.98-.78-2.81 5.2-5.36 10.59-8.82 17.49-7.85-12.62-6.45-11.4-13.2-17.95-.84-.82-2.28-1.01-2.88-1.96-5.35-8.45-14.32-7.99-18.95 1.09-.91 1.79-1.69 3.67-2.77 6.05-3.94-11.09-14.53-21.11-26.57-16.11-8.67 3.6-18.79 5.51-20.89 18.92-.13.83-2.24 1.87-3.36 1.77-15.76-1.41-13.45 16.29-21.42 8.59-3.87-3.75-7.72-3.35-10.67.08l-.06.27c-2.42 8.57-7.96 15.02-12.79 22.19 1.03 3.12-.2 7.09-3.7 8.19.45.9.67 1.92.71 2.92.03.86-.15 1.67-.54 2.41-.25.78-.68 1.45-1.3 2.02-.52.55-1.12.95-1.81 1.21-.79.78-1.74 1.28-2.77 1.55-.33.29-.7.53-1.11.75-.14.26-.29.52-.43.77-.09 1.56-.67 3.09-1.77 4.19-.08 1.24-.47 2.45-1.18 3.44v.16c.69.64 1.29 1.4 1.75 2.26.25.48.44.96.58 1.45 14.68-.13 32.63-.2 54.81-.19.04 0 220.39 4.73 239.15-1.02 1.74-.53 3.56-4.93 3.49-7.5-.05-1.7-3.26-4.42-5.27-4.72Z"
      style={{
        fill: "url(#s)",
      }}
    />
    <Path
      d="M1640.52 171.06c-2.05-.07-4.07 0-6.05.28-3.33.46-6.75-.76-7.71-4.38-.07-.25-.11-.51-.14-.78-2.35-2.56-2.18-7.16.54-9.4-3.85-6.54-7.46-14.27-12.17-19.27-4.4-4.67-16.32-5.94-21.62-5.02-4.02.7-10.09 4.34-12.46 10.3-1.5-1.76-3.09-3.3-4.73-4.49-5.8-9.62-11.11-21.35-18.16-28.83-6.31-6.7-23.43-8.52-31.03-7.21-5.77 1-14.48 6.23-17.88 14.78-7.2-8.45-15.9-13.53-24.04-7.07.43-.34.88-.6 1.28-.77-14.71 16.75-40.82 21.85-48.62 31.34-2.55 3.1-1.08 7.77-4.82 9.63-9.03 4.47-9.49-26.05-38.64 8.11-5.72 6.71-7.56 7.3-15.33 8.13-5.01.54-10.93 1.59-14.9 5.37-8.31 27.32 7.43 29.38 16.41 29.53 56.65.94 58.73 1.45 101.26-2.39 3.13 2.48 5.83 3.39 46.93 3.02 22.99-.21 45.81-4.31 68.9-4.15 13.32.09 29.17 1.7 41.94-5.6-.36-7.17 1.24-14.04 1.06-21.15l-.02.02Z"
      style={{
        fill: "url(#W)",
      }}
    />
    <Path
      d="M1309.54 247.75c-2.05-.07-4.07 0-6.05.28-3.33.46-6.75-.76-7.71-4.38-.07-.25-.11-.51-.14-.78-2.35-2.56-2.18-7.16.54-9.4-3.85-6.54-7.46-14.27-12.17-19.27-4.4-4.67-16.32-5.94-21.62-5.02-4.02.7-10.09 4.34-12.46 10.3-1.5-1.76-3.09-3.3-4.73-4.49-5.8-9.62-11.11-21.35-18.16-28.83-6.31-6.7-23.43-8.52-31.03-7.21-5.77 1-14.48 6.23-17.88 14.78-7.2-8.45-15.9-13.53-24.04-7.07.43-.34.88-.6 1.28-.77-14.71 16.75-40.82 21.85-48.62 31.34-2.55 3.1-1.08 7.77-4.82 9.63-9.03 4.47-9.49-26.05-38.64 8.11-5.72 6.71-7.56 7.3-15.33 8.13-5.01.54-10.93 1.59-14.9 5.37-8.31 27.32 7.43 29.38 16.41 29.53 56.65.94 58.73 1.45 101.26-2.39 3.13 2.48 5.83 3.39 46.93 3.02 22.99-.21 45.81-4.31 68.9-4.15 13.32.09 29.17 1.7 41.94-5.6-.36-7.17 1.24-14.04 1.06-21.15l-.02.02Z"
      style={{
        fill: "url(#X)",
      }}
    />
    <Path
      d="M1401.76 217.09c-1.66-6.09-1.66-6.07-6.06-4.68-1.95.61-1.69-1.07-6-7.17-1.16-1.64-2.43-4.03-3.77-4.24-6.66-1.07-1.07-10.24-6.24-12.96-1.94-1.02-3.76-1.4-5.15 1.54-7.96 16.85-3.46 11.27-9.59 8.13-10.56-5.42-10.32-1.6-12.27-4.41-2.14-3.09-4.62-3.9-7.41-2.46-1.19.62-2.07.09-7.42-1.7-.9-15.03-.08-22.14-6.78-16.56-.81.67-6.83-17.95-9.33-21.35-.63-.86-1.7-1.47-2.5-1.3-.53.11-.99 1.74-1.34 2.81-.23.7-.2 1.61-.34 2.91-.71-.87-1.09-1.61-1.57-1.87-2.42-1.29-5.11-4.7-6.07 4.26-.31 2.91.1 6-.02 8.99-.07 1.84-.56 3.63-.75 4.79-3.49-2.33-4.11-4.67-5.22 2.28-.48 2.99-3.16 6.48-5.1 6.74-1.14.15-2.24 1.02-3.35 1.57-4.9-5.71-4.75-1.36-6.92 4.13-.51-1.33-.8-2.61-1.36-3.44-.63-.93-1.76-2.33-2.22-1.99-1.79 1.29-2.55-.74-3.17-2.28-4.42-10.89-3.45-16.74-7.25-13.17-.26.25-.94-.45-1.37-.85-2.45-2.23-4.78-.65-5.37 3.73-.27 1.99-.43 4.02-.66 6.25-.87-.35-1.61-.29-1.9-.83-.91-1.71-1.65-4.37-3.31-2.2-3.17 4.14-3.02 9.47-3.51 13.96-9.46 4.24-6.1-1.05-8.1-6.81-.74-2.12-2.07-4.84-3.34-5.13-4.05-.94-4.06 6.76-6.77 16.64-.31 1.14-1.26 1.84-2.03 2.89-1.35-3.18-.41-2.53-3.35-4.53-.3 1.33-.98 2.56-2.06 3.43-.28 2.37-.07 1.98.29 5.4-.54-.2-1.02-.37-1.46-.53-.08.5-.23.98-.47 1.43-.25.78-.68 1.45-1.3 2.02-.52.55-1.12.95-1.81 1.21-.8.79-1.77 1.29-2.81 1.57-.32.28-.68.52-1.06.73-.14.26-.29.52-.43.78-.09 1.56-.67 3.08-1.77 4.19-.08 1.24-.47 2.45-1.18 3.44v.16c.69.64 1.29 1.4 1.75 2.26.82 1.54 1.01 3.15.63 4.83-.24 1.06-.84 1.97-1.6 2.72-.03.2-.06.4-.12.6 4.9.17 9.8.42 14.7.55 35.21.92 53.42.14 60.01.44 10.15.45 20.28 1.68 30.42 2.22 10.05.53 20.16-.27 30.15 1.16 22.9 3.27 28.44-.98 41.19-.61 4.62.13 3.86-3.77 3.86-9.68h-.02Z"
      style={{
        fill: "url(#t)",
      }}
    />
    <Path
      d="M1358.58 188.99c1.31-1.42 2.74-2.51 3.64-4.05 3.77-6.43 8.98-7.91 15.13-7.41 1.46.12 3.94-1.42 4.41-2.91 5.42-17.22 23.46-11.42 27.76-22.78 1.3-3.42 4.01-4.84 7.49-3.83 1.38.4 3.11-.67 4.63-1.29.66-.27 1.14-1.1 1.75-1.61 9.78-8.3 13.99-8.07 19.73 7.07.61 1.6.71 3.87 1.71 4.79 1.81 1.68 3.87 4.12 6.23.05 2.25-3.88 5.5-4.79 8.78-1.44 5.84 5.97 4.16-7.74 15.72-6.65.82.08 2.37-.73 2.46-1.37 1.54-10.39 8.97-11.87 15.34-14.66 8.84-3.88 16.61 3.89 19.5 12.49.8-1.84 1.36-3.3 2.03-4.69 3.4-7.04 9.98-7.39 13.91-.84.44.73 1.49.88 2.11 1.52 4.89 5.01 3.83 3.97 9.69 13.91 2.55-5.35 4.41-9.53 6.48-13.56 4.51-8.8 11.62-8.09 16.13.6 2.06 3.97 3.47 8.44 5.45 13.37 3.24-.26 6.21 1.55 7.11 6.83.1.57.82 1.5 1.14 1.43 6.09-1.22 9.19 8.89 14 7.12 18.7-6.89 20.81 23.39 37.72 9.51 1.37-1.12 4.63-1.48 5.53-.39 4.08 4.93 9.08 5.88 14.37 6.71 1.48.23 3.84 2.34 3.87 3.66.05 1.99-1.28 5.4-2.56 5.81-3.67 1.19-7.61 1.3-11.45 1.6-.03 0-81.7.84-111.79.79-30.42-.05-33.46-3.49-132.94-.29-12.08.39-12.2 3.78-47.3 6.21-2.66.18-5.49-.17-8.01-1.16-1.51-.6-2.83-2.72-3.64-4.54-.2-.45 1.75-3.15 2.98-3.52 4.4-1.31 8.15-3.14 11.21-7.73.97-1.45 5.04.05 7.68.32.69.07 1.35.6 2.01.92Z"
      style={{
        fill: "url(#b)",
      }}
    />
    <Path
      d="M1048.84 263.5c1.31-1.42 2.74-2.51 3.64-4.05 3.77-6.43 8.98-7.91 15.13-7.41 1.46.12 3.94-1.42 4.41-2.91 5.42-17.22 23.46-11.42 27.76-22.78 1.3-3.42 4.01-4.84 7.49-3.83 1.38.4 3.11-.67 4.63-1.29.66-.27 1.14-1.1 1.75-1.61 9.78-8.3 13.99-8.07 19.73 7.07.61 1.6.71 3.87 1.71 4.79 1.81 1.68 3.87 4.12 6.23.05 2.25-3.88 5.5-4.79 8.78-1.44 5.84 5.97 4.16-7.74 15.72-6.65.82.08 2.37-.73 2.46-1.37 1.54-10.39 8.97-11.87 15.34-14.66 8.84-3.88 16.61 3.89 19.5 12.49.8-1.84 1.36-3.3 2.03-4.69 3.4-7.04 9.98-7.39 13.91-.84.44.73 1.49.88 2.11 1.52 4.89 5.01 3.83 3.97 9.69 13.91 2.55-5.35 4.41-9.53 6.48-13.56 4.51-8.8 11.62-8.09 16.13.6 2.06 3.97 3.47 8.44 5.45 13.37 3.24-.26 6.21 1.55 7.11 6.83.1.57.82 1.5 1.14 1.43 6.09-1.22 9.19 8.89 14 7.12 18.7-6.89 20.81 23.39 37.72 9.51 1.37-1.12 4.63-1.48 5.53-.39 4.08 4.93 9.08 5.88 14.37 6.71 1.48.23 3.84 2.34 3.87 3.66.05 1.99-1.28 5.4-2.56 5.81-3.67 1.19-7.61 1.3-11.45 1.6-.03 0-81.7.84-111.79.79-30.42-.05-33.46-3.49-132.94-.29-12.08.39-12.2 3.78-47.3 6.21-2.66.18-5.49-.17-8.01-1.16-1.51-.6-2.83-2.72-3.64-4.54-.2-.45 1.75-3.15 2.98-3.52 4.4-1.31 8.15-3.14 11.21-7.73.97-1.45 5.04.05 7.68.32.69.07 1.35.6 2.01.92Z"
      style={{
        fill: "url(#Y)",
      }}
    />
    <Path
      d="M1254.57 170.71c7.5-2.32 15.09-4.5 20.88-9.61 3.02-2.67 6.14-6.31 10.15-5.79-1.35-1.68-.27-4.33 1.55-5.5s4.08-1.32 6.22-1.69c7.03-1.24 13.46-5.18 20.58-5.72 9.25-.69 17.87 4.42 27.02 5.97 1.97.33 4.06.53 5.7 1.66s2.58 3.58 1.33 5.12c4.32-.54 7.65 3.39 10.96 6.19 7.55 6.36 18.02 7.7 27.23 11.32 1.02.4 2.07.87 2.72 1.74 3.85 5.2-12.62 8.04-15.41 7.9-4.27-.21-8.51-1.62-12.74-.98-3.68.56-6.91 2.62-10.34 4.04-11.1 4.6-23.68 2.44-35.71 2.57-9.85.11-20.1 1.75-29.29-1.78-4.04-1.55-7.77-4.07-12.05-4.63-3.68-.48-7.36.53-11.06.84-5.72.48-11.57-.79-16.56-3.58-.66-.37-1.33-.8-1.64-1.48-.84-1.82 1.41-3.5 3.31-4.21 2.34-.88 4.75-1.64 7.16-2.38Z"
      style={{
        fill: "url(#Z)",
      }}
    />
    <Path
      d="M1160.95 225.15c7.5-2.32 15.09-4.5 20.88-9.61 3.02-2.67 6.14-6.31 10.15-5.79-1.35-1.68-.27-4.33 1.55-5.5s4.08-1.32 6.22-1.69c7.03-1.24 13.46-5.18 20.58-5.72 9.25-.69 17.87 4.42 27.02 5.97 1.97.33 4.06.53 5.7 1.66s2.58 3.58 1.33 5.12c4.32-.54 7.65 3.39 10.96 6.19 7.55 6.36 18.02 7.7 27.23 11.32 1.02.4 2.07.87 2.72 1.74 3.85 5.2-12.62 8.04-15.41 7.9-4.27-.21-8.51-1.62-12.74-.98-3.68.56-6.91 2.62-10.34 4.04-11.1 4.6-23.68 2.44-35.71 2.57-9.85.11-20.1 1.75-29.29-1.78-4.04-1.55-7.77-4.07-12.05-4.63-3.68-.48-7.36.53-11.06.84-5.72.48-11.57-.79-16.56-3.58-.66-.37-1.33-.8-1.64-1.48-.84-1.82 1.41-3.5 3.31-4.21 2.34-.88 4.75-1.64 7.16-2.38Z"
      style={{
        fill: "url(#aa)",
      }}
    />
    <Path
      d="M1223.93 238.64c6.96.31 7.87 1.02 9.04 7.91 1.05 6.17-1.15 10.75 3.34 13.93 4.01 2.84 3.17 10.21 2.47 15.08-.5 3.52-2.23 6.1-5.97 7.37-4.25 1.44-5.34 11.38-15.05 12.96-11.56 1.88-14.17-2.95-30.83-7.38-3.27-.87-8.21 7.26-15.71-.5-.53-.55-1.19-1.39-1.77-1.39-5.41.08-7.7-3.26-8.66-7.88-.38-1.84-1.01-2.62-2.97-3.12-3.49-.89-6.53-2.82-6.88-6.94-.66-7.8-2.33-19.6 5.27-18.43 4.57-12.44 8.04-29.46 19.57-19.83 3.08 2.57 5.2-1.09 7.54.22 1.23-8.93.74-20.27 11.63-17.14 3.65 1.05 6.37-6.15 14.78.39 12.89 10.03 11.96 14.45 14.19 24.75h.01Z"
      style={{
        fill: "url(#u)",
      }}
    />
    <Path
      d="M1209.54 284.47c-3.27-.87-8.21 7.26-15.71-.5-.53-.55-1.19-1.39-1.77-1.39-5.41.08-7.7-3.26-8.66-7.88-.38-1.84-1.01-2.62-2.97-3.12-3.49-.89-6.53-2.82-6.88-6.94-.66-7.8-2.33-19.6 5.27-18.43 4.57-12.45 8.04-29.46 19.57-19.83 3.08 2.57 5.2-1.09 7.54.22.66-4.84.98-10.77 2.39-13.73-7.25-4.74-9.93 1.61-13.37.62-10.88-3.13-10.4 8.2-11.63 17.14-2.34-1.31-4.46 2.35-7.54-.22-11.6-9.69-15.1 7.65-19.57 19.83-7.61-1.17-5.93 10.6-5.27 18.43.35 4.12 3.38 6.05 6.88 6.94 1.96.5 2.59 1.28 2.97 3.12.96 4.62 3.25 7.96 8.66 7.88.59 0 1.25.84 1.77 1.39 7.46 7.73 12.46-.37 15.71.5 4.56 1.21 9.22 2.44 13.41 4.54 7.68 3.84 19.34 5.69 26.17-2.52-5.35-1.91-3.42-2.44-16.97-6.05Z"
      style={{
        fill: "url(#v)",
      }}
    />
    <Path
      d="M1013.99 348.24c157.65-6.54 315.13-16.44 472.8-22.37 64.85-2.44 129.71-4.37 194.59-5.8V195.05l-271.91-.42c-79.92 23.99-167.19 28.71-189.77 31.5 1.19 3.02.17 7-3.06 8.36-.46.59-1.04 1.08-1.71 1.41-3.29 1.59-6.59 2.57-10.27 2.63h-.21c-.49.24-1.04.41-1.64.51-.23.04-1.09 1.53-1.42 1.77-.53.38-1.22.63-1.8.95-1.61.9-3.2 1.81-5.08 1.95-1.75.13-3.87-.26-4.97 1.11-.63.78-.65 1.86-.9 2.83-.42 1.65-1.53 3.02-2.67 4.28-1.82 2-3.9 3.91-6.47 4.72-2.57.81-5.75.25-7.34-1.93-.05.5-.63.72-1.11.84l-11.19 2.7c-1.6.39-3.25.78-4.88.56-1.07-.15-2.19-.55-3.2-.16-1.11.42-1.68 1.63-2.43 2.55-1.32 1.63-3.51 2.52-5.6 2.26-.97-.12-2.03-.45-2.88.05-.52.31-.83.87-1.18 1.35-1.62 2.2-4.81 3.09-7.33 2.04-1.06-.44-2.09-1.19-3.22-1.04-1.93.26-2.57 2.79-4.17 3.9-1.89 1.31-4.29.29-6.27-.2-2.31-.57-5.05.55-7.15-.43-1.59-.74-2.56-2.57-2.64-4.27-.19-3.83-3.16-5.31-5.52-7.91-1.1-1.22-1.91-2.51-2.57-3.91-6.05 16.11-20.41 28.57-36.7 34.55-9.23 3.39-19.38 2.21-28.96 1.58-10.8-.72-21.62-1.13-32.43-1.53-38.92-1.46-77.94-1.86-116.74-5.49-3.15-.3-6.3-.56-9.46-.83-14.26 1.7-28.65 2.9-42.99 3.35-14.24.45-28.47-.37-42.71-.47h-2.36v62.36c75.17.33 150.34.88 225.52 1.67Z"
      style={{
        fill: "url(#w)",
      }}
    />
    <Path
      d="M1276.36 335.5c10.53-.54 21.05-1.07 31.58-1.59-.15-.9-.44-2.42-4.13-5.37-1.81-1.35-4.14-2.31-6.44-3.43-2.41-1.16-3.4-4.33-4.9-6.66-1.05-1.63-1.52-4.71-2.84-4.87-5.74-.7-7.01-5.27-10.75-7.58-3.32-2.05-6.29-1.87-9.66 1.04-3.76 3.26-13.43 4.24-20.08 5.39-1.52-17.12-.23-12.35-5.9-16.44.77-3.8-.14-6.72-6.62-4.68-1.76.56-6.14-5.83-10.35.57-1.12 1.69 2.29-.13-14.16 7.94 2.14-7.4-.59-12.42-7.76-15.41-4.08-1.71.43-16.93-3.65-18.15-1.93-.58-4.64.32-6.73.57-1.7-.58-3.67-1.92-5.07-1.56-6.14 1.59-5.34 4.78-11.47 21.29l-.91-1.43c-2.6.44-5.38.73-7.89 1.28-3.52.78-5.69 2.08-7.91 2.17-5.94.24-3.17-9.61-9.48-7.52-3.09 1.03-5.43 4-7.7 6.36-.82.85-.61 2.38-.9 3.7-5.83-5.46-8.5-4.97-12.49 1.5-6.76-4.86-12.63.53-24.18 1.38l3.2 18.89c67.56.6 122.73 13.05 167.19 22.61Z"
      style={{
        fill: "url(#x)",
      }}
    />
    <Path
      d="M1228.48 208.2c-1.76-.03-3.69-.08-5.76-.15-.22.35-.48.68-.78.97-.08 1.24-.47 2.45-1.18 3.45v.15c.69.64 1.29 1.4 1.75 2.26.82 1.54 1.01 3.15.63 4.83-.06.26-.14.5-.24.74 36.13-.6 74.73.26 99.41-1.04 32.18-1.7-79.33-10.95-93.83-11.21Z"
      style={{
        fill: "url(#y)",
      }}
    />
    <Path
      d="M1331.51 221.49c-3.19-2.9-2.17-1.03-31.51-3.95-8.08-.86-10.35-.47-11.23-1.62-2.18-2.86-6.53-1.81-20.23-.86-13.74.92-17.2-2.21-24.25 1.31-3.55-3.85-6.22-2.98-11.24.11-.13-.74.2-1.64-.46-2.06-1.82-1.18-3.63-2.72-6.25-3.09-2.79-.39-3.82.94-4.73 2.2.34.42.65.86.91 1.35.82 1.54 1.01 3.15.63 4.83-.24 1.05-.84 1.97-1.6 2.72-.07.55-.23 1.08-.49 1.59-.25.78-.68 1.45-1.3 2.02l-.08.08c.64 1.58.68 3.43.08 5.04 13.71-1.8 27.95-3.48 42.84-4.92 58.19-5.55 63.61-1.25 68.2-2.36.8-.19 1.32-1.81.7-2.37v-.02Z"
      style={{
        fill: "url(#z)",
      }}
    />
    <Path
      d="M1665.27 176.56c-.6-.19-1.21-.39-1.83-.58-38.15.47-77.15-.67-115.01.25-68.41 1.67-112.43 18.21-175.2 26.76-59.76 8.13-73.95 19.39-135.87 22.28-2.53.12-8.85.77-17.37 1.79.68 2.86-9.39 3.02-12.3 4.25-8.4 6.71-20 7.41-23.56 17.11-1.19 1.75 1.46 3.91-.05 5.28 31.03 1.59 64.26 3.27 86.83 6.09 74 9.24 148.29 20.24 223.99 25.77 61.78 4.52 124.43 3.24 186.48 6.14V182.58c-5.26-2.31-10.68-4.28-16.12-6.02h.01Z"
      style={{
        fill: "url(#ab)",
      }}
    />
    <Path
      d="M1665.27 176.56c-3.85-1.23-8.02-2.56-12.27-3.6-21.33-1.31-43.32-3.69-63.35-2.97-97.2 3.48-169.05 26.43-352.28 55.28 63.65-1.71 164.44-21.35 224.85-24.66 158.43-8.68 173.06 10.02 219.17 4.22v-22.24c-5.26-2.31-10.68-4.28-16.12-6.02Z"
      style={{
        fill: "url(#A)",
      }}
    />
    <Path
      d="M1514.19 272.55c-1.91 2.25-3.62 4.23-7.62 3.58-4.49-.74-7.88-2.79-8.83-5.57-1.92-5.68-2.41-11.56.17-17.21 1.66-3.64 9.25-3.56 8.72-6.79-.97-5.88 2.5-11.25 4.75-16.73 1.48-3.58 3.55-3.98 9.52-2.68 3.82-5.37 5.54-9.37 11.28-8.82 6.6.63 4.69 8.66 8.04 9.26 8.85 1.59 7.04 7.98 7.06 18.16 0 .57-.25 1.45.2 1.68 3.45 1.76 2.4 4.06 2.16 6.35-.93 8.98.38 11.29-3.05 16.81-.42.68-2.54 1.56-3.31 1.36-2.84-.73-3.64.75-5.3 1.53-8.41 3.97-16.29 7.68-23.81-.93h.02Z"
      style={{
        fill: "url(#B)",
      }}
    />
    <Path
      d="M1421.51 281.43c-1.33 1.36-2.51 2.56-5.29 2.16-3.12-.44-5.47-1.69-6.13-3.37-1.34-3.43-1.68-6.98.12-10.39 1.2-2.28 6.44-2.07 6.05-4.1-.67-3.55 1.73-6.8 3.3-10.1 1.03-2.16 2.47-2.4 6.61-1.62 2.68-3.28 3.86-5.66 7.83-5.33 4.59.38 3.25 5.23 5.58 5.59 6.07.95 4.89 4.66 4.9 10.97 0 .35-.18.87.14 1.01 2.4 1.06 1.67 2.45 1.5 3.83-.66 5.53.26 6.83-2.12 10.15-.29.41-1.76.94-2.3.82-3.35-.75-12.55 8-20.21.36l.02.02Z"
      style={{
        fill: "url(#C)",
      }}
    />
    <Path
      d="M1108.05 274.49h-.01c-.43-.32.17-7.24-.17-12.87-1.11-1.74-2.93-2.97-4.49-4.68-1.09-1.2-1.94-2.46-2.58-3.79-4.95 13.1-15.41 23.78-27.92 30.51-.88 4.8-.47 9.71.62 14.47.65 2.84 3.01 4.94 6.13 5.69 2.78.67 3.96-1.36 5.29-3.66 5.21 8.79 10.68 5 16.53.95 1.15-.8 1.71-2.31 3.68-1.56.53.2 2-.7 2.3-1.39 2.38-5.63 1.46-7.82 2.12-17.18.17-2.34.9-4.69-1.5-6.49Z"
      style={{
        fill: "url(#D)",
      }}
    />
    <Path
      d="M1681.39 346.57v-33.4c-14.68 2.24-567.01 28.75-604.64 32.31 29.09-.85 575.54 1.73 604.64 1.09Z"
      style={{
        fill: "url(#E)",
      }}
    />
    <Path
      d="M1006.28 243.84c-.72-1.04-.94-2.61-.79-3.95-.38-.17-.72-.39-1.04-.63-.64-.22-1.2-.57-1.69-1.03-.68.27-1.46.42-2.33.42-1.94 0-3.41-.73-4.42-1.83-2.13-.25-3.59-1.45-4.41-3-.59-.1-1.13-.28-1.62-.51h-.22c-3.68-.06-6.98-1.04-10.27-2.63-.68-.33-1.26-.82-1.72-1.41-2.91-1.22-4.03-4.57-3.35-7.43-8.52-1.02-14.84-1.67-17.37-1.79-61.92-2.89-76.11-14.15-135.87-22.28-33.53-4.57-61.72-11.41-91.12-17.06-.41 1.7-.88 3.41-1.41 5.11l-.36 1.48c2.74 8.25 3.93 16.92 3.59 26-1.94 16.18-.92 32.44-.29 48.66.2 5.22.29 10.44.32 15.66 64.55-5.95 128.18-15.14 191.6-23.06 22.57-2.82 55.8-4.5 86.83-6.09-1.51-1.38-2.86-2.89-4.05-4.64Z"
      style={{
        fill: "url(#ac)",
      }}
    />
    <Path
      d="M1067.02 289.01v.02c-1.97-.12-3.93-.3-5.87-.5-8.41 2.22-17.43 1.22-25.98.65-10.8-.72-21.62-1.13-32.43-1.53-.33-.01-.66-.02-.98-.04 15.57 1.23 32.27 2.65 50.18 4.3 7.08.65 16.9 2.44 19.68 2.01.98-.15 1.66-1.85.92-2.5-1.14-1-3.61-2.3-5.52-2.42Z"
      style={{
        fill: "url(#F)",
      }}
    />
    <Path
      d="M1067.02 289.01v.02c-1.97-.12-3.93-.3-5.87-.5-8.41 2.22-17.43 1.22-25.98.65-10.8-.72-21.62-1.13-32.43-1.53-.33-.01-.66-.02-.98-.04 15.57 1.23 32.27 2.65 50.18 4.3 7.08.65 16.9 2.44 19.68 2.01.98-.15 1.66-1.85.92-2.5-1.14-1-3.61-2.3-5.52-2.42Z"
      style={{
        fill: "url(#G)",
      }}
    />
    <Path
      d="M1646.5 285.59c-.99-.07-2.22-.68-2.52-1.33-1.76-3.77-5.83-3.05-10.27-2.83-7.9.32-16.55 1.17-23.15-.77-5.31-1.51-9.24-3-15.12-.24-1.64-6.91-4.68-5.78-11.42-2.19.32-1.18 1.2-2.51.81-3.34-1.06-2.28-1.83-5.11-4.07-6.3-4.58-2.47-6.9 6.4-12.23 5.45-1.41-.27-2.64-1.2-4.01-2.04-2.23-1.36-5-2.08-7.97-3.3l-1.63 1.07c4.43-14.94 7.1-17.58 3.19-20.11-.85-.55-3.12.22-4.82.41-1.49-.61-2.97-1.85-4.78-1.75-2.65.16-7.52 8.01-10.87 11.92-3.93 4.58-6.69 1.13-14.31 6.07-2.85 1.84-5.09 4.35-6.75 7.5-2.28-2.97-4.38-5.74-6.31-8.59-.26-.38 3.04-6.45-5.48-4.31-.74.19-1.89.38-2.2.11-3.71-3.23-6.59-1.08-8.86 2.21-8 2-2.76-1.66-17.48 11.86-3.99-2.31-11.65-5.92-12.02-9.64-.9-8.84-14.57 1.02-21.52-.5-1.38-.3-11.24 6.47-15.95 6.77-5.6.37-10.69.12-15.79 4.2-2.51 2.01-27.56-7.46-30.35-4.21-1.48 1.73-2.54 3.89-2.46 5.59.08 1.89 10.02 2.13 10.37 2.18 72.46 11.12 98.98 11.67 131.96 14.99 104.69 9.8 135.8.37 160.9-1.56v-6.43c-12.16 1.12-22.72-.01-34.89-.92v.03Z"
      style={{
        fill: "url(#H)",
      }}
    />
    <Path
      d="M842.66 342.59c262.14-2.2 524.24-2.34 786.36.42 92.7-3.84 185.35-8.84 278.01-13.52-25.11-4.54-51.04-9.83-77.72-15.98-56.36-13-107.08-22.89-152.39-30.36-8.57 2.41-17.79 1.39-26.54.81-10.8-.72-21.62-1.13-32.43-1.53-38.92-1.46-77.94-1.86-116.74-5.49-3.17-.3-6.34-.55-9.51-.82-14.24 1.7-28.61 2.89-42.94 3.34-14.24.45-28.47-.37-42.71-.47-30.12-.2-60.24.6-90.37.36-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-3.67-.14-7-1.2-9.82-2.94-43.68 7.35-92.27 16.9-146.01 29.3-59.14 13.64-114.58 23.05-165.64 29.35 9.33-.1 18.66-.19 27.99-.27Z"
      style={{
        fill: "url(#ad)",
      }}
    />
    <Path
      d="M1033.3 237.56c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#f)",
      }}
    />
    <Path
      d="M1047.69 283.4c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05h.01Z"
      style={{
        fill: "url(#g)",
      }}
    />
    <Path
      d="M970.48 233.42c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#h)",
      }}
    />
    <Path
      d="M984.87 279.25c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05Z"
      style={{
        fill: "url(#i)",
      }}
    />
    <Path
      d="M1315.69 279.36c-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-6.68-.26-12.27-3.49-15.62-8.49-3.44-3.42-5.65-8.16-6.04-13.12-1.72-3.19-2.76-6.67-2.6-10.68.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.74-12.94-6.48-.64 2.48-1.8 4.71-3.68 6.79-2.36 2.6-5.33 4.08-5.52 7.91-.16 3.2-2.94 5.35-6.08 4.63-.83-.19-1.67-.39-2.5-.59-1 .99-2.41 1.63-4.23 1.63-3.11 0-5.02-1.86-5.73-4.15-.92-.25-1.85-.51-2.77-.77-1.01 1.18-2.52 1.96-4.54 1.96-3.22 0-5.14-2.01-5.79-4.42-.58.17-1.21.27-1.91.27-.42 0-.81-.05-1.18-.11-.37.07-.77.11-1.19.11-3.25 0-5.19-2.05-5.82-4.48-.76-.29-1.51-.59-2.27-.89-.96.75-2.21 1.22-3.76 1.22-1.67 0-2.99-.54-3.97-1.4-.28-.09-.55-.19-.81-.33-.47-.15-.9-.38-1.3-.67-.14.01-.28.03-.43.03h-1.19c-.5.02-.98-.05-1.44-.17-.47.11-.98.17-1.52.17-2.16 0-3.74-.9-4.74-2.22-1 1.32-2.58 2.22-4.74 2.22-.2 0-.39-.02-.58-.03-1.29.18-2.61-.04-3.77-.83a26.116 26.116 0 0 1-6.99-6.99c-.72-1.04-.94-2.61-.79-3.95-.38-.17-.72-.39-1.04-.63-.63-.22-1.19-.57-1.68-1.03-.68.27-1.46.42-2.33.42-1.94 0-3.41-.73-4.42-1.83-2.12-.25-3.58-1.45-4.4-3-.6-.1-1.15-.27-1.64-.51h-.21c-3.68-.06-6.98-1.04-10.27-2.63-.67-.33-1.25-.82-1.71-1.41-3.23-1.36-4.25-5.34-3.06-8.36-22.58-2.79-109.85-7.51-189.77-31.5l-55.96.09c2.25 7.59 3.22 15.53 2.9 23.81-1.94 16.18-.92 32.44-.29 48.66.65 16.86.22 33.65-.03 50.51-.13 8.51.19 16.98.39 25.49.05 2.02.03 4.04 0 6.06 36.91-.61 73.82-1.13 110.74-1.44 187.76-1.57 375.51-2.09 563.26-1.24v-62.36c-30.08-.2-60.15.6-90.24.36Z"
      style={{
        fill: "url(#j)",
      }}
    />
    <Path
      d="M1088.45 288.78c-11.56-.84-17.44-6.24-24.2-1.38-3.99-6.47-6.66-6.96-12.49-1.5-.29-1.32-.08-2.85-.9-3.7-2.27-2.36-4.61-5.33-7.7-6.36-6.31-2.09-3.54 7.76-9.48 7.52-2.22-.09-4.39-1.39-7.91-2.17-2.51-.55-5.29-.84-7.89-1.28l-.91 1.43c-6.13-16.51-5.33-19.7-11.47-21.29-1.4-.36-3.37.98-5.07 1.56-2.09-.25-4.8-1.15-6.73-.57-4.08 1.22.43 16.44-3.65 18.15-7.17 2.99-9.9 8.01-7.76 15.41-16.45-8.07-13.04-6.25-14.16-7.94-4.21-6.4-8.59-.01-10.35-.57-6.48-2.04-7.39.88-6.62 4.68-5.67 4.09-4.38-.68-5.9 16.44-6.65-1.15-16.32-2.13-20.08-5.39-3.37-2.91-6.34-3.09-9.66-1.04-3.74 2.31-5.01 6.88-10.75 7.58-1.32.16-1.79 3.24-2.84 4.87-1.5 2.33-2.49 5.5-4.9 6.66-2.3 1.12-4.63 2.08-6.44 3.43-8.86 7.07 1.91 5.94-13 7.75-1.75.2-7.93.93-9.5 1.75-.53.25-.54 1.53.12 2.16 1.5 1.44 5.94 5.27 5.91 5.25-.04-.02-1.18-1.78-.02-.04-.55-1.24 8.4-2.74 11.34-3.27 49.15-8.91 114.63-28.49 199.8-29.25l3.2-18.9h.01Z"
      style={{
        fill: "url(#k)",
      }}
    />
    <Path
      d="M965.93 202.98c1.76-.03 3.69-.08 5.76-.15.22.35.48.68.78.97.08 1.24.47 2.45 1.18 3.45v.15c-.69.64-1.29 1.4-1.75 2.26-.82 1.54-1.01 3.15-.63 4.83.06.26.14.5.24.74-36.13-.6-74.73.26-99.41-1.04-32.18-1.7 79.33-10.95 93.83-11.21Z"
      style={{
        fill: "url(#l)",
      }}
    />
    <Path
      d="M862.9 216.27c3.19-2.9 2.17-1.03 31.51-3.95 8.08-.86 10.35-.47 11.23-1.62 2.18-2.86 6.53-1.81 20.23-.86 13.74.92 17.2-2.21 24.25 1.31 3.55-3.85 6.22-2.98 11.24.11.13-.74-.2-1.64.46-2.06 1.82-1.18 3.63-2.72 6.25-3.09 2.79-.39 3.82.94 4.73 2.2-.34.42-.65.86-.91 1.35-.82 1.54-1.01 3.15-.63 4.83.24 1.05.84 1.97 1.6 2.72.07.55.23 1.08.49 1.59.25.78.68 1.45 1.3 2.02l.08.08a7.06 7.06 0 0 0-.08 5.04c-13.71-1.8-27.95-3.48-42.84-4.92-58.19-5.55-63.61-1.25-68.2-2.36-.8-.19-1.32-1.81-.7-2.37v-.02Z"
      style={{
        fill: "url(#m)",
      }}
    />
    <Path
      d="m730.42 195.29 1.77.09c60.41 3.31 161.2 22.95 224.85 24.66-102.41-16.13-170.03-30.41-226.72-40.47-.47 2.08-1.02 4.17-1.68 6.25-.12.5-.23.99-.35 1.49.87 2.62 1.57 5.28 2.13 7.98Z"
      style={{
        fill: "url(#n)",
      }}
    />
    <Path
      d="M1120.52 278.67a20.784 20.784 0 0 1-5.05-8.22c-.52-.54-.83-1.17-.73-2.05.02-.19.03-.38.05-.57-.15-.77-.25-1.54-.31-2.32-1.7-3.18-2.74-6.64-2.58-10.64.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.73-12.94-6.47-.64 2.48-1.8 4.71-3.68 6.78-1.56 1.71-3.38 2.94-4.49 4.68-.34 5.63.26 12.55-.17 12.87h-.01c-2.4 1.8-1.67 4.15-1.5 6.49.66 9.36-.26 11.55 2.12 17.18.3.69 1.77 1.59 2.3 1.39 1.97-.75 2.53.76 3.68 1.56 5.85 4.05 11.32 7.84 16.53-.95 1.33 2.3 2.51 4.33 5.29 3.66 3.12-.75 5.48-2.85 6.13-5.69.95-4.13 1.38-8.36.91-12.53-.46-.55-.9-1.11-1.3-1.71Z"
      style={{
        fill: "url(#o)",
      }}
    />
    <Path
      d="M1136.14 287.16c-3.8-.15-7.23-1.27-10.12-3.12-1.6.45-3.27 1.39-4.15 2.17-.74.65-.06 2.35.92 2.5 2.22.34 8.92-.73 15.14-1.5-.6-.01-1.19-.02-1.79-.05Z"
      style={{
        fill: "url(#p)",
      }}
    />
    <Path
      d="M2132.81 242.67c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#ae)",
      }}
    />
    <Path
      d="M2147.2 288.51c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05h.01Z"
      style={{
        fill: "url(#af)",
      }}
    />
    <Path
      d="M2069.99 238.53c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#ag)",
      }}
    />
    <Path
      d="M2084.38 284.36c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05Z"
      style={{
        fill: "url(#ah)",
      }}
    />
    <Path
      d="M2415.19 284.47c-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-6.68-.26-12.27-3.49-15.62-8.49-3.44-3.42-5.65-8.16-6.04-13.12-1.72-3.19-2.76-6.67-2.6-10.68.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.74-12.94-6.48-.64 2.48-1.8 4.71-3.68 6.79-2.36 2.6-5.33 4.08-5.52 7.91-.16 3.2-2.94 5.35-6.08 4.63-.83-.19-1.67-.39-2.5-.59-1 .99-2.41 1.63-4.23 1.63-3.11 0-5.02-1.86-5.73-4.15-.92-.25-1.85-.51-2.77-.77-1.01 1.18-2.52 1.96-4.54 1.96-3.22 0-5.14-2.01-5.79-4.42-.58.17-1.21.27-1.91.27-.42 0-.81-.05-1.18-.11-.37.07-.77.11-1.19.11-3.25 0-5.19-2.05-5.82-4.48-.76-.29-1.51-.59-2.27-.89-.96.75-2.21 1.22-3.76 1.22-1.67 0-2.99-.54-3.97-1.4-.28-.09-.55-.19-.81-.33-.47-.15-.9-.38-1.3-.67-.14.01-.28.03-.43.03h-1.19c-.5.02-.98-.05-1.44-.17-.47.11-.98.17-1.52.17-2.16 0-3.74-.9-4.74-2.22-1 1.32-2.58 2.22-4.74 2.22-.2 0-.39-.02-.58-.03-1.29.18-2.61-.04-3.77-.83a26.116 26.116 0 0 1-6.99-6.99c-.72-1.04-.94-2.61-.79-3.95-.38-.17-.72-.39-1.04-.63-.63-.22-1.19-.57-1.68-1.03-.68.27-1.46.42-2.33.42-1.94 0-3.41-.73-4.42-1.83-2.12-.25-3.58-1.45-4.4-3-.6-.1-1.15-.27-1.64-.51h-.21c-3.68-.06-6.98-1.04-10.27-2.63-.67-.33-1.25-.82-1.71-1.41-3.23-1.36-4.25-5.34-3.06-8.36-22.58-2.79-109.85-7.51-189.77-31.5l-271.91.42v154.7c3.31.5 6.61 1.03 9.9 1.56 2.92.23 5.85.36 8.78.38a95.563 95.563 0 0 1 26.81.31c19.44-.03 38.88-.28 58.31-.65 75.29-1.44 150.54-2.91 225.84-3.54 187.76-1.57 375.51-2.09 563.26-1.24v-62.36c-30.08-.2-60.15.6-90.24.36Z"
      style={{
        fill: "url(#ai)",
      }}
    />
    <Path
      d="M2187.96 293.89c-11.56-.84-17.44-6.24-24.2-1.38-3.99-6.47-6.66-6.96-12.49-1.5-.29-1.32-.08-2.85-.9-3.7-2.27-2.36-4.61-5.33-7.7-6.36-6.31-2.09-3.54 7.76-9.48 7.52-2.22-.09-4.39-1.39-7.91-2.17-2.51-.55-5.29-.84-7.89-1.28l-.91 1.43c-6.13-16.51-5.33-19.7-11.47-21.29-1.4-.36-3.37.98-5.07 1.56-2.09-.25-4.8-1.15-6.73-.57-4.08 1.22.43 16.44-3.65 18.15-7.17 2.99-9.9 8.01-7.76 15.41-16.45-8.07-13.04-6.25-14.16-7.94-4.21-6.4-8.59-.01-10.35-.57-6.48-2.04-7.39.88-6.62 4.68-5.67 4.09-4.38-.68-5.9 16.44-6.65-1.15-16.32-2.13-20.08-5.39-3.37-2.91-6.34-3.09-9.66-1.04-3.74 2.31-5.01 6.88-10.75 7.58-1.32.16-1.79 3.24-2.84 4.87-1.5 2.33-2.49 5.5-4.9 6.66-2.3 1.12-4.63 2.08-6.44 3.43-8.86 7.07 1.91 5.94-13 7.75-1.75.2-7.93.93-9.5 1.75-.53.25-.54 1.53.12 2.16 1.5 1.44 5.94 5.27 5.91 5.25-.04-.02-1.18-1.78-.02-.04-.55-1.24 8.4-2.74 11.34-3.27 49.15-8.91 114.63-28.49 199.8-29.25l3.2-18.9h.01Z"
      style={{
        fill: "url(#aj)",
      }}
    />
    <Path
      d="M2065.44 208.09c1.76-.03 3.69-.08 5.76-.15.22.35.48.68.78.97.08 1.24.47 2.45 1.18 3.45v.15c-.69.64-1.29 1.4-1.75 2.26-.82 1.54-1.01 3.15-.63 4.83.06.26.14.5.24.74-36.13-.6-74.73.26-99.41-1.04-32.18-1.7 79.33-10.95 93.83-11.21Z"
      style={{
        fill: "url(#ak)",
      }}
    />
    <Path
      d="M1962.41 221.38c3.19-2.9 2.17-1.03 31.51-3.95 8.08-.86 10.35-.47 11.23-1.62 2.18-2.86 6.53-1.81 20.23-.86 13.74.92 17.2-2.21 24.25 1.31 3.55-3.85 6.22-2.98 11.24.11.13-.74-.2-1.64.46-2.06 1.82-1.18 3.63-2.72 6.25-3.09 2.79-.39 3.82.94 4.73 2.2-.34.42-.65.86-.91 1.35-.82 1.54-1.01 3.15-.63 4.83.24 1.05.84 1.97 1.6 2.72.07.55.23 1.08.49 1.59.25.78.68 1.45 1.3 2.02l.08.08a7.06 7.06 0 0 0-.08 5.04c-13.71-1.8-27.95-3.48-42.84-4.92-58.19-5.55-63.61-1.25-68.2-2.36-.8-.19-1.32-1.81-.7-2.37v-.02Z"
      style={{
        fill: "url(#al)",
      }}
    />
    <Path
      d="M1628.65 176.45c.6-.19 1.21-.39 1.83-.58 38.15.47 77.15-.67 115.01.25 68.41 1.67 112.43 18.21 175.2 26.76 59.76 8.13 73.95 19.39 135.87 22.28 2.53.12 8.85.77 17.37 1.79-.68 2.86.44 6.21 3.35 7.43.46.59 1.04 1.08 1.72 1.41 3.29 1.59 6.59 2.57 10.27 2.63h.22c.49.23 1.03.41 1.62.51.82 1.55 2.28 2.75 4.41 3 1.01 1.1 2.48 1.83 4.42 1.83.87 0 1.65-.15 2.33-.42.49.46 1.05.81 1.69 1.03.32.24.66.46 1.04.63-.15 1.34.07 2.91.79 3.95 1.19 1.75 2.54 3.26 4.05 4.64-31.03 1.59-64.26 3.27-86.83 6.09-74 9.24-148.29 20.24-223.99 25.77-61.78 4.51-124.44 3.24-186.49 6.14V182.47c5.26-2.31 10.68-4.28 16.12-6.02Z"
      style={{
        fill: "url(#S)",
      }}
    />
    <Path
      d="M1628.65 173.43c3.85-1.23 8.02-2.56 12.27-3.6 21.33-1.31 43.32-3.69 63.35-2.97 97.2 3.48 169.05 26.43 352.28 55.28-63.65-1.71-164.44-21.35-224.85-24.66-158.43-8.69-173.06 10.01-219.17 4.21v-22.24c5.26-2.31 10.68-4.28 16.12-6.02Z"
      style={{
        fill: "url(#am)",
      }}
    />
    <Path
      d="M1624.41 177.86c24.28-.99 50.82-1.5 79.77-1.36 4.57.02 5.75 3.67 1.49 4.63-20.58 4.67-59.4 4.35-93.14 13.3v-11.96c3.89-1.71 7.87-3.23 11.88-4.61Z"
      style={{
        fill: "url(#I)",
      }}
    />
    <Path
      d="M1707.12 232.71c-4.35-6.32-10.52-8.45-17.86-7.61-15.93 1.82-10.45 16.06-17.58 22.93-2.12 2.04-1.34 12.07-2.75 12.58-1.15.42-2.96.19-4.01-.53-6.54-4.49-7.52-4.21-12.07 2.11-2.91 4.05-5.86 7.89-6.46 13.14-.11.94-1.23 2.22-2.13 2.5-4.61 1.44-3.87 4.79-3.19 8.35.76 4 3.29 4.41 6.48 3.81 26.87-5.04 17.86-2.83 43.75-2.58 11.96.12 23.93-.42 35.89-.58 17.46-.23 19.01 1.37 29.19-2.84.7-.29 1.16-3.89.49-4.48-5.77-5.16-10.48-6.43-11.62-9.89-2.85-8.64-2.82-8.41-11.48-8.78-2.25-.1-4.34-2.88-6.67-3.25-3.71-.59-3.09-3.17-3.8-5.65-6.94-24.44-9.73-22.4-16.18-19.23Z"
      style={{
        fill: "url(#J)",
      }}
    />
    <Path
      d="M1679.62 268.12c1.21.38 3.01.06 3.99-.69 1.19-.91-2.53-10.25-1.11-12.83 2.33-4.25 1.53-8.24 1.23-12.47-.69-9.71 5.48-14.89 14.31-16.37 3.54 1.11 6.6 3.35 9.08 6.94 6.11-3 7.61-2.77 10.55 3.42 8.11 17.04 4.09 20.61 9.43 21.46 2.33.37 4.42 3.15 6.67 3.25 8.65.37 8.63.14 11.48 8.78.71 2.16 2.65 3.27 4.62 4.64-9.09 2.76-27.86 9.6-39.79 12.94-30.22.72-32.15-.93-43.8-.31-.51-4.66 1.01-8.84 2.45-13.17 2.46-7.39 3.32-7.95 10.89-5.61v.02Z"
      style={{
        fill: "url(#K)",
      }}
    />
    <Path
      d="M1690.43 162.23c-7.72-4.01-8.83 4.25-11.26 12.32-1.91 0-3.3-.15-4.64.03-2.35.33-3.75 2.37-3.13 4.3.63 1.95 3.56 3.52 5.33 2.91 8.15-2.82 10.64-4.06 13.36-1.31 1.88 1.9 6.17 1.68 9.24.26.95.75 1.74 1.73 2.76 2.12 7.46 2.78 7.59-6.95 3.79-9.66-2.53-1.81-1.56-8.52-2.93-14.52-1.31-5.77-6.89-5.19-10.22-1.32-1.09 1.27-1.52 3.12-2.3 4.87Z"
      style={{
        fill: "url(#L)",
      }}
    />
    <Path
      d="M1779.73 272.44c1.91 2.25 3.62 4.23 7.62 3.58 4.49-.74 7.88-2.79 8.83-5.57 1.92-5.68 2.41-11.56-.17-17.21-1.66-3.64-9.25-3.56-8.72-6.79.97-5.88-2.5-11.25-4.75-16.73-1.48-3.58-3.55-3.98-9.52-2.68-3.82-5.37-5.54-9.37-11.28-8.82-6.6.63-4.69 8.66-8.04 9.26-8.85 1.59-7.04 7.98-7.06 18.16 0 .57.25 1.45-.2 1.68-3.45 1.76-2.4 4.06-2.16 6.35.93 8.98-.38 11.29 3.05 16.81.42.68 2.54 1.56 3.31 1.36 2.84-.73 3.64.75 5.3 1.53 8.41 3.97 16.29 7.68 23.81-.93h-.02Z"
      style={{
        fill: "url(#M)",
      }}
    />
    <Path
      d="M1872.41 281.32c1.33 1.36 2.51 2.56 5.29 2.16 3.12-.44 5.47-1.69 6.13-3.37 1.34-3.43 1.68-6.98-.12-10.39-1.2-2.28-6.44-2.07-6.05-4.1.67-3.55-1.73-6.8-3.3-10.1-1.03-2.16-2.47-2.4-6.61-1.62-2.68-3.28-3.86-5.66-7.83-5.33-4.59.38-3.25 5.23-5.58 5.59-6.07.95-4.89 4.66-4.9 10.97 0 .35.18.87-.14 1.01-2.4 1.06-1.67 2.45-1.5 3.83.66 5.53-.26 6.83 2.12 10.15.29.41 1.76.94 2.3.82 3.35-.75 12.55 8 20.21.37h-.02Z"
      style={{
        fill: "url(#N)",
      }}
    />
    <Path
      d="M2220.02 283.78a20.784 20.784 0 0 1-5.05-8.22c-.52-.54-.83-1.17-.73-2.05.02-.19.03-.38.05-.57-.15-.77-.25-1.54-.31-2.32-1.7-3.18-2.74-6.64-2.58-10.64.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.73-12.94-6.47-.64 2.48-1.8 4.71-3.68 6.78-1.56 1.71-3.38 2.94-4.49 4.68-.34 5.63.26 12.55-.17 12.87h-.01c-2.4 1.8-1.67 4.15-1.5 6.49.66 9.36-.26 11.55 2.12 17.18.3.69 1.77 1.59 2.3 1.39 1.97-.75 2.53.76 3.68 1.56 5.85 4.05 11.32 7.84 16.53-.95 1.33 2.3 2.51 4.33 5.29 3.66 3.12-.75 5.48-2.85 6.13-5.69.95-4.13 1.38-8.36.91-12.53-.46-.55-.9-1.11-1.3-1.71Z"
      style={{
        fill: "url(#an)",
      }}
    />
    <Path
      d="M2002.85 339.31c2.61.66 5.18 1.59 7.7 2.82a51.94 51.94 0 0 0-7.7-2.82Z"
      style={{
        fill: "url(#O)",
      }}
    />
    <Path
      d="M2235.65 292.27c-3.8-.15-7.23-1.27-10.12-3.12-1.6.45-3.27 1.39-4.15 2.17-.74.65-.06 2.35.92 2.5 2.22.34 8.92-.73 15.14-1.5-.6-.01-1.19-.02-1.79-.05Z"
      style={{
        fill: "url(#ao)",
      }}
    />
    <Path
      d="M1647.42 285.48c.99-.07 2.22-.68 2.52-1.33 1.76-3.77 5.83-3.05 10.27-2.83 7.9.32 16.55 1.17 23.15-.77 5.31-1.51 9.24-3 15.12-.24 1.64-6.91 4.68-5.78 11.42-2.19-.32-1.18-1.2-2.51-.81-3.34 1.06-2.28 1.83-5.11 4.07-6.3 4.58-2.47 6.9 6.4 12.23 5.45 1.41-.27 2.64-1.2 4.01-2.04 2.23-1.36 5-2.08 7.97-3.3l1.63 1.07c-4.43-14.94-7.1-17.58-3.19-20.11.85-.55 3.12.22 4.82.41 1.49-.61 2.97-1.85 4.78-1.75 2.65.16 7.52 8.01 10.87 11.92 3.93 4.58 6.69 1.13 14.31 6.07 2.85 1.84 5.09 4.35 6.75 7.5 2.28-2.97 4.38-5.74 6.31-8.59.26-.38-3.04-6.45 5.48-4.31.74.19 1.89.38 2.2.11 3.71-3.23 6.59-1.08 8.86 2.21 8 2 2.76-1.66 17.48 11.86 3.99-2.31 11.65-5.92 12.02-9.64.9-8.84 14.57 1.02 21.52-.5 1.38-.3 11.24 6.47 15.95 6.77 5.6.37 10.69.12 15.79 4.2 2.51 2.01 27.56-7.46 30.35-4.21 1.48 1.73 2.54 3.89 2.46 5.59-.08 1.89-10.02 2.13-10.37 2.18-72.46 11.12-98.98 11.67-131.96 14.99-104.69 9.83-174.53-4.95-199.63-6.88l38.73-1.08c12.16 1.12 22.72-.01 34.89-.92Z"
      style={{
        fill: "url(#P)",
      }}
    />
    <Path
      d="M2499.72 284.09c-28.18-.07-56.35.6-84.53.38-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-3.67-.14-7-1.2-9.82-2.94-43.68 7.35-92.27 16.9-146.01 29.3-59.14 13.64-114.58 23.05-165.64 29.35 9.33-.1 18.66-.19 27.99-.27 185.86-1.56 371.71-2.07 557.55-1.26v-62.35Z"
      style={{
        fill: "url(#ap)",
      }}
    />
    <Path
      d="M4009.15 264.62c-4.49-3.18-2.29-7.76-3.34-13.93-1.17-6.89-2.08-7.6-9.04-7.91h-.01c-2.23-10.3-1.3-14.72-14.19-24.75-8.41-6.54-11.13.66-14.78-.39-10.89-3.13-10.4 8.21-11.63 17.14-2.34-1.31-4.46 2.35-7.54-.22-4.34-3.62-7.53-3.47-10.07-1.22.7 1.97 1.04 4.05.9 6.16-.85 12.83-6.88 24.16-15.7 32.94l.03.38c.35 4.12 3.39 6.05 6.88 6.94 1.96.5 2.59 1.28 2.97 3.12.96 4.62 3.25 7.96 8.66 7.88.58 0 1.24.84 1.77 1.39 7.5 7.76 12.44-.37 15.71.5 16.66 4.43 19.27 9.26 30.83 7.38 9.71-1.58 10.8-11.52 15.05-12.96 3.74-1.27 5.47-3.85 5.97-7.37.7-4.87 1.54-12.24-2.47-15.08Z"
      style={{
        fill: "url(#aq)",
      }}
    />
    <Path
      d="M3982.38 288.62c-3.27-.87-8.21 7.26-15.71-.5-.53-.55-1.19-1.39-1.77-1.39-5.41.08-7.7-3.26-8.66-7.88-.38-1.84-1.01-2.62-2.97-3.12-3.49-.89-6.53-2.82-6.88-6.94-.66-7.8-2.33-19.6 5.27-18.43 4.57-12.45 8.04-29.46 19.57-19.83 3.08 2.57 5.2-1.09 7.54.22.66-4.84.98-10.77 2.39-13.73-7.25-4.74-9.93 1.61-13.37.62-10.88-3.13-10.4 8.2-11.63 17.14-2.34-1.31-4.46 2.35-7.54-.22-4.33-3.62-7.53-3.47-10.08-1.22.7 1.97 1.04 4.06.9 6.16-.85 12.83-6.88 24.16-15.7 32.94l.03.39c.35 4.12 3.38 6.05 6.88 6.94 1.96.5 2.59 1.28 2.97 3.12.96 4.62 3.25 7.96 8.66 7.88.59 0 1.25.84 1.77 1.39 7.46 7.73 12.46-.37 15.71.5 4.56 1.21 9.22 2.44 13.41 4.54 7.68 3.84 19.34 5.69 26.17-2.52-5.35-1.91-3.42-2.44-16.97-6.05Z"
      style={{
        fill: "url(#ar)",
      }}
    />
    <Path
      d="M3063.11 347.81c262.14-2.2 524.24-2.34 786.36.42 92.7-3.84 185.35-8.84 278.01-13.52-25.11-4.54-51.04-9.83-77.72-15.98-56.36-13-107.08-22.89-152.39-30.36-8.57 2.41-17.79 1.39-26.54.81-10.8-.72-21.62-1.13-32.43-1.53-38.92-1.46-77.94-1.86-116.74-5.49-3.17-.3-6.34-.55-9.51-.82-14.24 1.7-28.61 2.89-42.94 3.34-14.24.45-28.47-.37-42.71-.47-30.12-.2-60.24.6-90.37.36-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-3.67-.14-7-1.2-9.82-2.94-43.68 7.35-92.27 16.9-146.01 29.3-59.14 13.64-114.58 23.05-165.64 29.35 9.33-.1 18.66-.19 27.99-.27Z"
      style={{
        fill: "url(#as)",
      }}
    />
    <Path
      d="M4350.92 202.89c-7.21-1.07-14.02-2.3-19.58-8.66-1.23-1.4-5.67-.94-7.53.51-23.04 17.91-25.92-21.16-51.39-12.27-6.6 2.3-10.66-10.78-19.07-9.19-.43.08-1.41-1.11-1.55-1.85-1.23-6.81-5.28-9.15-9.69-8.82-2.7-6.36-4.62-12.12-7.43-17.25-6.15-11.22-15.83-12.14-21.98-.78-2.81 5.2-5.36 10.59-8.82 17.49-7.85-12.62-6.45-11.4-13.2-17.95-.84-.82-2.28-1.01-2.88-1.96-5.35-8.45-14.32-7.99-18.95 1.09-.91 1.79-1.69 3.67-2.77 6.05-3.94-11.09-14.53-21.11-26.57-16.11-8.67 3.6-18.79 5.51-20.89 18.92-.13.83-2.24 1.87-3.36 1.77-15.76-1.41-13.45 16.29-21.42 8.59-3.87-3.75-7.72-3.35-10.67.08l-.06.27c-2.42 8.57-7.96 15.02-12.79 22.19 1.03 3.12-.2 7.09-3.7 8.19.45.9.67 1.92.71 2.92.03.86-.15 1.67-.54 2.41-.25.78-.68 1.45-1.3 2.02-.52.55-1.12.95-1.81 1.21-.79.78-1.74 1.28-2.77 1.55-.33.29-.7.53-1.11.75-.14.26-.29.52-.43.77-.09 1.56-.67 3.09-1.77 4.19-.08 1.24-.47 2.45-1.18 3.44v.16c.69.64 1.29 1.4 1.75 2.26.25.48.44.96.58 1.45 14.68-.13 32.63-.2 54.81-.19.04 0 220.39 4.73 239.15-1.02 1.74-.53 3.56-4.93 3.49-7.5-.05-1.7-3.26-4.42-5.27-4.72Z"
      style={{
        fill: "url(#at)",
      }}
    />
    <Path
      d="M4476.18 171.06c-2.05-.07-4.07 0-6.05.28-3.33.46-6.75-.76-7.71-4.38-.07-.25-.11-.51-.14-.78-2.35-2.56-2.18-7.16.54-9.4-3.85-6.54-7.46-14.27-12.17-19.27-4.4-4.67-16.32-5.94-21.62-5.02-4.02.7-10.09 4.34-12.46 10.3-1.5-1.76-3.09-3.3-4.73-4.49-5.8-9.62-11.11-21.35-18.16-28.83-6.31-6.7-23.43-8.52-31.03-7.21-5.77 1-14.48 6.23-17.88 14.78-7.2-8.45-15.9-13.53-24.04-7.07.43-.34.88-.6 1.28-.77-14.71 16.75-40.82 21.85-48.62 31.34-2.55 3.1-1.08 7.77-4.82 9.63-9.03 4.47-9.49-26.05-38.64 8.11-5.72 6.71-7.56 7.3-15.33 8.13-5.01.54-10.93 1.59-14.9 5.37-8.31 27.32 7.43 29.38 16.41 29.53 56.65.94 58.73 1.45 101.26-2.39 3.13 2.48 5.83 3.39 46.93 3.02 22.99-.21 45.81-4.31 68.9-4.15 13.32.09 29.17 1.7 41.94-5.6-.36-7.17 1.24-14.04 1.06-21.15l-.02.02Z"
      style={{
        fill: "url(#au)",
      }}
    />
    <Path
      d="M4145.21 247.75c-2.05-.07-4.07 0-6.05.28-3.33.46-6.75-.76-7.71-4.38-.07-.25-.11-.51-.14-.78-2.35-2.56-2.18-7.16.54-9.4-3.85-6.54-7.46-14.27-12.17-19.27-4.4-4.67-16.32-5.94-21.62-5.02-4.02.7-10.09 4.34-12.46 10.3-1.5-1.76-3.09-3.3-4.73-4.49-5.8-9.62-11.11-21.35-18.16-28.83-6.31-6.7-23.43-8.52-31.03-7.21-5.77 1-14.48 6.23-17.88 14.78-7.2-8.45-15.9-13.53-24.04-7.07.43-.34.88-.6 1.28-.77-14.71 16.75-40.82 21.85-48.62 31.34-2.55 3.1-1.08 7.77-4.82 9.63-9.03 4.47-9.49-26.05-38.64 8.11-5.72 6.71-7.56 7.3-15.33 8.13-5.01.54-10.93 1.59-14.9 5.37-8.31 27.32 7.43 29.38 16.41 29.53 56.65.94 58.73 1.45 101.26-2.39 3.13 2.48 5.83 3.39 46.93 3.02 22.99-.21 45.81-4.31 68.9-4.15 13.32.09 29.17 1.7 41.94-5.6-.36-7.17 1.24-14.04 1.06-21.15l-.02.02Z"
      style={{
        fill: "url(#av)",
      }}
    />
    <Path
      d="M4237.42 217.09c-1.66-6.09-1.66-6.07-6.06-4.68-1.95.61-1.69-1.07-6-7.17-1.16-1.64-2.43-4.03-3.77-4.24-6.66-1.07-1.07-10.24-6.24-12.96-1.94-1.02-3.76-1.4-5.15 1.54-7.96 16.85-3.46 11.27-9.59 8.13-10.56-5.42-10.32-1.6-12.27-4.41-2.14-3.09-4.62-3.9-7.41-2.46-1.19.62-2.07.09-7.42-1.7-.9-15.03-.08-22.14-6.78-16.56-.81.67-6.83-17.95-9.33-21.35-.63-.86-1.7-1.47-2.5-1.3-.53.11-.99 1.74-1.34 2.81-.23.7-.2 1.61-.34 2.91-.71-.87-1.09-1.61-1.57-1.87-2.42-1.29-5.11-4.7-6.07 4.26-.31 2.91.1 6-.02 8.99-.07 1.84-.56 3.63-.75 4.79-3.49-2.33-4.11-4.67-5.22 2.28-.48 2.99-3.16 6.48-5.1 6.74-1.14.15-2.24 1.02-3.35 1.57-4.9-5.71-4.75-1.36-6.92 4.13-.51-1.33-.8-2.61-1.36-3.44-.63-.93-1.76-2.33-2.22-1.99-1.79 1.29-2.55-.74-3.17-2.28-4.42-10.89-3.45-16.74-7.25-13.17-.26.25-.94-.45-1.37-.85-2.45-2.23-4.78-.65-5.37 3.73-.27 1.99-.43 4.02-.66 6.25-.87-.35-1.61-.29-1.9-.83-.91-1.71-1.65-4.37-3.31-2.2-3.17 4.14-3.02 9.47-3.51 13.96-9.46 4.24-6.1-1.05-8.1-6.81-.74-2.12-2.07-4.84-3.34-5.13-4.05-.94-4.06 6.76-6.77 16.64-.31 1.14-1.26 1.84-2.03 2.89-1.35-3.18-.41-2.53-3.35-4.53-.3 1.33-.98 2.56-2.06 3.43-.28 2.37-.07 1.98.29 5.4-.54-.2-1.02-.37-1.46-.53-.08.5-.23.98-.47 1.43-.25.78-.68 1.45-1.3 2.02-.52.55-1.12.95-1.81 1.21-.8.79-1.77 1.29-2.81 1.57-.32.28-.68.52-1.06.73-.14.26-.29.52-.43.78-.09 1.56-.67 3.08-1.77 4.19-.08 1.24-.47 2.45-1.18 3.44v.16c.69.64 1.29 1.4 1.75 2.26.82 1.54 1.01 3.15.63 4.83-.24 1.06-.84 1.97-1.6 2.72-.03.2-.06.4-.12.6 4.9.17 9.8.42 14.7.55 35.21.92 53.42.14 60.01.44 10.15.45 20.28 1.68 30.42 2.22 10.05.53 20.16-.27 30.15 1.16 22.9 3.27 28.44-.98 41.19-.61 4.62.13 3.86-3.77 3.86-9.68h-.02Z"
      style={{
        fill: "url(#aw)",
      }}
    />
    <Path
      d="M4461.23 182.73c-14.18 8.02-17.15-18.1-34.65-11.65-4.81 1.77-7.91-8.34-14-7.12-.32.07-1.04-.86-1.14-1.43-.9-5.28-3.87-7.09-7.11-6.83-1.98-4.93-3.39-9.4-5.45-13.37-4.51-8.69-11.62-9.4-16.13-.6-2.07 4.03-3.93 8.21-6.48 13.56-5.86-9.94-4.8-8.9-9.69-13.91-.62-.64-1.67-.79-2.11-1.52-3.93-6.55-10.51-6.2-13.91.84-.67 1.39-1.23 2.85-2.03 4.69-2.89-8.6-10.66-16.37-19.5-12.49-6.37 2.79-13.8 4.27-15.34 14.66-.09.64-1.64 1.45-2.46 1.37-11.56-1.09-9.88 12.62-15.72 6.65-3.28-3.35-6.53-2.44-8.78 1.44-2.36 4.07-4.42 1.63-6.23-.05-1-.92-1.1-3.19-1.71-4.79-5.74-15.14-9.95-15.37-19.73-7.07-.61.51-1.09 1.34-1.75 1.61-1.52.62-3.25 1.69-4.63 1.29-3.48-1.01-6.19.41-7.49 3.83-4.3 11.36-22.34 5.56-27.76 22.78-.47 1.49-2.95 3.03-4.41 2.91-6.15-.5-11.36.98-15.13 7.41-.9 1.54-2.32 2.63-3.63 4.04-.66-.32-1.32-.85-2.01-.92-2.64-.27-6.71-1.77-7.68-.32-3.06 4.59-6.81 6.42-11.21 7.73-1.23.37-3.18 3.07-2.98 3.52.81 1.82 2.13 3.94 3.64 4.54 2.52.99 5.35 1.34 8.01 1.16 35.1-2.43 35.22-5.82 47.3-6.21 99.48-3.2 102.52.24 132.94.29 22.37.04 73.22-.42 97.88-.65-.05-5.13.31-10.26 1.09-15.39Z"
      style={{
        fill: "url(#ax)",
      }}
    />
    <Path
      d="m4194.25 188.98-.01.01.01-.01z"
      style={{
        fill: "url(#ay)",
      }}
    />
    <Path
      d="M3884.51 263.5c1.31-1.42 2.74-2.51 3.64-4.05 3.77-6.43 8.98-7.91 15.13-7.41 1.46.12 3.94-1.42 4.41-2.91 5.42-17.22 23.46-11.42 27.76-22.78 1.3-3.42 4.01-4.84 7.49-3.83 1.38.4 3.11-.67 4.63-1.29.66-.27 1.14-1.1 1.75-1.61 9.78-8.3 13.99-8.07 19.73 7.07.61 1.6.71 3.87 1.71 4.79 1.81 1.68 3.87 4.12 6.23.05 2.25-3.88 5.5-4.79 8.78-1.44 5.84 5.97 4.16-7.74 15.72-6.65.82.08 2.37-.73 2.46-1.37 1.54-10.39 8.97-11.87 15.34-14.66 8.84-3.88 16.61 3.89 19.5 12.49.8-1.84 1.36-3.3 2.03-4.69 3.4-7.04 9.98-7.39 13.91-.84.44.73 1.49.88 2.11 1.52 4.89 5.01 3.83 3.97 9.69 13.91 2.55-5.35 4.41-9.53 6.48-13.56 4.51-8.8 11.62-8.09 16.13.6 2.06 3.97 3.47 8.44 5.45 13.37 3.24-.26 6.21 1.55 7.11 6.83.1.57.82 1.5 1.14 1.43 6.09-1.22 9.19 8.89 14 7.12 18.7-6.89 20.81 23.39 37.72 9.51 1.37-1.12 4.63-1.48 5.53-.39 4.08 4.93 9.08 5.88 14.37 6.71 1.48.23 3.84 2.34 3.87 3.66.05 1.99-1.28 5.4-2.56 5.81-3.67 1.19-7.61 1.3-11.45 1.6-.03 0-81.7.84-111.79.79-30.42-.05-33.46-3.49-132.94-.29-12.08.39-12.2 3.78-47.3 6.21-2.66.18-5.49-.17-8.01-1.16-1.51-.6-2.83-2.72-3.64-4.54-.2-.45 1.75-3.15 2.98-3.52 4.4-1.31 8.15-3.14 11.21-7.73.97-1.45 5.04.05 7.68.32.69.07 1.35.6 2.01.92Z"
      style={{
        fill: "url(#az)",
      }}
    />
    <Path
      d="M4090.23 170.71c7.5-2.32 15.09-4.5 20.88-9.61 3.02-2.67 6.14-6.31 10.15-5.79-1.35-1.68-.27-4.33 1.55-5.5s4.08-1.32 6.22-1.69c7.03-1.24 13.46-5.18 20.58-5.72 9.25-.69 17.87 4.42 27.02 5.97 1.97.33 4.06.53 5.7 1.66s2.58 3.58 1.33 5.12c4.32-.54 7.65 3.39 10.96 6.19 7.55 6.36 18.02 7.7 27.23 11.32 1.02.4 2.07.87 2.72 1.74 3.85 5.2-12.62 8.04-15.41 7.9-4.27-.21-8.51-1.62-12.74-.98-3.68.56-6.91 2.62-10.34 4.04-11.1 4.6-23.68 2.44-35.71 2.57-9.85.11-20.1 1.75-29.29-1.78-4.04-1.55-7.77-4.07-12.05-4.63-3.68-.48-7.36.53-11.06.84-5.72.48-11.57-.79-16.56-3.58-.66-.37-1.33-.8-1.64-1.48-.84-1.82 1.41-3.5 3.31-4.21 2.34-.88 4.75-1.64 7.16-2.38Z"
      style={{
        fill: "url(#aA)",
      }}
    />
    <Path
      d="M3996.62 225.15c7.5-2.32 15.09-4.5 20.88-9.61 3.02-2.67 6.14-6.31 10.15-5.79-1.35-1.68-.27-4.33 1.55-5.5s4.08-1.32 6.22-1.69c7.03-1.24 13.46-5.18 20.58-5.72 9.25-.69 17.87 4.42 27.02 5.97 1.97.33 4.06.53 5.7 1.66s2.58 3.58 1.33 5.12c4.32-.54 7.65 3.39 10.96 6.19 7.55 6.36 18.02 7.7 27.23 11.32 1.02.4 2.07.87 2.72 1.74 3.85 5.2-12.62 8.04-15.41 7.9-4.27-.21-8.51-1.62-12.74-.98-3.68.56-6.91 2.62-10.34 4.04-11.1 4.6-23.68 2.44-35.71 2.57-9.85.11-20.1 1.75-29.29-1.78-4.04-1.55-7.77-4.07-12.05-4.63-3.68-.48-7.36.53-11.06.84-5.72.48-11.57-.79-16.56-3.58-.66-.37-1.33-.8-1.64-1.48-.84-1.82 1.41-3.5 3.31-4.21 2.34-.88 4.75-1.64 7.16-2.38Z"
      style={{
        fill: "url(#aB)",
      }}
    />
    <Path
      d="M4059.59 238.64c6.96.31 7.87 1.02 9.04 7.91 1.05 6.17-1.15 10.75 3.34 13.93 4.01 2.84 3.17 10.21 2.47 15.08-.5 3.52-2.23 6.1-5.97 7.37-4.25 1.44-5.34 11.38-15.05 12.96-11.56 1.88-14.17-2.95-30.83-7.38-3.27-.87-8.21 7.26-15.71-.5-.53-.55-1.19-1.39-1.77-1.39-5.41.08-7.7-3.26-8.66-7.88-.38-1.84-1.01-2.62-2.97-3.12-3.49-.89-6.53-2.82-6.88-6.94-.66-7.8-2.33-19.6 5.27-18.43 4.57-12.44 8.04-29.46 19.57-19.83 3.08 2.57 5.2-1.09 7.54.22 1.23-8.93.74-20.27 11.63-17.14 3.65 1.05 6.37-6.15 14.78.39 12.89 10.03 11.96 14.45 14.19 24.75h.01Z"
      style={{
        fill: "url(#aC)",
      }}
    />
    <Path
      d="M4045.2 284.47c-3.27-.87-8.21 7.26-15.71-.5-.53-.55-1.19-1.39-1.77-1.39-5.41.08-7.7-3.26-8.66-7.88-.38-1.84-1.01-2.62-2.97-3.12-3.49-.89-6.53-2.82-6.88-6.94-.66-7.8-2.33-19.6 5.27-18.43 4.57-12.45 8.04-29.46 19.57-19.83 3.08 2.57 5.2-1.09 7.54.22.66-4.84.98-10.77 2.39-13.73-7.25-4.74-9.93 1.61-13.37.62-10.88-3.13-10.4 8.2-11.63 17.14-2.34-1.31-4.46 2.35-7.54-.22-11.6-9.69-15.1 7.65-19.57 19.83-7.61-1.17-5.93 10.6-5.27 18.43.35 4.12 3.38 6.05 6.88 6.94 1.96.5 2.59 1.28 2.97 3.12.96 4.62 3.25 7.96 8.66 7.88.59 0 1.25.84 1.77 1.39 7.46 7.73 12.46-.37 15.71.5 4.56 1.21 9.22 2.44 13.41 4.54 7.68 3.84 19.34 5.69 26.17-2.52-5.35-1.91-3.42-2.44-16.97-6.05Z"
      style={{
        fill: "url(#aD)",
      }}
    />
    <Path
      d="M4462.57 294.39c-.07-.65-.15-1.3-.24-1.95l.25 1.89s0-.03.01-.04c-1.22-9.91-2.23-19.82-2.65-29.85-.53-12.55 1.12-24.92 3.07-37.28.1-1.99.15-3.99.15-5.98-2.21-8.74-3.21-17.48-3.01-26.22l-215.01-.33c-79.92 23.99-167.19 28.71-189.77 31.5 1.19 3.02.17 7-3.06 8.36-.46.59-1.04 1.08-1.71 1.41-3.29 1.59-6.59 2.57-10.27 2.63h-.21c-.49.24-1.04.41-1.64.51-.23.04-1.09 1.53-1.42 1.77-.53.38-1.22.63-1.8.95-1.61.9-3.2 1.81-5.08 1.95-1.75.13-3.87-.26-4.97 1.11-.63.78-.65 1.86-.9 2.83-.42 1.65-1.53 3.02-2.67 4.28-1.82 2-3.9 3.91-6.47 4.72-2.57.81-5.75.25-7.34-1.93-.05.5-.63.72-1.11.84l-11.19 2.7c-1.6.39-3.25.78-4.88.56-1.07-.15-2.19-.55-3.2-.16-1.11.42-1.68 1.63-2.43 2.55-1.32 1.63-3.51 2.52-5.6 2.26-.97-.12-2.03-.45-2.88.05-.52.31-.83.87-1.18 1.35-1.62 2.2-4.81 3.09-7.33 2.04-1.06-.44-2.09-1.19-3.22-1.04-1.93.26-2.57 2.79-4.17 3.9-1.89 1.31-4.29.29-6.27-.2-2.31-.57-5.05.55-7.15-.43-1.59-.74-2.56-2.57-2.64-4.27-.19-3.83-3.16-5.31-5.52-7.91-1.1-1.22-1.91-2.51-2.57-3.91-6.05 16.11-20.41 28.57-36.7 34.55-9.24 3.39-19.39 2.21-28.96 1.58-10.8-.72-21.62-1.13-32.43-1.53-38.92-1.46-77.94-1.86-116.74-5.49-3.15-.3-6.3-.56-9.46-.83-14.26 1.7-28.65 2.9-42.99 3.35-14.24.45-28.47-.37-42.71-.47h-2.36v62.36c75.17.33 150.34.88 225.52 1.67 157.65-6.54 315.13-16.44 472.8-22.37 45.36-1.71 90.73-3.13 136.11-4.33.86-9.55 1.4-18.42 4-27.15Z"
      style={{
        fill: "url(#aE)",
      }}
    />
    <Path
      d="M4112.03 335.5c10.53-.54 21.05-1.07 31.58-1.59-.15-.9-.44-2.42-4.13-5.37-1.81-1.35-4.14-2.31-6.44-3.43-2.41-1.16-3.4-4.33-4.9-6.66-1.05-1.63-1.52-4.71-2.84-4.87-5.74-.7-7.01-5.27-10.75-7.58-3.32-2.05-6.29-1.87-9.66 1.04-3.76 3.26-13.43 4.24-20.08 5.39-1.52-17.12-.23-12.35-5.9-16.44.77-3.8-.14-6.72-6.62-4.68-1.76.56-6.14-5.83-10.35.57-1.12 1.69 2.29-.13-14.16 7.94 2.14-7.4-.59-12.42-7.76-15.41-4.08-1.71.43-16.93-3.65-18.15-1.93-.58-4.64.32-6.73.57-1.7-.58-3.67-1.92-5.07-1.56-6.14 1.59-5.34 4.78-11.47 21.29l-.91-1.43c-2.6.44-5.38.73-7.89 1.28-3.52.78-5.69 2.08-7.91 2.17-5.94.24-3.17-9.61-9.48-7.52-3.09 1.03-5.43 4-7.7 6.36-.82.85-.61 2.38-.9 3.7-5.83-5.46-8.5-4.97-12.49 1.5-6.76-4.86-12.63.53-24.18 1.38l3.2 18.89c67.56.6 122.73 13.05 167.19 22.61Z"
      style={{
        fill: "url(#aF)",
      }}
    />
    <Path
      d="M4064.14 208.2c-1.76-.03-3.69-.08-5.76-.15-.22.35-.48.68-.78.97-.08 1.24-.47 2.45-1.18 3.45v.15c.69.64 1.29 1.4 1.75 2.26.82 1.54 1.01 3.15.63 4.83-.06.26-.14.5-.24.74 36.13-.6 74.73.26 99.41-1.04 32.18-1.7-79.33-10.95-93.83-11.21Z"
      style={{
        fill: "url(#aG)",
      }}
    />
    <Path
      d="M4167.17 221.49c-3.19-2.9-2.17-1.03-31.51-3.95-8.08-.86-10.35-.47-11.23-1.62-2.18-2.86-6.53-1.81-20.23-.86-13.74.92-17.2-2.21-24.25 1.31-3.55-3.85-6.22-2.98-11.24.11-.13-.74.2-1.64-.46-2.06-1.82-1.18-3.63-2.72-6.25-3.09-2.79-.39-3.82.94-4.73 2.2.34.42.65.86.91 1.35.82 1.54 1.01 3.15.63 4.83-.24 1.05-.84 1.97-1.6 2.72-.07.55-.23 1.08-.49 1.59-.25.78-.68 1.45-1.3 2.02l-.08.08c.64 1.58.68 3.43.08 5.04 13.71-1.8 27.95-3.48 42.84-4.92 58.19-5.55 63.61-1.25 68.2-2.36.8-.19 1.32-1.81.7-2.37v-.02Z"
      style={{
        fill: "url(#aH)",
      }}
    />
    <Path
      d="M4459.96 264.45c-.53-12.55 1.12-24.92 3.07-37.28.1-1.99.15-3.99.15-5.98-3.81-15.05-4.04-30.09-.69-45.14-26.24-.12-52.57-.44-78.38.18-68.41 1.67-112.43 18.21-175.2 26.76-59.76 8.13-73.95 19.39-135.87 22.28-2.53.12-8.85.77-17.37 1.79.68 2.86-9.39 3.02-12.3 4.25-8.4 6.71-20 7.41-23.56 17.11-1.19 1.75 1.46 3.91-.05 5.28 31.03 1.59 64.26 3.27 86.83 6.09 74 9.24 148.29 20.24 223.99 25.77 43.58 3.19 87.6 3.5 131.53 4.42-.98-8.47-1.78-16.95-2.14-25.53Z"
      style={{
        fill: "url(#aI)",
      }}
    />
    <Path
      d="M4463.54 171.65c.06-.15.13-.3.19-.45-13.14-.93-26.15-1.65-38.42-1.21-97.2 3.48-169.05 26.43-352.28 55.28 63.65-1.71 164.44-21.35 224.85-24.66 89.48-4.9 133.09-1.07 162.41 2.14-.61-10.37.47-20.74 3.25-31.11Z"
      style={{
        fill: "url(#aJ)",
      }}
    />
    <Path
      d="M4349.85 272.55c-1.91 2.25-3.62 4.23-7.62 3.58-4.49-.74-7.88-2.79-8.83-5.57-1.92-5.68-2.41-11.56.17-17.21 1.66-3.64 9.25-3.56 8.72-6.79-.97-5.88 2.5-11.25 4.75-16.73 1.48-3.58 3.55-3.98 9.52-2.68 3.82-5.37 5.54-9.37 11.28-8.82 6.6.63 4.69 8.66 8.04 9.26 8.85 1.59 7.04 7.98 7.06 18.16 0 .57-.25 1.45.2 1.68 3.45 1.76 2.4 4.06 2.16 6.35-.93 8.98.38 11.29-3.05 16.81-.42.68-2.54 1.56-3.31 1.36-2.84-.73-3.64.75-5.3 1.53-8.41 3.97-16.29 7.68-23.81-.93h.02Z"
      style={{
        fill: "url(#aK)",
      }}
    />
    <Path
      d="M4257.17 281.43c-1.33 1.36-2.51 2.56-5.29 2.16-3.12-.44-5.47-1.69-6.13-3.37-1.34-3.43-1.68-6.98.12-10.39 1.2-2.28 6.44-2.07 6.05-4.1-.67-3.55 1.73-6.8 3.3-10.1 1.03-2.16 2.47-2.4 6.61-1.62 2.68-3.28 3.86-5.66 7.83-5.33 4.59.38 3.25 5.23 5.58 5.59 6.07.95 4.89 4.66 4.9 10.97 0 .35-.18.87.14 1.01 2.4 1.06 1.67 2.45 1.5 3.83-.66 5.53.26 6.83-2.12 10.15-.29.41-1.76.94-2.3.82-3.35-.75-12.55 8-20.21.36l.02.02Z"
      style={{
        fill: "url(#aL)",
      }}
    />
    <Path
      d="M3943.71 274.49h-.01c-.43-.32.17-7.24-.17-12.87-1.11-1.74-2.93-2.97-4.49-4.68-1.09-1.2-1.94-2.46-2.58-3.79-4.95 13.1-15.41 23.78-27.92 30.51-.88 4.8-.47 9.71.62 14.47.65 2.84 3.01 4.94 6.13 5.69 2.78.67 3.96-1.36 5.29-3.66 5.21 8.79 10.68 5 16.53.95 1.15-.8 1.71-2.31 3.68-1.56.53.2 2-.7 2.3-1.39 2.38-5.63 1.46-7.82 2.12-17.18.17-2.34.9-4.69-1.5-6.49Z"
      style={{
        fill: "url(#aM)",
      }}
    />
    <Path
      d="M4458.35 338.29c.26-5.31-.34-10.61.15-15.9.18-1.93.34-3.83.51-5.71-145.68 7.87-821.29 17.52-863.22 29.76 23.92-.7 714.33-.05 862.56.19-.12-2.8-.13-5.59 0-8.35Z"
      style={{
        fill: "url(#aN)",
      }}
    />
    <Path
      d="M3841.95 243.84c-.72-1.04-.94-2.61-.79-3.95-.38-.17-.72-.39-1.04-.63-.64-.22-1.2-.57-1.69-1.03-.68.27-1.46.42-2.33.42-1.94 0-3.41-.73-4.42-1.83-2.13-.25-3.59-1.45-4.41-3-.59-.1-1.13-.28-1.62-.51h-.22c-3.68-.06-6.98-1.04-10.27-2.63-.68-.33-1.26-.82-1.72-1.41-2.91-1.22-4.03-4.57-3.35-7.43-8.52-1.02-14.84-1.67-17.37-1.79-61.92-2.89-76.11-14.15-135.87-22.28-62.77-8.55-106.79-25.09-175.2-26.76-37.86-.92-76.86.22-115.01-.25-.62.19-1.23.39-1.83.58-4.41 1.41-8.79 2.98-13.1 4.75-29.81-.05-59.85-.57-89.21.14-68.41 1.67-112.43 18.21-175.2 26.76-59.76 8.13-73.95 19.39-135.87 22.28-2.53.12-8.85.77-17.37 1.79.68 2.86-9.39 3.02-12.3 4.25-8.4 6.71-20 7.41-23.56 17.11-1.19 1.75 1.46 3.91-.05 5.28 31.03 1.59 64.26 3.27 86.83 6.09 74 9.24 148.29 20.24 223.99 25.77 61.78 4.52 124.43 3.24 186.48 6.14v-6.77c46.65-1.07 93.44-1.21 139.73-4.59 75.7-5.53 149.99-16.53 223.99-25.77 22.57-2.82 55.8-4.5 86.83-6.09-1.51-1.38-2.86-2.89-4.05-4.64Z"
      style={{
        fill: "url(#aO)",
      }}
    />
    <Path
      d="M3902.68 289.01v.02c-1.97-.12-3.93-.3-5.87-.5-8.41 2.22-17.43 1.22-25.98.65-10.8-.72-21.62-1.13-32.43-1.53-.33-.01-.66-.02-.98-.04 15.57 1.23 32.27 2.65 50.18 4.3 7.08.65 16.9 2.44 19.68 2.01.98-.15 1.66-1.85.92-2.5-1.14-1-3.61-2.3-5.52-2.42Z"
      style={{
        fill: "url(#aP)",
      }}
    />
    <Path
      d="M3902.68 289.01v.02c-1.97-.12-3.93-.3-5.87-.5-8.41 2.22-17.43 1.22-25.98.65-10.8-.72-21.62-1.13-32.43-1.53-.33-.01-.66-.02-.98-.04 15.57 1.23 32.27 2.65 50.18 4.3 7.08.65 16.9 2.44 19.68 2.01.98-.15 1.66-1.85.92-2.5-1.14-1-3.61-2.3-5.52-2.42Z"
      style={{
        fill: "url(#aQ)",
      }}
    />
    <Path
      d="M4462.57 294.39c-.07-.65-.15-1.3-.24-1.95l.25 1.89s0-.03.01-.04c-.51-4.16-.97-8.32-1.38-12.49-5.36.2-10.63.14-15-1.15-5.31-1.51-9.24-3-15.12-.24-1.64-6.91-4.68-5.78-11.42-2.19.32-1.18 1.2-2.51.81-3.34-1.06-2.28-1.83-5.11-4.07-6.3-4.58-2.47-6.9 6.4-12.23 5.45-1.41-.27-2.64-1.2-4.01-2.04-2.23-1.36-5-2.08-7.97-3.3l-1.63 1.07c4.43-14.94 7.1-17.58 3.19-20.11-.85-.55-3.12.22-4.82.41-1.49-.61-2.97-1.85-4.78-1.75-2.65.16-7.52 8.01-10.87 11.92-3.93 4.58-6.69 1.13-14.31 6.07-2.85 1.84-5.09 4.35-6.75 7.5-2.28-2.97-4.38-5.74-6.31-8.59-.26-.38 3.04-6.45-5.48-4.31-.74.19-1.89.38-2.2.11-3.71-3.23-6.59-1.08-8.86 2.21-8 2-2.76-1.66-17.48 11.86-3.99-2.31-11.65-5.92-12.02-9.64-.9-8.84-14.57 1.02-21.52-.5-1.38-.3-11.24 6.47-15.95 6.77-5.6.37-10.69.12-15.79 4.2-2.51 2.01-27.56-7.46-30.35-4.21-1.48 1.73-2.54 3.89-2.46 5.59.08 1.89 10.02 2.13 10.37 2.18 72.46 11.12 98.98 11.67 131.96 14.99 48.88 4.58 81.72 4.96 105.39 3.87.31-1.32.64-2.64 1.03-3.95Z"
      style={{
        fill: "url(#aR)",
      }}
    />
    <Path
      d="M4458.35 338.29c.26-5.31-.34-10.61.15-15.9.91-9.88 1.4-19.01 4.07-28-.07-.65-.15-1.3-.24-1.95l.25 1.89s0-.03.01-.04c-.48-3.85-.9-7.71-1.29-11.57l-7.69-.3c-38.92-1.46-77.94-1.86-116.74-5.49-3.17-.3-6.34-.55-9.51-.82-14.24 1.7-28.61 2.89-42.94 3.34-14.24.45-28.47-.37-42.71-.47-30.12-.2-60.24.6-90.37.36-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-3.67-.14-7-1.2-9.82-2.94-43.68 7.35-92.27 16.9-146.01 29.3-59.14 13.64-114.58 23.05-165.64 29.35 9.33-.1 18.66-.19 27.99-.27 259.99-2.18 519.95-2.33 779.92.36 0-1.56.02-3.12.1-4.66Z"
      style={{
        fill: "url(#aS)",
      }}
    />
    <Path
      d="M3868.97 237.56c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#aT)",
      }}
    />
    <Path
      d="M3883.36 283.4c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05h.01Z"
      style={{
        fill: "url(#aU)",
      }}
    />
    <Path
      d="M3806.15 233.42c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#aV)",
      }}
    />
    <Path
      d="M3820.54 279.25c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05Z"
      style={{
        fill: "url(#aW)",
      }}
    />
    <Path
      d="M4151.36 279.36c-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-6.68-.26-12.27-3.49-15.62-8.49-3.44-3.42-5.65-8.16-6.04-13.12-1.72-3.19-2.76-6.67-2.6-10.68.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.74-12.94-6.48-.64 2.48-1.8 4.71-3.68 6.79-2.36 2.6-5.33 4.08-5.52 7.91-.16 3.2-2.94 5.35-6.08 4.63-.83-.19-1.67-.39-2.5-.59-1 .99-2.41 1.63-4.23 1.63-3.11 0-5.02-1.86-5.73-4.15-.92-.25-1.85-.51-2.77-.77-1.01 1.18-2.52 1.96-4.54 1.96-3.22 0-5.14-2.01-5.79-4.42-.58.17-1.21.27-1.91.27-.42 0-.81-.05-1.18-.11-.37.07-.77.11-1.19.11-3.25 0-5.19-2.05-5.82-4.48-.76-.29-1.51-.59-2.27-.89-.96.75-2.21 1.22-3.76 1.22-1.67 0-2.99-.54-3.97-1.4-.28-.09-.55-.19-.81-.33-.47-.15-.9-.38-1.3-.67-.14.01-.28.03-.43.03h-1.19c-.5.02-.98-.05-1.44-.17-.47.11-.98.17-1.52.17-2.16 0-3.74-.9-4.74-2.22-1 1.32-2.58 2.22-4.74 2.22-.2 0-.39-.02-.58-.03-1.29.18-2.61-.04-3.77-.83a26.116 26.116 0 0 1-6.99-6.99c-.72-1.04-.94-2.61-.79-3.95-.38-.17-.72-.39-1.04-.63-.63-.22-1.19-.57-1.68-1.03-.68.27-1.46.42-2.33.42-1.94 0-3.41-.73-4.42-1.83-2.12-.25-3.58-1.45-4.4-3-.6-.1-1.15-.27-1.64-.51h-.21c-3.68-.06-6.98-1.04-10.27-2.63-.67-.33-1.25-.82-1.71-1.41-3.23-1.36-4.25-5.34-3.06-8.36-22.58-2.79-109.85-7.51-189.77-31.5l-271.91.42v154.7c3.31.5 6.61 1.03 9.9 1.56 2.92.23 5.85.36 8.78.38a95.563 95.563 0 0 1 26.81.31c19.44-.03 38.88-.28 58.31-.65 75.29-1.44 150.54-2.91 225.84-3.54 187.76-1.57 375.51-2.09 563.26-1.24v-62.36c-30.08-.2-60.15.6-90.24.36Z"
      style={{
        fill: "url(#aX)",
      }}
    />
    <Path
      d="M3924.12 288.78c-11.56-.84-17.44-6.24-24.2-1.38-3.99-6.47-6.66-6.96-12.49-1.5-.29-1.32-.08-2.85-.9-3.7-2.27-2.36-4.61-5.33-7.7-6.36-6.31-2.09-3.54 7.76-9.48 7.52-2.22-.09-4.39-1.39-7.91-2.17-2.51-.55-5.29-.84-7.89-1.28l-.91 1.43c-6.13-16.51-5.33-19.7-11.47-21.29-1.4-.36-3.37.98-5.07 1.56-2.09-.25-4.8-1.15-6.73-.57-4.08 1.22.43 16.44-3.65 18.15-7.17 2.99-9.9 8.01-7.76 15.41-16.45-8.07-13.04-6.25-14.16-7.94-4.21-6.4-8.59-.01-10.35-.57-6.48-2.04-7.39.88-6.62 4.68-5.67 4.09-4.38-.68-5.9 16.44-6.65-1.15-16.32-2.13-20.08-5.39-3.37-2.91-6.34-3.09-9.66-1.04-3.74 2.31-5.01 6.88-10.75 7.58-1.32.16-1.79 3.24-2.84 4.87-1.5 2.33-2.49 5.5-4.9 6.66-2.3 1.12-4.63 2.08-6.44 3.43-8.86 7.07 1.91 5.94-13 7.75-1.75.2-7.93.93-9.5 1.75-.53.25-.54 1.53.12 2.16 1.5 1.44 5.94 5.27 5.91 5.25-.04-.02-1.18-1.78-.02-.04-.55-1.24 8.4-2.74 11.34-3.27 49.15-8.91 114.63-28.49 199.8-29.25l3.2-18.9h.01Z"
      style={{
        fill: "url(#aY)",
      }}
    />
    <Path
      d="M3801.6 202.98c1.76-.03 3.69-.08 5.76-.15.22.35.48.68.78.97.08 1.24.47 2.45 1.18 3.45v.15c-.69.64-1.29 1.4-1.75 2.26-.82 1.54-1.01 3.15-.63 4.83.06.26.14.5.24.74-36.13-.6-74.73.26-99.41-1.04-32.18-1.7 79.33-10.95 93.83-11.21Z"
      style={{
        fill: "url(#aZ)",
      }}
    />
    <Path
      d="M3698.57 216.27c3.19-2.9 2.17-1.03 31.51-3.95 8.08-.86 10.35-.47 11.23-1.62 2.18-2.86 6.53-1.81 20.23-.86 13.74.92 17.2-2.21 24.25 1.31 3.55-3.85 6.22-2.98 11.24.11.13-.74-.2-1.64.46-2.06 1.82-1.18 3.63-2.72 6.25-3.09 2.79-.39 3.82.94 4.73 2.2-.34.42-.65.86-.91 1.35-.82 1.54-1.01 3.15-.63 4.83.24 1.05.84 1.97 1.6 2.72.07.55.23 1.08.49 1.59.25.78.68 1.45 1.3 2.02l.08.08a7.06 7.06 0 0 0-.08 5.04c-13.71-1.8-27.95-3.48-42.84-4.92-58.19-5.55-63.61-1.25-68.2-2.36-.8-.19-1.32-1.81-.7-2.37v-.02Z"
      style={{
        fill: "url(#ba)",
      }}
    />
    <Path
      d="M3364.81 171.34c3.85-1.23 8.02-2.56 12.27-3.6 21.33-1.31 43.32-3.69 63.35-2.97 97.2 3.48 169.05 26.43 352.28 55.28-63.65-1.71-164.44-21.35-224.85-24.66-158.43-8.69-173.06 10.01-219.17 4.21v-22.24c5.26-2.31 10.68-4.28 16.12-6.02Z"
      style={{
        fill: "url(#bb)",
      }}
    />
    <Path
      d="M3360.57 172.75c24.28-.99 50.82-1.5 79.77-1.36 4.57.02 5.75 3.67 1.49 4.63-20.58 4.67-59.4 4.35-93.14 13.3v-11.96c3.89-1.71 7.87-3.23 11.88-4.61Z"
      style={{
        fill: "url(#bc)",
      }}
    />
    <Path
      d="M3443.28 227.6c-4.35-6.32-10.52-8.45-17.86-7.61-15.93 1.82-10.45 16.06-17.58 22.93-2.12 2.04-1.34 12.07-2.75 12.58-1.15.42-2.96.19-4.01-.53-6.54-4.49-7.52-4.21-12.07 2.11-2.91 4.05-5.86 7.89-6.46 13.14-.11.94-1.23 2.22-2.13 2.5-4.61 1.44-3.87 4.79-3.19 8.35.76 4 3.29 4.41 6.48 3.81 26.87-5.04 17.86-2.83 43.75-2.58 11.96.12 23.93-.42 35.89-.58 17.46-.23 19.01 1.37 29.19-2.84.7-.29 1.16-3.89.49-4.48-5.77-5.16-10.48-6.43-11.62-9.89-2.85-8.64-2.82-8.41-11.48-8.78-2.25-.1-4.34-2.88-6.67-3.25-3.71-.59-3.09-3.17-3.8-5.65-6.94-24.44-9.73-22.4-16.18-19.23Z"
      style={{
        fill: "url(#bd)",
      }}
    />
    <Path
      d="M3415.78 263.01c1.21.38 3.01.06 3.99-.69 1.19-.91-2.53-10.25-1.11-12.83 2.33-4.25 1.53-8.24 1.23-12.47-.69-9.71 5.48-14.89 14.31-16.37 3.54 1.11 6.6 3.35 9.08 6.94 6.11-3 7.61-2.77 10.55 3.42 8.11 17.04 4.09 20.61 9.43 21.46 2.33.37 4.42 3.15 6.67 3.25 8.65.37 8.63.14 11.48 8.78.71 2.16 2.65 3.27 4.62 4.64-9.09 2.76-27.86 9.6-39.79 12.94-30.22.72-32.15-.93-43.8-.31-.51-4.66 1.01-8.84 2.45-13.17 2.46-7.39 3.32-7.95 10.89-5.61v.02Z"
      style={{
        fill: "url(#be)",
      }}
    />
    <Path
      d="M3426.59 157.12c-7.72-4.01-8.83 4.25-11.26 12.32-1.91 0-3.3-.15-4.64.03-2.35.33-3.75 2.37-3.13 4.3.63 1.95 3.56 3.52 5.33 2.91 8.15-2.82 10.64-4.06 13.36-1.31 1.88 1.9 6.17 1.68 9.24.26.95.75 1.74 1.73 2.76 2.12 7.46 2.78 7.59-6.95 3.79-9.66-2.53-1.81-1.56-8.52-2.93-14.52-1.31-5.77-6.89-5.19-10.22-1.32-1.09 1.27-1.52 3.12-2.3 4.87Z"
      style={{
        fill: "url(#bf)",
      }}
    />
    <Path
      d="M3515.89 267.33c1.91 2.25 3.62 4.23 7.62 3.58 4.49-.74 7.88-2.79 8.83-5.57 1.92-5.68 2.41-11.56-.17-17.21-1.66-3.64-9.25-3.56-8.72-6.79.97-5.88-2.5-11.25-4.75-16.73-1.48-3.58-3.55-3.98-9.52-2.68-3.82-5.37-5.54-9.37-11.28-8.82-6.6.63-4.69 8.66-8.04 9.26-8.85 1.59-7.04 7.98-7.06 18.16 0 .57.25 1.45-.2 1.68-3.45 1.76-2.4 4.06-2.16 6.35.93 8.98-.38 11.29 3.05 16.81.42.68 2.54 1.56 3.31 1.36 2.84-.73 3.64.75 5.3 1.53 8.41 3.97 16.29 7.68 23.81-.93h-.02Z"
      style={{
        fill: "url(#bg)",
      }}
    />
    <Path
      d="M3608.57 276.21c1.33 1.36 2.51 2.56 5.29 2.16 3.12-.44 5.47-1.69 6.13-3.37 1.34-3.43 1.68-6.98-.12-10.39-1.2-2.28-6.44-2.07-6.05-4.1.67-3.55-1.73-6.8-3.3-10.1-1.03-2.16-2.47-2.4-6.61-1.62-2.68-3.28-3.86-5.66-7.83-5.33-4.59.38-3.25 5.23-5.58 5.59-6.07.95-4.89 4.66-4.9 10.97 0 .35.18.87-.14 1.01-2.4 1.06-1.67 2.45-1.5 3.83.66 5.53-.26 6.83 2.12 10.15.29.41 1.76.94 2.3.82 3.35-.75 12.55 8 20.21.37h-.02Z"
      style={{
        fill: "url(#bh)",
      }}
    />
    <Path
      d="M3956.19 278.67a20.784 20.784 0 0 1-5.05-8.22c-.52-.54-.83-1.17-.73-2.05.02-.19.03-.38.05-.57-.15-.77-.25-1.54-.31-2.32-1.7-3.18-2.74-6.64-2.58-10.64.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.73-12.94-6.47-.64 2.48-1.8 4.71-3.68 6.78-1.56 1.71-3.38 2.94-4.49 4.68-.34 5.63.26 12.55-.17 12.87h-.01c-2.4 1.8-1.67 4.15-1.5 6.49.66 9.36-.26 11.55 2.12 17.18.3.69 1.77 1.59 2.3 1.39 1.97-.75 2.53.76 3.68 1.56 5.85 4.05 11.32 7.84 16.53-.95 1.33 2.3 2.51 4.33 5.29 3.66 3.12-.75 5.48-2.85 6.13-5.69.95-4.13 1.38-8.36.91-12.53-.46-.55-.9-1.11-1.3-1.71Z"
      style={{
        fill: "url(#bi)",
      }}
    />
    <Path
      d="M3739.01 334.2c2.61.66 5.18 1.59 7.7 2.82a51.94 51.94 0 0 0-7.7-2.82Z"
      style={{
        fill: "url(#bj)",
      }}
    />
    <Path
      d="M3688.05 334.48c-48.8 5.54-97.99-.41-146.46-6.61-49.54-6.32-163.07-15.36-192.9-19.92v27.19c6.52 4.28 13.01 7.99 19.5 11.23a95.49 95.49 0 0 1 25.99.41c19.44-.03 38.89-.28 58.31-.65 75.23-1.44 150.42-2.9 225.67-3.54 2.59-.52 5.18-1.08 7.75-1.7 16.8-4.04 35.74-11.13 53.1-6.7-16.37-4.6-34.41-1.6-50.96.28Z"
      style={{
        fill: "url(#U)",
      }}
    />
    <Path
      d="M3971.81 287.16c-3.8-.15-7.23-1.27-10.12-3.12-1.6.45-3.27 1.39-4.15 2.17-.74.65-.06 2.35.92 2.5 2.22.34 8.92-.73 15.14-1.5-.6-.01-1.19-.02-1.79-.05Z"
      style={{
        fill: "url(#bk)",
      }}
    />
    <Path
      d="M3383.58 280.37c.99-.07 2.22-.68 2.52-1.33 1.76-3.77 5.83-3.05 10.27-2.83 7.9.32 16.55 1.17 23.15-.77 5.31-1.51 9.24-3 15.12-.24 1.64-6.91 4.68-5.78 11.42-2.19-.32-1.18-1.2-2.51-.81-3.34 1.06-2.28 1.83-5.11 4.07-6.3 4.58-2.47 6.9 6.4 12.23 5.45 1.41-.27 2.64-1.2 4.01-2.04 2.23-1.36 5-2.08 7.97-3.3l1.63 1.07c-4.43-14.94-7.1-17.58-3.19-20.11.85-.55 3.12.22 4.82.41 1.49-.61 2.97-1.85 4.78-1.75 2.65.16 7.52 8.01 10.87 11.92 3.93 4.58 6.69 1.13 14.31 6.07 2.85 1.84 5.09 4.35 6.75 7.5 2.28-2.97 4.38-5.74 6.31-8.59.26-.38-3.04-6.45 5.48-4.31.74.19 1.89.38 2.2.11 3.71-3.23 6.59-1.08 8.86 2.21 8 2 2.76-1.66 17.48 11.86 3.99-2.31 11.65-5.92 12.02-9.64.9-8.84 14.57 1.02 21.52-.5 1.38-.3 11.24 6.47 15.95 6.77 5.6.37 10.69.12 15.79 4.2 2.51 2.01 27.56-7.46 30.35-4.21 1.48 1.73 2.54 3.89 2.46 5.59-.08 1.89-10.02 2.13-10.37 2.18-72.46 11.12-98.98 11.67-131.96 14.99-104.69 9.83-135.8.4-160.9-1.53v-6.43c12.16 1.12 22.72-.01 34.89-.92Z"
      style={{
        fill: "url(#bl)",
      }}
    />
    <Path
      d="M2887.56 264.62c-4.49-3.18-2.29-7.76-3.34-13.93-1.17-6.89-2.08-7.6-9.04-7.91h-.01c-2.23-10.3-1.3-14.72-14.19-24.75-8.41-6.54-11.13.66-14.78-.39-10.89-3.13-10.4 8.21-11.63 17.14-2.34-1.31-4.46 2.35-7.54-.22-4.34-3.62-7.53-3.47-10.07-1.22.7 1.97 1.04 4.05.9 6.16-.85 12.83-6.88 24.16-15.7 32.94l.03.38c.35 4.12 3.39 6.05 6.88 6.94 1.96.5 2.59 1.28 2.97 3.12.96 4.62 3.25 7.96 8.66 7.88.58 0 1.24.84 1.77 1.39 7.5 7.76 12.44-.37 15.71.5 16.66 4.43 19.27 9.26 30.83 7.38 9.71-1.58 10.8-11.52 15.05-12.96 3.74-1.27 5.47-3.85 5.97-7.37.7-4.87 1.54-12.24-2.47-15.08Z"
      style={{
        fill: "url(#bm)",
      }}
    />
    <Path
      d="M2860.79 288.62c-3.27-.87-8.21 7.26-15.71-.5-.53-.55-1.19-1.39-1.77-1.39-5.41.08-7.7-3.26-8.66-7.88-.38-1.84-1.01-2.62-2.97-3.12-3.49-.89-6.53-2.82-6.88-6.94-.66-7.8-2.33-19.6 5.27-18.43 4.57-12.45 8.04-29.46 19.57-19.83 3.08 2.57 5.2-1.09 7.54.22.66-4.84.98-10.77 2.39-13.73-7.25-4.74-9.93 1.61-13.37.62-10.88-3.13-10.4 8.2-11.63 17.14-2.34-1.31-4.46 2.35-7.54-.22-4.33-3.62-7.53-3.47-10.08-1.22.7 1.97 1.04 4.06.9 6.16-.85 12.83-6.88 24.16-15.7 32.94l.03.39c.35 4.12 3.38 6.05 6.88 6.94 1.96.5 2.59 1.28 2.97 3.12.96 4.62 3.25 7.96 8.66 7.88.59 0 1.25.84 1.77 1.39 7.46 7.73 12.46-.37 15.71.5 4.56 1.21 9.22 2.44 13.41 4.54 7.68 3.84 19.34 5.69 26.17-2.52-5.35-1.91-3.42-2.44-16.97-6.05Z"
      style={{
        fill: "url(#bn)",
      }}
    />
    <Path
      d="M1941.52 347.81c262.14-2.2 524.24-2.34 786.36.42 92.7-3.84 185.35-8.84 278.01-13.52-25.11-4.54-51.04-9.83-77.72-15.98-56.36-13-107.08-22.89-152.39-30.36-8.57 2.41-17.79 1.39-26.54.81-10.8-.72-21.62-1.13-32.43-1.53-38.92-1.46-77.94-1.86-116.74-5.49-3.17-.3-6.34-.55-9.51-.82-14.24 1.7-28.61 2.89-42.94 3.34-14.24.45-28.47-.37-42.71-.47-30.12-.2-60.24.6-90.37.36-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-3.67-.14-7-1.2-9.82-2.94-43.68 7.35-92.27 16.9-146.01 29.3-59.14 13.64-114.58 23.05-165.64 29.35 9.33-.1 18.66-.19 27.99-.27Z"
      style={{
        fill: "url(#bo)",
      }}
    />
    <Path
      d="M3229.33 202.89c-7.21-1.07-14.02-2.3-19.58-8.66-1.23-1.4-5.67-.94-7.53.51-23.04 17.91-25.92-21.16-51.39-12.27-6.6 2.3-10.66-10.78-19.07-9.19-.43.08-1.41-1.11-1.55-1.85-1.23-6.81-5.28-9.15-9.69-8.82-2.7-6.36-4.62-12.12-7.43-17.25-6.15-11.22-15.83-12.14-21.98-.78-2.81 5.2-5.36 10.59-8.82 17.49-7.85-12.62-6.45-11.4-13.2-17.95-.84-.82-2.28-1.01-2.88-1.96-5.35-8.45-14.32-7.99-18.95 1.09-.91 1.79-1.69 3.67-2.77 6.05-3.94-11.09-14.53-21.11-26.57-16.11-8.67 3.6-18.79 5.51-20.89 18.92-.13.83-2.24 1.87-3.36 1.77-15.76-1.41-13.45 16.29-21.42 8.59-3.87-3.75-7.72-3.35-10.67.08l-.06.27c-2.42 8.57-7.96 15.02-12.79 22.19 1.03 3.12-.2 7.09-3.7 8.19.45.9.67 1.92.71 2.92.03.86-.15 1.67-.54 2.41-.25.78-.68 1.45-1.3 2.02-.52.55-1.12.95-1.81 1.21-.79.78-1.74 1.28-2.77 1.55-.33.29-.7.53-1.11.75-.14.26-.29.52-.43.77-.09 1.56-.67 3.09-1.77 4.19-.08 1.24-.47 2.45-1.18 3.44v.16c.69.64 1.29 1.4 1.75 2.26.25.48.44.96.58 1.45 14.68-.13 32.63-.2 54.81-.19.04 0 220.39 4.73 239.15-1.02 1.74-.53 3.56-4.93 3.49-7.5-.05-1.7-3.26-4.42-5.27-4.72Z"
      style={{
        fill: "url(#bp)",
      }}
    />
    <Path
      d="M3354.59 171.06c-2.05-.07-4.07 0-6.05.28-3.33.46-6.75-.76-7.71-4.38-.07-.25-.11-.51-.14-.78-2.35-2.56-2.18-7.16.54-9.4-3.85-6.54-7.46-14.27-12.17-19.27-4.4-4.67-16.32-5.94-21.62-5.02-4.02.7-10.09 4.34-12.46 10.3-1.5-1.76-3.09-3.3-4.73-4.49-5.8-9.62-11.11-21.35-18.16-28.83-6.31-6.7-23.43-8.52-31.03-7.21-5.77 1-14.48 6.23-17.88 14.78-7.2-8.45-15.9-13.53-24.04-7.07.43-.34.88-.6 1.28-.77-14.71 16.75-40.82 21.85-48.62 31.34-2.55 3.1-1.08 7.77-4.82 9.63-9.03 4.47-9.49-26.05-38.64 8.11-5.72 6.71-7.56 7.3-15.33 8.13-5.01.54-10.93 1.59-14.9 5.37-8.31 27.32 7.43 29.38 16.41 29.53 56.65.94 58.73 1.45 101.26-2.39 3.13 2.48 5.83 3.39 46.93 3.02 22.99-.21 45.81-4.31 68.9-4.15 13.32.09 29.17 1.7 41.94-5.6-.36-7.17 1.24-14.04 1.06-21.15l-.02.02Z"
      style={{
        fill: "url(#bq)",
      }}
    />
    <Path
      d="M3115.83 217.09c-1.66-6.09-1.66-6.07-6.06-4.68-1.95.61-1.69-1.07-6-7.17-1.16-1.64-2.43-4.03-3.77-4.24-6.66-1.07-1.07-10.24-6.24-12.96-1.94-1.02-3.76-1.4-5.15 1.54-7.96 16.85-3.46 11.27-9.59 8.13-10.56-5.42-10.32-1.6-12.27-4.41-2.14-3.09-4.62-3.9-7.41-2.46-1.19.62-2.07.09-7.42-1.7-.9-15.03-.08-22.14-6.78-16.56-.81.67-6.83-17.95-9.33-21.35-.63-.86-1.7-1.47-2.5-1.3-.53.11-.99 1.74-1.34 2.81-.23.7-.2 1.61-.34 2.91-.71-.87-1.09-1.61-1.57-1.87-2.42-1.29-5.11-4.7-6.07 4.26-.31 2.91.1 6-.02 8.99-.07 1.84-.56 3.63-.75 4.79-3.49-2.33-4.11-4.67-5.22 2.28-.48 2.99-3.16 6.48-5.1 6.74-1.14.15-2.24 1.02-3.35 1.57-4.9-5.71-4.75-1.36-6.92 4.13-.51-1.33-.8-2.61-1.36-3.44-.63-.93-1.76-2.33-2.22-1.99-1.79 1.29-2.55-.74-3.17-2.28-4.42-10.89-3.45-16.74-7.25-13.17-.26.25-.94-.45-1.37-.85-2.45-2.23-4.78-.65-5.37 3.73-.27 1.99-.43 4.02-.66 6.25-.87-.35-1.61-.29-1.9-.83-.91-1.71-1.65-4.37-3.31-2.2-3.17 4.14-3.02 9.47-3.51 13.96-9.46 4.24-6.1-1.05-8.1-6.81-.74-2.12-2.07-4.84-3.34-5.13-4.05-.94-4.06 6.76-6.77 16.64-.31 1.14-1.26 1.84-2.03 2.89-1.35-3.18-.41-2.53-3.35-4.53-.3 1.33-.98 2.56-2.06 3.43-.28 2.37-.07 1.98.29 5.4-.54-.2-1.02-.37-1.46-.53-.08.5-.23.98-.47 1.43-.25.78-.68 1.45-1.3 2.02-.52.55-1.12.95-1.81 1.21-.8.79-1.77 1.29-2.81 1.57-.32.28-.68.52-1.06.73-.14.26-.29.52-.43.78-.09 1.56-.67 3.08-1.77 4.19-.08 1.24-.47 2.45-1.18 3.44v.16c.69.64 1.29 1.4 1.75 2.26.82 1.54 1.01 3.15.63 4.83-.24 1.06-.84 1.97-1.6 2.72-.03.2-.06.4-.12.6 4.9.17 9.8.42 14.7.55 35.21.92 53.42.14 60.01.44 10.15.45 20.28 1.68 30.42 2.22 10.05.53 20.16-.27 30.15 1.16 22.9 3.27 28.44-.98 41.19-.61 4.62.13 3.86-3.77 3.86-9.68h-.02Z"
      style={{
        fill: "url(#br)",
      }}
    />
    <Path
      d="M3379.34 176.56c-9.45-3.02-20.73-6.62-30.8-5.22-3.33.46-6.75-.76-7.71-4.38-.07-.25-.11-.51-.14-.78-.23-.26-.45-.52-.64-.82 0 .11-.02.21-.02.32 0 2.09-5.18 1.49-5.28 8.98-.01 1.08.22 2.52.91 3.19 6.49 6.21 10.69.96 11.75 1.73 4.22 3.09 6.99-1.77 8.79-.14 3.04 2.74 6.66 2.51 10.26 2 3.05-.43 5.82-2.32 9.21-1.01 2.29.88 10.19 1.6 14.12-.19a180.24 180.24 0 0 0-10.46-3.68h.01Z"
      style={{
        fill: "url(#bs)",
      }}
    />
    <Path
      d="M3072.65 188.99c1.31-1.42 2.74-2.51 3.64-4.05 3.77-6.43 8.98-7.91 15.13-7.41 1.46.12 3.94-1.42 4.41-2.91 5.42-17.22 23.46-11.42 27.76-22.78 1.3-3.42 4.01-4.84 7.49-3.83 1.38.4 3.11-.67 4.63-1.29.66-.27 1.14-1.1 1.75-1.61 9.78-8.3 13.99-8.07 19.73 7.07.61 1.6.71 3.87 1.71 4.79 1.81 1.68 3.87 4.12 6.23.05 2.25-3.88 5.5-4.79 8.78-1.44 5.84 5.97 4.16-7.74 15.72-6.65.82.08 2.37-.73 2.46-1.37 1.54-10.39 8.97-11.87 15.34-14.66 8.84-3.88 16.61 3.89 19.5 12.49.8-1.84 1.36-3.3 2.03-4.69 3.4-7.04 9.98-7.39 13.91-.84.44.73 1.49.88 2.11 1.52 4.89 5.01 3.83 3.97 9.69 13.91 2.55-5.35 4.41-9.53 6.48-13.56 4.51-8.8 11.62-8.09 16.13.6 2.06 3.97 3.47 8.44 5.45 13.37 3.24-.26 6.21 1.55 7.11 6.83.1.57.82 1.5 1.14 1.43 6.09-1.22 9.19 8.89 14 7.12 18.7-6.89 20.81 23.39 37.72 9.51 1.37-1.12 4.63-1.48 5.53-.39 4.08 4.93 9.08 5.88 14.37 6.71 1.48.23 3.84 2.34 3.87 3.66.05 1.99-1.28 5.4-2.56 5.81-3.67 1.19-7.61 1.3-11.45 1.6-.03 0-81.7.84-111.79.79-30.42-.05-33.46-3.49-132.94-.29-12.08.39-12.2 3.78-47.3 6.21-2.66.18-5.49-.17-8.01-1.16-1.51-.6-2.83-2.72-3.64-4.54-.2-.45 1.75-3.15 2.98-3.52 4.4-1.31 8.15-3.14 11.21-7.73.97-1.45 5.04.05 7.68.32.69.07 1.35.6 2.01.92Z"
      style={{
        fill: "url(#bt)",
      }}
    />
    <Path
      d="M2968.64 170.71c7.5-2.32 15.09-4.5 20.88-9.61 3.02-2.67 6.14-6.31 10.15-5.79-1.35-1.68-.27-4.33 1.55-5.5s4.08-1.32 6.22-1.69c7.03-1.24 13.46-5.18 20.58-5.72 9.25-.69 17.87 4.42 27.02 5.97 1.97.33 4.06.53 5.7 1.66s2.58 3.58 1.33 5.12c4.32-.54 7.65 3.39 10.96 6.19 7.55 6.36 18.02 7.7 27.23 11.32 1.02.4 2.07.87 2.72 1.74 3.85 5.2-12.62 8.04-15.41 7.9-4.27-.21-8.51-1.62-12.74-.98-3.68.56-6.91 2.62-10.34 4.04-11.1 4.6-23.68 2.44-35.71 2.57-9.85.11-20.1 1.75-29.29-1.78-4.04-1.55-7.77-4.07-12.05-4.63-3.68-.48-7.36.53-11.06.84-5.72.48-11.57-.79-16.56-3.58-.66-.37-1.33-.8-1.64-1.48-.84-1.82 1.41-3.5 3.31-4.21 2.34-.88 4.75-1.64 7.16-2.38Z"
      style={{
        fill: "url(#bu)",
      }}
    />
    <Path
      d="M3262.3 64.73h19.64v116.92h-19.64z"
      style={{
        fill: "url(#bv)",
      }}
    />
    <Path
      d="M3268.8 100.3h13.13v81.35h-13.13z"
      style={{
        fill: "#6689a5",
      }}
    />
    <Path
      d="M3278.98 69.81h2.24v111.85h-2.24z"
      style={{
        fill: "url(#bw)",
      }}
    />
    <Path
      d="M3276.32 69.81h2.24v111.85h-2.24z"
      style={{
        fill: "url(#bx)",
      }}
    />
    <Path
      d="M3273.66 69.81h2.24v111.85h-2.24z"
      style={{
        fill: "url(#by)",
      }}
    />
    <Path
      d="M3270.99 69.81h2.24v111.85h-2.24z"
      style={{
        fill: "url(#bz)",
      }}
    />
    <Path
      d="M3268.33 69.81h2.24v111.85h-2.24z"
      style={{
        fill: "url(#bA)",
      }}
    />
    <Path
      d="M3265.66 69.81h2.24v111.85h-2.24z"
      style={{
        fill: "url(#bB)",
      }}
    />
    <Path
      d="M3263 69.81h2.24v111.85H3263z"
      style={{
        fill: "url(#bC)",
      }}
    />
    <Path
      d="M3278.98 100.3h2.24v81.35h-2.24zM3276.32 100.3h2.24v81.35h-2.24zM3273.65 100.3h2.24v81.35h-2.24zM3270.99 100.3h2.24v81.35h-2.24zM3268.8 100.3h1.77v81.35h-1.77z"
      style={{
        fill: "#7aa3bd",
      }}
    />
    <Path
      d="m3177.71 55.58 21.55 11.96v114.11h-21.55V55.58z"
      style={{
        fill: "#fdfaff",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3164.93 55.58h12.78v126.07h-12.78V55.58z"
      style={{
        fill: "#a2d5e6",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3164.93 60.22h12.78v121.43h-12.78V60.22z"
      style={{
        fill: "#84bcd0",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3164.93 60.22h12.78v121.43h-12.78V60.22z"
      style={{
        fill: "url(#bD)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="m3177.71 55.58 21.55 11.96v114.11h-21.55V55.58z"
      style={{
        fill: "url(#bE)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="m3177.71 60.22 18.66 7.71v113.72h-18.66V60.22z"
      style={{
        fill: "#97d2e6",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="m3177.71 60.22 18.66 7.71v113.72h-18.66V60.22z"
      style={{
        fill: "url(#bF)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3196.37 181.65v-70.99l-4.22 6.48v64.51h4.22z"
      style={{
        fill: "#59778f",
        isolation: "isolate",
        opacity: 0.75,
      }}
    />
    <Path
      d="M3199.26 181.65v-75.43l-2.89 4.44v70.99h2.89z"
      style={{
        fill: "#41536c",
        isolation: "isolate",
        opacity: 0.25,
      }}
    />
    <Path
      d="M3196.37 168.76h-18.66v-.93h18.66v.93z"
      style={{
        fill: "url(#bG)",
      }}
    />
    <Path
      d="M3196.37 162.32h-18.66v-.93h18.66v.93z"
      style={{
        fill: "url(#bH)",
      }}
    />
    <Path
      d="M3196.37 155.88h-18.66v-.94h18.66v.94z"
      style={{
        fill: "url(#bI)",
      }}
    />
    <Path
      d="M3196.37 149.43h-18.66v-.93h18.66v.93z"
      style={{
        fill: "url(#bJ)",
      }}
    />
    <Path
      d="M3196.37 142.99h-18.66v-.93h18.66v.93z"
      style={{
        fill: "url(#bK)",
      }}
    />
    <Path
      d="M3196.37 136.55h-18.66v-.94h18.66v.94z"
      style={{
        fill: "url(#bL)",
      }}
    />
    <Path
      d="M3196.37 123.66h-18.66v-.94h18.66v.94z"
      style={{
        fill: "url(#bM)",
      }}
    />
    <Path
      d="M3196.37 117.21h-18.66v-.93h18.66v.93z"
      style={{
        fill: "url(#bN)",
      }}
    />
    <Path
      d="M3196.37 110.77h-18.66v-.93h18.66v.93z"
      style={{
        fill: "url(#bO)",
      }}
    />
    <Path
      d="M3196.37 104.33h-18.66v-.94h18.66v.94z"
      style={{
        fill: "url(#bP)",
      }}
    />
    <Path
      d="M3196.37 91.44h-18.66v-.93h18.66v.93z"
      style={{
        fill: "url(#bQ)",
      }}
    />
    <Path
      d="M3196.37 72.11h-18.66v-.93h18.66v.93z"
      style={{
        fill: "url(#bR)",
      }}
    />
    <Path
      d="M3177.71 84.06h2.75v.93h-2.75z"
      style={{
        fill: "url(#bS)",
      }}
    />
    <Path
      d="M3185.07 84.06h11.3v.93h-11.3z"
      style={{
        fill: "url(#bT)",
      }}
    />
    <Path
      d="M3177.71 77.62h14.17v.93h-14.17z"
      style={{
        fill: "url(#bU)",
      }}
    />
    <Path
      d="M3193.4 77.62h2.97v.93h-2.97z"
      style={{
        fill: "url(#bV)",
      }}
    />
    <Path
      d="M3177.71 129.17h2.51v.93h-2.51z"
      style={{
        fill: "url(#bW)",
      }}
    />
    <Path
      d="M3182.19 129.17h14.18v.93h-14.18z"
      style={{
        fill: "url(#bX)",
      }}
    />
    <Path
      d="M3177.71 96.95h11.57v.93h-11.57z"
      style={{
        fill: "url(#bY)",
      }}
    />
    <Path
      d="M3191.23 96.95h5.14v.93h-5.14z"
      style={{
        fill: "url(#bZ)",
      }}
    />
    <Path
      d="M3196.37 175.21h-18.66v-.94h18.66v.94z"
      style={{
        fill: "url(#ca)",
      }}
    />
    <Path
      d="M3177.71 168.76h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cb)",
      }}
    />
    <Path
      d="M3177.71 162.32h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cc)",
      }}
    />
    <Path
      d="M3177.71 155.88h-12.78v-.94h12.78v.94z"
      style={{
        fill: "url(#cd)",
      }}
    />
    <Path
      d="M3177.71 149.43h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#ce)",
      }}
    />
    <Path
      d="M3177.71 142.99h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cf)",
      }}
    />
    <Path
      d="M3177.71 136.55h-12.78v-.94h12.78v.94z"
      style={{
        fill: "url(#cg)",
      }}
    />
    <Path
      d="M3177.71 130.1h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#ch)",
      }}
    />
    <Path
      d="M3177.71 123.66h-12.78v-.94h12.78v.94z"
      style={{
        fill: "url(#ci)",
      }}
    />
    <Path
      d="M3177.71 117.21h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cj)",
      }}
    />
    <Path
      d="M3177.71 110.77h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#ck)",
      }}
    />
    <Path
      d="M3177.71 104.33h-12.78v-.94h12.78v.94z"
      style={{
        fill: "url(#cl)",
      }}
    />
    <Path
      d="M3177.71 97.88h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cm)",
      }}
    />
    <Path
      d="M3177.71 91.44h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cn)",
      }}
    />
    <Path
      d="M3177.71 85h-12.78v-.94h12.78V85z"
      style={{
        fill: "url(#co)",
      }}
    />
    <Path
      d="M3177.71 78.55h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cp)",
      }}
    />
    <Path
      d="M3177.71 72.11h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cq)",
      }}
    />
    <Path
      d="M3177.71 175.21h-12.78v-.94h12.78v.94z"
      style={{
        fill: "url(#cr)",
      }}
    />
    <Path
      d="M3177.71 74.9v10.31l18.66 9.22V84.11l-18.66-9.21z"
      style={{
        fill: "url(#cs)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3177.71 88.4v5.15l18.66 9.22v-5.16l-18.66-9.21z"
      style={{
        fill: "url(#ct)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3192.15 167.83h4.23v.93h-4.23zM3192.15 161.39h4.23v.93h-4.23zM3192.15 154.94h4.23v.93h-4.23zM3192.15 148.5h4.23v.93h-4.23zM3192.15 142.99h4.22v-.93h-4.22v.93zM3192.15 136.55h4.22v-.94h-4.22v.94zM3192.15 130.1h4.22v-.93h-4.22v.93zM3192.15 123.66h4.22v-.94h-4.22v.94zM3192.15 117.21h4.22v-.93h-3.66l-.56.86v.07z"
      style={{
        fill: "#4f697e",
      }}
    />
    <Path
      d="m3136.37 57.08 21.55-11.97v136.54h-21.55V57.08z"
      style={{
        fill: "#fdfaff",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3123.59 57.08h12.78l21.55-11.97h-23.3l-11.03 11.97z"
      style={{
        fill: "#cef1f6",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3123.59 57.08h12.78v124.57h-12.78V57.08z"
      style={{
        fill: "#a2d5e6",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3123.59 61.72h12.78v119.93h-12.78V61.72z"
      style={{
        fill: "url(#cu)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="m3136.37 57.08 21.55-11.97v136.54h-21.55V57.08z"
      style={{
        fill: "url(#cv)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="m3136.37 61.72 18.66-13.41v133.34h-18.66V61.72z"
      style={{
        fill: "#97d2e6",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="m3136.37 61.72 18.66-13.41v133.34h-18.66V61.72z"
      style={{
        fill: "url(#cw)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3123.59 57.08h12.78l21.55-11.97h-23.3l-11.03 11.97z"
      style={{
        fill: "url(#cx)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3136.37 168.76h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cy)",
      }}
    />
    <Path
      d="M3136.37 162.32h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cz)",
      }}
    />
    <Path
      d="M3136.37 155.88h-12.78v-.94h12.78v.94z"
      style={{
        fill: "url(#cA)",
      }}
    />
    <Path
      d="M3136.37 149.43h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cB)",
      }}
    />
    <Path
      d="M3136.37 142.99h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cC)",
      }}
    />
    <Path
      d="M3136.37 136.55h-12.78v-.94h12.78v.94z"
      style={{
        fill: "url(#cD)",
      }}
    />
    <Path
      d="M3136.37 130.1h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cE)",
      }}
    />
    <Path
      d="M3136.37 123.66h-12.78v-.94h12.78v.94z"
      style={{
        fill: "url(#cF)",
      }}
    />
    <Path
      d="M3136.37 117.21h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cG)",
      }}
    />
    <Path
      d="M3136.37 110.77h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cH)",
      }}
    />
    <Path
      d="M3136.37 104.33h-12.78v-.94h12.78v.94z"
      style={{
        fill: "url(#cI)",
      }}
    />
    <Path
      d="M3136.37 97.88h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cJ)",
      }}
    />
    <Path
      d="M3136.37 91.44h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cK)",
      }}
    />
    <Path
      d="M3136.37 85h-12.78v-.94h12.78V85z"
      style={{
        fill: "url(#cL)",
      }}
    />
    <Path
      d="M3136.37 78.55h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cM)",
      }}
    />
    <Path
      d="M3136.37 72.11h-12.78v-.93h12.78v.93z"
      style={{
        fill: "url(#cN)",
      }}
    />
    <Path
      d="M3136.37 65.67h-12.78v-.94h12.78v.94z"
      style={{
        fill: "url(#cO)",
      }}
    />
    <Path
      d="M3136.37 175.21h-12.78v-.94h12.78v.94z"
      style={{
        fill: "url(#cP)",
      }}
    />
    <Path
      d="M3136.37 168.76h18.66v-.93h-18.66v.93z"
      style={{
        fill: "url(#cQ)",
      }}
    />
    <Path
      d="M3136.37 162.32h18.66v-.93h-18.66v.93z"
      style={{
        fill: "url(#cR)",
      }}
    />
    <Path
      d="M3136.37 155.88h18.66v-.94h-18.66v.94z"
      style={{
        fill: "url(#cS)",
      }}
    />
    <Path
      d="M3136.37 175.21h18.66v-.94h-18.66v.94z"
      style={{
        fill: "url(#cT)",
      }}
    />
    <Path
      d="M3136.37 149.43h18.66v-.93h-18.66v.93z"
      style={{
        fill: "url(#cU)",
      }}
    />
    <Path
      d="M3136.37 142.99h18.66v-.93h-18.66v.93z"
      style={{
        fill: "url(#cV)",
      }}
    />
    <Path
      d="M3136.37 136.55h18.66v-.94h-18.66v.94z"
      style={{
        fill: "url(#cW)",
      }}
    />
    <Path
      d="M3136.37 130.1h18.66v-.93h-18.66v.93z"
      style={{
        fill: "url(#cX)",
      }}
    />
    <Path
      d="M3136.37 123.66h18.66v-.94h-18.66v.94z"
      style={{
        fill: "url(#cY)",
      }}
    />
    <Path
      d="M3136.37 117.21h18.66v-.93h-18.66v.93z"
      style={{
        fill: "url(#cZ)",
      }}
    />
    <Path
      d="M3136.37 104.33h18.66v-.94h-18.66v.94z"
      style={{
        fill: "url(#da)",
      }}
    />
    <Path
      d="M3136.37 91.44h18.66v-.93h-18.66v.93z"
      style={{
        fill: "url(#db)",
      }}
    />
    <Path
      d="M3136.37 85h18.66v-.94h-18.66V85z"
      style={{
        fill: "url(#dc)",
      }}
    />
    <Path
      d="M3136.37 78.55h18.66v-.93h-18.66v.93z"
      style={{
        fill: "url(#dd)",
      }}
    />
    <Path
      d="M3136.37 65.67h18.66v-.94h-18.66v.94z"
      style={{
        fill: "url(#de)",
      }}
    />
    <Path
      d="M3136.37 71.18h12.04v.93h-12.04z"
      style={{
        fill: "url(#df)",
      }}
    />
    <Path
      d="M3150.73 71.18h4.29v.93h-4.29z"
      style={{
        fill: "url(#dg)",
      }}
    />
    <Path
      d="M3136.37 109.84h4.37v.93h-4.37z"
      style={{
        fill: "url(#dh)",
      }}
    />
    <Path
      d="M3142.73 109.84h12.3v.93h-12.3z"
      style={{
        fill: "url(#di)",
      }}
    />
    <Path
      d="M3136.37 96.95h9.81v.93h-9.81z"
      style={{
        fill: "url(#dj)",
      }}
    />
    <Path
      d="M3148.11 96.95h6.92v.93h-6.92z"
      style={{
        fill: "url(#dk)",
      }}
    />
    <Path
      d="m3155.03 63.71-12.7-6.27-5.96 4.28v3.09l18.66 9.21V63.71z"
      style={{
        fill: "url(#dl)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="m3155.03 77.2-18.66-9.21v5.16l18.66 9.21V77.2z"
      style={{
        fill: "url(#dm)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3127.97 181.65h-15.9V93.57l15.9 3.22v84.86z"
      style={{
        fill: "#8bd0e7",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3127.97 181.65h-15.9V93.57l15.9 3.22v84.86z"
      style={{
        fill: "url(#dn)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 181.65h16.89V93.57l-16.89 1.78v86.3z"
      style={{
        fill: "#73a8c5",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 181.65h16.89V93.57l-16.89 1.78v86.3z"
      style={{
        fill: "url(#do)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3112.07 106.54h15.9v4.19h-15.9v-4.19z"
      style={{
        fill: "url(#dp)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3112.07 113.37h15.9v4.19h-15.9v-4.19z"
      style={{
        fill: "url(#dq)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3112.07 120.2h15.9v4.19h-15.9v-4.19z"
      style={{
        fill: "url(#dr)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3112.07 127.03h15.9v4.18h-15.9v-4.18z"
      style={{
        fill: "url(#ds)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3112.07 133.85h15.9v4.19h-15.9v-4.19z"
      style={{
        fill: "url(#dt)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3112.07 140.68h15.9v4.19h-15.9v-4.19z"
      style={{
        fill: "url(#du)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3112.07 147.51h15.9v4.19h-15.9v-4.19z"
      style={{
        fill: "url(#dv)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3112.07 154.34h15.9v4.19h-15.9v-4.19z"
      style={{
        fill: "url(#dw)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3112.07 161.17h15.9v4.18h-15.9v-4.18z"
      style={{
        fill: "url(#dx)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3112.07 168h15.9v4.18h-15.9V168z"
      style={{
        fill: "url(#dy)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3112.07 99.71h15.9v4.19h-15.9v-4.19z"
      style={{
        fill: "url(#dz)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3112.07 174.82h15.9v4.19h-15.9v-4.19z"
      style={{
        fill: "url(#dA)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 106.54h16.89v4.19h-16.89v-4.19z"
      style={{
        fill: "url(#dB)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 113.37h16.89v4.19h-16.89v-4.19z"
      style={{
        fill: "url(#dC)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 120.2h16.89v4.19h-16.89v-4.19z"
      style={{
        fill: "url(#dD)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 127.03h16.89v4.18h-16.89v-4.18z"
      style={{
        fill: "url(#dE)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 133.85h16.89v4.19h-16.89v-4.19z"
      style={{
        fill: "url(#dF)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 140.68h16.89v4.19h-16.89v-4.19z"
      style={{
        fill: "url(#dG)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 147.51h16.89v4.19h-16.89v-4.19z"
      style={{
        fill: "url(#dH)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 154.34h16.89v4.19h-16.89v-4.19z"
      style={{
        fill: "url(#dI)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 161.17h16.89v4.18h-16.89v-4.18z"
      style={{
        fill: "url(#dJ)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 168h16.89v4.18h-16.89V168z"
      style={{
        fill: "url(#dK)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 99.71h16.89v4.19h-16.89v-4.19z"
      style={{
        fill: "url(#dL)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 174.82h16.89v4.19h-16.89v-4.19z"
      style={{
        fill: "url(#dM)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="m3127.97 96.79-8.34-12.82-7.56 9.6 15.9 3.22z"
      style={{
        fill: "#caf0f6",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="m3095.18 95.35 16.89-1.78 7.56-9.6h-6.48l-17.97 11.38z"
      style={{
        fill: "#8bd0e7",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="m3112.07 93.57 15.9 3.22-8.34-12.82-7.56 9.6z"
      style={{
        fill: "url(#dN)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3127.97 124.39h-9.43v-4.19h9.43v4.19zM3116.79 131.21h11.18v-4.18h-11.18v4.18zM3121.51 133.93h-9.44v4.11h9.44v-4.11zM3116.79 172.18h6.11V168h-6.11v4.18zM3127.97 151.7h-11.18v-4.19h11.18v4.19z"
      style={{
        fill: "#7eb9d7",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3095.18 110.73h7.3v-4.19h-7.3v4.19z"
      style={{
        fill: "url(#dO)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3112.07 162.35h15.9v-1.18h-15.9v1.18z"
      style={{
        fill: "url(#dP)",
      }}
    />
    <Path
      d="M3112.07 155.52h15.9v-1.18h-15.9v1.18z"
      style={{
        fill: "url(#dQ)",
      }}
    />
    <Path
      d="M3112.07 148.69h15.9v-1.18h-15.9v1.18z"
      style={{
        fill: "url(#dR)",
      }}
    />
    <Path
      d="M3112.07 141.86h15.9v-1.18h-15.9v1.18z"
      style={{
        fill: "url(#dS)",
      }}
    />
    <Path
      d="M3112.07 135.03h15.9v-1.18h-15.9v1.18z"
      style={{
        fill: "url(#dT)",
      }}
    />
    <Path
      d="M3112.07 128.21h15.9v-1.18h-15.9v1.18z"
      style={{
        fill: "url(#dU)",
      }}
    />
    <Path
      d="M3112.07 121.38h15.9v-1.18h-15.9v1.18z"
      style={{
        fill: "url(#dV)",
      }}
    />
    <Path
      d="M3112.07 114.55h15.9v-1.18h-15.9v1.18z"
      style={{
        fill: "url(#dW)",
      }}
    />
    <Path
      d="M3112.07 107.72h15.9v-1.18h-15.9v1.18z"
      style={{
        fill: "url(#dX)",
      }}
    />
    <Path
      d="M3112.07 169.18h15.9V168h-15.9v1.18z"
      style={{
        fill: "url(#dY)",
      }}
    />
    <Path
      d="M3112.07 100.89h15.9v-1.18h-15.9v1.18z"
      style={{
        fill: "url(#dZ)",
      }}
    />
    <Path
      d="M3112.07 176h15.9v-1.18h-15.9V176z"
      style={{
        fill: "url(#ea)",
      }}
    />
    <Path
      d="M3218.75 181.65h-19.49V84.06h6.41v16.04h13.08v81.55z"
      style={{
        fill: "#fff",
        isolation: "isolate",
        opacity: 0.5,
      }}
    />
    <Path
      d="M3207.54 116.75h11.21v64.9h-11.21z"
      style={{
        fill: "url(#eb)",
      }}
    />
    <Path
      d="M3199.26 116.75h8.27v64.9h-8.27z"
      style={{
        fill: "url(#ec)",
      }}
    />
    <Path
      d="M3247.21 64.73h15.08v116.92h-15.08z"
      style={{
        fill: "url(#ed)",
      }}
    />
    <Path
      d="M3291.43 90.5h19.64v91.15h-19.64z"
      style={{
        fill: "url(#ee)",
      }}
    />
    <Path
      d="M3276.35 90.51h15.08v91.15h-15.08z"
      style={{
        fill: "url(#ef)",
      }}
    />
    <Path
      d="M3157.92 71.18h7.01v110.48h-7.01z"
      style={{
        fill: "#fff",
        isolation: "isolate",
        opacity: 0.5,
      }}
    />
    <Path
      d="M3251.54 181.65h-17.79V85.53h17.79v96.12z"
      style={{
        fill: "#8bd0e7",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3233.75 85.53h17.8v96.12h-17.8z"
      style={{
        fill: "url(#eg)",
      }}
    />
    <Path
      d="M3233.75 99.71h17.79v3.88h-17.79v-3.88z"
      style={{
        fill: "url(#eh)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3233.75 106.54h17.79v3.87h-17.79v-3.87z"
      style={{
        fill: "url(#ei)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3233.75 113.37h17.79v3.87h-17.79v-3.87z"
      style={{
        fill: "url(#ej)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3233.75 120.2h17.79v3.87h-17.79v-3.87z"
      style={{
        fill: "url(#ek)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3233.75 127.03h17.79v3.87h-17.79v-3.87z"
      style={{
        fill: "url(#el)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3233.75 133.85h17.79v3.88h-17.79v-3.88z"
      style={{
        fill: "url(#em)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3233.75 140.68h17.79v3.88h-17.79v-3.88z"
      style={{
        fill: "url(#en)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3233.75 147.51h17.79v3.87h-17.79v-3.87z"
      style={{
        fill: "url(#eo)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3233.75 154.34h17.79v3.87h-17.79v-3.87z"
      style={{
        fill: "url(#ep)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3233.75 161.17h17.79v3.87h-17.79v-3.87z"
      style={{
        fill: "url(#eq)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3233.75 167.99h17.79v3.88h-17.79v-3.88z"
      style={{
        fill: "url(#er)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3233.75 92.88h17.79v3.88h-17.79v-3.88z"
      style={{
        fill: "url(#es)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3251.54 89.4h-17.79v-3.87h17.79v3.87z"
      style={{
        fill: "url(#et)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3233.75 174.82h17.79v3.88h-17.79v-3.88z"
      style={{
        fill: "url(#eu)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 181.65h15V85.53h-15v96.12z"
      style={{
        fill: "#73a8c5",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="m3235.14 75.16-16.39 10.37h15l1.39-10.37z"
      style={{
        fill: "#8bd0e7",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="m3235.14 75.16 16.4 10.37h-17.79l1.39-10.37z"
      style={{
        fill: "#caf0f6",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="m3235.14 75.16 16.4 10.37h-17.79l1.39-10.37z"
      style={{
        fill: "url(#ev)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 113.37h8.12v3.87h-8.12v-3.87zM3224.35 99.71h9.4v3.88h-9.4v-3.88z"
      style={{
        fill: "#54758f",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 85.53h15v96.12h-15z"
      style={{
        fill: "url(#ew)",
      }}
    />
    <Path
      d="M3251.54 155.43h-17.79v-1.09h17.79v1.09z"
      style={{
        fill: "url(#ex)",
      }}
    />
    <Path
      d="M3251.54 148.6h-17.79v-1.09h17.79v1.09z"
      style={{
        fill: "url(#ey)",
      }}
    />
    <Path
      d="M3251.54 141.77h-17.79v-1.09h17.79v1.09z"
      style={{
        fill: "url(#ez)",
      }}
    />
    <Path
      d="M3251.54 134.95h-17.79v-1.1h17.79v1.1z"
      style={{
        fill: "url(#eA)",
      }}
    />
    <Path
      d="M3251.54 128.12h-17.79v-1.09h17.79v1.09z"
      style={{
        fill: "url(#eB)",
      }}
    />
    <Path
      d="M3251.54 121.29h-17.79v-1.09h17.79v1.09z"
      style={{
        fill: "url(#eC)",
      }}
    />
    <Path
      d="M3251.54 114.46h-17.79v-1.09h17.79v1.09z"
      style={{
        fill: "url(#eD)",
      }}
    />
    <Path
      d="M3251.54 107.63h-17.79v-1.09h17.79v1.09z"
      style={{
        fill: "url(#eE)",
      }}
    />
    <Path
      d="M3251.54 100.8h-17.79v-1.09h17.79v1.09z"
      style={{
        fill: "url(#eF)",
      }}
    />
    <Path
      d="M3251.54 162.26h-17.79v-1.09h17.79v1.09z"
      style={{
        fill: "url(#eG)",
      }}
    />
    <Path
      d="M3251.54 169.09h-17.79v-1.1h17.79v1.1z"
      style={{
        fill: "url(#eH)",
      }}
    />
    <Path
      d="M3251.54 93.98h-17.79v-1.1h17.79v1.1z"
      style={{
        fill: "url(#eI)",
      }}
    />
    <Path
      d="M3236.34 107.63h5.27v2.78h-5.27v-2.78zM3239.19 121.29h5.43v2.78h-5.43v-2.78z"
      style={{
        fill: "#7eb9d7",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3251.54 175.92h-17.79v-1.1h17.79v1.1z"
      style={{
        fill: "url(#eJ)",
      }}
    />
    <Path
      d="M3218.75 99.71h15v3.88h-15v-3.88z"
      style={{
        fill: "url(#eK)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 106.54h15v3.87h-15v-3.87z"
      style={{
        fill: "url(#eL)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 113.37h15v3.87h-15v-3.87z"
      style={{
        fill: "url(#eM)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 120.2h15v3.87h-15v-3.87z"
      style={{
        fill: "url(#eN)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 127.03h15v3.87h-15v-3.87z"
      style={{
        fill: "url(#eO)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 133.85h15v3.88h-15v-3.88z"
      style={{
        fill: "url(#eP)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 140.68h15v3.88h-15v-3.88z"
      style={{
        fill: "url(#eQ)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 147.51h15v3.87h-15v-3.87z"
      style={{
        fill: "url(#eR)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 154.34h15v3.87h-15v-3.87z"
      style={{
        fill: "url(#eS)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 161.17h15v3.87h-15v-3.87z"
      style={{
        fill: "url(#eT)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3233.75 85.53h-15v3.87h15v-3.87z"
      style={{
        fill: "url(#eU)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 167.99h15v3.88h-15v-3.88z"
      style={{
        fill: "url(#eV)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 92.88h15v3.88h-15v-3.88z"
      style={{
        fill: "url(#eW)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3218.75 174.82h15v3.88h-15v-3.88z"
      style={{
        fill: "url(#eX)",
        fillRule: "evenodd",
      }}
    />
    <Path
      d="M3259.77 59.2h9.03v5.53h-9.03z"
      style={{
        fill: "url(#eY)",
      }}
    />
    <Path
      d="M3252.54 59.2h7.23v5.53h-7.23z"
      style={{
        fill: "url(#eZ)",
      }}
    />
    <Path
      d="M3288.92 84.98h9.03v5.53h-9.03z"
      style={{
        fill: "url(#fa)",
      }}
    />
    <Path
      d="M3281.68 84.97h7.23v5.53h-7.23z"
      style={{
        fill: "url(#fb)",
      }}
    />
    <Path
      d="M3308.13 95.58h2.24v86.07h-2.24z"
      style={{
        fill: "url(#fc)",
      }}
    />
    <Path
      d="M3305.46 95.58h2.24v86.07h-2.24z"
      style={{
        fill: "url(#fd)",
      }}
    />
    <Path
      d="M3302.8 95.58h2.24v86.07h-2.24z"
      style={{
        fill: "url(#fe)",
      }}
    />
    <Path
      d="M3300.13 95.58h2.24v86.07h-2.24z"
      style={{
        fill: "url(#ff)",
      }}
    />
    <Path
      d="M3297.47 95.58h2.24v86.07h-2.24z"
      style={{
        fill: "url(#fg)",
      }}
    />
    <Path
      d="M3294.81 95.58h2.24v86.07h-2.24z"
      style={{
        fill: "url(#fh)",
      }}
    />
    <Path
      d="M3292.14 95.58h2.24v86.07h-2.24z"
      style={{
        fill: "url(#fi)",
      }}
    />
    <Path
      d="M3215.26 121.75h2.91v59.9h-2.91z"
      style={{
        fill: "url(#fj)",
      }}
    />
    <Path
      d="M3211.8 121.75h2.91v59.9h-2.91z"
      style={{
        fill: "url(#fk)",
      }}
    />
    <Path
      d="M3208.34 121.75h2.91v59.9h-2.91z"
      style={{
        fill: "url(#fl)",
      }}
    />
    <Path
      d="M3047.88 200.2h318.01v1.13h-318.01z"
      style={{
        fill: "#d6d6d6",
      }}
    />
    <Path
      d="M3186.12 190.27h179.77v-7h-150.82c-10.52 0-16.54 7-28.95 7Z"
      style={{
        fill: "#abc645",
      }}
    />
    <Path
      d="m3337.7 105.06-6.58-17.19-6.59 17.19v48.05h13.17v-48.05z"
      style={{
        fill: "url(#fm)",
      }}
    />
    <Path
      d="M3297.62 105.07h26.92v48.05h-26.92z"
      style={{
        fill: "#f5cb85",
      }}
    />
    <Path
      d="m3305.67 88.85 25.45-.98-6.59 17.19h-26.92l8.06-16.21z"
      style={{
        fill: "#fff",
      }}
    />
    <Path
      d="M3308.35 92.45h7.17v10.19h-7.17z"
      style={{
        fill: "#f5cb85",
      }}
    />
    <Path
      d="M3310.08 94.58h3.7v8.05h-3.7z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3310.85 96.17h2.93v6.47h-2.93z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3311.67 98.33h2.11v4.31h-2.11z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="m3315.51 102.64 3.94-10.19h-3.94v10.19z"
      style={{
        fill: "url(#fn)",
      }}
    />
    <Path
      d="M3309.22 126.63h3.7v11.11h-3.7zM3316.21 126.63h3.7v11.11h-3.7zM3302.25 126.63h3.7v11.11h-3.7zM3309.22 111.25h3.7v11.11h-3.7zM3316.21 111.25h3.7v11.11h-3.7zM3302.25 111.25h3.7v11.11h-3.7z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3303.02 112.75h2.93v9.61h-2.93zM3310 112.75h2.93v9.61H3310z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3316.98 112.75h2.93v9.61h-2.93z"
      style={{
        fill: "#486d7f",
      }}
    />
    <Path
      d="M3303.84 114.88h2.11v4.07h-2.11zM3303.84 119.63h2.11v2.73h-2.11zM3310.82 114.88h2.11v4.07h-2.11zM3310.81 119.63l.01 2.73h2.11l-.01-2.73h-2.11z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3317.8 114.88h2.11v4.07h-2.11zM3317.8 119.63h2.11v2.73h-2.11z"
      style={{
        fill: "#80d9de",
      }}
    />
    <Path
      d="M3303.02 128.13h2.93v9.17h-2.93z"
      style={{
        fill: "#486d7f",
      }}
    />
    <Path
      d="M3303.84 131.65h2.11v5.65h-2.11z"
      style={{
        fill: "#80d9de",
      }}
    />
    <Path
      d="M3310 128.13h2.93v9.17H3310zM3316.98 128.13h2.93v9.17h-2.93z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3310.82 131.65h2.11v5.65h-2.11zM3317.8 131.65h2.11v5.65h-2.11z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3325.98 126.68h5.64v25.97h-5.64z"
      style={{
        fill: "#865955",
      }}
    />
    <Path
      d="M3327.45 130.11h.99v1.16h.83v-1.16h.99v3.06h-.99v-1.24h-.83v1.24h-.99v-3.06ZM3330.5 135.74c0 .57-.17 1-.52 1.29-.29.24-.67.37-1.13.37s-.85-.12-1.14-.36c-.34-.29-.52-.72-.52-1.29 0-.54.17-.95.51-1.22.29-.23.67-.35 1.14-.35s.85.12 1.13.35c.34.28.52.68.52 1.22h.01Zm-1.65.91c.19 0 .34-.09.44-.27.09-.16.14-.37.14-.64 0-.23-.04-.42-.13-.57-.1-.17-.25-.26-.45-.26s-.34.09-.45.26c-.09.15-.14.34-.14.57 0 .27.04.49.13.64.1.18.25.27.45.27h.01ZM3327.42 138.37h2.81v.75h-.91v2.32h-.99v-2.32h-.91v-.75ZM3327.61 142.5h2.48v.75h-1.49v.41h1.24v.66h-1.24v.5h1.49v.75h-2.48v-3.06ZM3327.65 146.63h.99v2.32h1.41v.75h-2.4v-3.06Z"
      style={{
        fill: "#f5cb85",
      }}
    />
    <Path
      d="M3309.22 142.01h3.7v11.11h-3.7zM3316.21 142.01h3.7v11.11h-3.7zM3302.25 142.01h3.7v11.11h-3.7z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3303.02 143.5h2.93v9.17h-2.93z"
      style={{
        fill: "#486d7f",
      }}
    />
    <Path
      d="M3303.84 147.03h2.11v5.65h-2.11z"
      style={{
        fill: "#80d9de",
      }}
    />
    <Path
      d="M3310 143.5h2.93v9.17H3310zM3316.98 143.5h2.93v9.17h-2.93z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3310.82 147.03h2.11v5.65h-2.11zM3317.8 147.03h2.11v5.65h-2.11z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3324.53 153.11h13.16v21.16h-13.16z"
      style={{
        fill: "#865955",
      }}
    />
    <Path
      d="M3297.62 153.11h26.92v21.16h-26.92z"
      style={{
        fill: "#b27771",
      }}
    />
    <Path
      d="M3302.25 157.38h17.67v16.88h-17.67z"
      style={{
        fill: "#865955",
      }}
    />
    <Path
      d="M3303.02 158.88h16.89v14.72h-16.89z"
      style={{
        fill: "#384d70",
      }}
    />
    <Path
      d="M3317.8 162.4h2.11v5.65h-2.11zM3304.61 162.4h12.37v5.65h-12.37zM3304.61 168.73h12.37v4.88h-12.37zM3317.8 168.73h2.11v4.88h-2.11z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3308.02 173.61h3.07l-4.87-14.73h-3.07l4.87 14.73zM3311.99 173.61h2.04l-4.88-14.73h-2.03l4.87 14.73z"
      style={{
        fill: "#fff",
        isolation: "isolate",
        opacity: 0.4,
      }}
    />
    <Path
      d="m3104.19 152.77-6.59-16.13-6.58 16.13v55.24h13.17v-55.24z"
      style={{
        fill: "#f5cb85",
      }}
    />
    <Path
      d="M3064.11 152.78h26.92v55.24h-26.92z"
      style={{
        fill: "url(#fo)",
      }}
    />
    <Path
      d="m3071.84 137.21 25.76-.57-6.58 16.13h-26.92l7.74-15.56z"
      style={{
        fill: "#fff",
      }}
    />
    <Path
      d="M3074.63 140.71h7.17v10.19h-7.17z"
      style={{
        fill: "url(#fp)",
      }}
    />
    <Path
      d="M3076.36 142.64h3.7v8.26h-3.7z"
      style={{
        fill: "#f5cb85",
      }}
    />
    <Path
      d="M3077.14 144.48h2.93v5.65h-2.93z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3077.96 146.74h2.11v3.38h-2.11z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="m3081.8 150.9 3.94-10.19h-3.94v10.19zM3075.71 158.08h3.7v11.78h-3.7zM3082.7 158.08h3.7v11.78h-3.7zM3068.73 158.08h3.7v11.78h-3.7z"
      style={{
        fill: "#f5cb85",
      }}
    />
    <Path
      d="M3069.51 159.92h2.93v9.17h-2.93zM3076.49 159.92h2.93v9.17h-2.93z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3083.47 159.92h2.93v9.17h-2.93z"
      style={{
        fill: "#486d7f",
      }}
    />
    <Path
      d="M3070.33 161.61h2.11v4.07h-2.11zM3070.33 166.36h2.11v2.73h-2.11zM3077.3 161.61h2.11v4.07h-2.11zM3077.3 166.36v2.72h2.11v-2.72h-2.11z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3084.29 161.61h2.11v4.07h-2.11zM3084.29 166.36h2.11v2.73h-2.11z"
      style={{
        fill: "#80d9de",
      }}
    />
    <Path
      d="M3075.71 173.54h3.7v11.78h-3.7zM3082.7 173.54h3.7v11.78h-3.7zM3068.73 173.54h3.7v11.78h-3.7z"
      style={{
        fill: "#f5cb85",
      }}
    />
    <Path
      d="M3069.51 175.37h2.93v9.17h-2.93z"
      style={{
        fill: "#486d7f",
      }}
    />
    <Path
      d="M3070.33 178.89h2.11v5.65h-2.11z"
      style={{
        fill: "#80d9de",
      }}
    />
    <Path
      d="M3076.49 175.37h2.93v9.17h-2.93zM3083.47 175.37h2.93v9.17h-2.93z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3077.3 178.89h2.11v5.65h-2.11zM3084.29 178.89h2.11v5.65h-2.11z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3064.11 185.31h26.92v22.7h-26.92z"
      style={{
        fill: "#865955",
      }}
    />
    <Path
      d="M3068.73 191.39h17.67v16.63h-17.67z"
      style={{
        fill: "#b27771",
      }}
    />
    <Path
      d="M3069.51 193.15h16.89v14.09h-16.89z"
      style={{
        fill: "#384d70",
      }}
    />
    <Path
      d="M3084.29 196.03h2.11v5.65h-2.11zM3071.1 196.03h12.37v5.65h-12.37zM3071.1 202.36h12.37v4.88h-12.37zM3084.29 202.36h2.11v4.88h-2.11z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3075.46 207.24h3.07l-4.66-14.09h-3.07l4.66 14.09zM3079.43 207.24h2.03l-4.65-14.09h-2.04l4.66 14.09z"
      style={{
        fill: "#fff",
        isolation: "isolate",
        opacity: 0.4,
      }}
    />
    <Path
      d="M3091.02 185.32h13.16v22.7h-13.16z"
      style={{
        fill: "#b27771",
      }}
    />
    <Path
      d="M3069.65 187.38c.13 0 .25.02.34.07a.646.646 0 0 1 .34.43c.02.09.04.19.04.29 0 .13-.02.25-.06.35s-.1.18-.17.24-.16.11-.26.14-.22.05-.34.05h-.28v1.04h-.53v-2.61h.92Zm-.16 1.18c.1 0 .19-.03.25-.1.06-.06.1-.16.1-.3s-.03-.23-.08-.3c-.06-.07-.15-.1-.28-.1h-.22v.79h.23ZM3071.34 187.38v1h.61v-1h.53v2.61h-.53v-1.16h-.61v1.16h-.53v-2.61h.53ZM3074.18 187.38l.69 2.61h-.55l-.12-.55h-.69l-.12.55h-.55l.69-2.61h.65Zm-.07 1.63-.25-1.17-.25 1.17h.51-.01ZM3076.21 187.38c.22 0 .39.06.51.17s.18.28.18.51c0 .18-.04.32-.11.43-.07.11-.18.18-.33.21.13.03.23.07.29.14.06.07.1.17.11.32 0 .05 0 .1.01.16v.19c0 .14.01.24.02.32.01.07.05.12.09.15v.02h-.57s-.04-.08-.05-.13c0-.05-.01-.1-.01-.15v-.5a.67.67 0 0 0-.09-.24c-.05-.06-.12-.09-.23-.09h-.28v1.1h-.53v-2.61h.99Zm-.23 1.14c.13 0 .22-.03.29-.09.07-.06.1-.16.1-.3 0-.24-.12-.36-.37-.36h-.25v.75h.23ZM3078.24 187.38l.37 1.84.38-1.84h.78v2.61h-.48v-2.08l-.46 2.08h-.41l-.46-2.08v2.08h-.48v-2.61h.78-.02ZM3081.49 187.38l.69 2.61h-.55l-.12-.55h-.69l-.12.55h-.55l.69-2.61h.65Zm-.07 1.63-.25-1.17-.25 1.17h.51-.01ZM3083.6 187.84c-.05-.09-.13-.13-.25-.13-.07 0-.12.02-.16.05-.04.04-.08.09-.1.17s-.04.18-.05.31c0 .13-.01.28-.01.47 0 .2 0 .36.02.48.01.12.03.22.06.28.03.07.06.11.11.14.04.02.09.03.15.03.05 0 .09 0 .13-.02s.07-.05.1-.09c.03-.05.05-.11.07-.2.02-.09.03-.2.03-.34h.53c0 .14-.01.28-.03.4a.81.81 0 0 1-.12.33c-.06.09-.14.17-.25.22s-.25.08-.42.08c-.2 0-.35-.03-.47-.09s-.21-.15-.27-.27c-.06-.12-.1-.26-.12-.43s-.03-.35-.03-.55 0-.38.03-.55c.02-.17.06-.31.12-.43s.15-.21.27-.28c.12-.07.27-.1.47-.1s.34.03.44.09c.11.06.19.14.24.23s.09.19.1.3.02.21.02.3h-.53c0-.19-.02-.33-.07-.41ZM3085.46 188.39l.37-1.01h.57l-.68 1.58v1.03h-.53v-1.03l-.68-1.58h.59l.34 1.01h.02Z"
      style={{
        fill: "#ba807d",
      }}
    />
    <Path
      d="M3153.26 132.95h28.58v57.32h-28.58z"
      style={{
        fill: "#f5cb85",
      }}
    />
    <Path
      d="M3135.68 190.27h17.58v-57.32l-8.28-18.84-9.3 18.84v57.32z"
      style={{
        fill: "url(#fq)",
      }}
    />
    <Path
      d="M3172.08 114.11h-27.1l8.28 18.84h28.58l-9.76-18.84z"
      style={{
        fill: "#fff",
      }}
    />
    <Path
      d="M3165.33 153.77h4.44v11.11h-4.44zM3157.56 153.77h4.44v11.11h-4.44zM3173.11 153.77h4.44v11.11h-4.44zM3165.33 138.4h4.44v11.11h-4.44zM3157.56 138.4h4.44v11.11h-4.44zM3173.11 138.4h4.44v11.11h-4.44zM3157.56 167.7h19.99v5.94h-19.99z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3173.11 139.9h3.51v9.61h-3.51zM3165.33 139.9h3.51v9.61h-3.51z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3157.56 139.9h3.51v9.61h-3.51z"
      style={{
        fill: "#486d7f",
      }}
    />
    <Path
      d="M3173.1 142.03h2.53v4.07h-2.53zM3173.1 146.78h2.53v2.73h-2.53zM3165.33 142.03h2.53v4.07h-2.53zM3167.86 146.78v2.73h-2.53v-2.73h2.53z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3157.55 142.03h2.53v4.07h-2.53zM3157.55 146.78h2.53v2.73h-2.53z"
      style={{
        fill: "#80d9de",
      }}
    />
    <Path
      d="M3173.11 155.27h3.51v9.17h-3.51z"
      style={{
        fill: "#486d7f",
      }}
    />
    <Path
      d="M3173.1 158.79h2.53v5.65h-2.53z"
      style={{
        fill: "#80d9de",
      }}
    />
    <Path
      d="M3165.33 155.27h3.51v9.17h-3.51zM3157.56 155.27h3.51v9.17h-3.51z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3165.33 158.79h2.53v5.65h-2.53zM3157.56 158.79h2.53v5.65h-2.53z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3162 174.7h15.55v11.72H3162z"
      style={{
        fill: "#805d3f",
      }}
    />
    <Path
      d="M3162 176.2h14.62v9.54H3162z"
      style={{
        fill: "#384d70",
      }}
    />
    <Path
      d="M3162 179.72h13.64v6.02H3162z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3157.56 174.65h4.44v15.57h-4.44z"
      style={{
        fill: "#805d3f",
      }}
    />
    <Path
      d="M3157.55 176.19h3.51v13.61h-3.51z"
      style={{
        fill: "#384d70",
      }}
    />
    <Path
      d="m3157.56 185.73-.01-6.02h2.53l.01 6.03-2.53-.01zM3160.08 186.42v3.38h-2.53v-3.38h2.53z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3159.47 169.1h1.62c.4 0 .72.12.95.35.22.21.32.46.32.76s-.11.55-.32.76c-.24.23-.56.35-.96.35h-.59v.94h-1.02v-3.15Zm1.02.77v.68h.42c.12 0 .21-.03.28-.1s.1-.15.1-.24c0-.1-.03-.18-.1-.24-.06-.07-.16-.1-.28-.1h-.42ZM3163.08 169.1h1.02v3.15h-1.02v-3.15ZM3164.88 169.1h2.72v.77l-1.53 1.53h1.62v.85h-2.89v-.85l1.53-1.53h-1.45v-.77ZM3168.47 169.1h2.72v.77l-1.53 1.53h1.62v.85h-2.89v-.85l1.53-1.53h-1.45v-.77ZM3173.15 169.1h1.02l1.19 3.15h-1.1l-.12-.42h-.95l-.12.42h-1.1l1.19-3.15h-.01Zm.51 1.02-.31 1.1h.61l-.3-1.1Z"
      style={{
        fill: "#f4d887",
      }}
    />
    <Path
      d="M3161.99 186.42h19.85v3.85h-19.85zM3153.26 186.42h4.29v3.85h-4.29z"
      style={{
        fill: "#b27771",
      }}
    />
    <Path
      d="M3135.68 186.42h17.58v3.85h-17.58z"
      style={{
        fill: "#865955",
      }}
    />
    <Path
      d="M3166.91 185.74h3.08l-3.16-9.54h-3.07l3.15 9.54z"
      style={{
        fill: "#fff",
        isolation: "isolate",
        opacity: 0.4,
      }}
    />
    <Path
      d="M3157.35 132.97h-4.09l-8.28-18.86h3.71l8.66 18.86z"
      style={{
        fill: "#f1f1f1",
      }}
    />
    <Path
      d="M3165.57 132.97h-4.05l-8.61-18.86h4.08l8.58 18.86z"
      style={{
        fill: "url(#fr)",
      }}
    />
    <Path
      d="M3162.16 120.4h7.42v10.19h-7.42z"
      style={{
        fill: "#f5cb85",
      }}
    />
    <Path
      d="M3163.66 123.2h4.44v7.39h-4.44z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3163.66 124.12h3.51v6.47h-3.51z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3163.66 126.28h2.53v4.31h-2.53z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="m3162.17 130.59-3.94-10.18h3.94v10.18z"
      style={{
        fill: "#e08c3e",
      }}
    />
    <Path
      d="m3162.17 130.59-3.94-10.18-1 3.87 4.94 6.31z"
      style={{
        fill: "#e4e4e4",
      }}
    />
    <Path
      d="M3117.63 154.91h27.62v42.1h-27.62z"
      style={{
        fill: "#f4d887",
      }}
    />
    <Path
      d="m3117.62 154.91-6.58-16.1-6.58 16.1v42.1h13.16v-42.1z"
      style={{
        fill: "url(#fs)",
      }}
    />
    <Path
      d="M3117.62 154.91h27.63l-8.43-16.94-25.78.84 6.58 16.1z"
      style={{
        fill: "#fff",
      }}
    />
    <Path
      d="M3137.21 176.5h3.89v12.75h-3.89zM3129.78 176.5h3.89v12.75h-3.89zM3122.58 176.5h3.89v12.75h-3.89z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3127.17 147.4v1.14h-1.72v-1.14c0-2.29 1.86-4.15 4.15-4.15h1.72c-2.29 0-4.15 1.86-4.15 4.15ZM3123.05 148.54v4.48l-1.71-3.22v-1.26h1.71z"
      style={{
        fill: "#e3a845",
      }}
    />
    <Path
      d="M3127.17 148.54v-1.14c0-2.29 1.86-4.15 4.15-4.15s4.15 1.86 4.15 4.15v1.14h4.11v4.48h-16.54v-4.48h4.13Z"
      style={{
        fill: "#f4d887",
      }}
    />
    <Path
      d="M3137.21 178.33h2.93v10.14h-2.93zM3129.78 178.33h3.03v10.14h-3.03zM3122.59 178.33h3.03v10.14h-3.03z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3122.59 181.37h2.35v7.11h-2.35zM3129.78 181.37h2.35v7.11h-2.35zM3137.21 181.37h2.35v7.11h-2.35z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3129.38 147.4v5.62h3.89v-5.62c0-1.07-.87-1.94-1.94-1.94-.48 0-.91.17-1.25.46-.42.35-.69.89-.69 1.48h-.01Z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3129.38 147.4v5.62h2.93v-5.36c0-1.04-.73-1.89-1.63-1.89-.22 0-.42.05-.61.14-.42.35-.69.89-.69 1.48Z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3129.38 150.5h2.35v2.53h-2.35zM3129.38 149.68v-2.13s.03-.04.05-.05c.24-.22.56-.35.9-.35.77 0 1.4.66 1.4 1.47v1.06h-2.35Z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="m3119.38 145.55 2.81-.14v-12.04h-2.81v12.18z"
      style={{
        fill: "#f4d887",
      }}
    />
    <Path
      d="m3119.38 145.55-1.47-2.61v-9.57h1.47v12.18z"
      style={{
        fill: "#e3a845",
      }}
    />
    <Path
      d="m3119.38 145.55-1.47-2.61-.01-3.4-.82-.93-3.76.12 6.06 6.82z"
      style={{
        fill: "#e4e4e4",
      }}
    />
    <Path
      d="M3117.63 189.25h27.62v7.76h-27.62z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3104.46 189.25h13.16v7.76h-13.16z"
      style={{
        fill: "#805d3f",
      }}
    />
    <Path
      d="M3137.6 197.01c.46-.94 1.4-1.59 2.49-1.59s2.02.65 2.49 1.59h-4.98ZM3125.07 197.01c.61-.88 1.86-1.48 3.31-1.48s2.7.6 3.31 1.48h-6.62Z"
      style={{
        fill: "#cbe14c",
      }}
    />
    <Path
      d="M3116.24 197.01c.95-2.05 2.89-3.46 5.14-3.46s4.18 1.41 5.14 3.46h-10.28Z"
      style={{
        fill: "#cbe14c",
      }}
    />
    <Path
      d="M3137.21 162.29v9.8h3.89v-9.8c0-1.07-.87-1.94-1.94-1.94-.48 0-.91.17-1.25.46-.42.35-.69.89-.69 1.48h-.01Z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3137.21 162.29v9.02h2.93v-8.76c0-1.04-.73-1.89-1.63-1.89-.22 0-.42.05-.61.14-.42.35-.69.89-.69 1.48Z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3137.21 166.4h2.35v4.92h-2.35zM3137.21 165.58v-3.14s.03-.04.05-.05c.24-.22.56-.35.9-.35.77 0 1.4.66 1.4 1.47v2.07h-2.35Z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3129.78 162.29v9.8h3.89v-9.8c0-1.07-.87-1.94-1.94-1.94-.48 0-.91.17-1.25.46-.42.35-.69.89-.69 1.48h-.01Z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3129.78 162.29v9.02h2.93v-8.76c0-1.04-.73-1.89-1.63-1.89-.22 0-.42.05-.61.14-.42.35-.69.89-.69 1.48Z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3129.78 166.4h2.35v4.92h-2.35zM3129.78 165.58v-3.14s.03-.04.05-.05c.24-.22.56-.35.9-.35.77 0 1.4.66 1.4 1.47v2.07h-2.35Z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3122.58 162.29v9.8h3.89v-9.8c0-1.07-.87-1.94-1.94-1.94-.48 0-.91.17-1.25.46-.42.35-.69.89-.69 1.48h-.01Z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3122.58 162.29v9.02h2.93v-8.76c0-1.04-.73-1.89-1.63-1.89-.22 0-.42.05-.61.14-.42.35-.69.89-.69 1.48Z"
      style={{
        fill: "#486d7f",
      }}
    />
    <Path
      d="M3122.58 166.4h2.35v4.92h-2.35zM3122.58 165.58v-3.14s.03-.04.05-.05c.24-.22.56-.35.9-.35.77 0 1.4.66 1.4 1.47v2.07h-2.35Z"
      style={{
        fill: "#80d9de",
      }}
    />
    <Path
      d="M3236.74 133.5h27.62v42.1h-27.62z"
      style={{
        fill: "url(#ft)",
      }}
    />
    <Path
      d="m3264.36 133.5 6.58-16.1 6.59 16.1v42.1h-13.17v-42.1z"
      style={{
        fill: "url(#fu)",
      }}
    />
    <Path
      d="M3264.36 133.5h-27.62l8.42-16.94 25.78.84-6.58 16.1z"
      style={{
        fill: "#fff",
      }}
    />
    <Path
      d="M3240.89 155.09h3.89v12.75h-3.89zM3248.32 155.09h3.89v12.75h-3.89zM3255.51 155.09h3.89v12.75h-3.89z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3254.82 125.99v1.14h1.72v-1.14c0-2.29-1.86-4.15-4.15-4.15h-1.72c2.29 0 4.15 1.86 4.15 4.15Z"
      style={{
        fill: "url(#fv)",
      }}
    />
    <Path
      d="M3258.93 127.14v4.48l1.72-3.23v-1.25h-1.72z"
      style={{
        fill: "url(#fw)",
      }}
    />
    <Path
      d="M3254.82 127.14V126c0-2.29-1.86-4.15-4.15-4.15s-4.15 1.86-4.15 4.15v1.14h-4.11v4.48h16.54v-4.48h-4.13Z"
      style={{
        fill: "url(#fx)",
      }}
    />
    <Path
      d="M3241.84 156.93h2.93v10.14h-2.93zM3249.18 156.93h3.03v10.14h-3.03zM3256.37 156.93h3.03v10.14h-3.03z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3257.05 159.96h2.35v7.11h-2.35zM3249.86 159.96h2.35v7.11h-2.35zM3242.43 159.96h2.35v7.11h-2.35z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3252.61 125.99v5.62h-3.89v-5.62c0-1.07.87-1.94 1.94-1.94.48 0 .91.17 1.25.46.42.35.69.89.69 1.48h.01Z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3252.61 125.99v5.62h-2.93v-5.36c0-1.04.73-1.89 1.63-1.89.22 0 .42.05.61.14.42.35.69.89.69 1.48Z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3250.26 129.09h2.35v2.53h-2.35zM3252.61 128.27v-2.13s-.03-.04-.05-.05c-.24-.22-.56-.35-.9-.35-.77 0-1.4.66-1.4 1.47v1.06h2.35Z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="m3262.61 124.15-2.82-.15v-12.04h2.82v12.19z"
      style={{
        fill: "url(#fy)",
      }}
    />
    <Path
      d="m3262.61 124.15 1.47-2.62v-9.57h-1.47v12.19z"
      style={{
        fill: "url(#fz)",
      }}
    />
    <Path
      d="M3236.74 167.84h27.62v7.76h-27.62z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3264.37 167.85h13.16v7.76h-13.16z"
      style={{
        fill: "#805d3f",
      }}
    />
    <Path
      d="M3244.38 175.6c-.46-.94-1.4-1.59-2.49-1.59s-2.02.65-2.49 1.59h4.98ZM3256.91 175.6c-.61-.88-1.86-1.48-3.31-1.48s-2.7.6-3.31 1.48h6.62Z"
      style={{
        fill: "#cbe14c",
      }}
    />
    <Path
      d="M3265.75 175.6c-.95-2.05-2.89-3.46-5.14-3.46s-4.18 1.41-5.14 3.46h10.28Z"
      style={{
        fill: "#cbe14c",
      }}
    />
    <Path
      d="M3244.78 140.89v9.8h-3.89v-9.8c0-1.07.87-1.94 1.94-1.94.48 0 .91.17 1.25.46.42.35.69.89.69 1.48h.01Z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3244.78 140.89v9.02h-2.93v-8.76c0-1.04.73-1.89 1.63-1.89.22 0 .42.05.61.14.42.35.69.89.69 1.48Z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3242.43 144.99h2.35v4.92h-2.35zM3244.78 144.17v-3.14s-.03-.04-.05-.05c-.24-.22-.56-.35-.9-.35-.77 0-1.4.66-1.4 1.47v2.07h2.35Z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3252.21 140.89v9.8h-3.89v-9.8c0-1.07.87-1.94 1.94-1.94.48 0 .91.17 1.25.46.42.35.69.89.69 1.48h.01Z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3252.21 140.89v9.02h-2.93v-8.76c0-1.04.73-1.89 1.63-1.89.22 0 .42.05.61.14.42.35.69.89.69 1.48Z"
      style={{
        fill: "#40597e",
      }}
    />
    <Path
      d="M3249.86 144.99h2.35v4.92h-2.35zM3252.21 144.17v-3.14s-.03-.04-.05-.05c-.24-.22-.56-.35-.9-.35-.77 0-1.4.66-1.4 1.47v2.07h2.35Z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3259.4 140.89v9.8h-3.89v-9.8c0-1.07.87-1.94 1.94-1.94.48 0 .91.17 1.25.46.42.35.69.89.69 1.48h.01Z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3259.4 140.89v9.02h-2.93v-8.76c0-1.04.73-1.89 1.63-1.89.22 0 .42.05.61.14.42.35.69.89.69 1.48Z"
      style={{
        fill: "#486d7f",
      }}
    />
    <Path
      d="M3257.05 144.99h2.35v4.92h-2.35zM3259.4 144.17v-3.14s-.03-.04-.05-.05c-.24-.22-.56-.35-.9-.35-.77 0-1.4.66-1.4 1.47v2.07h2.35Z"
      style={{
        fill: "#80d9de",
      }}
    />
    <Path
      d="M3351.61 148.19h9.64v34.28h-9.64z"
      style={{
        fill: "#e9dec4",
      }}
    />
    <Path
      d="m3351.61 148.19-6.58-13.17-6.58 13.17v34.28h13.16v-34.28z"
      style={{
        fill: "#ccb393",
      }}
    />
    <Path
      d="M3351.61 148.19h35.19l-6.88-15.05-34.89 1.88 6.58 13.17z"
      style={{
        fill: "#fff",
      }}
    />
    <Path
      d="M3357.9 139.55h5.47v42.92h-2.12v-34.28l-3.35-8.64z"
      style={{
        fill: "#875f42",
      }}
    />
    <Path
      d="M3363.36 139.55h13.87v42.92h-13.87z"
      style={{
        fill: "#b47f54",
      }}
    />
    <Path
      d="M3377.23 148.19h9.57v34.28h-9.57z"
      style={{
        fill: "#e9dec4",
      }}
    />
    <Path
      d="M3365.43 162.7h9.6v16.75h-9.6zM3365.43 143.48h9.6v15.26h-9.6z"
      style={{
        fill: "#875f42",
      }}
    />
    <Path
      d="M3343.44 165h3.28v11.81h-3.28zM3343.44 150.36h3.28v8.39h-3.28z"
      style={{
        fill: "#e9dec4",
      }}
    />
    <Path
      d="M3358.56 148.19h2.69v34.28h-2.69z"
      style={{
        fill: "#ccb393",
      }}
    />
    <Path
      d="M3370.16 128.04h2.81v10.22h-2.81z"
      style={{
        fill: "#e9dec4",
      }}
    />
    <Path
      d="m3370.16 138.26-1.48-2.21v-8.02h1.48v10.23z"
      style={{
        fill: "#ccb393",
      }}
    />
    <Path
      d="M3344.31 150.36h2.42v8.39h-2.42zM3344.31 165h2.42v11.81h-2.42zM3365.44 162.7h8.59v16.75h-8.59zM3365.44 143.48h8.59v15.26h-8.59z"
      style={{
        fill: "#384d70",
      }}
    />
    <Path
      d="M3365.43 146.01h6.95v12.74h-6.95zM3365.44 169.96h6.95v9.49h-6.95zM3365.44 165.7h6.95v3.58h-6.95zM3345.51 165.7h1.21v3.58h-1.21zM3345.51 169.96h1.21v6.84h-1.21zM3345.51 152.87h1.21v5.87h-1.21z"
      style={{
        fill: "#619fd0",
      }}
    />
    <Path
      d="M3365.44 158.75v-4.46l1.47 4.46h-1.47zM3368.22 162.7l5.54 16.75h-3.07l-5.25-15.87v-.88h2.78z"
      style={{
        fill: "#fff",
        isolation: "isolate",
        opacity: 0.4,
      }}
    />
    <Path
      d="m3370.16 138.26-1.48-2.21v-2.3l-2.41.13 3.89 4.38z"
      style={{
        fill: "#d6d6d6",
      }}
    />
    <Path
      d="M3359.26 148.19h-3.66l-6.91-13.36 3.5-.19 7.07 13.55z"
      style={{
        fill: "url(#fA)",
      }}
    />
    <Path
      d="M3361.25 148.19h-2.69l-.38-1.31-.28-7.33 3.35 8.64z"
      style={{
        fill: "#d6d6d6",
      }}
    />
    <Path
      d="m3367.81 158.75-2.37-7.18v-6.14l4.4 13.32h-2.03zM3371.15 162.7l2.88 8.69v6.15l-4.91-14.84h2.03z"
      style={{
        fill: "#fff",
        isolation: "isolate",
        opacity: 0.4,
      }}
    />
    <Path
      d="M2938 238.64c6.96.31 7.87 1.02 9.04 7.91 1.05 6.17-1.15 10.75 3.34 13.93 4.01 2.84 3.17 10.21 2.47 15.08-.5 3.52-2.23 6.1-5.97 7.37-4.25 1.44-5.34 11.38-15.05 12.96-11.56 1.88-14.17-2.95-30.83-7.38-3.27-.87-8.21 7.26-15.71-.5-.53-.55-1.19-1.39-1.77-1.39-5.41.08-7.7-3.26-8.66-7.88-.38-1.84-1.01-2.62-2.97-3.12-3.49-.89-6.53-2.82-6.88-6.94-.66-7.8-2.33-19.6 5.27-18.43 4.57-12.44 8.04-29.46 19.57-19.83 3.08 2.57 5.2-1.09 7.54.22 1.23-8.93.74-20.27 11.63-17.14 3.65 1.05 6.37-6.15 14.78.39 12.89 10.03 11.96 14.45 14.19 24.75h.01Z"
      style={{
        fill: "url(#fB)",
      }}
    />
    <Path
      d="M2923.61 284.47c-3.27-.87-8.21 7.26-15.71-.5-.53-.55-1.19-1.39-1.77-1.39-5.41.08-7.7-3.26-8.66-7.88-.38-1.84-1.01-2.62-2.97-3.12-3.49-.89-6.53-2.82-6.88-6.94-.66-7.8-2.33-19.6 5.27-18.43 4.57-12.45 8.04-29.46 19.57-19.83 3.08 2.57 5.2-1.09 7.54.22.66-4.84.98-10.77 2.39-13.73-7.25-4.74-9.93 1.61-13.37.62-10.88-3.13-10.4 8.2-11.63 17.14-2.34-1.31-4.46 2.35-7.54-.22-11.6-9.69-15.1 7.65-19.57 19.83-7.61-1.17-5.93 10.6-5.27 18.43.35 4.12 3.38 6.05 6.88 6.94 1.96.5 2.59 1.28 2.97 3.12.96 4.62 3.25 7.96 8.66 7.88.59 0 1.25.84 1.77 1.39 7.46 7.73 12.46-.37 15.71.5 4.56 1.21 9.22 2.44 13.41 4.54 7.68 3.84 19.34 5.69 26.17-2.52-5.35-1.91-3.42-2.44-16.97-6.05Z"
      style={{
        fill: "url(#fC)",
      }}
    />
    <Path
      d="M2728.07 348.24c157.65-6.54 315.13-16.44 472.8-22.37 64.85-2.44 129.71-4.37 194.59-5.8V195.05l-271.91-.42c-79.92 23.99-167.19 28.71-189.77 31.5 1.19 3.02.17 7-3.06 8.36-.46.59-1.04 1.08-1.71 1.41-3.29 1.59-6.59 2.57-10.27 2.63h-.21c-.49.24-1.04.41-1.64.51-.23.04-1.09 1.53-1.42 1.77-.53.38-1.22.63-1.8.95-1.61.9-3.2 1.81-5.08 1.95-1.75.13-3.87-.26-4.97 1.11-.63.78-.65 1.86-.9 2.83-.42 1.65-1.53 3.02-2.67 4.28-1.82 2-3.9 3.91-6.47 4.72-2.57.81-5.75.25-7.34-1.93-.05.5-.63.72-1.11.84l-11.19 2.7c-1.6.39-3.25.78-4.88.56-1.07-.15-2.19-.55-3.2-.16-1.11.42-1.68 1.63-2.43 2.55-1.32 1.63-3.51 2.52-5.6 2.26-.97-.12-2.03-.45-2.88.05-.52.31-.83.87-1.18 1.35-1.62 2.2-4.81 3.09-7.33 2.04-1.06-.44-2.09-1.19-3.22-1.04-1.93.26-2.57 2.79-4.17 3.9-1.89 1.31-4.29.29-6.27-.2-2.31-.57-5.05.55-7.15-.43-1.59-.74-2.56-2.57-2.64-4.27-.19-3.83-3.16-5.31-5.52-7.91-1.1-1.22-1.91-2.51-2.57-3.91-6.05 16.11-20.41 28.57-36.7 34.55-9.23 3.39-19.38 2.21-28.96 1.58-10.8-.72-21.62-1.13-32.43-1.53-38.92-1.46-77.94-1.86-116.74-5.49-3.15-.3-6.3-.56-9.46-.83-14.26 1.7-28.65 2.9-42.99 3.35-14.24.45-28.47-.37-42.71-.47h-2.36v62.36c75.17.33 150.34.88 225.52 1.67Z"
      style={{
        fill: "url(#fD)",
      }}
    />
    <Path
      d="M2990.44 335.5c10.53-.54 21.05-1.07 31.58-1.59-.15-.9-.44-2.42-4.13-5.37-1.81-1.35-4.14-2.31-6.44-3.43-2.41-1.16-3.4-4.33-4.9-6.66-1.05-1.63-1.52-4.71-2.84-4.87-5.74-.7-7.01-5.27-10.75-7.58-3.32-2.05-6.29-1.87-9.66 1.04-3.76 3.26-13.43 4.24-20.08 5.39-1.52-17.12-.23-12.35-5.9-16.44.77-3.8-.14-6.72-6.62-4.68-1.76.56-6.14-5.83-10.35.57-1.12 1.69 2.29-.13-14.16 7.94 2.14-7.4-.59-12.42-7.76-15.41-4.08-1.71.43-16.93-3.65-18.15-1.93-.58-4.64.32-6.73.57-1.7-.58-3.67-1.92-5.07-1.56-6.14 1.59-5.34 4.78-11.47 21.29l-.91-1.43c-2.6.44-5.38.73-7.89 1.28-3.52.78-5.69 2.08-7.91 2.17-5.94.24-3.17-9.61-9.48-7.52-3.09 1.03-5.43 4-7.7 6.36-.82.85-.61 2.38-.9 3.7-5.83-5.46-8.5-4.97-12.49 1.5-6.76-4.86-12.63.53-24.18 1.38l3.2 18.89c67.56.6 122.73 13.05 167.19 22.61Z"
      style={{
        fill: "url(#fE)",
      }}
    />
    <Path
      d="M2942.55 208.2c-1.76-.03-3.69-.08-5.76-.15-.22.35-.48.68-.78.97-.08 1.24-.47 2.45-1.18 3.45v.15c.69.64 1.29 1.4 1.75 2.26.82 1.54 1.01 3.15.63 4.83-.06.26-.14.5-.24.74 36.13-.6 74.73.26 99.41-1.04 32.18-1.7-79.33-10.95-93.83-11.21Z"
      style={{
        fill: "url(#fF)",
      }}
    />
    <Path
      d="M3045.58 221.49c-3.19-2.9-2.17-1.03-31.51-3.95-8.08-.86-10.35-.47-11.23-1.62-2.18-2.86-6.53-1.81-20.23-.86-13.74.92-17.2-2.21-24.25 1.31-3.55-3.85-6.22-2.98-11.24.11-.13-.74.2-1.64-.46-2.06-1.82-1.18-3.63-2.72-6.25-3.09-2.79-.39-3.82.94-4.73 2.2.34.42.65.86.91 1.35.82 1.54 1.01 3.15.63 4.83-.24 1.05-.84 1.97-1.6 2.72-.07.55-.23 1.08-.49 1.59-.25.78-.68 1.45-1.3 2.02l-.08.08c.64 1.58.68 3.43.08 5.04 13.71-1.8 27.95-3.48 42.84-4.92 58.19-5.55 63.61-1.25 68.2-2.36.8-.19 1.32-1.81.7-2.37v-.02Z"
      style={{
        fill: "url(#fG)",
      }}
    />
    <Path
      d="M3379.34 176.56c-3.85-1.23-8.02-2.56-12.27-3.6-21.33-1.31-43.32-3.69-63.35-2.97-97.2 3.48-169.05 26.43-352.28 55.28 63.65-1.71 164.44-21.35 224.85-24.66 158.43-8.68 174.18 3.71 220.29-2.09l-1.12-15.93c-5.26-2.31-10.68-4.28-16.12-6.02Z"
      style={{
        fill: "url(#fH)",
      }}
    />
    <Path
      d="M3382.74 176.75c-24.28-.99-50.82-1.5-79.77-1.36-4.57.02-5.75 3.67-1.49 4.63 20.59 4.67 82.1 1.29 115.84 10.25l-22.69-8.9c-3.89-1.71-7.87-3.23-11.88-4.61h-.01Z"
      style={{
        fill: "url(#T)",
      }}
    />
    <Path
      d="M3300.87 232.82c4.35-6.32 10.52-8.45 17.86-7.61 15.93 1.82 10.45 16.06 17.58 22.93 2.12 2.04 1.34 12.07 2.75 12.58 1.15.42 2.96.19 4.01-.53 6.54-4.49 7.52-4.21 12.07 2.11 2.91 4.05 5.86 7.89 6.46 13.14.11.94 1.23 2.22 2.13 2.5 4.61 1.44 3.87 4.79 3.19 8.35-.76 4-3.29 4.41-6.48 3.81-26.87-5.04-17.86-2.83-43.75-2.58-11.96.12-23.93-.42-35.89-.58-17.46-.23-19.01 1.37-29.19-2.84-.7-.29-1.16-3.89-.49-4.48 5.77-5.16 10.48-6.43 11.62-9.89 2.85-8.64 2.82-8.41 11.48-8.78 2.25-.1 4.34-2.88 6.67-3.25 3.71-.59 3.09-3.17 3.8-5.65 6.94-24.44 9.73-22.4 16.18-19.23Z"
      style={{
        fill: "url(#Q)",
      }}
    />
    <Path
      d="M3328.37 268.23c-1.21.38-3.01.06-3.99-.69-1.19-.91 2.53-10.25 1.11-12.83-2.33-4.25-1.53-8.24-1.23-12.47.69-9.71-5.48-14.89-14.31-16.37-3.54 1.11-6.6 3.35-9.08 6.94-6.11-3-7.61-2.77-10.55 3.42-8.11 17.04-4.09 20.61-9.43 21.46-2.33.37-4.42 3.15-6.67 3.25-8.65.37-8.63.14-11.48 8.78-.71 2.16-2.65 3.27-4.62 4.64 9.09 2.76 27.86 9.6 39.79 12.94 30.22.72 32.15-.93 43.8-.31.51-4.66-1.01-8.84-2.45-13.17-2.46-7.39-3.32-7.95-10.89-5.61v.02Z"
      style={{
        fill: "url(#R)",
      }}
    />
    <Path
      d="M3366.79 232.82c4.35-6.32 10.52-8.45 17.86-7.61 15.93 1.82 10.45 16.06 17.58 22.93 2.12 2.04 1.34 12.07 2.75 12.58 1.15.42 2.96.19 4.01-.53 6.54-4.49 7.52-4.21 12.07 2.11 2.91 4.05 5.86 7.89 6.46 13.14.11.94 1.23 2.22 2.13 2.5 4.61 1.44 3.87 4.79 3.19 8.35-.76 4-3.29 4.41-6.48 3.81-26.87-5.04-17.86-2.83-43.75-2.58-11.96.12-23.93-.42-35.89-.58-17.46-.23-19.01 1.37-29.19-2.84-.7-.29-1.16-3.89-.49-4.48 5.77-5.16 10.48-6.43 11.62-9.89 2.85-8.64 2.82-8.41 11.48-8.78 2.25-.1 4.34-2.88 6.67-3.25 3.71-.59 3.09-3.17 3.8-5.65 6.94-24.44 9.73-22.4 16.18-19.23Z"
      style={{
        fill: "url(#fI)",
      }}
    />
    <Path
      d="M3394.29 268.23c-1.21.38-3.01.06-3.99-.69-1.19-.91 2.53-10.25 1.11-12.83-2.33-4.25-1.53-8.24-1.23-12.47.69-9.71-5.48-14.89-14.31-16.37-3.54 1.11-6.6 3.35-9.08 6.94-6.11-3-7.61-2.77-10.55 3.42-8.11 17.04-4.09 20.61-9.43 21.46-2.33.37-4.42 3.15-6.67 3.25-8.65.37-8.63.14-11.48 8.78-.71 2.16-2.65 3.27-4.62 4.64 9.09 2.76 27.86 9.6 39.79 12.94 30.22.72 32.15-.93 43.8-.31.51-4.66-1.01-8.84-2.45-13.17-2.46-7.39-3.32-7.95-10.89-5.61v.02Z"
      style={{
        fill: "url(#fJ)",
      }}
    />
    <Path
      d="M3317.56 162.34c7.72-4.01 8.83 4.25 11.26 12.32 1.91 0 3.3-.15 4.64.03 2.35.33 3.75 2.37 3.13 4.3-.63 1.95-3.56 3.52-5.33 2.91-8.15-2.82-10.64-4.06-13.36-1.31-1.88 1.9-6.17 1.68-9.24.26-.95.75-1.74 1.73-2.76 2.12-7.46 2.78-7.59-6.95-3.79-9.66 2.53-1.81 1.56-8.52 2.93-14.52 1.31-5.77 6.89-5.19 10.22-1.32 1.09 1.27 1.52 3.12 2.3 4.87Z"
      style={{
        fill: "url(#V)",
      }}
    />
    <Path
      d="M3228.26 272.55c-1.91 2.25-3.62 4.23-7.62 3.58-4.49-.74-7.88-2.79-8.83-5.57-1.92-5.68-2.41-11.56.17-17.21 1.66-3.64 9.25-3.56 8.72-6.79-.97-5.88 2.5-11.25 4.75-16.73 1.48-3.58 3.55-3.98 9.52-2.68 3.82-5.37 5.54-9.37 11.28-8.82 6.6.63 4.69 8.66 8.04 9.26 8.85 1.59 7.04 7.98 7.06 18.16 0 .57-.25 1.45.2 1.68 3.45 1.76 2.4 4.06 2.16 6.35-.93 8.98.38 11.29-3.05 16.81-.42.68-2.54 1.56-3.31 1.36-2.84-.73-3.64.75-5.3 1.53-8.41 3.97-16.29 7.68-23.81-.93h.02Z"
      style={{
        fill: "url(#fK)",
      }}
    />
    <Path
      d="M3135.58 281.43c-1.33 1.36-2.51 2.56-5.29 2.16-3.12-.44-5.47-1.69-6.13-3.37-1.34-3.43-1.68-6.98.12-10.39 1.2-2.28 6.44-2.07 6.05-4.1-.67-3.55 1.73-6.8 3.3-10.1 1.03-2.16 2.47-2.4 6.61-1.62 2.68-3.28 3.86-5.66 7.83-5.33 4.59.38 3.25 5.23 5.58 5.59 6.07.95 4.89 4.66 4.9 10.97 0 .35-.18.87.14 1.01 2.4 1.06 1.67 2.45 1.5 3.83-.66 5.53.26 6.83-2.12 10.15-.29.41-1.76.94-2.3.82-3.35-.75-12.55 8-20.21.36l.02.02Z"
      style={{
        fill: "url(#fL)",
      }}
    />
    <Path
      d="M2822.12 274.49h-.01c-.43-.32.17-7.24-.17-12.87-1.11-1.74-2.93-2.97-4.49-4.68-1.09-1.2-1.94-2.46-2.58-3.79-4.95 13.1-15.41 23.78-27.92 30.51-.88 4.8-.47 9.71.62 14.47.65 2.84 3.01 4.94 6.13 5.69 2.78.67 3.96-1.36 5.29-3.66 5.21 8.79 10.68 5 16.53.95 1.15-.8 1.71-2.31 3.68-1.56.53.2 2-.7 2.3-1.39 2.38-5.63 1.46-7.82 2.12-17.18.17-2.34.9-4.69-1.5-6.49Z"
      style={{
        fill: "url(#fM)",
      }}
    />
    <Path
      d="M3395.46 346.57v-33.4c-14.68 2.24-567.01 28.75-604.64 32.31 29.09-.85 575.54 1.73 604.64 1.09Z"
      style={{
        fill: "url(#fN)",
      }}
    />
    <Path
      d="M2781.09 289.01v.02c-1.97-.12-3.93-.3-5.87-.5-8.41 2.22-17.43 1.22-25.98.65-10.8-.72-21.62-1.13-32.43-1.53-.33-.01-.66-.02-.98-.04 15.57 1.23 32.27 2.65 50.18 4.3 7.08.65 16.9 2.44 19.68 2.01.98-.15 1.66-1.85.92-2.5-1.14-1-3.61-2.3-5.52-2.42Z"
      style={{
        fill: "url(#fO)",
      }}
    />
    <Path
      d="M2781.09 289.01v.02c-1.97-.12-3.93-.3-5.87-.5-8.41 2.22-17.43 1.22-25.98.65-10.8-.72-21.62-1.13-32.43-1.53-.33-.01-.66-.02-.98-.04 15.57 1.23 32.27 2.65 50.18 4.3 7.08.65 16.9 2.44 19.68 2.01.98-.15 1.66-1.85.92-2.5-1.14-1-3.61-2.3-5.52-2.42Z"
      style={{
        fill: "url(#fP)",
      }}
    />
    <Path
      d="M3360.57 285.59c-.99-.07-2.22-.68-2.52-1.33-1.76-3.77-5.83-3.05-10.27-2.83-7.9.32-16.55 1.17-23.15-.77-5.31-1.51-9.24-3-15.12-.24-1.64-6.91-4.68-5.78-11.42-2.19.32-1.18 1.2-2.51.81-3.34-1.06-2.28-1.83-5.11-4.07-6.3-4.58-2.47-6.9 6.4-12.23 5.45-1.41-.27-2.64-1.2-4.01-2.04-2.23-1.36-5-2.08-7.97-3.3l-1.63 1.07c4.43-14.94 7.1-17.58 3.19-20.11-.85-.55-3.12.22-4.82.41-1.49-.61-2.97-1.85-4.78-1.75-2.65.16-7.52 8.01-10.87 11.92-3.93 4.58-6.69 1.13-14.31 6.07-2.85 1.84-5.09 4.35-6.75 7.5-2.28-2.97-4.38-5.74-6.31-8.59-.26-.38 3.04-6.45-5.48-4.31-.74.19-1.89.38-2.2.11-3.71-3.23-6.59-1.08-8.86 2.21-8 2-2.76-1.66-17.48 11.86-3.99-2.31-11.65-5.92-12.02-9.64-.9-8.84-14.57 1.02-21.52-.5-1.38-.3-11.24 6.47-15.95 6.77-5.6.37-10.69.12-15.79 4.2-2.51 2.01-27.56-7.46-30.35-4.21-1.48 1.73-2.54 3.89-2.46 5.59.08 1.89 10.02 2.13 10.37 2.18 72.46 11.12 98.98 11.67 131.96 14.99 104.69 9.8 135.8.37 160.9-1.56v-6.43c-12.16 1.12-22.72-.01-34.89-.92v.03Z"
      style={{
        fill: "url(#fQ)",
      }}
    />
    <Path
      d="M2132.16 242.78c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#fR)",
      }}
    />
    <Path
      d="M2146.55 288.62c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05h.01Z"
      style={{
        fill: "url(#fS)",
      }}
    />
    <Path
      d="M2069.34 238.64c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#fT)",
      }}
    />
    <Path
      d="M2083.73 284.47c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05Z"
      style={{
        fill: "url(#fU)",
      }}
    />
    <Path
      d="M2414.55 284.58c-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-6.68-.26-12.27-3.49-15.62-8.49-3.44-3.42-5.65-8.16-6.04-13.12-1.72-3.19-2.76-6.67-2.6-10.68.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.74-12.94-6.48-.64 2.48-1.8 4.71-3.68 6.79-2.36 2.6-5.33 4.08-5.52 7.91-.16 3.2-2.94 5.35-6.08 4.63-.83-.19-1.67-.39-2.5-.59-1 .99-2.41 1.63-4.23 1.63-3.11 0-5.02-1.86-5.73-4.15-.92-.25-1.85-.51-2.77-.77-1.01 1.18-2.52 1.96-4.54 1.96-3.22 0-5.14-2.01-5.79-4.42-.58.17-1.21.27-1.91.27-.42 0-.81-.05-1.18-.11-.37.07-.77.11-1.19.11-3.25 0-5.19-2.05-5.82-4.48-.76-.29-1.51-.59-2.27-.89-.96.75-2.21 1.22-3.76 1.22-1.67 0-2.99-.54-3.97-1.4-.28-.09-.55-.19-.81-.33-.47-.15-.9-.38-1.3-.67-.14.01-.28.03-.43.03h-1.19c-.5.02-.98-.05-1.44-.17-.47.11-.98.17-1.52.17-2.16 0-3.74-.9-4.74-2.22-1 1.32-2.58 2.22-4.74 2.22-.2 0-.39-.02-.58-.03-1.29.18-2.61-.04-3.77-.83a26.116 26.116 0 0 1-6.99-6.99c-.72-1.04-.94-2.61-.79-3.95-.38-.17-.72-.39-1.04-.63-.63-.22-1.19-.57-1.68-1.03-.68.27-1.46.42-2.33.42-1.94 0-3.41-.73-4.42-1.83-2.12-.25-3.58-1.45-4.4-3-.6-.1-1.15-.27-1.64-.51h-.21c-3.68-.06-6.98-1.04-10.27-2.63-.67-.33-1.25-.82-1.71-1.41-3.23-1.36-4.25-5.34-3.06-8.36-22.58-2.79-109.85-7.51-189.77-31.5l-271.91.42v154.7c3.31.5 6.61 1.03 9.9 1.56 2.92.23 5.85.36 8.78.38a95.563 95.563 0 0 1 26.81.31c19.44-.03 38.88-.28 58.31-.65 75.29-1.44 150.54-2.91 225.84-3.54 187.76-1.57 375.51-2.09 563.26-1.24v-62.36c-30.08-.2-60.15.6-90.24.36Z"
      style={{
        fill: "url(#fV)",
      }}
    />
    <Path
      d="M2187.31 294c-11.56-.84-17.44-6.24-24.2-1.38-3.99-6.47-6.66-6.96-12.49-1.5-.29-1.32-.08-2.85-.9-3.7-2.27-2.36-4.61-5.33-7.7-6.36-6.31-2.09-3.54 7.76-9.48 7.52-2.22-.09-4.39-1.39-7.91-2.17-2.51-.55-5.29-.84-7.89-1.28l-.91 1.43c-6.13-16.51-5.33-19.7-11.47-21.29-1.4-.36-3.37.98-5.07 1.56-2.09-.25-4.8-1.15-6.73-.57-4.08 1.22.43 16.44-3.65 18.15-7.17 2.99-9.9 8.01-7.76 15.41-16.45-8.07-13.04-6.25-14.16-7.94-4.21-6.4-8.59-.01-10.35-.57-6.48-2.04-7.39.88-6.62 4.68-5.67 4.09-4.38-.68-5.9 16.44-6.65-1.15-16.32-2.13-20.08-5.39-3.37-2.91-6.34-3.09-9.66-1.04-3.74 2.31-5.01 6.88-10.75 7.58-1.32.16-1.79 3.24-2.84 4.87-1.5 2.33-2.49 5.5-4.9 6.66-2.3 1.12-4.63 2.08-6.44 3.43-8.86 7.07 1.91 5.94-13 7.75-1.75.2-7.93.93-9.5 1.75-.53.25-.54 1.53.12 2.16 1.5 1.44 5.94 5.27 5.91 5.25-.04-.02-1.18-1.78-.02-.04-.55-1.24 8.4-2.74 11.34-3.27 49.15-8.91 114.63-28.49 199.8-29.25l3.2-18.9h.01Z"
      style={{
        fill: "url(#fW)",
      }}
    />
    <Path
      d="M2064.79 208.2c1.76-.03 3.69-.08 5.76-.15.22.35.48.68.78.97.08 1.24.47 2.45 1.18 3.45v.15c-.69.64-1.29 1.4-1.75 2.26-.82 1.54-1.01 3.15-.63 4.83.06.26.14.5.24.74-36.13-.6-74.73.26-99.41-1.04-32.18-1.7 79.33-10.95 93.83-11.21Z"
      style={{
        fill: "url(#fX)",
      }}
    />
    <Path
      d="M1961.76 221.49c3.19-2.9 2.17-1.03 31.51-3.95 8.08-.86 10.35-.47 11.23-1.62 2.18-2.86 6.53-1.81 20.23-.86 13.74.92 17.2-2.21 24.25 1.31 3.55-3.85 6.22-2.98 11.24.11.13-.74-.2-1.64.46-2.06 1.82-1.18 3.63-2.72 6.25-3.09 2.79-.39 3.82.94 4.73 2.2-.34.42-.65.86-.91 1.35-.82 1.54-1.01 3.15-.63 4.83.24 1.05.84 1.97 1.6 2.72.07.55.23 1.08.49 1.59.25.78.68 1.45 1.3 2.02l.08.08a7.06 7.06 0 0 0-.08 5.04c-13.71-1.8-27.95-3.48-42.84-4.92-58.19-5.55-63.61-1.25-68.2-2.36-.8-.19-1.32-1.81-.7-2.37v-.02Z"
      style={{
        fill: "url(#fY)",
      }}
    />
    <Path
      d="M1628 176.56c.6-.19 1.21-.39 1.83-.58 38.15.47 77.15-.67 115.01.25 68.41 1.67 112.43 18.21 175.2 26.76 59.76 8.13 73.95 19.39 135.87 22.28 2.53.12 8.85.77 17.37 1.79-.68 2.86.44 6.21 3.35 7.43.46.59 1.04 1.08 1.72 1.41 3.29 1.59 6.59 2.57 10.27 2.63h.22c.49.23 1.03.41 1.62.51.82 1.55 2.28 2.75 4.41 3 1.01 1.1 2.48 1.83 4.42 1.83.87 0 1.65-.15 2.33-.42.49.46 1.05.81 1.69 1.03.32.24.66.46 1.04.63-.15 1.34.07 2.91.79 3.95 1.19 1.75 2.54 3.26 4.05 4.64-31.03 1.59-64.26 3.27-86.83 6.09-74 9.24-148.29 20.24-223.99 25.77-61.78 4.51-124.44 3.24-186.49 6.14V182.58c5.26-2.31 10.68-4.28 16.12-6.02Z"
      style={{
        fill: "url(#fZ)",
      }}
    />
    <Path
      d="M1628 176.56c3.85-1.23 8.02-2.56 12.27-3.6 21.33-1.31 43.32-3.69 63.35-2.97 97.2 3.48 169.05 26.43 352.28 55.28-63.65-1.71-164.44-21.35-224.85-24.66-158.43-8.69-180.82 5.94-226.93.14l7.76-18.17c5.26-2.31 10.68-4.28 16.12-6.02Z"
      style={{
        fill: "url(#ga)",
      }}
    />
    <Path
      d="M1623.76 177.97c24.28-.99 50.82-1.5 79.77-1.36 4.57.02 5.75 3.67 1.49 4.63-20.58 4.67-59.4 4.35-93.14 13.3v-11.96c3.89-1.71 7.87-3.23 11.88-4.61Z"
      style={{
        fill: "url(#gb)",
      }}
    />
    <Path
      d="M1669.51 177.97c-24.28-.99-50.82-1.5-79.77-1.36-4.57.02-5.75 3.67-1.49 4.63 20.59 4.67-10.49 4.23 23.25 13.19l69.9-11.84c-3.89-1.71-7.87-3.23-11.88-4.61h-.01Z"
      style={{
        fill: "url(#gc)",
      }}
    />
    <Path
      d="M1706.47 232.82c-4.35-6.32-10.52-8.45-17.86-7.61-15.93 1.82-10.45 16.06-17.58 22.93-2.12 2.04-1.34 12.07-2.75 12.58-1.15.42-2.96.19-4.01-.53-6.54-4.49-7.52-4.21-12.07 2.11-2.91 4.05-5.86 7.89-6.46 13.14-.11.94-1.23 2.22-2.13 2.5-4.61 1.44-3.87 4.79-3.19 8.35.76 4 3.29 4.41 6.48 3.81 26.87-5.04 17.86-2.83 43.75-2.58 11.96.12 23.93-.42 35.89-.58 17.46-.23 19.01 1.37 29.19-2.84.7-.29 1.16-3.89.49-4.48-5.77-5.16-10.48-6.43-11.62-9.89-2.85-8.64-2.82-8.41-11.48-8.78-2.25-.1-4.34-2.88-6.67-3.25-3.71-.59-3.09-3.17-3.8-5.65-6.94-24.44-9.73-22.4-16.18-19.23Z"
      style={{
        fill: "url(#gd)",
      }}
    />
    <Path
      d="M1678.97 268.23c1.21.38 3.01.06 3.99-.69 1.19-.91-2.53-10.25-1.11-12.83 2.33-4.25 1.53-8.24 1.23-12.47-.69-9.71 5.48-14.89 14.31-16.37 3.54 1.11 6.6 3.35 9.08 6.94 6.11-3 7.61-2.77 10.55 3.42 8.11 17.04 4.09 20.61 9.43 21.46 2.33.37 4.42 3.15 6.67 3.25 8.65.37 8.63.14 11.48 8.78.71 2.16 2.65 3.27 4.62 4.64-9.09 2.76-27.86 9.6-39.79 12.94-30.22.72-32.15-.93-43.8-.31-.51-4.66 1.01-8.84 2.45-13.17 2.46-7.39 3.32-7.95 10.89-5.61v.02Z"
      style={{
        fill: "url(#ge)",
      }}
    />
    <Path
      d="M1689.78 162.34c-7.72-4.01-8.83 4.25-11.26 12.32-1.91 0-3.3-.15-4.64.03-2.35.33-3.75 2.37-3.13 4.3.63 1.95 3.56 3.52 5.33 2.91 8.15-2.82 10.64-4.06 13.36-1.31 1.88 1.9 6.17 1.68 9.24.26.95.75 1.74 1.73 2.76 2.12 7.46 2.78 7.59-6.95 3.79-9.66-2.53-1.81-1.56-8.52-2.93-14.52-1.31-5.77-6.89-5.19-10.22-1.32-1.09 1.27-1.52 3.12-2.3 4.87Z"
      style={{
        fill: "url(#gf)",
      }}
    />
    <Path
      d="M1779.08 272.55c1.91 2.25 3.62 4.23 7.62 3.58 4.49-.74 7.88-2.79 8.83-5.57 1.92-5.68 2.41-11.56-.17-17.21-1.66-3.64-9.25-3.56-8.72-6.79.97-5.88-2.5-11.25-4.75-16.73-1.48-3.58-3.55-3.98-9.52-2.68-3.82-5.37-5.54-9.37-11.28-8.82-6.6.63-4.69 8.66-8.04 9.26-8.85 1.59-7.04 7.98-7.06 18.16 0 .57.25 1.45-.2 1.68-3.45 1.76-2.4 4.06-2.16 6.35.93 8.98-.38 11.29 3.05 16.81.42.68 2.54 1.56 3.31 1.36 2.84-.73 3.64.75 5.3 1.53 8.41 3.97 16.29 7.68 23.81-.93h-.02Z"
      style={{
        fill: "url(#gg)",
      }}
    />
    <Path
      d="M1871.76 281.43c1.33 1.36 2.51 2.56 5.29 2.16 3.12-.44 5.47-1.69 6.13-3.37 1.34-3.43 1.68-6.98-.12-10.39-1.2-2.28-6.44-2.07-6.05-4.1.67-3.55-1.73-6.8-3.3-10.1-1.03-2.16-2.47-2.4-6.61-1.62-2.68-3.28-3.86-5.66-7.83-5.33-4.59.38-3.25 5.23-5.58 5.59-6.07.95-4.89 4.66-4.9 10.97 0 .35.18.87-.14 1.01-2.4 1.06-1.67 2.45-1.5 3.83.66 5.53-.26 6.83 2.12 10.15.29.41 1.76.94 2.3.82 3.35-.75 12.55 8 20.21.37h-.02Z"
      style={{
        fill: "url(#gh)",
      }}
    />
    <Path
      d="M2219.38 283.89a20.784 20.784 0 0 1-5.05-8.22c-.52-.54-.83-1.17-.73-2.05.02-.19.03-.38.05-.57-.15-.77-.25-1.54-.31-2.32-1.7-3.18-2.74-6.64-2.58-10.64.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.73-12.94-6.47-.64 2.48-1.8 4.71-3.68 6.78-1.56 1.71-3.38 2.94-4.49 4.68-.34 5.63.26 12.55-.17 12.87h-.01c-2.4 1.8-1.67 4.15-1.5 6.49.66 9.36-.26 11.55 2.12 17.18.3.69 1.77 1.59 2.3 1.39 1.97-.75 2.53.76 3.68 1.56 5.85 4.05 11.32 7.84 16.53-.95 1.33 2.3 2.51 4.33 5.29 3.66 3.12-.75 5.48-2.85 6.13-5.69.95-4.13 1.38-8.36.91-12.53-.46-.55-.9-1.11-1.3-1.71Z"
      style={{
        fill: "url(#gi)",
      }}
    />
    <Path
      d="M2002.2 339.42c2.61.66 5.18 1.59 7.7 2.82a51.94 51.94 0 0 0-7.7-2.82Z"
      style={{
        fill: "url(#gj)",
      }}
    />
    <Path
      d="M1951.24 339.7c-48.8 5.54-97.99-.41-146.46-6.61-40.96-5.22-229.93-22.88-311.01-31.42-12.36-1.3-19.99 13.12-11.99 22.62l12.83 15.22c2.23 2.65 5.37 4.36 8.81 4.8 24.26 3.09 112.92 4.28 126.49 6.88 1.3.25 2.63.27 3.95.14 7.52-.71 15.03-.54 22.55.53.65.09 1.3.14 1.95.14 19.12-.04 38.23-.28 57.34-.65 74.79-1.43 149.53-2.89 224.33-3.52.89 0 1.78-.1 2.65-.28 2.15-.44 4.3-.92 6.44-1.43 16.8-4.04 35.74-11.13 53.1-6.7-16.37-4.6-34.41-1.6-50.96.28Z"
      style={{
        fill: "url(#gk)",
      }}
    />
    <Path
      d="M2235 292.38c-3.8-.15-7.23-1.27-10.12-3.12-1.6.45-3.27 1.39-4.15 2.17-.74.65-.06 2.35.92 2.5 2.22.34 8.92-.73 15.14-1.5-.6-.01-1.19-.02-1.79-.05Z"
      style={{
        fill: "url(#gl)",
      }}
    />
    <Path
      d="M1646.77 285.59c.99-.07 2.22-.68 2.52-1.33 1.76-3.77 5.83-3.05 10.27-2.83 7.9.32 16.55 1.17 23.15-.77 5.31-1.51 9.24-3 15.12-.24 1.64-6.91 4.68-5.78 11.42-2.19-.32-1.18-1.2-2.51-.81-3.34 1.06-2.28 1.83-5.11 4.07-6.3 4.58-2.47 6.9 6.4 12.23 5.45 1.41-.27 2.64-1.2 4.01-2.04 2.23-1.36 5-2.08 7.97-3.3l1.63 1.07c-4.43-14.94-7.1-17.58-3.19-20.11.85-.55 3.12.22 4.82.41 1.49-.61 2.97-1.85 4.78-1.75 2.65.16 7.52 8.01 10.87 11.92 3.93 4.58 6.69 1.13 14.31 6.07 2.85 1.84 5.09 4.35 6.75 7.5 2.28-2.97 4.38-5.74 6.31-8.59.26-.38-3.04-6.45 5.48-4.31.74.19 1.89.38 2.2.11 3.71-3.23 6.59-1.08 8.86 2.21 8 2 2.76-1.66 17.48 11.86 3.99-2.31 11.65-5.92 12.02-9.64.9-8.84 14.57 1.02 21.52-.5 1.38-.3 11.24 6.47 15.95 6.77 5.6.37 10.69.12 15.79 4.2 2.51 2.01 27.56-7.46 30.35-4.21 1.48 1.73 2.54 3.89 2.46 5.59-.08 1.89-10.02 2.13-10.37 2.18-72.46 11.12-98.98 11.67-131.96 14.99-104.69 9.83-135.8.4-160.9-2.3v-6.43c12.16 1.89 22.72.76 34.89-.15Z"
      style={{
        fill: "url(#gm)",
      }}
    />
    <Path
      d="M295.59 195.89c-79.92 23.99-167.19 28.71-189.77 31.5 1.19 3.02.17 7-3.06 8.36-.46.59-1.04 1.08-1.71 1.41-3.29 1.59-6.59 2.57-10.27 2.63h-.21c-.49.24-1.04.41-1.64.51-.23.04-1.09 1.53-1.42 1.77-.53.38-1.22.63-1.8.95-1.61.9-3.2 1.81-5.08 1.95-1.75.13-3.87-.26-4.97 1.11-.63.78-.65 1.86-.9 2.83-.42 1.65-1.53 3.02-2.67 4.28-1.82 2-3.9 3.91-6.47 4.72-2.57.81-5.75.25-7.34-1.93-.05.5-.63.72-1.11.84l-11.19 2.7c-1.6.39-3.25.78-4.88.56-1.07-.15-2.19-.55-3.2-.16-1.11.42-1.68 1.63-2.43 2.55-1.32 1.63-3.51 2.52-5.6 2.26-.97-.12-2.03-.45-2.88.05-.52.31-.83.87-1.18 1.35-1.62 2.2-4.81 3.09-7.33 2.04-1.06-.44-2.09-1.19-3.22-1.04-1.93.26-2.57 2.79-4.17 3.9-1.89 1.31-4.29.29-6.27-.2-1.59-.39-3.39 0-5.04-.02.49 24.73-1.42 49.46-.3 74.19 124.47-6.03 248.89-13.18 373.43-17.86 64.85-2.44 129.71-4.37 194.59-5.8V196.32l-271.91-.42Z"
      style={{
        fill: "url(#gn)",
      }}
    />
    <Path
      d="M551.38 174.82c-.6-.19-1.21-.39-1.83-.58-38.15.47-77.15-.67-115.01.25-68.41 1.67-112.43 18.21-175.2 26.76-59.76 8.13-73.95 19.39-135.87 22.28-2.53.12-8.85.77-17.37 1.79.68 2.86-9.39 3.02-12.3 4.25-8.4 6.71-20 7.41-23.56 17.11-1.19 1.75 1.46 3.91-.05 5.28 31.03 1.59 64.26 3.27 86.83 6.09 74 9.24 148.29 20.24 223.99 25.77 61.78 4.52 124.43 3.24 186.48 6.14V180.84c-5.26-2.31-10.68-4.28-16.12-6.02h.01Z"
      style={{
        fill: "url(#go)",
      }}
    />
    <Path
      d="M551.38 174.82c-3.85-1.23-8.02-2.56-12.27-3.6-21.33-1.31-43.32-3.69-63.35-2.97-97.2 3.48-169.05 26.43-352.28 55.28 63.65-1.71 164.44-21.35 224.85-24.66 158.43-8.68 173.06 10.02 219.17 4.22v-22.24c-5.26-2.31-10.68-4.28-16.12-6.02Z"
      style={{
        fill: "url(#gp)",
      }}
    />
    <Path
      d="M0 344.34v2.22c129.55-.04 542.32 1.81 567.5 1.26v-33.4c-12.81 1.96-434.97 22.37-567.5 29.92Z"
      style={{
        fill: "url(#gq)",
      }}
    />
    <Path
      d="M532.61 283.85c-.99-.07-2.22-.68-2.52-1.33-1.76-3.77-5.83-3.05-10.27-2.83-7.9.32-16.55 1.17-23.15-.77-5.31-1.51-9.24-3-15.12-.24-1.64-6.91-4.68-5.78-11.42-2.19.32-1.18 1.2-2.51.81-3.34-1.06-2.28-1.83-5.11-4.07-6.3-4.58-2.47-6.9 6.4-12.23 5.45-1.41-.27-2.64-1.2-4.01-2.04-2.23-1.36-5-2.08-7.97-3.3l-1.63 1.07c4.43-14.94 7.1-17.58 3.19-20.11-.85-.55-3.12.22-4.82.41-1.49-.61-2.97-1.85-4.78-1.75-2.65.16-7.52 8.01-10.87 11.92-3.93 4.58-6.69 1.13-14.31 6.07-2.85 1.84-5.09 4.35-6.75 7.5-2.28-2.97-4.38-5.74-6.31-8.59-.26-.38 3.04-6.45-5.48-4.31-.74.19-1.89.38-2.2.11-3.71-3.23-6.59-1.08-8.86 2.21-8 2-2.76-1.66-17.48 11.86-3.99-2.31-11.65-5.92-12.02-9.64-.9-8.84-14.57 1.02-21.52-.5-1.38-.3-11.24 6.47-15.95 6.77-5.6.37-10.69.12-15.79 4.2-2.51 2.01-27.56-7.46-30.35-4.21-1.48 1.73-2.54 3.89-2.46 5.59.08 1.89 10.02 2.13 10.37 2.18 72.46 11.12 98.98 11.67 131.96 14.99 104.69 9.8 135.8.37 160.9-1.56v-6.43c-12.16 1.12-22.72-.01-34.89-.92v.03Z"
      style={{
        fill: "url(#gr)",
      }}
    />
    <Path
      d="M715.42 314.77c-56.36-13-107.08-22.89-152.39-30.36-8.57 2.41-17.79 1.39-26.54.81-10.8-.72-21.62-1.13-32.43-1.53-38.92-1.46-77.94-1.86-116.74-5.49-3.17-.3-6.34-.55-9.51-.82-14.24 1.7-28.61 2.89-42.94 3.34-14.24.45-28.47-.37-42.71-.47-30.12-.2-60.24.6-90.37.36-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-3.67-.14-7-1.2-9.82-2.94-4.16.7-8.38 1.43-12.63 2.17-.24 18.24-1.1 36.47-.42 54.71 171.92-.45 343.83.11 515.76 1.92 92.7-3.84 185.35-8.84 278.01-13.52-25.11-4.54-51.04-9.83-77.72-15.98Z"
      style={{
        fill: "url(#gs)",
      }}
    />
    <Path
      d="M1018.92 240.93c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#gt)",
      }}
    />
    <Path
      d="M1033.31 286.77c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05h.01Z"
      style={{
        fill: "url(#gu)",
      }}
    />
    <Path
      d="M956.1 236.79c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#gv)",
      }}
    />
    <Path
      d="M970.49 282.62c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05Z"
      style={{
        fill: "url(#gw)",
      }}
    />
    <Path
      d="M1301.3 282.73c-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-6.68-.26-12.27-3.49-15.62-8.49-3.44-3.42-5.65-8.16-6.04-13.12-1.72-3.19-2.76-6.67-2.6-10.68.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.74-12.94-6.48-.64 2.48-1.8 4.71-3.68 6.79-2.36 2.6-5.33 4.08-5.52 7.91-.16 3.2-2.94 5.35-6.08 4.63-.83-.19-1.67-.39-2.5-.59-1 .99-2.41 1.63-4.23 1.63-3.11 0-5.02-1.86-5.73-4.15-.92-.25-1.85-.51-2.77-.77-1.01 1.18-2.52 1.96-4.54 1.96-3.22 0-5.14-2.01-5.79-4.42-.58.17-1.21.27-1.91.27-.42 0-.81-.05-1.18-.11-.37.07-.77.11-1.19.11-3.25 0-5.19-2.05-5.82-4.48-.76-.29-1.51-.59-2.27-.89-.96.75-2.21 1.22-3.76 1.22-1.67 0-2.99-.54-3.97-1.4-.28-.09-.55-.19-.81-.33-.47-.15-.9-.38-1.3-.67-.14.01-.28.03-.43.03h-1.19c-.5.02-.98-.05-1.44-.17-.47.11-.98.17-1.52.17-2.16 0-3.74-.9-4.74-2.22-1 1.32-2.58 2.22-4.74 2.22-.2 0-.39-.02-.58-.03-1.29.18-2.61-.04-3.77-.83a26.116 26.116 0 0 1-6.99-6.99c-.72-1.04-.94-2.61-.79-3.95-.38-.17-.72-.39-1.04-.63-.63-.22-1.19-.57-1.68-1.03-.68.27-1.46.42-2.33.42-1.94 0-3.41-.73-4.42-1.83-2.12-.25-3.58-1.45-4.4-3-.6-.1-1.15-.27-1.64-.51h-.21c-3.68-.06-6.98-1.04-10.27-2.63-.67-.33-1.25-.82-1.71-1.41-3.23-1.36-4.25-5.34-3.06-8.36-22.58-2.79-109.85-7.51-189.77-31.5l-271.91.42v154.7c3.31.5 6.61 1.03 9.9 1.56 2.92.23 5.85.36 8.78.38a95.563 95.563 0 0 1 26.81.31c19.44-.03 38.88-.28 58.31-.65 75.29-1.44 150.54-2.91 225.84-3.54 187.76-1.57 375.51-2.09 563.26-1.24v-62.36c-30.08-.2-60.15.6-90.24.36Z"
      style={{
        fill: "url(#gx)",
      }}
    />
    <Path
      d="M1074.07 292.15c-11.56-.84-17.44-6.24-24.2-1.38-3.99-6.47-6.66-6.96-12.49-1.5-.29-1.32-.08-2.85-.9-3.7-2.27-2.36-4.61-5.33-7.7-6.36-6.31-2.09-3.54 7.76-9.48 7.52-2.22-.09-4.39-1.39-7.91-2.17-2.51-.55-5.29-.84-7.89-1.28l-.91 1.43c-6.13-16.51-5.33-19.7-11.47-21.29-1.4-.36-3.37.98-5.07 1.56-2.09-.25-4.8-1.15-6.73-.57-4.08 1.22.43 16.44-3.65 18.15-7.17 2.99-9.9 8.01-7.76 15.41-16.45-8.07-13.04-6.25-14.16-7.94-4.21-6.4-8.59-.01-10.35-.57-6.48-2.04-7.39.88-6.62 4.68-5.67 4.09-4.38-.68-5.9 16.44-6.65-1.15-16.32-2.13-20.08-5.39-3.37-2.91-6.34-3.09-9.66-1.04-3.74 2.31-5.01 6.88-10.75 7.58-1.32.16-1.79 3.24-2.84 4.87-1.5 2.33-2.49 5.5-4.9 6.66-2.3 1.12-4.63 2.08-6.44 3.43-8.86 7.07 1.91 5.94-13 7.75-1.75.2-7.93.93-9.5 1.75-.53.25-.54 1.53.12 2.16 1.5 1.44 5.94 5.27 5.91 5.25-.04-.02-1.18-1.78-.02-.04-.55-1.24 8.4-2.74 11.34-3.27 49.15-8.91 114.63-28.49 199.8-29.25l3.2-18.9h.01Z"
      style={{
        fill: "url(#gy)",
      }}
    />
    <Path
      d="M951.55 206.35c1.76-.03 3.69-.08 5.76-.15.22.35.48.68.78.97.08 1.24.47 2.45 1.18 3.45v.15c-.69.64-1.29 1.4-1.75 2.26-.82 1.54-1.01 3.15-.63 4.83.06.26.14.5.24.74-36.13-.6-74.73.26-99.41-1.04-32.18-1.7 79.33-10.95 93.83-11.21Z"
      style={{
        fill: "url(#gz)",
      }}
    />
    <Path
      d="M848.52 219.64c3.19-2.9 2.17-1.03 31.51-3.95 8.08-.86 10.35-.47 11.23-1.62 2.18-2.86 6.53-1.81 20.23-.86 13.74.92 17.2-2.21 24.25 1.31 3.55-3.85 6.22-2.98 11.24.11.13-.74-.2-1.64.46-2.06 1.82-1.18 3.63-2.72 6.25-3.09 2.79-.39 3.82.94 4.73 2.2-.34.42-.65.86-.91 1.35-.82 1.54-1.01 3.15-.63 4.83.24 1.05.84 1.97 1.6 2.72.07.55.23 1.08.49 1.59.25.78.68 1.45 1.3 2.02l.08.08a7.06 7.06 0 0 0-.08 5.04c-13.71-1.8-27.95-3.48-42.84-4.92-58.19-5.55-63.61-1.25-68.2-2.36-.8-.19-1.32-1.81-.7-2.37v-.02Z"
      style={{
        fill: "url(#gA)",
      }}
    />
    <Path
      d="M514.76 174.71c.6-.19 1.21-.39 1.83-.58 38.15.47 77.15-.67 115.01.25 68.41 1.67 112.43 18.21 175.2 26.76 59.76 8.13 73.95 19.39 135.87 22.28 2.53.12 8.85.77 17.37 1.79-.68 2.86.44 6.21 3.35 7.43.46.59 1.04 1.08 1.72 1.41 3.29 1.59 6.59 2.57 10.27 2.63h.22c.49.23 1.03.41 1.62.51.82 1.55 2.28 2.75 4.41 3 1.01 1.1 2.48 1.83 4.42 1.83.87 0 1.65-.15 2.33-.42.49.46 1.05.81 1.69 1.03.32.24.66.46 1.04.63-.15 1.34.07 2.91.79 3.95 1.19 1.75 2.54 3.26 4.05 4.64-31.03 1.59-64.26 3.27-86.83 6.09-74 9.24-148.29 20.24-223.99 25.77-61.78 4.51-124.44 3.24-186.49 6.14V180.73c5.26-2.31 10.68-4.28 16.12-6.02Z"
      style={{
        fill: "url(#gB)",
      }}
    />
    <Path
      d="M514.76 171.69c3.85-1.23 8.02-2.56 12.27-3.6 21.33-1.31 43.32-3.69 63.35-2.97 97.2 3.48 169.05 26.43 352.28 55.28-63.65-1.71-164.44-21.35-224.85-24.66-158.43-8.69-173.06 10.01-219.17 4.21v-22.24c5.26-2.31 10.68-4.28 16.12-6.02Z"
      style={{
        fill: "url(#gC)",
      }}
    />
    <Path
      d="M510.52 176.12c24.28-.99 50.82-1.5 79.77-1.36 4.57.02 5.75 3.67 1.49 4.63-20.58 4.67-59.4 4.35-93.14 13.3v-11.96c3.89-1.71 7.87-3.23 11.88-4.61Z"
      style={{
        fill: "url(#gD)",
      }}
    />
    <Path
      d="M593.23 230.97c-4.35-6.32-10.52-8.45-17.86-7.61-15.93 1.82-10.45 16.06-17.58 22.93-2.12 2.04-1.34 12.07-2.75 12.58-1.15.42-2.96.19-4.01-.53-6.54-4.49-7.52-4.21-12.07 2.11-2.91 4.05-5.86 7.89-6.46 13.14-.11.94-1.23 2.22-2.13 2.5-4.61 1.44-3.87 4.79-3.19 8.35.76 4 3.29 4.41 6.48 3.81 26.87-5.04 17.86-2.83 43.75-2.58 11.96.12 23.93-.42 35.89-.58 17.46-.23 19.01 1.37 29.19-2.84.7-.29 1.16-3.89.49-4.48-5.77-5.16-10.48-6.43-11.62-9.89-2.85-8.64-2.82-8.41-11.48-8.78-2.25-.1-4.34-2.88-6.67-3.25-3.71-.59-3.09-3.17-3.8-5.65-6.94-24.44-9.73-22.4-16.18-19.23Z"
      style={{
        fill: "url(#gE)",
      }}
    />
    <Path
      d="M565.73 266.38c1.21.38 3.01.06 3.99-.69 1.19-.91-2.53-10.25-1.11-12.83 2.33-4.25 1.53-8.24 1.23-12.47-.69-9.71 5.48-14.89 14.31-16.37 3.54 1.11 6.6 3.35 9.08 6.94 6.11-3 7.61-2.77 10.55 3.42 8.11 17.04 4.09 20.61 9.43 21.46 2.33.37 4.42 3.15 6.67 3.25 8.65.37 8.63.14 11.48 8.78.71 2.16 2.65 3.27 4.62 4.64-9.09 2.76-27.86 9.6-39.79 12.94-30.22.72-32.15-.93-43.8-.31-.51-4.66 1.01-8.84 2.45-13.17 2.46-7.39 3.32-7.95 10.89-5.61v.02Z"
      style={{
        fill: "url(#gF)",
      }}
    />
    <Path
      d="M576.54 160.49c-7.72-4.01-8.83 4.25-11.26 12.32-1.91 0-3.3-.15-4.64.03-2.35.33-3.75 2.37-3.13 4.3.63 1.95 3.56 3.52 5.33 2.91 8.15-2.82 10.64-4.06 13.36-1.31 1.88 1.9 6.17 1.68 9.24.26.95.75 1.74 1.73 2.76 2.12 7.46 2.78 7.59-6.95 3.79-9.66-2.53-1.81-1.56-8.52-2.93-14.52-1.31-5.77-6.89-5.19-10.22-1.32-1.09 1.27-1.52 3.12-2.3 4.87Z"
      style={{
        fill: "url(#gG)",
      }}
    />
    <Path
      d="M665.84 270.7c1.91 2.25 3.62 4.23 7.62 3.58 4.49-.74 7.88-2.79 8.83-5.57 1.92-5.68 2.41-11.56-.17-17.21-1.66-3.64-9.25-3.56-8.72-6.79.97-5.88-2.5-11.25-4.75-16.73-1.48-3.58-3.55-3.98-9.52-2.68-3.82-5.37-5.54-9.37-11.28-8.82-6.6.63-4.69 8.66-8.04 9.26-8.85 1.59-7.04 7.98-7.06 18.16 0 .57.25 1.45-.2 1.68-3.45 1.76-2.4 4.06-2.16 6.35.93 8.98-.38 11.29 3.05 16.81.42.68 2.54 1.56 3.31 1.36 2.84-.73 3.64.75 5.3 1.53 8.41 3.97 16.29 7.68 23.81-.93h-.02Z"
      style={{
        fill: "url(#gH)",
      }}
    />
    <Path
      d="M758.52 279.58c1.33 1.36 2.51 2.56 5.29 2.16 3.12-.44 5.47-1.69 6.13-3.37 1.34-3.43 1.68-6.98-.12-10.39-1.2-2.28-6.44-2.07-6.05-4.1.67-3.55-1.73-6.8-3.3-10.1-1.03-2.16-2.47-2.4-6.61-1.62-2.68-3.28-3.86-5.66-7.83-5.33-4.59.38-3.25 5.23-5.58 5.59-6.07.95-4.89 4.66-4.9 10.97 0 .35.18.87-.14 1.01-2.4 1.06-1.67 2.45-1.5 3.83.66 5.53-.26 6.83 2.12 10.15.29.41 1.76.94 2.3.82 3.35-.75 12.55 8 20.21.37h-.02Z"
      style={{
        fill: "url(#gI)",
      }}
    />
    <Path
      d="M1106.14 282.04a20.784 20.784 0 0 1-5.05-8.22c-.52-.54-.83-1.17-.73-2.05.02-.19.03-.38.05-.57-.15-.77-.25-1.54-.31-2.32-1.7-3.18-2.74-6.64-2.58-10.64.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.73-12.94-6.47-.64 2.48-1.8 4.71-3.68 6.78-1.56 1.71-3.38 2.94-4.49 4.68-.34 5.63.26 12.55-.17 12.87h-.01c-2.4 1.8-1.67 4.15-1.5 6.49.66 9.36-.26 11.55 2.12 17.18.3.69 1.77 1.59 2.3 1.39 1.97-.75 2.53.76 3.68 1.56 5.85 4.05 11.32 7.84 16.53-.95 1.33 2.3 2.51 4.33 5.29 3.66 3.12-.75 5.48-2.85 6.13-5.69.95-4.13 1.38-8.36.91-12.53-.46-.55-.9-1.11-1.3-1.71Z"
      style={{
        fill: "url(#gJ)",
      }}
    />
    <Path
      d="M888.96 337.57c2.61.66 5.18 1.59 7.7 2.82a51.94 51.94 0 0 0-7.7-2.82Z"
      style={{
        fill: "url(#gK)",
      }}
    />
    <Path
      d="M1121.76 290.53c-3.8-.15-7.23-1.27-10.12-3.12-1.6.45-3.27 1.39-4.15 2.17-.74.65-.06 2.35.92 2.5 2.22.34 8.92-.73 15.14-1.5-.6-.01-1.19-.02-1.79-.05Z"
      style={{
        fill: "url(#gL)",
      }}
    />
    <Path
      d="M533.53 283.74c.99-.07 2.22-.68 2.52-1.33 1.76-3.77 5.83-3.05 10.27-2.83 7.9.32 16.55 1.17 23.15-.77 5.31-1.51 9.24-3 15.12-.24 1.64-6.91 4.68-5.78 11.42-2.19-.32-1.18-1.2-2.51-.81-3.34 1.06-2.28 1.83-5.11 4.07-6.3 4.58-2.47 6.9 6.4 12.23 5.45 1.41-.27 2.64-1.2 4.01-2.04 2.23-1.36 5-2.08 7.97-3.3l1.63 1.07c-4.43-14.94-7.1-17.58-3.19-20.11.85-.55 3.12.22 4.82.41 1.49-.61 2.97-1.85 4.78-1.75 2.65.16 7.52 8.01 10.87 11.92 3.93 4.58 6.69 1.13 14.31 6.07 2.85 1.84 5.09 4.35 6.75 7.5 2.28-2.97 4.38-5.74 6.31-8.59.26-.38-3.04-6.45 5.48-4.31.74.19 1.89.38 2.2.11 3.71-3.23 6.59-1.08 8.86 2.21 8 2 2.76-1.66 17.48 11.86 3.99-2.31 11.65-5.92 12.02-9.64.9-8.84 14.57 1.02 21.52-.5 1.38-.3 11.24 6.47 15.95 6.77 5.6.37 10.69.12 15.79 4.2 2.51 2.01 27.56-7.46 30.35-4.21 1.48 1.73 2.54 3.89 2.46 5.59-.08 1.89-10.02 2.13-10.37 2.18-72.46 11.12-98.98 11.67-131.96 14.99-104.69 9.83-174.53-4.95-199.63-6.88l38.73-1.08c12.16 1.12 22.72-.01 34.89-.92Z"
      style={{
        fill: "url(#gM)",
      }}
    />
    <Path
      d="M1385.83 282.35c-28.18-.07-56.35.6-84.53.38-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-3.67-.14-7-1.2-9.82-2.94-43.68 7.35-92.27 16.9-146.01 29.3-59.14 13.64-114.58 23.05-165.64 29.35 9.33-.1 18.66-.19 27.99-.27 185.86-1.56 371.71-2.07 557.55-1.26v-62.35Z"
      style={{
        fill: "url(#gN)",
      }}
    />
    <Path
      d="M827.63 346.07c262.14-2.2 524.24-2.34 786.36.42 92.7-3.84 185.35-8.84 278.01-13.52-25.11-4.54-51.04-9.83-77.72-15.98-56.36-13-107.08-22.89-152.39-30.36-8.57 2.41-17.79 1.39-26.54.81-10.8-.72-21.62-1.13-32.43-1.53-38.92-1.46-77.94-1.86-116.74-5.49-3.17-.3-6.34-.55-9.51-.82-14.24 1.7-28.61 2.89-42.94 3.34-14.24.45-28.47-.37-42.71-.47-30.12-.2-60.24.6-90.37.36-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-3.67-.14-7-1.2-9.82-2.94-43.68 7.35-92.27 16.9-146.01 29.3-59.14 13.64-114.58 23.05-165.64 29.35 9.33-.1 18.66-.19 27.99-.27Z"
      style={{
        fill: "url(#gO)",
      }}
    />
    <Path
      d="M1018.27 241.04c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#gP)",
      }}
    />
    <Path
      d="M1032.66 286.88c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05h.01Z"
      style={{
        fill: "url(#gQ)",
      }}
    />
    <Path
      d="M955.45 236.9c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#gR)",
      }}
    />
    <Path
      d="M969.84 282.73c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05Z"
      style={{
        fill: "url(#gS)",
      }}
    />
    <Path
      d="M1300.66 282.84c-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-6.68-.26-12.27-3.49-15.62-8.49-3.44-3.42-5.65-8.16-6.04-13.12-1.72-3.19-2.76-6.67-2.6-10.68.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.74-12.94-6.48-.64 2.48-1.8 4.71-3.68 6.79-2.36 2.6-5.33 4.08-5.52 7.91-.16 3.2-2.94 5.35-6.08 4.63-.83-.19-1.67-.39-2.5-.59-1 .99-2.41 1.63-4.23 1.63-3.11 0-5.02-1.86-5.73-4.15-.92-.25-1.85-.51-2.77-.77-1.01 1.18-2.52 1.96-4.54 1.96-3.22 0-5.14-2.01-5.79-4.42-.58.17-1.21.27-1.91.27-.42 0-.81-.05-1.18-.11-.37.07-.77.11-1.19.11-3.25 0-5.19-2.05-5.82-4.48-.76-.29-1.51-.59-2.27-.89-.96.75-2.21 1.22-3.76 1.22-1.67 0-2.99-.54-3.97-1.4-.28-.09-.55-.19-.81-.33-.47-.15-.9-.38-1.3-.67-.14.01-.28.03-.43.03H1015c-.5.02-.98-.05-1.44-.17-.47.11-.98.17-1.52.17-2.16 0-3.74-.9-4.74-2.22-1 1.32-2.58 2.22-4.74 2.22-.2 0-.39-.02-.58-.03-1.29.18-2.61-.04-3.77-.83a26.116 26.116 0 0 1-6.99-6.99c-.72-1.04-.94-2.61-.79-3.95-.38-.17-.72-.39-1.04-.63-.63-.22-1.19-.57-1.68-1.03-.68.27-1.46.42-2.33.42-1.94 0-3.41-.73-4.42-1.83-2.12-.25-3.58-1.45-4.4-3-.6-.1-1.15-.27-1.64-.51h-.21c-3.68-.06-6.98-1.04-10.27-2.63-.67-.33-1.25-.82-1.71-1.41-3.23-1.36-4.25-5.34-3.06-8.36-22.58-2.79-109.85-7.51-189.77-31.5l-271.91.42v154.7c3.31.5 6.61 1.03 9.9 1.56 2.92.23 5.85.36 8.78.38a95.563 95.563 0 0 1 26.81.31c19.44-.03 38.88-.28 58.31-.65 75.29-1.44 150.54-2.91 225.84-3.54 187.76-1.57 375.51-2.09 563.26-1.24v-62.36c-30.08-.2-60.15.6-90.24.36Z"
      style={{
        fill: "url(#gT)",
      }}
    />
    <Path
      d="M1073.42 292.26c-11.56-.84-17.44-6.24-24.2-1.38-3.99-6.47-6.66-6.96-12.49-1.5-.29-1.32-.08-2.85-.9-3.7-2.27-2.36-4.61-5.33-7.7-6.36-6.31-2.09-3.54 7.76-9.48 7.52-2.22-.09-4.39-1.39-7.91-2.17-2.51-.55-5.29-.84-7.89-1.28l-.91 1.43c-6.13-16.51-5.33-19.7-11.47-21.29-1.4-.36-3.37.98-5.07 1.56-2.09-.25-4.8-1.15-6.73-.57-4.08 1.22.43 16.44-3.65 18.15-7.17 2.99-9.9 8.01-7.76 15.41-16.45-8.07-13.04-6.25-14.16-7.94-4.21-6.4-8.59-.01-10.35-.57-6.48-2.04-7.39.88-6.62 4.68-5.67 4.09-4.38-.68-5.9 16.44-6.65-1.15-16.32-2.13-20.08-5.39-3.37-2.91-6.34-3.09-9.66-1.04-3.74 2.31-5.01 6.88-10.75 7.58-1.32.16-1.79 3.24-2.84 4.87-1.5 2.33-2.49 5.5-4.9 6.66-2.3 1.12-4.63 2.08-6.44 3.43-8.86 7.07 1.91 5.94-13 7.75-1.75.2-7.93.93-9.5 1.75-.53.25-.54 1.53.12 2.16 1.5 1.44 5.94 5.27 5.91 5.25-.04-.02-1.18-1.78-.02-.04-.55-1.24 8.4-2.74 11.34-3.27 49.15-8.91 114.63-28.49 199.8-29.25l3.2-18.9h.01Z"
      style={{
        fill: "url(#gU)",
      }}
    />
    <Path
      d="M950.9 206.46c1.76-.03 3.69-.08 5.76-.15.22.35.48.68.78.97.08 1.24.47 2.45 1.18 3.45v.15c-.69.64-1.29 1.4-1.75 2.26-.82 1.54-1.01 3.15-.63 4.83.06.26.14.5.24.74-36.13-.6-74.73.26-99.41-1.04-32.18-1.7 79.33-10.95 93.83-11.21Z"
      style={{
        fill: "url(#gV)",
      }}
    />
    <Path
      d="M847.87 219.75c3.19-2.9 2.17-1.03 31.51-3.95 8.08-.86 10.35-.47 11.23-1.62 2.18-2.86 6.53-1.81 20.23-.86 13.74.92 17.2-2.21 24.25 1.31 3.55-3.85 6.22-2.98 11.24.11.13-.74-.2-1.64.46-2.06 1.82-1.18 3.63-2.72 6.25-3.09 2.79-.39 3.82.94 4.73 2.2-.34.42-.65.86-.91 1.35-.82 1.54-1.01 3.15-.63 4.83.24 1.05.84 1.97 1.6 2.72.07.55.23 1.08.49 1.59.25.78.68 1.45 1.3 2.02l.08.08a7.06 7.06 0 0 0-.08 5.04c-13.71-1.8-27.95-3.48-42.84-4.92-58.19-5.55-63.61-1.25-68.2-2.36-.8-.19-1.32-1.81-.7-2.37v-.02Z"
      style={{
        fill: "url(#gW)",
      }}
    />
    <Path
      d="M514.11 174.82c.6-.19 1.21-.39 1.83-.58 38.15.47 77.15-.67 115.01.25 68.41 1.67 112.43 18.21 175.2 26.76 59.76 8.13 73.95 19.39 135.87 22.28 2.53.12 8.85.77 17.37 1.79-.68 2.86.44 6.21 3.35 7.43.46.59 1.04 1.08 1.72 1.41 3.29 1.59 6.59 2.57 10.27 2.63h.22c.49.23 1.03.41 1.62.51.82 1.55 2.28 2.75 4.41 3 1.01 1.1 2.48 1.83 4.42 1.83.87 0 1.65-.15 2.33-.42.49.46 1.05.81 1.69 1.03.32.24.66.46 1.04.63-.15 1.34.07 2.91.79 3.95 1.19 1.75 2.54 3.26 4.05 4.64-31.03 1.59-64.26 3.27-86.83 6.09-74 9.24-148.29 20.24-223.99 25.77-61.78 4.51-124.44 3.24-186.49 6.14V180.84c5.26-2.31 10.68-4.28 16.12-6.02Z"
      style={{
        fill: "url(#gX)",
      }}
    />
    <Path
      d="M514.11 174.82c3.85-1.23 8.02-2.56 12.27-3.6 21.33-1.31 43.32-3.69 63.35-2.97 97.2 3.48 169.05 26.43 352.28 55.28-63.65-1.71-164.44-21.35-224.85-24.66-158.43-8.69-180.82 5.94-226.93.14l7.76-18.17c5.26-2.31 10.68-4.28 16.12-6.02Z"
      style={{
        fill: "url(#gY)",
      }}
    />
    <Path
      d="M509.87 176.23c24.28-.99 50.82-1.5 79.77-1.36 4.57.02 5.75 3.67 1.49 4.63-20.58 4.67-59.4 4.35-93.14 13.3v-11.96c3.89-1.71 7.87-3.23 11.88-4.61Z"
      style={{
        fill: "url(#gZ)",
      }}
    />
    <Path
      d="M555.62 176.23c-24.28-.99-50.82-1.5-79.77-1.36-4.57.02-5.75 3.67-1.49 4.63 20.59 4.67-10.49 4.23 23.25 13.19l69.9-11.84c-3.89-1.71-7.87-3.23-11.88-4.61h-.01Z"
      style={{
        fill: "url(#ha)",
      }}
    />
    <Path
      d="M592.58 231.08c-4.35-6.32-10.52-8.45-17.86-7.61-15.93 1.82-10.45 16.06-17.58 22.93-2.12 2.04-1.34 12.07-2.75 12.58-1.15.42-2.96.19-4.01-.53-6.54-4.49-7.52-4.21-12.07 2.11-2.91 4.05-5.86 7.89-6.46 13.14-.11.94-1.23 2.22-2.13 2.5-4.61 1.44-3.87 4.79-3.19 8.35.76 4 3.29 4.41 6.48 3.81 26.87-5.04 17.86-2.83 43.75-2.58 11.96.12 23.93-.42 35.89-.58 17.46-.23 19.01 1.37 29.19-2.84.7-.29 1.16-3.89.49-4.48-5.77-5.16-10.48-6.43-11.62-9.89-2.85-8.64-2.82-8.41-11.48-8.78-2.25-.1-4.34-2.88-6.67-3.25-3.71-.59-3.09-3.17-3.8-5.65-6.94-24.44-9.73-22.4-16.18-19.23Z"
      style={{
        fill: "url(#hb)",
      }}
    />
    <Path
      d="M565.08 266.49c1.21.38 3.01.06 3.99-.69 1.19-.91-2.53-10.25-1.11-12.83 2.33-4.25 1.53-8.24 1.23-12.47-.69-9.71 5.48-14.89 14.31-16.37 3.54 1.11 6.6 3.35 9.08 6.94 6.11-3 7.61-2.77 10.55 3.42 8.11 17.04 4.09 20.61 9.43 21.46 2.33.37 4.42 3.15 6.67 3.25 8.65.37 8.63.14 11.48 8.78.71 2.16 2.65 3.27 4.62 4.64-9.09 2.76-27.86 9.6-39.79 12.94-30.22.72-32.15-.93-43.8-.31-.51-4.66 1.01-8.84 2.45-13.17 2.46-7.39 3.32-7.95 10.89-5.61v.02Z"
      style={{
        fill: "url(#hc)",
      }}
    />
    <Path
      d="M575.89 160.6c-7.72-4.01-8.83 4.25-11.26 12.32-1.91 0-3.3-.15-4.64.03-2.35.33-3.75 2.37-3.13 4.3.63 1.95 3.56 3.52 5.33 2.91 8.15-2.82 10.64-4.06 13.36-1.31 1.88 1.9 6.17 1.68 9.24.26.95.75 1.74 1.73 2.76 2.12 7.46 2.78 7.59-6.95 3.79-9.66-2.53-1.81-1.56-8.52-2.93-14.52-1.31-5.77-6.89-5.19-10.22-1.32-1.09 1.27-1.52 3.12-2.3 4.87Z"
      style={{
        fill: "url(#hd)",
      }}
    />
    <Path
      d="M665.19 270.81c1.91 2.25 3.62 4.23 7.62 3.58 4.49-.74 7.88-2.79 8.83-5.57 1.92-5.68 2.41-11.56-.17-17.21-1.66-3.64-9.25-3.56-8.72-6.79.97-5.88-2.5-11.25-4.75-16.73-1.48-3.58-3.55-3.98-9.52-2.68-3.82-5.37-5.54-9.37-11.28-8.82-6.6.63-4.69 8.66-8.04 9.26-8.85 1.59-7.04 7.98-7.06 18.16 0 .57.25 1.45-.2 1.68-3.45 1.76-2.4 4.06-2.16 6.35.93 8.98-.38 11.29 3.05 16.81.42.68 2.54 1.56 3.31 1.36 2.84-.73 3.64.75 5.3 1.53 8.41 3.97 16.29 7.68 23.81-.93h-.02Z"
      style={{
        fill: "url(#he)",
      }}
    />
    <Path
      d="M757.87 279.69c1.33 1.36 2.51 2.56 5.29 2.16 3.12-.44 5.47-1.69 6.13-3.37 1.34-3.43 1.68-6.98-.12-10.39-1.2-2.28-6.44-2.07-6.05-4.1.67-3.55-1.73-6.8-3.3-10.1-1.03-2.16-2.47-2.4-6.61-1.62-2.68-3.28-3.86-5.66-7.83-5.33-4.59.38-3.25 5.23-5.58 5.59-6.07.95-4.89 4.66-4.9 10.97 0 .35.18.87-.14 1.01-2.4 1.06-1.67 2.45-1.5 3.83.66 5.53-.26 6.83 2.12 10.15.29.41 1.76.94 2.3.82 3.35-.75 12.55 8 20.21.37h-.02Z"
      style={{
        fill: "url(#hf)",
      }}
    />
    <Path
      d="M1105.49 282.14a20.784 20.784 0 0 1-5.05-8.22c-.52-.54-.83-1.17-.73-2.05.02-.19.03-.38.05-.57-.15-.77-.25-1.54-.31-2.32-1.7-3.18-2.74-6.64-2.58-10.64.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.73-12.94-6.47-.64 2.48-1.8 4.71-3.68 6.78-1.56 1.71-3.38 2.94-4.49 4.68-.34 5.63.26 12.55-.17 12.87h-.01c-2.4 1.8-1.67 4.15-1.5 6.49.66 9.36-.26 11.55 2.12 17.18.3.69 1.77 1.59 2.3 1.39 1.97-.75 2.53.76 3.68 1.56 5.85 4.05 11.32 7.84 16.53-.95 1.33 2.3 2.51 4.33 5.29 3.66 3.12-.75 5.48-2.85 6.13-5.69.95-4.13 1.38-8.36.91-12.53-.46-.55-.9-1.11-1.3-1.71Z"
      style={{
        fill: "url(#hg)",
      }}
    />
    <Path
      d="M888.31 337.68c2.61.66 5.18 1.59 7.7 2.82a51.94 51.94 0 0 0-7.7-2.82Z"
      style={{
        fill: "url(#hh)",
      }}
    />
    <Path
      d="M837.35 337.96c-48.8 5.54-97.99-.41-146.46-6.61-40.96-5.22-229.93-22.88-311.01-31.42-12.36-1.3-19.99 13.12-11.99 22.62l12.83 15.22c2.23 2.65 5.37 4.36 8.81 4.8 24.26 3.09 112.92 4.28 126.49 6.88 1.3.25 2.63.27 3.95.14 7.52-.71 15.03-.54 22.55.53.65.09 1.3.14 1.95.14 19.12-.04 38.23-.28 57.34-.65 74.79-1.43 149.53-2.89 224.33-3.52.89 0 1.78-.1 2.65-.28 2.15-.44 4.3-.92 6.44-1.43 16.8-4.04 35.74-11.13 53.1-6.7-16.37-4.6-34.41-1.6-50.96.28Z"
      style={{
        fill: "url(#hi)",
      }}
    />
    <Path
      d="M1121.11 290.64c-3.8-.15-7.23-1.27-10.12-3.12-1.6.45-3.27 1.39-4.15 2.17-.74.65-.06 2.35.92 2.5 2.22.34 8.92-.73 15.14-1.5-.6-.01-1.19-.02-1.79-.05Z"
      style={{
        fill: "url(#hj)",
      }}
    />
    <Path
      d="M532.88 283.85c.99-.07 2.22-.68 2.52-1.33 1.76-3.77 5.83-3.05 10.27-2.83 7.9.32 16.55 1.17 23.15-.77 5.31-1.51 9.24-3 15.12-.24 1.64-6.91 4.68-5.78 11.42-2.19-.32-1.18-1.2-2.51-.81-3.34 1.06-2.28 1.83-5.11 4.07-6.3 4.58-2.47 6.9 6.4 12.23 5.45 1.41-.27 2.64-1.2 4.01-2.04 2.23-1.36 5-2.08 7.97-3.3l1.63 1.07c-4.43-14.94-7.1-17.58-3.19-20.11.85-.55 3.12.22 4.82.41 1.49-.61 2.97-1.85 4.78-1.75 2.65.16 7.52 8.01 10.87 11.92 3.93 4.58 6.69 1.13 14.31 6.07 2.85 1.84 5.09 4.35 6.75 7.5 2.28-2.97 4.38-5.74 6.31-8.59.26-.38-3.04-6.45 5.48-4.31.74.19 1.89.38 2.2.11 3.71-3.23 6.59-1.08 8.86 2.21 8 2 2.76-1.66 17.48 11.86 3.99-2.31 11.65-5.92 12.02-9.64.9-8.84 14.57 1.02 21.52-.5 1.38-.3 11.24 6.47 15.95 6.77 5.6.37 10.69.12 15.79 4.2 2.51 2.01 27.56-7.46 30.35-4.21 1.48 1.73 2.54 3.89 2.46 5.59-.08 1.89-10.02 2.13-10.37 2.18-72.46 11.12-98.98 11.67-131.96 14.99-104.69 9.83-135.8.4-160.9-2.3V284c12.16 1.89 22.72.76 34.89-.15Z"
      style={{
        fill: "url(#hk)",
      }}
    />
    <Path
      d="M4458.35 338.29c.26-5.31-.34-10.61.15-15.9.91-9.88 1.4-19.01 4.07-28-.07-.65-.15-1.3-.24-1.95l.25 1.89s0-.03.01-.04c-1.22-9.91-2.23-19.82-2.65-29.85-.53-12.55 1.12-24.92 3.07-37.28.1-1.99.15-3.99.15-5.98-2.22-8.75-3.21-17.51-3.01-26.26l-11.97.02v154.7c3.31.5 6.61 1.03 9.9 1.56l.53.03c-.35-4.34-.48-8.68-.28-12.95Z"
      style={{
        fill: "url(#hl)",
      }}
    />
    <Path
      d="M4459.96 264.45c-.53-12.55 1.12-24.92 3.07-37.28.1-1.99.15-3.99.15-5.98-3.72-14.68-4.03-29.36-.93-44.04-4.74 1.57-9.45 3.32-14.04 5.33V291.6c4.67-.22 9.35-.41 14.02-.58-1.04-8.81-1.89-17.64-2.26-26.57Z"
      style={{
        fill: "url(#hm)",
      }}
    />
    <Path
      d="M4462.98 173.87c-4.99 1.63-9.96 3.46-14.79 5.58v22.24c4.25.54 8.23.85 12.09 1.01-.56-9.61.34-19.22 2.69-28.83Z"
      style={{
        fill: "url(#hn)",
      }}
    />
    <Path
      d="M4462.09 177.79c-.67.03-1.35.05-2.02.08-4.01 1.38-7.99 2.9-11.88 4.61v11.96a206.6 206.6 0 0 1 12.1-2.79c.27-4.62.86-9.24 1.8-13.86Z"
      style={{
        fill: "url(#ho)",
      }}
    />
    <Path
      d="m4462.34 292.45.18 1.33h.03c-.28-2.32-.56-4.63-.81-6.95-4.4.1-8.85 0-13.54-.43l-38.73 1.08c10.33.79 28.24 3.76 53.04 6.3-.05-.44-.11-.89-.16-1.33Z"
      style={{
        fill: "url(#hp)",
      }}
    />
    <Path
      d="M4462.31 176.8a1854.2 1854.2 0 0 0-36.91-.19c-4.57.02-5.75 3.67-1.49 4.63 20.59 4.67-10.49 4.23 23.25 13.19l13.09-2.22c.27-5.14.95-10.28 2.05-15.42Z"
      style={{
        fill: "url(#hq)",
      }}
    />
    <Path
      d="M4439.15 162.34c7.72-4.01 8.83 4.25 11.26 12.32 1.91 0 3.3-.15 4.64.03 2.35.33 3.75 2.37 3.13 4.3-.63 1.95-3.56 3.52-5.33 2.91-8.15-2.82-10.64-4.06-13.36-1.31-1.88 1.9-6.17 1.68-9.24.26-.95.75-1.74 1.73-2.76 2.12-7.46 2.78-7.59-6.95-3.79-9.66 2.53-1.81 1.56-8.52 2.93-14.52 1.31-5.77 6.89-5.19 10.22-1.32 1.09 1.27 1.52 3.12 2.3 4.87Z"
      style={{
        fill: "url(#hr)",
      }}
    />
    <Path
      d="M4461.74 286.9c-.82-7.45-1.46-14.91-1.78-22.45-.08-2-.1-3.99-.08-5.98-.43-3.21-.44-8.85-1.98-10.33-7.13-6.87-1.65-21.11-17.58-22.93-7.34-.84-13.51 1.29-17.86 7.61-6.45-3.17-9.24-5.21-16.18 19.23-.71 2.48-.09 5.06-3.8 5.65-2.33.37-4.42 3.15-6.67 3.25-8.66.37-8.63.14-11.48 8.78-1.14 3.46-5.85 4.73-11.62 9.89-.67.59-.21 4.19.49 4.48 10.18 4.21 11.73 2.61 29.19 2.84 11.96.16 23.93.7 35.89.58 14.83-.14 18.22-.93 23.45-.62Z"
      style={{
        fill: "url(#hs)",
      }}
    />
    <Path
      d="M4449.96 268.23c-1.21.38-3.01.06-3.99-.69-1.19-.91 2.53-10.25 1.11-12.83-2.33-4.25-1.53-8.24-1.23-12.47.69-9.71-5.48-14.89-14.31-16.37-3.54 1.11-6.6 3.35-9.08 6.94-6.11-3-7.61-2.77-10.55 3.42-8.11 17.04-4.09 20.61-9.43 21.46-2.33.37-4.42 3.15-6.67 3.25-8.65.37-8.63.14-11.48 8.78-.71 2.16-2.65 3.27-4.62 4.64 9.09 2.76 27.86 9.6 39.79 12.94 30.22.72 32.15-.93 43.8-.31.51-4.66-1.01-8.84-2.45-13.17-2.46-7.39-3.32-7.95-10.89-5.61v.02Z"
      style={{
        fill: "url(#ht)",
      }}
    />
    <Path
      d="M1586.8 232.82c4.35-6.32 10.52-8.45 17.86-7.61 15.93 1.82 10.45 16.06 17.58 22.93 2.12 2.04 1.34 12.07 2.75 12.58 1.15.42 2.96.19 4.01-.53 6.54-4.49 7.52-4.21 12.07 2.11 2.91 4.05 5.86 7.89 6.46 13.14.11.94 1.23 2.22 2.13 2.5 4.61 1.44 3.87 4.79 3.19 8.35-.76 4-3.29 4.41-6.48 3.81-26.87-5.04-17.86-2.83-43.75-2.58-11.96.12-23.93-.42-35.89-.58-17.46-.23-19.01 1.37-29.19-2.84-.7-.29-1.16-3.89-.49-4.48 5.77-5.16 10.48-6.43 11.62-9.89 2.85-8.64 2.82-8.41 11.48-8.78 2.25-.1 4.34-2.88 6.67-3.25 3.71-.59 3.09-3.17 3.8-5.65 6.94-24.44 9.73-22.4 16.18-19.23Z"
      style={{
        fill: "url(#hu)",
      }}
    />
    <Path
      d="M1614.3 268.23c-1.21.38-3.01.06-3.99-.69-1.19-.91 2.53-10.25 1.11-12.83-2.33-4.25-1.53-8.24-1.23-12.47.69-9.71-5.48-14.89-14.31-16.37-3.54 1.11-6.6 3.35-9.08 6.94-6.11-3-7.61-2.77-10.55 3.42-8.11 17.04-4.09 20.61-9.43 21.46-2.33.37-4.42 3.15-6.67 3.25-8.65.37-8.63.14-11.48 8.78-.71 2.16-2.65 3.27-4.62 4.64 9.09 2.76 27.86 9.6 39.79 12.94 30.22.72 32.15-.93 43.8-.31.51-4.66-1.01-8.84-2.45-13.17-2.46-7.39-3.32-7.95-10.89-5.61v.02Z"
      style={{
        fill: "url(#hv)",
      }}
    />
    <Path
      d="M1603.49 162.34c7.72-4.01 8.83 4.25 11.26 12.32 1.91 0 3.3-.15 4.64.03 2.35.33 3.75 2.37 3.13 4.3-.63 1.95-3.56 3.52-5.33 2.91-8.15-2.82-10.64-4.06-13.36-1.31-1.88 1.9-6.17 1.68-9.24.26-.95.75-1.74 1.73-2.76 2.12-7.46 2.78-7.59-6.95-3.79-9.66 2.53-1.81 1.56-8.52 2.93-14.52 1.31-5.77 6.89-5.19 10.22-1.32 1.09 1.27 1.52 3.12 2.3 4.87Z"
      style={{
        fill: "url(#hw)",
      }}
    />
    <Path
      d="M3898.85 348.19c157.65-6.54 315.13-16.44 472.8-22.37 64.85-2.44 129.71-4.37 194.59-5.8V195l-271.91-.42c-79.92 23.99-167.19 28.71-189.77 31.5 1.19 3.02.17 7-3.06 8.36-.46.59-1.04 1.08-1.71 1.41-3.29 1.59-6.59 2.57-10.27 2.63h-.21c-.49.24-1.04.41-1.64.51-.23.04-1.09 1.53-1.42 1.77-.53.38-1.22.63-1.8.95-1.61.9-3.2 1.81-5.08 1.95-1.75.13-3.87-.26-4.97 1.11-.63.78-.65 1.86-.9 2.83-.42 1.65-1.53 3.02-2.67 4.28-1.82 2-3.9 3.91-6.47 4.72-2.57.81-5.75.25-7.34-1.93-.05.5-.63.72-1.11.84l-11.19 2.7c-1.6.39-3.25.78-4.88.56-1.07-.15-2.19-.55-3.2-.16-1.11.42-1.68 1.63-2.43 2.55-1.32 1.63-3.51 2.52-5.6 2.26-.97-.12-2.03-.45-2.88.05-.52.31-.83.87-1.18 1.35-1.62 2.2-4.81 3.09-7.33 2.04-1.06-.44-2.09-1.19-3.22-1.04-1.93.26-2.57 2.79-4.17 3.9-1.89 1.31-4.29.29-6.27-.2-2.31-.57-5.05.55-7.15-.43-1.59-.74-2.56-2.57-2.64-4.27-.19-3.83-3.16-5.31-5.52-7.91-1.1-1.22-1.91-2.51-2.57-3.91-6.05 16.11-20.41 28.57-36.7 34.55-9.23 3.39-19.38 2.21-28.96 1.58-10.8-.72-21.62-1.13-32.43-1.53-38.92-1.46-77.94-1.86-116.74-5.49-3.15-.3-6.3-.56-9.46-.83-14.26 1.7-28.65 2.9-42.99 3.35-14.24.45-28.47-.37-42.71-.47h-2.36v62.36c75.17.33 150.34.88 225.52 1.67Z"
      style={{
        fill: "url(#hx)",
      }}
    />
    <Path
      d="M4550.12 176.51c-.6-.19-1.21-.39-1.83-.58-38.15.47-77.15-.67-115.01.25-68.41 1.67-112.43 18.21-175.2 26.76-59.76 8.13-73.95 19.39-135.87 22.28-2.53.12-8.85.77-17.37 1.79.68 2.86-9.39 3.02-12.3 4.25-8.4 6.71-20 7.41-23.56 17.11-1.19 1.75 1.46 3.91-.05 5.28 31.03 1.59 64.26 3.27 86.83 6.09 74 9.24 148.29 20.24 223.99 25.77 61.78 4.52 124.43 3.24 186.48 6.14V182.53c-5.26-2.31-10.68-4.28-16.12-6.02h.01Z"
      style={{
        fill: "url(#hy)",
      }}
    />
    <Path
      d="M4550.12 176.51c-3.85-1.23-8.02-2.56-12.27-3.6-21.33-1.31-43.32-3.69-63.35-2.97-97.2 3.48-169.05 26.43-352.28 55.28 63.65-1.71 164.44-21.35 224.85-24.66 158.43-8.68 173.06 10.02 219.17 4.22v-22.24c-5.26-2.31-10.68-4.28-16.12-6.02Z"
      style={{
        fill: "url(#hz)",
      }}
    />
    <Path
      d="M4566.24 346.51v-33.4c-14.68 2.24-567.01 28.75-604.64 32.31 29.09-.85 575.54 1.73 604.64 1.09Z"
      style={{
        fill: "url(#hA)",
      }}
    />
    <Path
      d="M4531.35 285.54c-.99-.07-2.22-.68-2.52-1.33-1.76-3.77-5.83-3.05-10.27-2.83-7.9.32-16.55 1.17-23.15-.77-5.31-1.51-9.24-3-15.12-.24-1.64-6.91-4.68-5.78-11.42-2.19.32-1.18 1.2-2.51.81-3.34-1.06-2.28-1.83-5.11-4.07-6.3-4.58-2.47-6.9 6.4-12.23 5.45-1.41-.27-2.64-1.2-4.01-2.04-2.23-1.36-5-2.08-7.97-3.3l-1.63 1.07c4.43-14.94 7.1-17.58 3.19-20.11-.85-.55-3.12.22-4.82.41-1.49-.61-2.97-1.85-4.78-1.75-2.65.16-7.52 8.01-10.87 11.92-3.93 4.58-6.69 1.13-14.31 6.07-2.85 1.84-5.09 4.35-6.75 7.5-2.28-2.97-4.38-5.74-6.31-8.59-.26-.38 3.04-6.45-5.48-4.31-.74.19-1.89.38-2.2.11-3.71-3.23-6.59-1.08-8.86 2.21-8 2-2.76-1.66-17.48 11.86-3.99-2.31-11.65-5.92-12.02-9.64-.9-8.84-14.57 1.02-21.52-.5-1.38-.3-11.24 6.47-15.95 6.77-5.6.37-10.69.12-15.79 4.2-2.51 2.01-27.56-7.46-30.35-4.21-1.48 1.73-2.54 3.89-2.46 5.59.08 1.89 10.02 2.13 10.37 2.18 72.46 11.12 98.98 11.67 131.96 14.99 104.69 9.8 135.8.37 160.9-1.56v-6.43c-12.16 1.12-22.72-.01-34.89-.92v.03Z"
      style={{
        fill: "url(#hB)",
      }}
    />
    <Path
      d="M3727.52 342.54c262.14-2.2 524.24-2.34 786.36.42 92.7-3.84 185.35-8.84 278.01-13.52-25.11-4.54-51.04-9.83-77.72-15.98-56.36-13-107.08-22.89-152.39-30.36-8.57 2.41-17.79 1.39-26.54.81-10.8-.72-21.62-1.13-32.43-1.53-38.92-1.46-77.94-1.86-116.74-5.49-3.17-.3-6.34-.55-9.51-.82-14.24 1.7-28.61 2.89-42.94 3.34-14.24.45-28.47-.37-42.71-.47-30.12-.2-60.24.6-90.37.36-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-3.67-.14-7-1.2-9.82-2.94-43.68 7.35-92.27 16.9-146.01 29.3-59.14 13.64-114.58 23.05-165.64 29.35 9.33-.1 18.66-.19 27.99-.27Z"
      style={{
        fill: "url(#hC)",
      }}
    />
    <Path
      d="M5017.66 242.62c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#hD)",
      }}
    />
    <Path
      d="M5032.05 288.46c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05h.01Z"
      style={{
        fill: "url(#hE)",
      }}
    />
    <Path
      d="M4954.84 238.48c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#hF)",
      }}
    />
    <Path
      d="M4969.23 284.31c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05Z"
      style={{
        fill: "url(#hG)",
      }}
    />
    <Path
      d="M5300.05 284.42c-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-6.68-.26-12.27-3.49-15.62-8.49-3.44-3.42-5.65-8.16-6.04-13.12-1.72-3.19-2.76-6.67-2.6-10.68.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.74-12.94-6.48-.64 2.48-1.8 4.71-3.68 6.79-2.36 2.6-5.33 4.08-5.52 7.91-.16 3.2-2.94 5.35-6.08 4.63-.83-.19-1.67-.39-2.5-.59-1 .99-2.41 1.63-4.23 1.63-3.11 0-5.02-1.86-5.73-4.15-.92-.25-1.85-.51-2.77-.77-1.01 1.18-2.52 1.96-4.54 1.96-3.22 0-5.14-2.01-5.79-4.42-.58.17-1.21.27-1.91.27-.42 0-.81-.05-1.18-.11-.37.07-.77.11-1.19.11-3.25 0-5.19-2.05-5.82-4.48-.76-.29-1.51-.59-2.27-.89-.96.75-2.21 1.22-3.76 1.22-1.67 0-2.99-.54-3.97-1.4-.28-.09-.55-.19-.81-.33-.47-.15-.9-.38-1.3-.67-.14.01-.28.03-.43.03h-1.19c-.5.02-.98-.05-1.44-.17-.47.11-.98.17-1.52.17-2.16 0-3.74-.9-4.74-2.22-1 1.32-2.58 2.22-4.74 2.22-.2 0-.39-.02-.58-.03-1.29.18-2.61-.04-3.77-.83a26.116 26.116 0 0 1-6.99-6.99c-.72-1.04-.94-2.61-.79-3.95-.38-.17-.72-.39-1.04-.63-.63-.22-1.19-.57-1.68-1.03-.68.27-1.46.42-2.33.42-1.94 0-3.41-.73-4.42-1.83-2.12-.25-3.58-1.45-4.4-3-.6-.1-1.15-.27-1.64-.51h-.21c-3.68-.06-6.98-1.04-10.27-2.63-.67-.33-1.25-.82-1.71-1.41-3.23-1.36-4.25-5.34-3.06-8.36-22.58-2.79-109.85-7.51-189.77-31.5l-271.91.42v154.7c3.31.5 6.61 1.03 9.9 1.56 2.92.23 5.85.36 8.78.38a95.563 95.563 0 0 1 26.81.31c19.44-.03 38.88-.28 58.31-.65 75.29-1.44 150.54-2.91 225.84-3.54 187.76-1.57 375.51-2.09 563.26-1.24v-62.36c-30.08-.2-60.15.6-90.24.36Z"
      style={{
        fill: "url(#hH)",
      }}
    />
    <Path
      d="M5072.81 293.84c-11.56-.84-17.44-6.24-24.2-1.38-3.99-6.47-6.66-6.96-12.49-1.5-.29-1.32-.08-2.85-.9-3.7-2.27-2.36-4.61-5.33-7.7-6.36-6.31-2.09-3.54 7.76-9.48 7.52-2.22-.09-4.39-1.39-7.91-2.17-2.51-.55-5.29-.84-7.89-1.28l-.91 1.43c-6.13-16.51-5.33-19.7-11.47-21.29-1.4-.36-3.37.98-5.07 1.56-2.09-.25-4.8-1.15-6.73-.57-4.08 1.22.43 16.44-3.65 18.15-7.17 2.99-9.9 8.01-7.76 15.41-16.45-8.07-13.04-6.25-14.16-7.94-4.21-6.4-8.59-.01-10.35-.57-6.48-2.04-7.39.88-6.62 4.68-5.67 4.09-4.38-.68-5.9 16.44-6.65-1.15-16.32-2.13-20.08-5.39-3.37-2.91-6.34-3.09-9.66-1.04-3.74 2.31-5.01 6.88-10.75 7.58-1.32.16-1.79 3.24-2.84 4.87-1.5 2.33-2.49 5.5-4.9 6.66-2.3 1.12-4.63 2.08-6.44 3.43-8.86 7.07 1.91 5.94-13 7.75-1.75.2-7.93.93-9.5 1.75-.53.25-.54 1.53.12 2.16 1.5 1.44 5.94 5.27 5.91 5.25-.04-.02-1.18-1.78-.02-.04-.55-1.24 8.4-2.74 11.34-3.27 49.15-8.91 114.63-28.49 199.8-29.25l3.2-18.9h.01Z"
      style={{
        fill: "url(#hI)",
      }}
    />
    <Path
      d="M4950.29 208.04c1.76-.03 3.69-.08 5.76-.15.22.35.48.68.78.97.08 1.24.47 2.45 1.18 3.45v.15c-.69.64-1.29 1.4-1.75 2.26-.82 1.54-1.01 3.15-.63 4.83.06.26.14.5.24.74-36.13-.6-74.73.26-99.41-1.04-32.18-1.7 79.33-10.95 93.83-11.21Z"
      style={{
        fill: "url(#hJ)",
      }}
    />
    <Path
      d="M4847.26 221.33c3.19-2.9 2.17-1.03 31.51-3.95 8.08-.86 10.35-.47 11.23-1.62 2.18-2.86 6.53-1.81 20.23-.86 13.74.92 17.2-2.21 24.25 1.31 3.55-3.85 6.22-2.98 11.24.11.13-.74-.2-1.64.46-2.06 1.82-1.18 3.63-2.72 6.25-3.09 2.79-.39 3.82.94 4.73 2.2-.34.42-.65.86-.91 1.35-.82 1.54-1.01 3.15-.63 4.83.24 1.05.84 1.97 1.6 2.72.07.55.23 1.08.49 1.59.25.78.68 1.45 1.3 2.02l.08.08a7.06 7.06 0 0 0-.08 5.04c-13.71-1.8-27.95-3.48-42.84-4.92-58.19-5.55-63.61-1.25-68.2-2.36-.8-.19-1.32-1.81-.7-2.37v-.02Z"
      style={{
        fill: "url(#hK)",
      }}
    />
    <Path
      d="M4513.5 176.4c.6-.19 1.21-.39 1.83-.58 38.15.47 77.15-.67 115.01.25 68.41 1.67 112.43 18.21 175.2 26.76 59.76 8.13 73.95 19.39 135.87 22.28 2.53.12 8.85.77 17.37 1.79-.68 2.86.44 6.21 3.35 7.43.46.59 1.04 1.08 1.72 1.41 3.29 1.59 6.59 2.57 10.27 2.63h.22c.49.23 1.03.41 1.62.51.82 1.55 2.28 2.75 4.41 3 1.01 1.1 2.48 1.83 4.42 1.83.87 0 1.65-.15 2.33-.42.49.46 1.05.81 1.69 1.03.32.24.66.46 1.04.63-.15 1.34.07 2.91.79 3.95 1.19 1.75 2.54 3.26 4.05 4.64-31.03 1.59-64.26 3.27-86.83 6.09-74 9.24-148.29 20.24-223.99 25.77-61.78 4.51-124.44 3.24-186.49 6.14V182.42c5.26-2.31 10.68-4.28 16.12-6.02Z"
      style={{
        fill: "url(#hL)",
      }}
    />
    <Path
      d="M4513.5 173.38c3.85-1.23 8.02-2.56 12.27-3.6 21.33-1.31 43.32-3.69 63.35-2.97 97.2 3.48 169.05 26.43 352.28 55.28-63.65-1.71-164.44-21.35-224.85-24.66-158.43-8.69-173.06 10.01-219.17 4.21V179.4c5.26-2.31 10.68-4.28 16.12-6.02Z"
      style={{
        fill: "url(#hM)",
      }}
    />
    <Path
      d="M4509.26 177.81c24.28-.99 50.82-1.5 79.77-1.36 4.57.02 5.75 3.67 1.49 4.63-20.58 4.67-59.4 4.35-93.14 13.3v-11.96c3.89-1.71 7.87-3.23 11.88-4.61Z"
      style={{
        fill: "url(#hN)",
      }}
    />
    <Path
      d="M4591.97 232.66c-4.35-6.32-10.52-8.45-17.86-7.61-15.93 1.82-10.45 16.06-17.58 22.93-2.12 2.04-1.34 12.07-2.75 12.58-1.15.42-2.96.19-4.01-.53-6.54-4.49-7.52-4.21-12.07 2.11-2.91 4.05-5.86 7.89-6.46 13.14-.11.94-1.23 2.22-2.13 2.5-4.61 1.44-3.87 4.79-3.19 8.35.76 4 3.29 4.41 6.48 3.81 26.87-5.04 17.86-2.83 43.75-2.58 11.96.12 23.93-.42 35.89-.58 17.46-.23 19.01 1.37 29.19-2.84.7-.29 1.16-3.89.49-4.48-5.77-5.16-10.48-6.43-11.62-9.89-2.85-8.64-2.82-8.41-11.48-8.78-2.25-.1-4.34-2.88-6.67-3.25-3.71-.59-3.09-3.17-3.8-5.65-6.94-24.44-9.73-22.4-16.18-19.23Z"
      style={{
        fill: "url(#hO)",
      }}
    />
    <Path
      d="M4564.47 268.07c1.21.38 3.01.06 3.99-.69 1.19-.91-2.53-10.25-1.11-12.83 2.33-4.25 1.53-8.24 1.23-12.47-.69-9.71 5.48-14.89 14.31-16.37 3.54 1.11 6.6 3.35 9.08 6.94 6.11-3 7.61-2.77 10.55 3.42 8.11 17.04 4.09 20.61 9.43 21.46 2.33.37 4.42 3.15 6.67 3.25 8.65.37 8.63.14 11.48 8.78.71 2.16 2.65 3.27 4.62 4.64-9.09 2.76-27.86 9.6-39.79 12.94-30.22.72-32.15-.93-43.8-.31-.51-4.66 1.01-8.84 2.45-13.17 2.46-7.39 3.32-7.95 10.89-5.61v.02Z"
      style={{
        fill: "url(#hP)",
      }}
    />
    <Path
      d="M4575.28 162.18c-7.72-4.01-8.83 4.25-11.26 12.32-1.91 0-3.3-.15-4.64.03-2.35.33-3.75 2.37-3.13 4.3.63 1.95 3.56 3.52 5.33 2.91 8.15-2.82 10.64-4.06 13.36-1.31 1.88 1.9 6.17 1.68 9.24.26.95.75 1.74 1.73 2.76 2.12 7.46 2.78 7.59-6.95 3.79-9.66-2.53-1.81-1.56-8.52-2.93-14.52-1.31-5.77-6.89-5.19-10.22-1.32-1.09 1.27-1.52 3.12-2.3 4.87Z"
      style={{
        fill: "url(#hQ)",
      }}
    />
    <Path
      d="M4664.58 272.39c1.91 2.25 3.62 4.23 7.62 3.58 4.49-.74 7.88-2.79 8.83-5.57 1.92-5.68 2.41-11.56-.17-17.21-1.66-3.64-9.25-3.56-8.72-6.79.97-5.88-2.5-11.25-4.75-16.73-1.48-3.58-3.55-3.98-9.52-2.68-3.82-5.37-5.54-9.37-11.28-8.82-6.6.63-4.69 8.66-8.04 9.26-8.85 1.59-7.04 7.98-7.06 18.16 0 .57.25 1.45-.2 1.68-3.45 1.76-2.4 4.06-2.16 6.35.93 8.98-.38 11.29 3.05 16.81.42.68 2.54 1.56 3.31 1.36 2.84-.73 3.64.75 5.3 1.53 8.41 3.97 16.29 7.68 23.81-.93h-.02Z"
      style={{
        fill: "url(#hR)",
      }}
    />
    <Path
      d="M4757.26 281.27c1.33 1.36 2.51 2.56 5.29 2.16 3.12-.44 5.47-1.69 6.13-3.37 1.34-3.43 1.68-6.98-.12-10.39-1.2-2.28-6.44-2.07-6.05-4.1.67-3.55-1.73-6.8-3.3-10.1-1.03-2.16-2.47-2.4-6.61-1.62-2.68-3.28-3.86-5.66-7.83-5.33-4.59.38-3.25 5.23-5.58 5.59-6.07.95-4.89 4.66-4.9 10.97 0 .35.18.87-.14 1.01-2.4 1.06-1.67 2.45-1.5 3.83.66 5.53-.26 6.83 2.12 10.15.29.41 1.76.94 2.3.82 3.35-.75 12.55 8 20.21.37h-.02Z"
      style={{
        fill: "url(#hS)",
      }}
    />
    <Path
      d="M5104.88 283.73a20.784 20.784 0 0 1-5.05-8.22c-.52-.54-.83-1.17-.73-2.05.02-.19.03-.38.05-.57-.15-.77-.25-1.54-.31-2.32-1.7-3.18-2.74-6.64-2.58-10.64.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.73-12.94-6.47-.64 2.48-1.8 4.71-3.68 6.78-1.56 1.71-3.38 2.94-4.49 4.68-.34 5.63.26 12.55-.17 12.87h-.01c-2.4 1.8-1.67 4.15-1.5 6.49.66 9.36-.26 11.55 2.12 17.18.3.69 1.77 1.59 2.3 1.39 1.97-.75 2.53.76 3.68 1.56 5.85 4.05 11.32 7.84 16.53-.95 1.33 2.3 2.51 4.33 5.29 3.66 3.12-.75 5.48-2.85 6.13-5.69.95-4.13 1.38-8.36.91-12.53-.46-.55-.9-1.11-1.3-1.71Z"
      style={{
        fill: "url(#hT)",
      }}
    />
    <Path
      d="M4887.7 339.26c2.61.66 5.18 1.59 7.7 2.82a51.94 51.94 0 0 0-7.7-2.82Z"
      style={{
        fill: "url(#hU)",
      }}
    />
    <Path
      d="M5120.5 292.22c-3.8-.15-7.23-1.27-10.12-3.12-1.6.45-3.27 1.39-4.15 2.17-.74.65-.06 2.35.92 2.5 2.22.34 8.92-.73 15.14-1.5-.6-.01-1.19-.02-1.79-.05Z"
      style={{
        fill: "url(#hV)",
      }}
    />
    <Path
      d="M4532.27 285.43c.99-.07 2.22-.68 2.52-1.33 1.76-3.77 5.83-3.05 10.27-2.83 7.9.32 16.55 1.17 23.15-.77 5.31-1.51 9.24-3 15.12-.24 1.64-6.91 4.68-5.78 11.42-2.19-.32-1.18-1.2-2.51-.81-3.34 1.06-2.28 1.83-5.11 4.07-6.3 4.58-2.47 6.9 6.4 12.23 5.45 1.41-.27 2.64-1.2 4.01-2.04 2.23-1.36 5-2.08 7.97-3.3l1.63 1.07c-4.43-14.94-7.1-17.58-3.19-20.11.85-.55 3.12.22 4.82.41 1.49-.61 2.97-1.85 4.78-1.75 2.65.16 7.52 8.01 10.87 11.92 3.93 4.58 6.69 1.13 14.31 6.07 2.85 1.84 5.09 4.35 6.75 7.5 2.28-2.97 4.38-5.74 6.31-8.59.26-.38-3.04-6.45 5.48-4.31.74.19 1.89.38 2.2.11 3.71-3.23 6.59-1.08 8.86 2.21 8 2 2.76-1.66 17.48 11.86 3.99-2.31 11.65-5.92 12.02-9.64.9-8.84 14.57 1.02 21.52-.5 1.38-.3 11.24 6.47 15.95 6.77 5.6.37 10.69.12 15.79 4.2 2.51 2.01 27.56-7.46 30.35-4.21 1.48 1.73 2.54 3.89 2.46 5.59-.08 1.89-10.02 2.13-10.37 2.18-72.46 11.12-98.98 11.67-131.96 14.99-104.69 9.83-174.53-4.95-199.63-6.88l38.73-1.08c12.16 1.12 22.72-.01 34.89-.92Z"
      style={{
        fill: "url(#hW)",
      }}
    />
    <Path
      d="M5384.58 284.04c-28.18-.07-56.35.6-84.53.38-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-3.67-.14-7-1.2-9.82-2.94-43.68 7.35-92.27 16.9-146.01 29.3-59.14 13.64-114.58 23.05-165.64 29.35 9.33-.1 18.66-.19 27.99-.27 185.86-1.56 371.71-2.07 557.55-1.26v-62.35Z"
      style={{
        fill: "url(#hX)",
      }}
    />
    <Path
      d="M5017.02 242.73c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#hY)",
      }}
    />
    <Path
      d="M5031.41 288.57c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05h.01Z"
      style={{
        fill: "url(#hZ)",
      }}
    />
    <Path
      d="M4954.2 238.59c-6.96.31-7.87 1.02-9.04 7.91-1.05 6.17 1.15 10.75-3.34 13.93-4.01 2.84-3.17 10.21-2.47 15.08.5 3.52 2.23 6.1 5.97 7.37 4.25 1.44 5.34 11.38 15.05 12.96 11.56 1.88 14.17-2.95 30.83-7.38 3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.78-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.44-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-1.23-8.93-.74-20.27-11.63-17.14-3.65 1.05-6.37-6.15-14.78.39-12.89 10.03-11.96 14.45-14.19 24.75h-.02Z"
      style={{
        fill: "url(#ia)",
      }}
    />
    <Path
      d="M4968.59 284.42c3.27-.87 8.21 7.26 15.71-.5.53-.55 1.19-1.39 1.77-1.39 5.41.08 7.7-3.26 8.66-7.88.38-1.84 1.01-2.62 2.97-3.12 3.49-.89 6.53-2.82 6.88-6.94.66-7.8 2.33-19.6-5.27-18.43-4.57-12.45-8.04-29.46-19.57-19.83-3.08 2.57-5.2-1.09-7.54.22-.66-4.84-.98-10.77-2.39-13.73 7.25-4.74 9.93 1.61 13.37.62 10.88-3.13 10.4 8.2 11.63 17.14 2.34-1.31 4.46 2.35 7.54-.22 11.6-9.69 15.1 7.65 19.57 19.83 7.61-1.17 5.93 10.6 5.27 18.43-.35 4.12-3.38 6.05-6.88 6.94-1.96.5-2.59 1.28-2.97 3.12-.96 4.62-3.25 7.96-8.66 7.88-.59 0-1.25.84-1.78 1.39-7.46 7.73-12.46-.37-15.71.5-4.56 1.21-9.22 2.44-13.41 4.54-7.68 3.84-19.34 5.69-26.17-2.52 5.35-1.91 3.42-2.44 16.97-6.05Z"
      style={{
        fill: "url(#ib)",
      }}
    />
    <Path
      d="M5299.4 284.53c-15.2-.12-30.41-.27-45.61.09-15.23.36-30.37 1.89-45.58 2.43-4.11.15-8.22.23-12.33.31-1.45.14-2.9.28-4.35.4-9.88.83-19.78 1.15-29.68 1.26-13.98 1.51-27.87 3.85-41.99 3.3-6.68-.26-12.27-3.49-15.62-8.49-3.44-3.42-5.65-8.16-6.04-13.12-1.72-3.19-2.76-6.67-2.6-10.68.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.74-12.94-6.48-.64 2.48-1.8 4.71-3.68 6.79-2.36 2.6-5.33 4.08-5.52 7.91-.16 3.2-2.94 5.35-6.08 4.63-.83-.19-1.67-.39-2.5-.59-1 .99-2.41 1.63-4.23 1.63-3.11 0-5.02-1.86-5.73-4.15-.92-.25-1.85-.51-2.77-.77-1.01 1.18-2.52 1.96-4.54 1.96-3.22 0-5.14-2.01-5.79-4.42-.58.17-1.21.27-1.91.27-.42 0-.81-.05-1.18-.11-.37.07-.77.11-1.19.11-3.25 0-5.19-2.05-5.82-4.48-.76-.29-1.51-.59-2.27-.89-.96.75-2.21 1.22-3.76 1.22-1.67 0-2.99-.54-3.97-1.4-.28-.09-.55-.19-.81-.33-.47-.15-.9-.38-1.3-.67-.14.01-.28.03-.43.03h-1.19c-.5.02-.98-.05-1.44-.17-.47.11-.98.17-1.52.17-2.16 0-3.74-.9-4.74-2.22-1 1.32-2.58 2.22-4.74 2.22-.2 0-.39-.02-.58-.03-1.29.18-2.61-.04-3.77-.83a26.116 26.116 0 0 1-6.99-6.99c-.72-1.04-.94-2.61-.79-3.95-.38-.17-.72-.39-1.04-.63-.63-.22-1.19-.57-1.68-1.03-.68.27-1.46.42-2.33.42-1.94 0-3.41-.73-4.42-1.83-2.12-.25-3.58-1.45-4.4-3-.6-.1-1.15-.27-1.64-.51h-.21c-3.68-.06-6.98-1.04-10.27-2.63-.67-.33-1.25-.82-1.71-1.41-3.23-1.36-4.25-5.34-3.06-8.36-22.58-2.79-109.85-7.51-189.77-31.5l-271.91.42v154.7c3.31.5 6.61 1.03 9.9 1.56 2.92.23 5.85.36 8.78.38a95.563 95.563 0 0 1 26.81.31c19.44-.03 38.88-.28 58.31-.65 75.29-1.44 150.54-2.91 225.84-3.54 187.76-1.57 375.51-2.09 563.26-1.24v-62.36c-30.08-.2-60.15.6-90.24.36Z"
      style={{
        fill: "url(#ic)",
      }}
    />
    <Path
      d="M5072.17 293.95c-11.56-.84-17.44-6.24-24.2-1.38-3.99-6.47-6.66-6.96-12.49-1.5-.29-1.32-.08-2.85-.9-3.7-2.27-2.36-4.61-5.33-7.7-6.36-6.31-2.09-3.54 7.76-9.48 7.52-2.22-.09-4.39-1.39-7.91-2.17-2.51-.55-5.29-.84-7.89-1.28l-.91 1.43c-6.13-16.51-5.33-19.7-11.47-21.29-1.4-.36-3.37.98-5.07 1.56-2.09-.25-4.8-1.15-6.73-.57-4.08 1.22.43 16.44-3.65 18.15-7.17 2.99-9.9 8.01-7.76 15.41-16.45-8.07-13.04-6.25-14.16-7.94-4.21-6.4-8.59-.01-10.35-.57-6.48-2.04-7.39.88-6.62 4.68-5.67 4.09-4.38-.68-5.9 16.44-6.65-1.15-16.32-2.13-20.08-5.39-3.37-2.91-6.34-3.09-9.66-1.04-3.74 2.31-5.01 6.88-10.75 7.58-1.32.16-1.79 3.24-2.84 4.87-1.5 2.33-2.49 5.5-4.9 6.66-2.3 1.12-4.63 2.08-6.44 3.43-8.86 7.07 1.91 5.94-13 7.75-1.75.2-7.93.93-9.5 1.75-.53.25-.54 1.53.12 2.16 1.5 1.44 5.94 5.27 5.91 5.25-.04-.02-1.18-1.78-.02-.04-.55-1.24 8.4-2.74 11.34-3.27 49.15-8.91 114.63-28.49 199.8-29.25l3.2-18.9h.01Z"
      style={{
        fill: "url(#id)",
      }}
    />
    <Path
      d="M4949.65 208.15c1.76-.03 3.69-.08 5.76-.15.22.35.48.68.78.97.08 1.24.47 2.45 1.18 3.45v.15c-.69.64-1.29 1.4-1.75 2.26-.82 1.54-1.01 3.15-.63 4.83.06.26.14.5.24.74-36.13-.6-74.73.26-99.41-1.04-32.18-1.7 79.33-10.95 93.83-11.21Z"
      style={{
        fill: "url(#ie)",
      }}
    />
    <Path
      d="M4846.62 221.44c3.19-2.9 2.17-1.03 31.51-3.95 8.08-.86 10.35-.47 11.23-1.62 2.18-2.86 6.53-1.81 20.23-.86 13.74.92 17.2-2.21 24.25 1.31 3.55-3.85 6.22-2.98 11.24.11.13-.74-.2-1.64.46-2.06 1.82-1.18 3.63-2.72 6.25-3.09 2.79-.39 3.82.94 4.73 2.2-.34.42-.65.86-.91 1.35-.82 1.54-1.01 3.15-.63 4.83.24 1.05.84 1.97 1.6 2.72.07.55.23 1.08.49 1.59.25.78.68 1.45 1.3 2.02l.08.08a7.06 7.06 0 0 0-.08 5.04c-13.71-1.8-27.95-3.48-42.84-4.92-58.19-5.55-63.61-1.25-68.2-2.36-.8-.19-1.32-1.81-.7-2.37v-.02Z"
      style={{
        fill: "url(#if)",
      }}
    />
    <Path
      d="M4512.86 176.51c.6-.19 1.21-.39 1.83-.58 38.15.47 77.15-.67 115.01.25 68.41 1.67 112.43 18.21 175.2 26.76 59.76 8.13 73.95 19.39 135.87 22.28 2.53.12 8.85.77 17.37 1.79-.68 2.86.44 6.21 3.35 7.43.46.59 1.04 1.08 1.72 1.41 3.29 1.59 6.59 2.57 10.27 2.63h.22c.49.23 1.03.41 1.62.51.82 1.55 2.28 2.75 4.41 3 1.01 1.1 2.48 1.83 4.42 1.83.87 0 1.65-.15 2.33-.42.49.46 1.05.81 1.69 1.03.32.24.66.46 1.04.63-.15 1.34.07 2.91.79 3.95 1.19 1.75 2.54 3.26 4.05 4.64-31.03 1.59-64.26 3.27-86.83 6.09-74 9.24-148.29 20.24-223.99 25.77-61.78 4.51-124.44 3.24-186.49 6.14V182.53c5.26-2.31 10.68-4.28 16.12-6.02Z"
      style={{
        fill: "url(#ig)",
      }}
    />
    <Path
      d="M4512.86 176.51c3.85-1.23 8.02-2.56 12.27-3.6 21.33-1.31 43.32-3.69 63.35-2.97 97.2 3.48 169.05 26.43 352.28 55.28-63.65-1.71-164.44-21.35-224.85-24.66-158.43-8.69-180.82 5.94-226.93.14l7.76-18.17c5.26-2.31 10.68-4.28 16.12-6.02Z"
      style={{
        fill: "url(#ih)",
      }}
    />
    <Path
      d="M4508.62 177.92c24.28-.99 50.82-1.5 79.77-1.36 4.57.02 5.75 3.67 1.49 4.63-20.58 4.67-59.4 4.35-93.14 13.3v-11.96c3.89-1.71 7.87-3.23 11.88-4.61Z"
      style={{
        fill: "url(#ii)",
      }}
    />
    <Path
      d="M4554.36 177.92c-24.28-.99-50.82-1.5-79.77-1.36-4.57.02-5.75 3.67-1.49 4.63 20.59 4.67-10.49 4.23 23.25 13.19l69.9-11.84c-3.89-1.71-7.87-3.23-11.88-4.61h-.01Z"
      style={{
        fill: "url(#ij)",
      }}
    />
    <Path
      d="M4591.33 232.77c-4.35-6.32-10.52-8.45-17.86-7.61-15.93 1.82-10.45 16.06-17.58 22.93-2.12 2.04-1.34 12.07-2.75 12.58-1.15.42-2.96.19-4.01-.53-6.54-4.49-7.52-4.21-12.07 2.11-2.91 4.05-5.86 7.89-6.46 13.14-.11.94-1.23 2.22-2.13 2.5-4.61 1.44-3.87 4.79-3.19 8.35.76 4 3.29 4.41 6.48 3.81 26.87-5.04 17.86-2.83 43.75-2.58 11.96.12 23.93-.42 35.89-.58 17.46-.23 19.01 1.37 29.19-2.84.7-.29 1.16-3.89.49-4.48-5.77-5.16-10.48-6.43-11.62-9.89-2.85-8.64-2.82-8.41-11.48-8.78-2.25-.1-4.34-2.88-6.67-3.25-3.71-.59-3.09-3.17-3.8-5.65-6.94-24.44-9.73-22.4-16.18-19.23Z"
      style={{
        fill: "url(#ik)",
      }}
    />
    <Path
      d="M4563.83 268.18c1.21.38 3.01.06 3.99-.69 1.19-.91-2.53-10.25-1.11-12.83 2.33-4.25 1.53-8.24 1.23-12.47-.69-9.71 5.48-14.89 14.31-16.37 3.54 1.11 6.6 3.35 9.08 6.94 6.11-3 7.61-2.77 10.55 3.42 8.11 17.04 4.09 20.61 9.43 21.46 2.33.37 4.42 3.15 6.67 3.25 8.65.37 8.63.14 11.48 8.78.71 2.16 2.65 3.27 4.62 4.64-9.09 2.76-27.86 9.6-39.79 12.94-30.22.72-32.15-.93-43.8-.31-.51-4.66 1.01-8.84 2.45-13.17 2.46-7.39 3.32-7.95 10.89-5.61v.02Z"
      style={{
        fill: "url(#il)",
      }}
    />
    <Path
      d="M4574.64 162.29c-7.72-4.01-8.83 4.25-11.26 12.32-1.91 0-3.3-.15-4.64.03-2.35.33-3.75 2.37-3.13 4.3.63 1.95 3.56 3.52 5.33 2.91 8.15-2.82 10.64-4.06 13.36-1.31 1.88 1.9 6.17 1.68 9.24.26.95.75 1.74 1.73 2.76 2.12 7.46 2.78 7.59-6.95 3.79-9.66-2.53-1.81-1.56-8.52-2.93-14.52-1.31-5.77-6.89-5.19-10.22-1.32-1.09 1.27-1.52 3.12-2.3 4.87Z"
      style={{
        fill: "url(#im)",
      }}
    />
    <Path
      d="M4663.94 272.5c1.91 2.25 3.62 4.23 7.62 3.58 4.49-.74 7.88-2.79 8.83-5.57 1.92-5.68 2.41-11.56-.17-17.21-1.66-3.64-9.25-3.56-8.72-6.79.97-5.88-2.5-11.25-4.75-16.73-1.48-3.58-3.55-3.98-9.52-2.68-3.82-5.37-5.54-9.37-11.28-8.82-6.6.63-4.69 8.66-8.04 9.26-8.85 1.59-7.04 7.98-7.06 18.16 0 .57.25 1.45-.2 1.68-3.45 1.76-2.4 4.06-2.16 6.35.93 8.98-.38 11.29 3.05 16.81.42.68 2.54 1.56 3.31 1.36 2.84-.73 3.64.75 5.3 1.53 8.41 3.97 16.29 7.68 23.81-.93h-.02Z"
      style={{
        fill: "url(#in)",
      }}
    />
    <Path
      d="M4756.62 281.38c1.33 1.36 2.51 2.56 5.29 2.16 3.12-.44 5.47-1.69 6.13-3.37 1.34-3.43 1.68-6.98-.12-10.39-1.2-2.28-6.44-2.07-6.05-4.1.67-3.55-1.73-6.8-3.3-10.1-1.03-2.16-2.47-2.4-6.61-1.62-2.68-3.28-3.86-5.66-7.83-5.33-4.59.38-3.25 5.23-5.58 5.59-6.07.95-4.89 4.66-4.9 10.97 0 .35.18.87-.14 1.01-2.4 1.06-1.67 2.45-1.5 3.83.66 5.53-.26 6.83 2.12 10.15.29.41 1.76.94 2.3.82 3.35-.75 12.55 8 20.21.37h-.02Z"
      style={{
        fill: "url(#io)",
      }}
    />
    <Path
      d="M5104.23 283.83a20.784 20.784 0 0 1-5.05-8.22c-.52-.54-.83-1.17-.73-2.05.02-.19.03-.38.05-.57-.15-.77-.25-1.54-.31-2.32-1.7-3.18-2.74-6.64-2.58-10.64.01-.24.03-.47.05-.71-.47-.48-.89-1-1.26-1.56-1.1-.21-2.14-.6-3.04-1.18-4.12-2.64-8.46-4.73-12.94-6.47-.64 2.48-1.8 4.71-3.68 6.78-1.56 1.71-3.38 2.94-4.49 4.68-.34 5.63.26 12.55-.17 12.87h-.01c-2.4 1.8-1.67 4.15-1.5 6.49.66 9.36-.26 11.55 2.12 17.18.3.69 1.77 1.59 2.3 1.39 1.97-.75 2.53.76 3.68 1.56 5.85 4.05 11.32 7.84 16.53-.95 1.33 2.3 2.51 4.33 5.29 3.66 3.12-.75 5.48-2.85 6.13-5.69.95-4.13 1.38-8.36.91-12.53-.46-.55-.9-1.11-1.3-1.71Z"
      style={{
        fill: "url(#ip)",
      }}
    />
    <Path
      d="M4887.06 339.37c2.61.66 5.18 1.59 7.7 2.82a51.94 51.94 0 0 0-7.7-2.82Z"
      style={{
        fill: "url(#iq)",
      }}
    />
    <Path
      d="M4836.1 339.65c-48.8 5.54-97.99-.41-146.46-6.61-40.96-5.22-229.93-22.88-311.01-31.42-12.36-1.3-19.99 13.12-11.99 22.62l12.83 15.22c2.23 2.65 5.37 4.36 8.81 4.8 24.26 3.09 112.92 4.28 126.49 6.88 1.3.25 2.63.27 3.95.14 7.52-.71 15.03-.54 22.55.53.65.09 1.3.14 1.95.14 19.12-.04 38.23-.28 57.34-.65 74.79-1.43 149.53-2.89 224.33-3.52.89 0 1.78-.1 2.65-.28 2.15-.44 4.3-.92 6.44-1.43 16.8-4.04 35.74-11.13 53.1-6.7-16.37-4.6-34.41-1.6-50.96.28Z"
      style={{
        fill: "url(#ir)",
      }}
    />
    <Path
      d="M5119.85 292.33c-3.8-.15-7.23-1.27-10.12-3.12-1.6.45-3.27 1.39-4.15 2.17-.74.65-.06 2.35.92 2.5 2.22.34 8.92-.73 15.14-1.5-.6-.01-1.19-.02-1.79-.05Z"
      style={{
        fill: "url(#is)",
      }}
    />
    <Path
      d="M4531.63 285.54c.99-.07 2.22-.68 2.52-1.33 1.76-3.77 5.83-3.05 10.27-2.83 7.9.32 16.55 1.17 23.15-.77 5.31-1.51 9.24-3 15.12-.24 1.64-6.91 4.68-5.78 11.42-2.19-.32-1.18-1.2-2.51-.81-3.34 1.06-2.28 1.83-5.11 4.07-6.3 4.58-2.47 6.9 6.4 12.23 5.45 1.41-.27 2.64-1.2 4.01-2.04 2.23-1.36 5-2.08 7.97-3.3l1.63 1.07c-4.43-14.94-7.1-17.58-3.19-20.11.85-.55 3.12.22 4.82.41 1.49-.61 2.97-1.85 4.78-1.75 2.65.16 7.52 8.01 10.87 11.92 3.93 4.58 6.69 1.13 14.31 6.07 2.85 1.84 5.09 4.35 6.75 7.5 2.28-2.97 4.38-5.74 6.31-8.59.26-.38-3.04-6.45 5.48-4.31.74.19 1.89.38 2.2.11 3.71-3.23 6.59-1.08 8.86 2.21 8 2 2.76-1.66 17.48 11.86 3.99-2.31 11.65-5.92 12.02-9.64.9-8.84 14.57 1.02 21.52-.5 1.38-.3 11.24 6.47 15.95 6.77 5.6.37 10.69.12 15.79 4.2 2.51 2.01 27.56-7.46 30.35-4.21 1.48 1.73 2.54 3.89 2.46 5.59-.08 1.89-10.02 2.13-10.37 2.18-72.46 11.12-98.98 11.67-131.96 14.99-104.69 9.83-135.8.4-160.9-2.3v-6.43c12.16 1.89 22.72.76 34.89-.15Z"
      style={{
        fill: "url(#it)",
      }}
    />
  </Svg>
)}
export default SvgComponent
