import { Modal } from '@constants/Themed';
import React, { useState, useEffect } from 'react';
import { Text, View, Pressable, Platform, FlatList, Alert } from 'react-native';
import { useStripeTerminal } from '@stripe/stripe-terminal-react-native';
import styles from '@stylesheet';
import Button from '@components/UI/Button/Button';
import { ActivityIndicator, ProgressBar } from 'react-native-paper';
import { Title } from '@constants/Themed';
import AnimatedLottieView from 'lottie-react-native';
import { store } from '@reduxLocal/persistState';
import { SET_ACTIVE_READER } from '@reduxLocal/actions/types';
import { FontAwesome, AntDesign } from '@expo/vector-icons';
import * as Animatable from 'react-native-animatable';
import Checkbox from 'expo-checkbox';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import { FontAwesome5 } from '@expo/vector-icons';
import { deviceName } from 'expo-device';
import { Progress } from 'tamagui';

const ConnectModal = ({ modalOpen, setModalOpen }) => {
  const [visible, setVisible] = useState(modalOpen);
  const [availableReaders, setAvailableReaders] = useState([]);
  const [selectedReader, setSelectedReader] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const theme = themeSelector();
  const [loadingModal, setLoadingModal] = useState(false);
  const [ProgressBar, setProgressBar] = useState(0);
  const [helpText, setHelpText] = useState('');

  const { discoverReaders, connectedReader, connectLocalMobileReader, cancelDiscovering, disconnectReader } =
    useStripeTerminal({
      onUpdateDiscoveredReaders: (readers) => {
        console.log(readers)
        setAvailableReaders(readers);
        setLoading(false);
      },
      onDidChangeConnectionStatus: (status) => {
        if (status == 'connecting') {
          setLoadingModal(true);
          setHelpText('Connecting to reader...')
        }
        if (status == 'connected') {
          setHelpText('Connected to reader!')
          setIsConnected(true);
        } else setIsConnected(false);
        setErrorMessage("")
      },
      onDidReportReaderSoftwareUpdateProgressBar(ProgressBar) {
        console.log('ProgressBar', ProgressBar);
        setHelpText('Updating reader software...')
        const ProgressBarValue = parseFloat(ProgressBar.match(/\d+(\.\d+)?/)[0]);

        setProgressBar(ProgressBarValue);
      },
      onDidReportUnexpectedReaderDisconnect: (readers) => {
        alert("Reader disconnected, please check your connection and re-establish link");
        setSelectedReader({});
      }
    });

  useEffect(() => {
    if (connectedReader) {
      setIsConnected(true);
    }
    // readerDiscovery();
  }, []);


  const readerDiscovery = async () => {
    setErrorMessage('Looking for readers!')
    setLoading(true);
    const { error } = await discoverReaders({
      discoveryMethod: 'localMobile',
      simulated: false,
    });
    if (error) {
      setLoading(false)
      console.error(error);
      setErrorMessage(error)
      alert("Readers error occurred", `${error.message}`)
    } else { setLoading(false); setErrorMessage('') }
  }

  const connectReader = async () => {
    setProgressBar(0);
    setLoadingModal(true);
    setHelpText('Connecting to reader...');


    if (Platform.OS === 'android') {
      // Request bluetooth permission before connecting to reader
      const { status } = await Bluetooth.requestPermissionsAsync();
      if (status !== 'granted') {
        alert('Bluetooth permission is required to connect to reader');
        return;
      }
    }

    const { reader, error } = await connectLocalMobileReader({
      reader: selectedReader,
      locationId: 'tml_Fa5kLQ2TtWO4YD'
    });

    setLoadingModal(false);

    if (error) {
      console.log('connectBluetoothReader error', error);
      Alert.alert('Error connecting to the reader', error.message);
      setErrorMessage('Error connecting to the reader');
      return;
    }
    if (reader) {
      setHelpText('Connected to reader!');
      setIsConnected(true);
    }
  };

  const disconnectAndHandleError = async () => {
    try {
      await disconnectReader();
    } catch (error) {
      console.error('Error disconnecting reader:', error);
    } finally {
      setIsConnected(false);
    }
  };

  const handleReaderSelection = (item) => {
    console.log('the reader we are trying to select is', item);
    if (item) {
      setSelectedReader(item);
      // dispatch({ type: SET_ACTIVE_READER, payload: item.location?.id || 'tml_Fa4LTg7aPwZezi' });
    } else {
      setSelectedReader({});
      dispatch({ type: SET_ACTIVE_READER, payload: undefined });
    }
  }

  const RenderReaders = ({ item, index }) => {
    const isSelected = item === selectedReader;
    const deviceType = item.deviceType === 'appleBuiltIn';
    const backgroundColor = isSelected ? 'rgb(90,192,234)' : 'white';
    const isSimulated = item.simulated;

    const renderIcon = () => {
      if (deviceType && !isSimulated) {
        return <FontAwesome5 name="apple-pay" size={24} color="black" style={{ paddingVertical: 10 }} />;
      }
      return null;
    };

    return (
      <View style={[styles.centerEverything, { flexDirection: 'row', gap: 10 }]}>
        <Checkbox
          style={{ flex: 1, maxWidth: 20 }}
          value={isSelected}
          onValueChange={(value) => {
            console.log(value);
            if (value) {
              handleReaderSelection(item);
            } else {
              setSelectedReader({});
              handleReaderSelection({});
            }
          }}
        />
        <View style={{ flex: 5 }}>
          <View
            style={{
              backgroundColor,
              padding: 10,
              borderRadius: 10,
              shadowRadius: 5,
              shadowOpacity: 0.2,
              flexDirection: 'row',
              gap: 10,
            }}
          >
            {renderIcon()}
            <View>
              <Title style={{color:'black'}}>
                {deviceType && !isSimulated ? deviceName : ''} Reader {item.location?.displayName}
              </Title>
              <Text style={{ color: isSelected ? 'white' : 'black' }}>Reader {index}</Text>
            </View>
          </View>
        </View>
      </View>
    );
  };


  return (
    <Modal
      isVisible={modalOpen}
      style={{ padding: 0, maxWidth: '90%', overflow: 'visible' }}
    >
      <Pressable onPress={() => setVisible(setModalOpen(false))} style={{ minWidth: 40, minHeight: 40, position: 'absolute', top: -15, right: -15, borderRadius: 40, backgroundColor: 'white', zIndex: 2 }}>
        <AntDesign name="closecircle" size={40} color="red" />
      </Pressable>
      <Modal style={{ marginVertical: '70%' }} isVisible={loadingModal}>
        <View style={{ backgroundColor: theme ? 'white' : 'black', alignSelf: 'center', height: 100, width: 200, paddingVertical: '20%', flex: 1, gap: 15 }}>
          <View style={{ flex: 1 }}>
            <AnimatedLottieView source={require('@assets/animations/leaf_Loader.json')} autoPlay loop />
          </View>
          <Text style={{ textAlign: 'center' }}>{helpText}</Text>
          <Progress color={'rgb(90,192,234)'} ProgressBar={ProgressBar} />
        </View>
      </Modal>
      <Pressable onPress={() => setVisible(false)} style={{ position: 'absolute', top: -15, right: -15, borderRadius: 40, backgroundColor: 'white' }}>
        <AntDesign name="closecircle" size={40} color="red" />
      </Pressable>
      <View style={{ flex: 1, gap: 50, backgroundColor: 'rgb(90,192,234)', padding: 30, overflow: 'hidden', borderRadius: 20, minHeight: 300, minWidth: 300, alignSelf: 'stretch' }}>
        <View style={{ flex: 5 }}>
          {
            isConnected ?
              <View style={[styles.centerEverything]}>
                <AnimatedLottieView source={require('@assets/animations/connected_animation.json')} autoPlay loop={false} />
                <Animatable.View animation={'fadeIn'} style={{ borderRadius: 20, backgroundColor: 'white', alignSelf: 'stretch', padding: 10 }}>
                  <Title style={{color:'black'}}>Connected to {selectedReader.location?.displayName}</Title>
                </Animatable.View>
              </View>
              :
              <FlatList data={availableReaders} renderItem={({ item, index }) => (<RenderReaders item={item} index={index} />)} contentContainerStyle={{ padding: 10, paddingVertical: 40 }} />
          }
        </View>
        <View style={{ flex: 3, height: '80%' }}>
          {
            loading ?
              <ActivityIndicator />
              :
              <>
                {
                  errorMessage?.message ?
                    <View style={{ flex: 1, backgroundColor: 'white', gap: 20, padding: 20, borderRadius: 20 }}>
                      <Text style={{ color: 'red' }}>An error occurred</Text>
                      <Text>{errorMessage && errorMessage.message}</Text>
                      <Text>code: {errorMessage && errorMessage.code}</Text>
                    </View>
                    :
                    null
                }
              </>
          }
        </View>
        <View style={{ flex: 1, gap: 20, minHeight: 70, flexDirection: 'row' }}>
          {
            isConnected ?
              <Button portrait onPress={() => disconnectAndHandleError()} textColor='black' buttonText={"Disconnect"} icon={"link"} iconColor='black' customStyle={{ minWidth: '100%', flex: 1, shadowOpacity: 0.2, borderRadius: 10, minHeight: 60 }} defaultColor='white' />
              :
              <Button portrait onPress={() => connectReader()} textColor='black' buttonText={"Connect"} icon={"link"} iconColor='black' customStyle={{ minWidth: '100%', flex: 1, shadowOpacity: 0.2, borderRadius: 10, minHeight: 60 }} defaultColor='white' />
          }
          {
            loading ?
              <Animatable.View animation={'slideInUp'} duration={100}>
                <Button portrait onPress={() => { setAvailableReaders([]); cancelDiscovering() }} textColor='black' buttonText={"Cancel discovery"} icon={"closecircle"} iconColor='black' customStyle={{ minWidth: '100%', flex: 1, shadowOpacity: 0.2, borderRadius: 10, minHeight: 60 }} defaultColor='red' />
              </Animatable.View>
              :
              <>{
                !isConnected &&
                <Button portrait textColor='black' onPress={() => { setAvailableReaders([]); cancelDiscovering(); readerDiscovery() }} buttonText={"Refresh readers"} icon={<FontAwesome name="refresh" size={24} color="black" />} customStyle={{ minWidth: '100%', flex: 1, shadowOpacity: 0.2, borderRadius: 10, minHeight: 60 }} defaultColor='white' />
              }
              </>
          }
        </View>
      </View>
    </Modal>
  )
}
export default ConnectModal;