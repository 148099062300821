import { produce } from "immer";
import { FLUSH, persistStore } from 'redux-persist';
import { persistConfig, } from "../../persistState";

export function updateObject(oldObject, newValues) {
	// Encapsulate the idea of passing a new object as the first parameter
	// to Object.assign to ensure we correctly copy data instead of mutating
	return Object.assign({}, oldObject, newValues);
}

export function updateItemInArray(array, itemId, updateItemCallback) {
	const updatedItems = array.map((item) => {
		if (item.id !== itemId) {
			// Since we only want to update one item, preserve all others as they are now
			return item;
		}

		// Use the provided callback to create an updated item
		const updatedItem = updateItemCallback(item);
		return updatedItem;
	});


	return updatedItems;
}

export function clearPersistState() {
	store.dispatch({ type: FLUSH });
}

export function useImmer(oldState, action) {

}

export function replaceItemInArray(array, action) {
	return array.filter((item, index) => index !== action.index)
}

export function updateImmutableArray(oldState, action, callback) {
	const updatedState = produce(oldState, newState => {
		newState.tabs[0].id = action.id
		newState.tabs[0].page = action.page
	})
	const returnState = callback(updatedState);
	return returnState;
}