import { useSelector } from 'react-redux';
import {
	SHOW_AUTH_MODAL,
	SHOW_TC_MODAL,
	ACCEPT_TERMS,
	HIDE_TC_MODAL,
} from '@reduxLocal/actions/types';
import { DefaultRootState, store } from '@reduxLocal/persistState';

function select(state) {
	return state.modals.tcModal;
}

function handleChange(resolve, reject, unsubscribe) {
	return function () {
		const currentValue = select(store.getState());

		if (!currentValue.modalDisplayed) {
			store.dispatch({ type: HIDE_TC_MODAL });
			setTimeout(() => {
				store.dispatch({
					type: SHOW_AUTH_MODAL,
					data: {
						content:
							'You must accept the terms and conditions to continue.',
						type: 'error',
					},
				});
			}, 1000);
			unsubscribe(); // Unsubscribe after rejection
			reject(
				new Error(
					'You must accept the terms and conditions to continue.'
				)
			);
		}

		if (currentValue.accepted) {
			console.log('Detected state change', currentValue);
			unsubscribe(); // Unsubscribe after resolution
			resolve(true);
		}
	};
}

const getTCConfirm = async () => {
	const timeoutPromise = new Promise((_, reject) => {
		setTimeout(() => {
			store.dispatch({ type: HIDE_TC_MODAL });
			reject(new Error('Timeout: The operation took too long.'));
		}, 600000); // 10 minutes
	});

	return Promise.race([
		timeoutPromise,
		new Promise((resolve, reject) => {
			// Create the unsubscribe function before passing it to handleChange
			const unsubscribe = store.subscribe(() =>
				handleChange(resolve, reject, unsubscribe)()
			);
		}),
	]);
};

export default getTCConfirm;
