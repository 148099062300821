import { useEffect, useRef } from "react";
import LottieView from 'lottie-react-native';

export function useLottieAnim() {
  const animation = useRef<LottieView>(null);

  useEffect(() => {
    if (animation.current) {
      animation.current.play();
    }
    return () => {
      animation.current && animation.current.reset();
    };
  }, []);

  return animation;
}
