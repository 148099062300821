import React from 'react';
import { View, FlatList, Image, Animated, Pressable } from 'react-native';
import { Text } from '@constants/Themed';
import { connect, useDispatch } from 'react-redux'
import { Swipeable } from 'react-native-gesture-handler';
import { AntDesign } from '@expo/vector-icons'
import styles from '@stylesheet';
import { CLEAR_BASKET_ITEM } from '@reduxLocal/actions/types';
import { Badge } from "@mui/material";

const mapStateToProps = state => {
  return {
    basketItems: state.store.basketItems
  }
}

interface BasketProps {
  basketItems?: any
}

let BasketItems = ({ basketItems }: BasketProps) => {
  const swipeRef = React.useRef(null);
const dispatch = useDispatch();

  const _renderBasketItem = ({ item, index }) => {
    console.log(item);

    const renderLeftActions = () => {

      return (
        <Animated.View>
          <View style={{ zIndex: index * -1, marginVertical: 10, flexDirection: 'column', minHeight: '95%', alignSelf: 'stretch', justifyContent: 'center', borderTopRightRadius: 20, borderBottomRightRadius: 20, overflow: 'hidden', top: -6, left: -2 }}>
            <Pressable style={{ flex: 1, padding: 20, backgroundColor: 'red' }} onPress={async () => { dispatch({ type: CLEAR_BASKET_ITEM, payload: item._id }) }}>
              <AntDesign name="delete" size={15} color="white" style={[styles.centerEverything, { flex: 1, verticalAlign: 'middle', textAlign: 'center' }]} />
            </Pressable>
          </View>
        </Animated.View>
      );
    };

    const calculateTotal = (item.data.price * item.basketQuantity).toFixed(2);
    return(
      <Swipeable renderRightActions={renderLeftActions}>
        <View style={{ minHeight: 60, flexDirection: 'row', alignContent: 'space-around', flex: 1, padding: 4, paddingHorizontal: 10, alignSelf: 'stretch', gap: 10, backgroundColor: index % 2 ? '#f3f3f3' : undefined }}>
          <View style={{ flex: 1 }}>
            <Badge badgeContent={item.basketQuantity} color="primary" style={{ position: 'absolute', top: 0, right: 0 }}>
            <Image source={{ uri: item.dataImages[0] }} style={{ width: 50, height: 50, borderRadius: 10 }} />
            </Badge>
          </View>
          <View style={{ alignContent: 'flex-start', alignItems: 'flex-start' }}>
            <Text style={{ flex: 1, fontWeight: 'bold', color: 'black' }}>{item.data.product_name}</Text>
            <Text style={{ verticalAlign: 'top', flex: 4, color: 'black' }}>{item.data.description}</Text>
          </View>
          <Text style={{ flex: 1, textAlign: "right", color: 'black' }}>£ {calculateTotal}</Text>
        </View>
      </Swipeable>
    )
  }

  return (
    <View style={{ flex: 1.5, marginHorizontal: -3 }}>
      <FlatList
        ListHeaderComponent={(
          <View style={{ flex: 1 }}>
            <Text style={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center', marginVertical: 10 }}>Basket</Text>
          </View>
        )}
        data={basketItems}
        renderItem={_renderBasketItem}
        style={{ flex: 1 }}
        contentContainerStyle={{ gap: 3 }} />
    </View>
  )
}

BasketItems = connect(mapStateToProps)(BasketItems);

export default BasketItems;