import toTitleCase from "@hooks/toTitleCase";

export default function parseByDelimiter(string) {
  try {
    const myArray = string.split("_");
    const upperCaseArray = myArray.map(item => toTitleCase(item));
    const parsedString = upperCaseArray.join(" ");
    return parsedString
  } catch (e) {
    return string
  }
}