import * as React from "react"
import Svg, { Defs, LinearGradient, Stop, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      id="uuid-59b89d3e-cbb4-4753-b3e1-8a3e7547671f"
      xmlns="http://www.w3.org/2000/svg"
      width={22.78}
      height={20.07}
      viewBox="0 0 22.78 20.07"
      {...props}
    >
      <Defs>
        <LinearGradient
          id="uuid-1918c909-f4c7-4bff-9943-69091f75eb88"
          x1={-352.57}
          y1={410.35}
          x2={-351.7}
          y2={409.28}
          gradientTransform="matrix(22.78 0 0 -19.94 8035.96 8176.69)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0} stopColor="#5aedcc" />
          <Stop offset={1} stopColor="#4d53e2" />
        </LinearGradient>
      </Defs>
      <Path
        d="M8.69.17c-.08.06-.15.14-.2.25-.2.45-.15 1.4 0 1.86.16.5.57.59 1.03.59 3.46 0 6.93.02 10.39.04v14.18c0 .06-8.93-.02-9.66-.03-.68 0-1.45-.08-1.84.58-.51.86-.05 1.93.87 2.25.64.22 1.4.15 2.08.16.76.01 1.51.02 2.27.02 2.89 0 5.78-.07 8.66-.12.02 0 .48 0 .48-.01V.05C22.77.04 10.6.02 9.49.01c-.28 0-.58.01-.8.17zM5.7 5.75c-.99.73-2 1.45-2.98 2.21L1.12 9.2c-.08.06-1.06.86-1.12.8l5.7 4.27v-2.85c.02-.22 9.68 0 10.6 0 .55 0 .82-.21.82-.79 0-.95.37-2.11-.89-2.11-3.51.02-7.02.05-10.53.05V5.75z"
        id="uuid-35cce4dd-f043-4ddf-b02c-57d01eab51ad"
        fill="url(#uuid-1918c909-f4c7-4bff-9943-69091f75eb88)"
      />
    </Svg>
  )
}

export default SvgComponent
