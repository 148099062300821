import React, { useState } from 'react';
import { TextInput, View, Pressable } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import * as Animatable from 'react-native-animatable';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import styles from '@stylesheet';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import { debounce } from "lodash";
import apis from '@api/api';

const DataSearch = ({ callback }) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchingError, setSearchingError] = useState(false);
  const [searchingErrorMessage, setSearchingErrorMessage] = useState('');
  const [selection, setSelection] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [items, setItems] = useState([]);
  const theme = themeSelector();

  const [typeSelection, setTypeSelection] = useState();

  const handleSearchRegEx = async (text) => {
    await apis.searchRegEx(text).then((res) => {
        setSearchResults(res.data);
    })
  }

  const handler = debounce(handleSearchRegEx, 500)

  return (
    <View style={[styles.centerEverything, { flex: 1, flexDirection: 'row', backgroundColor: theme ? 'grey' : '#f3f3f3', borderRadius: 20, maxWidth: '100%' }]}>
      <Animatable.View transition={'flex'} style={{
        flex: selection ? 1 : 6, flexDirection: 'row', backgroundColor: selection ? (theme ? 'grey' : "black") : (theme ? "grey" : 'black')
      }}>
        <Pressable style={{borderTopStartRadius: 20, borderBottomStartRadius: 20}} onPress={() => setSelection(false)}>
          <MaterialCommunityIcons
            name="folder-search-outline"
            size={40}
            color={'white'}
            style={{ padding: 15, left: -5 }}
          />
        </Pressable>
        {
          !selection &&
          <TextInput
            placeholder={'Search for data'}
            placeholderTextColor={'white'}
            style={{ color: 'white', fontSize: 20, padding: 15, outlineStyle: "none" }}
            onChangeText={(text) => { setSearchValue(text); handler(text) }}
            value={searchValue} />
        }
      </Animatable.View>
      <View style={[styles.centerEverything, { flex: !selection ? 2 : 5 }]}>
        {
          selection ?
            <DropDownPicker
              items={[
                { label: 'UK', value: 'uk' },
                { label: 'France', value: 'france' },
              ]}
              open={open}
              setOpen={setOpen}
              value={value}
              placeholder='Select the type of data'
              searchable
              containerStyle={{ height: 40 }}
              style={{ backgroundColor: '#fafafa', maxWidth: '90%', marginHorizontal: 10, top: -4 }}
              onSelectItem={(item) => setValue(item.value)}
              setValue={setValue}
            />
            :
            <Pressable onPress={() => setSelection(true)}>
              <MaterialCommunityIcons name="folder-information" size={40} color={ "black" }/>
            </Pressable>
        }
      </View>
    </View>
  )
}
export default DataSearch;