import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={24}
    height={24}
    {...props}
  >
    <Path
      d="M166.77 307.58c3.65-13.92-6.9-26.58-20.27-26.58H84c4.2-23.94 24.15-42 41.16-42h18.31c10.66 0 19.93-7.92 20.93-18.53 1.15-12.15-8.48-22.47-20.41-22.47h-20.5c-43.66 0-82 42.43-82 91.02v13.15c0 11.46 9.37 20.83 20.83 20.83h83.91c9.51 0 18.11-6.22 20.53-15.42ZM156.56 177h31.12c11.45 0 20.82-9.37 20.82-20.82v-51.22c0-29.27-23.76-53.51-53.02-52.94-28.23.55-50.98 23.66-50.98 52.07v20.86c0 28.75 23.31 52.06 52.06 52.06Zm187 0h31.12c11.45 0 20.82-9.37 20.82-20.82v-51.22c0-29.27-23.76-53.51-53.02-52.94-28.23.55-50.98 23.66-50.98 52.07v20.86c0 28.75 23.31 52.06 52.06 52.06Zm32.94 21h-19.97c-10.66 0-19.93 7.92-20.93 18.53-1.15 12.15 8.48 22.47 20.41 22.47h18.84c16.8 0 36.96 18.06 41.16 42h-62.24c-9.51 0-18.11 6.22-20.53 15.42-3.65 13.92 6.9 26.58 20.27 26.58h84.17c11.46 0 20.83-9.37 20.83-20.83v-13.15c0-48.58-38.33-91.02-82-91.02Zm-84.9 146h-83.2c-45.9 0-83.09 37.13-83.2 83-.03 11.55 9.29 21 20.83 21h207.94c11.55 0 20.86-9.45 20.83-21-.11-45.88-37.3-83-83.2-83Zm-41.04-42h31.12c11.45 0 20.82-9.37 20.82-20.82v-51.22c0-29.27-23.76-53.51-53.02-52.94-28.23.55-50.98 23.66-50.98 52.07v20.86c0 28.75 23.31 52.06 52.06 52.06Z"
      style={{
        fill: "#fff",
      }}
    />
  </Svg>
)

export default SvgComponent
