import * as React from "react"
import Svg, { Defs, LinearGradient, Stop, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      id="uuid-2ec77422-f02b-40ba-8d33-9303b08d7d8a"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={30}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="-7.7 -3 23.58 23.33"
      preserveAspectRatio="xMinYMin meet"
      {...props}
    >
      <Defs>
        <LinearGradient
          id="uuid-06ad9daf-e89c-481b-ab62-cec7f6a04f4f"
          x1={-226.93}
          y1={277.12}
          x2={-226.06}
          y2={276.05}
          gradientTransform="matrix(4.84 0 0 -2.64 1106.76 750.22)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0} stopColor="#5aedcc" />
          <Stop offset={1} stopColor="#4d53e2" />
        </LinearGradient>
        <LinearGradient
          id="uuid-c6fecfff-df9f-4bcb-b750-a268cb349468"
          x1={-268.49}
          y1={407.17}
          x2={-267.68}
          y2={406.17}
          gradientTransform="matrix(15.94 0 0 -16.34 4280.02 6655.23)"
          xlinkHref="#uuid-06ad9daf-e89c-481b-ab62-cec7f6a04f4f"
        />
      </Defs>
      <Path
        d="M10.39 21.64c1.3.04 2.38-.98 2.42-2.28 0-.12 0-.24-.02-.36h-4.8c-.16 1.29.75 2.46 2.04 2.62.12.02.24.02.37.02z"
        transform="translate(-6.761 -3.93)"
        fill="url(#uuid-06ad9daf-e89c-481b-ab62-cec7f6a04f4f)"
      />
      <Path
        d="M18.17 16.47c-.94-1.25-1.8-2.06-2.07-3.7-.55-3.3.13-8.39-4.17-9.4-.18-.05-.31-.1-.31-.29v-.14c0-.67-.54-1.22-1.22-1.22s-1.22.54-1.22 1.22v.14c0 .18-.13.25-.31.29-2.91.73-3.73 3.76-3.94 6.4-.12 1.47-.03 2.94-.59 4.34-.39.97-1.11 1.55-1.72 2.36a.998.998 0 00.19 1.39c.17.13.38.2.6.2h13.96c.55 0 .99-.44.99-.99 0-.21-.07-.42-.2-.6z"
        transform="translate(-6.761 -3.93)"
        fill="url(#uuid-c6fecfff-df9f-4bcb-b750-a268cb349468)"
      />
    </Svg>
  )
}

export default SvgComponent
