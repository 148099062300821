import * as React from "react"

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.52 37.53" {...props}>
    <path
      d="M21.26 21.67 2.67 36.53c-1.28 0-2.08-1.39-1.44-2.5l9.29-16.1 9.3-16.1a1.662 1.662 0 0 1 2.88 0l9.3 16.1 9.3 16.1c.64 1.11-.16 2.5-1.44 2.5L21.27 21.67Z"
      style={{
        fill: "#72b855",
        stroke: "#347330",
        strokeMiterlimit: 10,
        strokeWidth: 2,
      }}
    />
    <path
      d="M21.25 20.39 2.77 36.14c-1.17 0-1.9-1.27-1.32-2.28l9.58-16.59L19.41 3.6c.59-1.01.74-1.58 1.77-1.58"
      style={{
        fill: "#b8ce76",
      }}
    />
  </svg>
)

export default SvgComponent
