import React from 'react';
import * as Animatable from 'react-native-animatable';
import { AntDesign } from '@expo/vector-icons';
import { Image } from 'react-native';
import { View } from 'tamagui'
import styles from '@stylesheet';
// import '../../../../../assets/css/style.css';
import { useNavigation } from '@react-navigation/native';
import { H4 } from 'tamagui';

const Packages = ({ open }) => {
  const navigation = useNavigation();

  return open && (
    <div className="card h-100 p-0 radius-12 overflow-hidden">
      <div style={{ borderRadius: 20 }} className="card-header border-bottom bg-base py-16 px-24">
        <h6 className="mb-0 text-lg">SmartSaaS Pricing Plan</h6>
      </div>
      <div style={{ paddingInline: 20 }} className="card-body p-40">
        <div className="row justify-content-center">
          <div className="col-xxl-10">
            <View style={[styles.centerEverything, { minHeight: 200 }]}>
              <h4 className="mb-16">Pricing Plan</h4>
              <p className="mb-0 text-lg text-secondary-light">No contracts. No surprise fees.</p>
            </View>
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-monthly" role="tabpanel" aria-labelledby="pills-monthly-tab" tabIndex={0}>
                <View style={{ flexDirection: "row", gap: 20 }} >
                  <Animatable.View style={{ maxWidth: 400 }} animation={'fadeInUp'} className="col-xxl-4 col-sm-6 pricing-plan-wrapper">
                    <div style={styles.centerEverything} className="pricing-plan position-relative radius-24 overflow-hidden border bg-lilac-100">
                      <div style={styles.centerEverything} className="d-flex align-items-center gap-16">
                        <span style={styles.centerEverything} className="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                          <Image style={{ width: 40, height: 40, alignContent: "center", resizeMode: "contain", marginHorizontal: "23%", alignSelf: "center", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 16, }} source={require("@assets/images/pricing/price-icon1.png")} alt="" />
                        </span>
                        <div className="">
                          <span className="fw-medium text-md text-secondary-light">For individuals</span>
                          <h6 className="mb-0">Basic</h6>
                        </div>
                      </div>
                      <p className="mt-16 mb-0 text-secondary-light mb-28">Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. </p>
                      <h3 className="mb-24">$99 <span className="fw-medium text-md text-secondary-light">/monthly</span> </h3>
                      <span className="mb-20 fw-medium">What’s included</span>
                      <ul>
                        <li className="d-flex align-items-center gap-16 mb-16">
                          <span style={{ borderRadius: 5, margin: 5 }} className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-lilac-600 rounded-circle"><AntDesign name="checkcircleo" size={24} color="white" /></span>
                          <span className="text-secondary-light text-lg">All analytics features</span>
                        </li>
                        <li className="d-flex align-items-center gap-16 mb-16">
                          <span style={{ borderRadius: 5, margin: 5 }} className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-lilac-600 rounded-circle"><AntDesign name="checkcircleo" size={24} color="white" /></span>
                          <span className="text-secondary-light text-lg">Up to 250,000 tracked visits</span>
                        </li>
                        <li className="d-flex align-items-center gap-16 mb-16">
                          <span style={{ borderRadius: 5, margin: 5 }} className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-lilac-600 rounded-circle"><AntDesign name="checkcircleo" size={24} color="white" /></span>
                          <span className="text-secondary-light text-lg">Normal support</span>
                        </li>
                        <li className="d-flex align-items-center gap-16">
                          <span style={{ borderRadius: 5, margin: 5 }} className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-lilac-600 rounded-circle"><AntDesign name="checkcircleo" size={24} color="white" /></span>
                          <span className="text-secondary-light text-lg">Up to 3 team members</span>
                        </li>
                      </ul>
                      <Button buttonText={"Get started"} defaultColor='purple' customStyle={{ marginHorizontal: "20%", justifyContent: "center", alignContent: "center", alignItems: "center", alignSelf: "center", marginVertical: 10, margin: 10, minHeight: 50, borderRadius: 15 }} onPress={() => { setOpen(false); navigation.navigate("Settings", { screen: "Upgrade" }) }} />
                    </div>
                  </Animatable.View>
                  <Animatable.View style={{ maxWidth: 400 }} delay={200} animation={'fadeInUp'}>
                    <div className="pricing-plan scale-item position-relative radius-24 overflow-hidden border bg-primary-600 text-white">
                      <span className="bg-white bg-opacity-25 text-white radius-24 py-8 px-24 text-sm position-absolute end-0 top-0 z-1 rounded-start-top-0 rounded-end-bottom-0">Popular</span>
                      <div className="d-flex align-items-center gap-16">
                        <span style={styles.centerEverything} className="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                          <Image style={{ width: 40, height: 40, alignContent: "center", resizeMode: "contain", marginHorizontal: "23%", alignSelf: "center", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 16, }} source={require("@assets/images/pricing/price-icon2.png")} alt="" />
                        </span>
                        <div className="">
                          <span className="fw-medium text-md text-white">For startups</span>
                          <h6 className="mb-0 text-white">Pro</h6>
                        </div>
                      </div>
                      <p className="mt-16 mb-0 text-white mb-28">Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. </p>
                      <h3 className="mb-24 text-white">$199 <span className="fw-medium text-md text-white">/monthly</span> </h3>
                      <span className="mb-20 fw-medium">What’s included</span>
                      <ul>
                        <li className="d-flex align-items-center gap-16 mb-16">
                          <span className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><AntDesign name="checkcircleo" size={24} color="white" /></span>
                          <span className="text-white text-lg">All analytics features</span>
                        </li>
                        <li className="d-flex align-items-center gap-16 mb-16">
                          <span className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><AntDesign name="checkcircleo" size={24} color="white" /></span>
                          <span className="text-white text-lg">Up to 250,000 tracked visits</span>
                        </li>
                        <li className="d-flex align-items-center gap-16 mb-16">
                          <span className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><AntDesign name="checkcircleo" size={24} color="white" /></span>
                          <span className="text-white text-lg">Normal support</span>
                        </li>
                        <li className="d-flex align-items-center gap-16">
                          <span className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-white rounded-circle text-primary-600"><AntDesign name="checkcircleo" size={24} color="white" /></span>
                          <span className="text-white text-lg">Up to 3 team members</span>
                        </li>
                      </ul>
                      <button type="button" className="bg-white text-primary-600 text-white text-center border border-white text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28" data-bs-toggle="modal" data-bs-target="#exampleModal"><p style={{ color: "white" }}>Get started</p></button>

                    </div>
                  </Animatable.View>
                  <Animatable.View animation={'fadeInUp'} style={{ maxWidth: 400 }}>
                    <div className="pricing-plan position-relative radius-24 overflow-hidden border bg-success-100">
                      <div className="d-flex align-items-center gap-16">
                        <span style={styles.centerEverything} className="w-72-px h-72-px d-flex justify-content-center align-items-center radius-16 bg-base">
                          <Image style={{ width: 40, height: 40, alignContent: "center", resizeMode: "contain", marginHorizontal: "23%", alignSelf: "center", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 16, }} source={require("@assets/images/pricing/price-icon3.png")} alt="" />
                        </span>
                        <div className="">
                          <span className="fw-medium text-md text-secondary-light">For big companies</span>
                          <h6 className="mb-0">Enterprise</h6>
                        </div>
                      </div>
                      <p className="mt-16 mb-0 text-secondary-light mb-28">Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. </p>
                      <h3 className="mb-24">Get a quote</h3>
                      <span className="mb-20 fw-medium">What’s included</span>
                      <ul>
                        <li>
                          <span className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><AntDesign name="checkcircleo" size={24} color="white" /></span>
                          <span className="text-secondary-light text-lg">All analytics features</span>
                        </li>
                        <li className="d-flex align-items-center gap-16 mb-16">
                          <span className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><AntDesign name="checkcircleo" size={24} color="white" /></span>
                          <span className="text-secondary-light text-lg">Up to 250,000 tracked visits</span>
                        </li>
                        <li className="d-flex align-items-center gap-16 mb-16">
                          <span className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><AntDesign name="checkcircleo" size={24} color="white" /></span>
                          <span className="text-secondary-light text-lg">Normal support</span>
                        </li>
                        <li className="d-flex align-items-center gap-16">
                          <span className="w-24-px h-24-px d-flex justify-content-center align-items-center bg-success-600 rounded-circle"><AntDesign name="checkcircleo" size={24} color="white" /></span>
                          <span className="text-secondary-light text-lg">Up to 3 team members</span>
                        </li>
                      </ul>
                      <button type="button" className="bg-success-600 bg-hover-success-700 text-white text-center border border-success-600 text-sm btn-sm px-12 py-10 w-100 radius-8 mt-28" data-bs-toggle="modal" data-bs-target="#exampleModal"><p style={{ color: "white" }}>Get started</p></button>
                    </div>
                  </Animatable.View>
                </View>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Packages;