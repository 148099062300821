import { StyleSheet } from 'react-native';

const buttonStyles = StyleSheet.create({
	button: {
		maxWidth: 300,
		maxHeight: 40,
		borderRadius: 17,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#58BFEA',
		shadowRadius: 10,
		shadowOpacity: 0.2,
		flex: 1,
	},
	disabledButton: {
		backgroundColor: '#848484',
	},
	outlineButton: {
		backgroundColor: 'white',
		borderWidth: 1,
	},
	buttonText: {
		fontWeight: 'bold',
		fontSize: 16,
	},
	iconContainer: {
		marginRight: 5,
	},
	portraitButton: {
		flexDirection: 'column',
	},
	webButton: {
		flex: 1,
		maxWidth: 300,
		padding: 10,
		shadowRadius: 20,
		shadowOpacity: 0.2,
	},
	mobileButton: {
		minWidth: 110,
	},
	rotateButton: {
		flexDirection: 'column',
		maxHeight: 80,
	},
});

export default buttonStyles;
