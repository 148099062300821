import * as React from "react"

const SvgComponent = ({ date }) => {
  let parsedDate = Date.parse(date);
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(parsedDate).toString();
  const weekDay = new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(parsedDate).toString();
  const year = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(parsedDate).toString();

  const shortDay = date.toString().split(',')
  const day = shortDay[0];
  return (
    <svg
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 98.77 125.42"
      width='50'
    >
      <defs>
        <style>
          {
            ".cls-3{fill:#1d1d1b;font-family:Montserrat-Bold,Montserrat}.cls-6,.cls-7{fill:#d8d6d6;stroke:#1d1d1b;stroke-miterlimit:10}.cls-7{fill:#fff}.cls-3{font-size:24px}"
          }
        </style>
      </defs>
      <g id="Layer_1-2">
        <path
          className="cls-6"
          d="M15.79 4.45h67.19C91.27 4.45 98 11.18 98 19.47V109c0 8.29-6.73 15.02-15.02 15.02H.76V19.47c0-8.29 6.73-15.02 15.03-15.02Z"
        />
        <path
          className="cls-7"
          d="M86.28 4.45H12.49C6.01 4.45.76 10.9.76 18.87v56.17c0 12.38 3.97 24.25 11.04 33.06 8.19 10.19 19.37 15.93 31.02 15.93h43.45c6.48 0 11.73-6.46 11.73-14.42V18.87c0-7.97-5.25-14.42-11.73-14.42Z"
        />
        <path
          style={{
            fill: "#cdcccc",
            opacity: 0.43,
          }}
          d="M1.16 23.76h96.38v2.42H1.16z"
        />
        <path
          style={{
            fill: "#c74e54",
            stroke: "#1d1d1b",
            strokeMiterlimit: 10,
          }}
          d="M.5 3.52h97.77v20.29H.5z"
        />
        <rect
          className="cls-7"
          x={3.49}
          y={0.5}
          width={5.18}
          height={10.55}
          rx={0.52}
          ry={0.52}
        />
        <rect
          className="cls-7"
          x={89.83}
          y={0.5}
          width={5.18}
          height={10.55}
          rx={0.52}
          ry={0.52}
        />
        <text
          id="day"
          className="cls-3"
          transform="matrix(1.02 0 0 1 22.14 44.38)"
        >
          <tspan x={0} y={0}>
            {day}
          </tspan>
        </text>
        <text
          id="year"
          transform="matrix(1.02 0 0 1 26.64 20)"
          style={{
            fontSize: 19,
            fill: "#fff",
            fontFamily: "Montserrat-Bold,Montserrat",
          }}
        >
          <tspan
            x={0}
            y={0}
            style={{
              letterSpacing: 0,
            }}
          >
            {year}
          </tspan>
        </text>
        <text
          id="month"
          className="cls-3"
          transform="matrix(1.02 0 0 1 22.81 116.37)"
        >
          <tspan x={0} y={0}>
            {month}
          </tspan>
        </text>
        <text
          id="date"
          transform="matrix(1.02 0 0 1 23.68 89.33)"
          style={{
            fontFamily: "Montserrat-Bold,Montserrat",
            fill: "#1d1d1b",
            fontSize: 50,
          }}
        >
          <tspan x={0} y={0}>
            {weekDay}
          </tspan>
        </text>
        <path
          className="cls-6"
          d="M21.19 116.84c-3.86-4.59-3.5-11.75-3.5-11.75-6.5.24-10.16-3.78-10.16-3.78s2.18 4.19 5.07 7.71c3.55 4.33 8.6 7.81 8.6 7.81Z"
        />
      </g>
    </svg>
  )
}


export default SvgComponent
