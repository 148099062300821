import { CLOSE_MENU, CLOSE_SECOND_MENU, MENU_RESIZE, SHOW_HELP_MODAL, TOGGLE_ADVANCED_MODE } from '@reduxLocal/actions/types';
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { View, Text, Animated, Pressable, Alert, useWindowDimensions } from 'react-native';
import styles from '@stylesheet';
import { useSelector, useDispatch, connect } from 'react-redux';
import { DefaultRootState, store, persistor } from '@reduxLocal/persistState';
import * as Animatable from 'react-native-animatable';
import LottieView from 'lottie-react-native';
import { useLottieAnim } from '@hooks/lottieHook';
import superAdmin from './Components/dynamicMenuList';
import { LinearGradient } from 'expo-linear-gradient';
import { ScrollView } from 'react-native-gesture-handler';
import StaticMenuHeader from './Components/StaticMenuHeader';
import PrimaryList from './Components/PrimaryList';
import { useNavigation } from '@react-navigation/native';
import SecondaryMenuList from './subcomponents/SecondaryMenuList';
import PrimaryMenu from './Components/PrimaryMenu';
import apis from '@api/api';
import { LOGGED_OUT } from '@reduxLocal/actions/types';
import toTitleCase from '@hooks/toTitleCase';
import MenuWarper, { MenuWarperHandle } from './Components/MenuWarp';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import { selectUser } from '@reduxLocal/selectors/userSelectors';
import { SmastSaaSWhite } from '@assets/--native';
import Constants from 'expo-constants';
import { Separator } from 'tamagui';

const SecondMenu = () => {
	const dispatch = useDispatch();
	const secondaryMenu = useSelector((state: DefaultRootState) => state.UI.secondaryMenuOpen);
	const theme = themeSelector();
	return secondaryMenu && (
		<Animatable.View
			duration={secondaryMenu ? 500 : 1000}
			direction={secondaryMenu ? 'normal' : 'reverse'}
			transition="maxWidth"
			easing={'ease-in-out-quint'}
			style={{ position: 'relative', maxWidth: secondaryMenu ? 80 : 0, minWidth: secondaryMenu ? 60 : 0, borderRadius: 20, borderTopStartRadius: 0, borderBottomStartRadius: 0, marginVertical: "80%", backgroundColor: theme ? '#008080' : '#2c6273', elevation: 1, flexDirection: 'column', zIndex: -40, flex: 1, alignContent: 'stretch', justifyContent: 'flex-start', marginRight: secondaryMenu ? -45 : 0, }}>
			<LinearGradient
				// Background Linear Gradient
				colors={theme ? ['#1E90FF', 'transparent'] : ['#333333', 'black']}
				style={{ flex: 1, borderRadius: 20, borderTopStartRadius: 0, borderBottomStartRadius: 0, justifyContent: 'center', flexDirection: 'column' }}>
				<View style={{ alignSelf: 'center', overflow: "hidden", borderRadius: 20, borderTopStartRadius: 0, borderBottomStartRadius: 0, flex: 16, paddingTop: 50, alignItems: 'center', paddingVertical: '100%' }}>
					<SecondaryMenuList />
				</View>
			</LinearGradient>
		</Animatable.View >
	)
}
const Menu = ({ theme, menuType, messageCount }) => {
	const navigation = useNavigation();
	const dispatch = useDispatch();
	const { width } = useWindowDimensions();

	const _handleLogout = useCallback(async () => {
		navigation.replace('LoadingScreen', { params: { flagHold: true } })
		await apis.logoutUser()
			.then(async () => {
				dispatch({ type: LOGGED_OUT }) //Clears redux store
				await persistor.purge().then(() => {
					navigation.replace('Login');
				});
			});
	}, [])

	const MainMenu = () => {
			const menuOpen = width < 500 ? false : useSelector((state: DefaultRootState) => state.UI.menuOpen);
		const secondaryMenu = useSelector((state: DefaultRootState) => state.UI.secondaryMenuOpen);
			const animation = useLottieAnim();
			const dispatch = useDispatch();
			const [mailCount, setMailCount] = useState(0);
			const defaultMenuArray = useMemo(() => superAdmin, []);
			const filteredMenu = defaultMenuArray.filter(menu => menu.menuType === menuType)
			const returnMenu = filteredMenu[0].menu;
			const [newMenuArray, setNewMenuArray] = useState(returnMenu);
			const [forceSmall, setForceSmall] = useState(false);
			const menuWarper = useRef<MenuWarperHandle>(null);
			const { name, surname } = selectUser();
			const animation3 = useLottieAnim();


		const menuWidth = useRef(new Animated.Value(menuOpen ? 230 : 70)).current;

		useEffect(() => {
			Animated.timing(menuWidth, {
				toValue: menuOpen ? 230 : 60,
				duration: 150,
				useNativeDriver: false
			}).start();
		}, [menuOpen]);

			const RenderPrimary = () => useMemo(() => {
				return (<PrimaryList />)
			}, [newMenuArray])

			const _showWarper = useCallback(() => {
				menuWarper.current?.toggleVisibility?.();
			}, [menuWarper]);

			useEffect(() => {
				if (window.innerWidth < 500) {
					console.log("Force small menu")
					dispatch({ type: CLOSE_MENU });
				}
				const unsubscribe = store.subscribe(() => {
					const action = store.getState().lastAction; // Access the last dispatched action
					if (action && action.type === 'OPEN_WARPER') {
						_showWarper();
					}
					if (action && action.type === 'CLOSE_WARPER') {
						_showWarper();
					}
					if (action && action.type === 'HIDE_FIRST_TUTORIAL') {
						// Perform any necessary cleanup here
						unsubscribe(); // Unsubscribe from the store
					}
				});

					// Return a cleanup function to unsubscribe from the store
					return () => unsubscribe();
				}, []);

			return useMemo(() => (
				<LinearGradient
					colors={theme ? ['#1E90FF', '#338BA8'] : ['#333333', 'black']}
					style={{ maxWidth: menuOpen ? 250 : 65, borderRadius: 20, shadowRadius: 20, shadowOpacity: .4, margin: 5, marginRight: 0, flex: 1, flexDirection: 'row', zIndex: 10 }}
				>
					{
						width > 500 &&
						<View style={{ position: "absolute", top: -8, left: -32, flexDirection: 'column', justifyContent: 'flex-start', alignContent: 'flex-start', flex: 0.00001, zIndex: -10 }}>
							<Animated.View style={{ zIndex: -1, left: menuWidth, maxWidth: 70 }}>
								<Pressable
									onPress={() => {
										if (menuOpen) {
											Animated.timing(menuWidth, {
												toValue: 60,
												duration: 150,
												useNativeDriver: false
											}).start(() => { dispatch({ type: MENU_RESIZE }) })
										} else {
											dispatch({ type: MENU_RESIZE })
										}
									}}
									disabled={forceSmall}
									onHoverIn={() => { animation.current?.reset(), animation.current?.play() }}
									style={{ flex: 2, top: 20, maxWidth: 60, left: menuOpen ? 5 : 20 }}>
									<LottieView key={1} ref={animation} style={[styles.menuHideShow, { borderTopStartRadius: 5, borderBottomStartRadius: 5, width: 35, backgroundColor: theme ? '#58BFEA' : '#3a8099' }]} source={require('@assets/animations/LordIcons/35-compare-solid-edited.json')} autoPlay ></LottieView>
								</Pressable>
							</Animated.View>
						</View>
					}
					<Animated.View
						style={{ width: menuWidth, minWidth: menuWidth, maxWidth: menuOpen ? 200 : 65, elevation: 1, flexDirection: 'column', zIndex: 100, flex: 3 }}>
						<Animatable.View transition={'minWidth'} style={[styles.centerEverything, { maxHeight: 60, marginVertical: 10, flex: 0.1, overflow: "visible", flexDirection: "row" }]}>
							<Pressable onLongPress={() => dispatch({ type: TOGGLE_ADVANCED_MODE })} onPress={() => navigation.navigate('Dashboard')} style={{ flex: 1, alignSelf: 'stretch', padding: 2, paddingTop: 10 }}>
								<SmastSaaSWhite />
							</Pressable>
							<Animatable.View transition={'width'} style={{ width: menuOpen ? 120 : 0.01 }}>
								{
									menuOpen &&
									<Animatable.View animation={'fadeIn'} style={{ gap: 5 }}>
										<Text style={{ userSelect: "none", fontSize: 14, fontWeight: "bold", textAlign: 'center', color: "white" }}>SmartSaaS</Text>
										<Text style={{ userSelect: "none", fontSize: 12, textAlign: 'center', color: "white" }}>Version: {Constants.expoConfig?.version}</Text>
									</Animatable.View>
								}
							</Animatable.View>
						</Animatable.View>
						<Separator borderColor={"white"} marginHorizontal={10} shadowRadius={20} shadowColor={"white"} opacity={0.5} />
						<Animatable.View transition={'flex'} style={[styles.centerEverything, { flex: menuOpen ? .15 : 0.35, zIndex: 2, top: menuOpen ? 10 : undefined, paddingTop: 17, maxHeight: !menuOpen ? 170 : 170 }]}>
							{/* <Pressable style={{ flex: 2, top: -22, left: -7, minHeight: "15%", maxHeight: !menuOpen ? "35%" : "70%" }} onPress={() => { navigation.replace('Settings', { screen: 'Profile' }) }} >
											<GetAvatarImage style={{ flex: 1, backgroundColor: theme ? 'white' : 'black', borderColor: availabilityColor(availableState), borderRightWidth: 5, borderBottomWidth: 5, minWidth: menuOpen ? "100%" : "170%", minHeight: "100%", width: "100%", padding: 10, borderRadius: 0, borderBottomRightRadius: 26, top: 12, left: -3, aspectRatio: 1 }} />
										</Pressable> */}
								{/* TODO Resolve changing menu */}
								<StaticMenuHeader filteredMenu={filteredMenu} newMenuArray={newMenuArray} theme={theme} menuOpen={menuOpen} navigation={navigation} messageCount={messageCount} />
								{/*MENU GENERATED SECTION! */}
						</Animatable.View >
						<Separator borderColor={"white"} marginHorizontal={10} shadowRadius={20} shadowColor={"white"} opacity={0.5} />
						<Animated.View style={{ flex: 0.15, maxHeight: 70, width: menuWidth, alignSelf: 'center' }}>
								<Pressable
								//TODO ADD REMAINING MENUS TO DYNAMIC MENU LIST !
								// disabled={true}
									onPress={_showWarper}
									onHoverIn={() => { animation3.current?.reset(), animation3.current?.play() }}
								style={{ flex: 1, minHeight: 40, flexDirection: 'row', paddingHorizontal: 5, alignContent: 'center', alignItems: 'center', gap: 5 }}
									hitSlop={15}>
								<LottieView key={4} ref={animation3} style={[{ left: 5, width: 40, borderRadius: 100, elevation: 1 }]} source={require('@assets/animations/1865-shooting-stars-outline-edited.json')} colorFilters={[{ keypath: 'Star-3', color: 'white' }, { keypath: 'Star-2', color: 'white' }, { keypath: 'Star-1', color: 'white' }, { keypath: 'outline 20', color: 'white' }, { keypath: 'outline 21', color: 'white' }, { keypath: 'outline 18', color: 'white' }, { keypath: 'outline 19', color: 'white' }, { keypath: 'outline 17', color: 'white' }, { keypath: 'outline 16', color: 'white' }, { keypath: 'outline 15', color: 'white' }]} loop={false} />
									{
										menuOpen &&
									<Animatable.Text selectable={false} direction={'alternate'} animation={'fadeIn'} style={{ flex: 1, textAlign: 'center', color: 'white', fontWeight: '600', justifyContent: 'center', verticalAlign: 'middle', fontSize: 16 }}>{filteredMenu[0].menuLabel}</Animatable.Text>
									}
								</Pressable>
							<Separator borderColor={"white"} marginHorizontal={10} shadowRadius={20} shadowColor={"white"} opacity={0.5} />
							</Animated.View>
							<LinearGradient
								// Background Linear Gradient
								colors={theme ? ['#1E90FF', 'transparent'] : ['#333333', 'black']}
							style={{ flex: 2, top: -10, flexDirection: 'column', borderBottomRightRadius: 1000, zIndex: 1 }}>
								<MenuWarper ref={menuWarper} />
							<View style={{ flex: 1, flexGrow: 2, zIndex: 1, flexDirection: 'column' }}>
								<ScrollView
										showsVerticalScrollIndicator={false}
									contentContainerStyle={{ paddingVertical: 10, paddingLeft: 5 }}
									>
										<RenderPrimary />
									</ScrollView>
								<Separator borderColor={"white"} marginHorizontal={10} shadowRadius={20} shadowColor={"white"} opacity={0.5} />
								<View style={{ minHeight: 200, gap: 5, maxHeight: 200, alignSelf: 'stretch', justifyContent: 'flex-end', flex: 7, paddingLeft: 10, borderTopColor: 'rgba(255,255,255,0.5)', paddingTop: 20, top: 5, alignContent: 'flex-end' }}>
										<PrimaryMenu item={{ key: 'user', icon: require('@assets/animations/automation_robot.json'), header: 'AI', function: () => window.alert("Coming Soon!") }} />
										<PrimaryMenu item={{ key: 'user', icon: require('@assets/animations/111742-diamond-upgrade-reward-animation.json'), header: 'menuUpgrade', function: () => navigation.navigate("Settings", { screen: "Upgrade" }) }} />
										<PrimaryMenu item={{ key: 'user', icon: require('@assets/animations/LordIcons/account.json'), header: `${toTitleCase(name)} ${toTitleCase(surname)}`, function: () => navigation.navigate('Settings') }} />
										<PrimaryMenu item={{ key: 'user', icon: require('@assets/animations/LordIcons/logout.json'), header: 'logout', function: _handleLogout }} />
									</View>
								</View>
							</LinearGradient>
						</Animated.View >
				</LinearGradient >
				), [menuOpen, newMenuArray, secondaryMenu, forceSmall, messageCount, menuType, filteredMenu, menuWarper]);
		}



	const MenuCompose = () => {
		return (
			<View style={{ zIndex: 10, height: '100%', maxHeight: '100%', alignSelf: 'stretch', flexDirection: 'row' }} >
				<MainMenu />
				{/* SECONDARY MENU */}
				<SecondMenu />
			</View>
		)
	}
	return MenuCompose();
}



const mapStateToProps = function (state) {
	return {
		theme: state.app.theme.lightTheme,
		mailAuthenticated: state.app.mail.mailAuthenticated,
		accessToken: state.promiseData.google?.googleCredentials?.accessToken,
		menuType: state.navigation.menuType,
		messageCount: state.app.mail.mailCount,
		accessLevel: state.promiseData.data.userData.access
	}
}

export default connect(mapStateToProps)(Menu);