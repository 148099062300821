import { createContext } from "react";

export const ToolbarContext = createContext({
  calendarContext: null,
  calendarHeader: null,
  setCalendarHeader: (header: any) => { },
  setCalendarContext: (month: Date) => { }, // Add parentheses and return an object
  setHierarchySocket: (socket) => { },
  hierarchySocket: null,
  ref: null,
  draggedItem: null,
  setDraggedItem: (item: any) => { },
  setRef: () => { },
  optionsRef: null,
  setOptionsRef: () => { },
  reportRef: null,
  setReportRef: () => { },
  projectContext: null,
  setProjectContext: (project) => { }
});
