import * as React from "react"
import Svg, { SvgProps, G, LinearGradient, Stop, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props: SvgProps) => (
  <Svg
    id="uuid-2419925a-7c93-4159-a326-c0d8537966ef"
    xmlns="http://www.w3.org/2000/svg"
    width={5000}
    height={1080}
    {...props}
    viewBox="35 0 199 945"
    preserveAspectRatio="xMinYMid meet"
    pointerEvents={'none'}
  >
    <G id="uuid-32facdd9-c38c-402b-85bd-add3420aa4ed">
      <LinearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1={105.425}
        y1={158.618}
        x2={105.425}
        y2={939.04}
        gradientTransform="matrix(1 0 0 -1 0 948)"
      >
        <Stop
          offset={0}
          style={{
            stopColor: "#8bbedc",
          }}
        />
        <Stop
          offset={1}
          style={{
            stopColor: "#58bfea",
          }}
        />
      </LinearGradient>
      <Path
        d="M28 567.8c-1.1-2.9-1.8-6.1-2-9.3-.5-7.4.2-14.9.4-22.3.1-7.5.1-15.1.1-22.6V337c0-94.8 68.7-180.5 113.2-211.5C203.8 80.7 202 9 202 9L8.9 9.1v679.1-36.9 138.1c12.4-.9 46.3-4.3 52.4-15.1 3.5-6.1 3.8-13.3 4-20.3 2.2-63.9 4.4-68.7 6.6-132.6.2-4.8.3-9.6-.9-14.3-2.9-10.7-13-15.5-20.5-22.6-5-4.8-20.2-10.3-22.5-16.7z"
        style={{
          fill: "url(#SVGID_1_)",
          enableBackground: "new",
        }}
      />
    </G>
    <LinearGradient
      id="SVGID_00000047762949342346963950000017653660730555649927_"
      gradientUnits="userSpaceOnUse"
      x1={178.46}
      y1={8.592}
      x2={44.46}
      y2={192.592}
    >
      <Stop
        offset={0}
        style={{
          stopColor: "#49aed8",
        }}
      />
      <Stop
        offset={1}
        style={{
          stopColor: "#36758e",
        }}
      />
    </LinearGradient>
    <Path
      style={{
        fill: "url(#SVGID_00000047762949342346963950000017653660730555649927_)",
      }}
      d="M201.3 9c2 18.3 1.6 32.6-10.1 59.7-21.8 50.6-68.1 71.4-84.8 78.9C80.1 159.4 9 175.3 9 175.3v-17.2c16.5-3.8 19.9-4.6 46.2-16.4 16.7-7.5 63-28.3 84.8-78.9 11.6-27.1 12-3.4 10-53.7l51.3-.1z"
    />
  </Svg>
)

export default SvgComponent
