import { GB, DE, ES, GR, JP, TR, AR, CN, IT } from "../../../../../assets/--native/index";
import { View } from 'react-native';
import styles from "../../../../../stylesheet";
const lang = [
  {
    flag: 'GB',
    language: 'English',
    label: 'English',
    languageCode: 'en-GB',
    value: 'en-GB',
    source: <View style={styles.flagContainer}><GB style={styles.flagContainer} /></View>,
  },
  {
    flag: 'DE',
    language: 'Deutsch',
    label: 'Deutsch',
    languageCode: 'de',
    value: 'de',
    source: <View style={styles.flagContainer}><DE style={styles.flagContainer} /></View>,
  },
  {
    flag: 'ES',
    language: 'Espanol',
    label: 'Espanol',
    languageCode: 'es',
    value: 'es',
    source: <View style={styles.flagContainer}><ES style={styles.flagContainer} /></View>,
  },
  {
    flag: 'GR',
    language: 'Greek',
    label: 'Greek',
    languageCode: 'el',
    value: 'el',
    source: <View style={styles.flagContainer}><GR style={styles.flagContainer} /></View>,
  },
  {
    flag: 'JP',
    language: 'Japanese',
    label: 'Japanese',
    languageCode: 'ja',
    value: 'ja',
    source: <View style={styles.flagContainer}><JP style={styles.flagContainer} /></View>,
  },
  {
    flag: 'TR',
    language: 'Turkish',
    label: 'Turkish',
    languageCode: 'tk',
    value: 'tk',
    source: <View style={styles.flagContainer}><TR style={styles.flagContainer} /></View>,
  },
  {
    flag: 'UAE',
    language: 'Arabic',
    label: 'Arabic',
    languageCode: 'ar',
    value: 'ar',
    source: <View style={styles.flagContainer}><AR style={styles.flagContainer} /></View>,
  },
  {
    flag: 'CN',
    language: 'Chinese',
    label: 'Chinese',
    languageCode: 'zh',
    value: 'zh',
    source: <View style={styles.flagContainer}><CN style={styles.flagContainer} /></View>,
  },
  {
    flag: 'IT',
    language: 'Italian',
    label: 'Italian',
    languageCode: 'it',
    value: 'it',
    source: <View style={styles.flagContainer}><IT style={styles.flagContainer} /></View>
  }
];
export default lang;
