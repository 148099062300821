import { updateObject } from './utils/globalStateFunctions';
import {
	ADD_BASKET_ITEM,
	REMOVE_BASKET_ITEM,
	CLEAR_BASKET,
	SET_STORE_ID,
	UPDATE_PRODUCT_LIST,
	CLEAR_PRODUCT_LIST,
	UPDATE_READERS,
	REMOVE_READER,
	ADD_READER,
	SET_ACTIVE_READER,
	CLEAR_BASKET_ITEM
} from '../actions/types';

const INITIAL_STATE = {
	basketItems: [],
	totalCost: 0,
	vat: 0,
	storeId: '',
	productList: [],
	readers: [],
	activeReaderID: '',
};

const findItemIndex = (basketItems, itemId) => {
	return basketItems.findIndex((item) => item._id === itemId);
};

const storeReducer = (state = INITIAL_STATE || null, action) => {
	switch (action.type) {
		case SET_STORE_ID:
			return updateObject(state, {
				...state,
				storeId: action.payload,
			});
		case SET_ACTIVE_READER:
			return updateObject(state, {
				...state,
				activeReaderID: action.payload,
			});
		case ADD_BASKET_ITEM:
			const existingItemIndex = findItemIndex(
				state.basketItems,
				action.payload._id
			);

			if (existingItemIndex !== -1) {
				// Item already exists, update quantity
				const updatedBasketItems = state.basketItems.map(
					(item, index) => {
						if (index === existingItemIndex) {
							return {
								...item,
								basketQuantity: item.basketQuantity + 1,
							};
						} else {
							return item;
						}
					}
				);

				return updateObject(state, {
					...state,
					basketItems: updatedBasketItems,
				});
			} else {
				// Item doesn't exist, add with quantity 1
				const newItem = { ...action.payload, basketQuantity: 1 };
				return updateObject(state, {
					...state,
					basketItems: [...state.basketItems, newItem],
				});
			}
		case ADD_READER:
			return updateObject(state, {
				...state,
				readers: [...state.readers, action.payload],
			});
		case REMOVE_READER:
			const removalIndex = state.readers.findIndex(
				(reader) => reader._id === action.payload._id
			);
			if (removalIndex !== -1) {
				// Item exists, update quantity or remove
				const updatedReaders = state.readers
					.map((reader, index) => {
						if (index === removalIndex) {
							return null;
						} else {
							return reader;
						}
					})
					.filter((reader) => reader !== null);

				return updateObject(state, {
					...state,
					readers: updatedReaders,
				});
			} else {
				// Item doesn't exist, do nothing
				return state;
			}
		case UPDATE_PRODUCT_LIST:
			return updateObject(state, {
				...state,
				productList: action.payload,
			});
		case CLEAR_PRODUCT_LIST:
			return updateObject(state, {
				...state,
				productList: [],
			});
			case CLEAR_BASKET_ITEM: 
			const removeIndex = findItemIndex(
				state.basketItems,
				action.payload
			);

			if (removeIndex !== -1) {
				// Item exists, update quantity or remove
				const updatedBasketItems = state.basketItems
					.map((item, index) => {
						if (index === removeIndex) {
							return null;
						} else {
							return item;
						}
					})
					.filter((item) => item !== null);

				return updateObject(state, {
					...state,
					basketItems: updatedBasketItems,
				});
			} else {
				// Item doesn't exist, do nothing
				return state;
			}
		case REMOVE_BASKET_ITEM:
			const indexToRemove = findItemIndex(
				state.basketItems,
				action.payload._id
			);


			if (indexToRemove !== -1) {
				// Item exists, update quantity or remove
				const updatedBasketItems = state.basketItems
					.map((item, index) => {
						if (index === indexToRemove) {
							if (item.basketQuantity > 1) {
								// If quantity > 1, decrement quantity
								return {
									...item,
									basketQuantity: item.basketQuantity - 1,
								};
							}
							// If quantity === 1, remove item
							return null;
						} else {
							return item;
						}
					})
					.filter((item) => item !== null);

				return updateObject(state, {
					...state,
					basketItems: updatedBasketItems,
				});
			} else {
				// Item doesn't exist, do nothing
				return state;
			}
		case SET_STORE_ID:
			return updateObject(state, {
				...state,
				storeId: action.payload,
			});
		case CLEAR_BASKET:
			return updateObject(state, {
				...state,
				basketItems: [],
			});

		default:
			return state;
	}
};

export default storeReducer;
