import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={24}
    height={24}
    {...props}
  >
    <Path
      d="m429.29 399.8-75.49-75.49c21.02-27.25 33.66-61.28 33.66-98.23 0-89.15-72.49-161.43-161.43-161.43S64.6 136.92 64.6 226.08s72.49 161.43 161.43 161.43c37.01 0 71.07-12.69 98.34-33.74l75.47 75.49c4.06 4.06 9.39 6.1 14.73 6.1s10.66-2.04 14.73-6.1c8.14-8.14 8.14-21.31 0-29.45ZM226.03 106.3c66.03 0 119.77 53.74 119.77 119.77s-53.74 119.77-119.77 119.77-119.77-53.74-119.77-119.77S160 106.3 226.03 106.3Z"
      style={{
        fill: "#fff",
      }}
    />
  </Svg>
)

export default SvgComponent
