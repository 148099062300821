import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={24}
    height={24}
    {...props}
  >
    <Path
      d="m414.37 346.02-35.83-71.66c-1.46-2.5-2.08-5.39-2.08-8.52v-55.43c0-62.28-45.83-114.13-105.61-123.92V61.12c0-11.5-9.31-20.83-20.83-20.83s-20.83 9.33-20.83 20.83v25.37c-59.78 9.79-105.61 61.64-105.61 123.92v55.43c0 3.12-.62 6.02-2.08 8.52l-35.83 71.66c-3.33 6.46-2.92 14.19.83 20.23 3.75 6.25 10.41 10 17.71 10h291.62c7.29 0 13.96-3.75 17.71-10 3.75-6.04 4.17-13.77.83-20.23Zm-143.52 61.45h-86.44c6.87 29.79 33.74 52.07 65.61 52.07s58.74-22.29 65.61-52.07h-44.78Z"
      style={{
        fill: "#fff",
      }}
    />
  </Svg>
)

export default SvgComponent
