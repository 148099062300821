import * as AppleAuthentication from 'expo-apple-authentication';
import { View, Platform, StyleSheet } from 'react-native';
import { DefaultRootState } from '@reduxLocal/persistState';
import { navigationRef } from '../../../../rootNavigation';
import apis from '@api/api';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import DeviceInfo from 'react-native-device-info';
import getTCConfirm from '@components/modals/TCModal/utils/getTCConfirm';
import { HIDE_LOADING_MODAL, INITIALISE_MENU, SHOW_AUTH_MODAL, SHOW_LOADING_MODAL, SHOW_OTP, SHOW_TC_MODAL, SUCCESS } from '@reduxLocal/actions/types';


const AppleButton = () => {
  const useNavigation = navigationRef;
  const notificationToken = useSelector(
    (state: DefaultRootState) => state.app.notificationToken
  );
  const dispatch = useDispatch();

  // Centralized error handler
  function handleError(message) {
    console.error(message);
    setTimeout(() => dispatch({
      type: SHOW_AUTH_MODAL,
      data: { content: message, type: 'error' }
    }), 1000);
  }

  // Helper function to process user data and handle notification tokens
  async function handleUserData(res) {
    let amendedData = res.data.data;

    if (Platform.OS !== 'web') {
      let activeTokens = amendedData.notification_info?.map(item => item.notification_token) || [];

      if (!activeTokens.length || activeTokens.includes(notificationToken)) {
        await apis.updateNotificationToken(notificationToken, amendedData.userData.id);
      }

      amendedData.expireAt = Date.now() + 1000 * 60 * 60 * 24 * 7;
    }

    return amendedData;
  }

  // Helper function to handle Terms & Conditions
  async function handleTermsAndConditions(res) {
    if (res.data.terms) {
      console.log("Terms and Conditions need acceptance");
      dispatch({ type: HIDE_LOADING_MODAL });
      setTimeout(() => dispatch({ type: SHOW_TC_MODAL, payload: res.data.data.userData.id }), 1000);

      try {
        await getTCConfirm();
      } catch (e) {
        console.error('Error awaiting T&C confirmation', e);
        handleError("Could not fetch terms and conditions, please try again");
      }
    }
  }
  
  return Platform.OS !='web' && (
    <View style={{ maxHeight: 50, flex: 1, elevation: 1, shadowRadius: 10, shadowOpacity: .2, backgroundColor: 'white', borderRadius: 30, paddingHorizontal: 20, flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'stretch', }}>
      <AppleAuthentication.AppleAuthenticationButton
        buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
        buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE}
        cornerRadius={15}
        style={{ flex: 1 }}
        onPress={async () => {
          try {
            const credential = await AppleAuthentication.signInAsync({
              requestedScopes: [
                AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                AppleAuthentication.AppleAuthenticationScope.EMAIL,
              ],
            });

            console.log(credential)

            const newOAuthUser = {
              surname: credential.fullName?.familyName,
              name: credential.fullName?.givenName,
              authId: credential.user,
              email: credential.email,
              oauthToken: credential.identityToken,
              type: 'apple',
              device: {
                brand: DeviceInfo.getBrand(),
                model: DeviceInfo.getModel(),
                os: DeviceInfo.getSystemName(),
                osVersion: DeviceInfo.getSystemVersion(),
              },
            }
            const res = await apis.handleOAuth(newOAuthUser);
            if (res.status === 202) {
              if (res.data.terms) {
                console.log("user has terms and conditions to accept");
                dispatch({ type: SHOW_TC_MODAL, payload: res.data.userId });

                try {
                  const confirm = await getTCConfirm();
                  console.log('received a response from the TC Confirmation', confirm);
                  dispatch({ type: SHOW_OTP, payload: res.data });
                } catch (e) {
                  handleError('Please accept our terms of service to continue');
                }
              } else {
                dispatch({ type: SHOW_OTP, payload: res.data });
              }
            } else {
              await handleTermsAndConditions(res);
              const amendedData = await handleUserData(res);

              dispatch({ type: SUCCESS, data: amendedData });
              useNavigation.navigate('Dashboard');
            }
            // signed in
          } catch (e) {
            if (e.code === 'ERR_REQUEST_CANCELED') {
              dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Login was cancelled', type: 'error' } })
            } else {
              console.log(e)
              dispatch({ type: SHOW_AUTH_MODAL, data: { content: e.toString(), type: 'error' } })
            }
          }
        }}
      />
    </View>
  )
}
export default AppleButton