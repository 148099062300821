import moment from 'moment';

const getDaysAgo = (date) => {
	//Parse date using moment and return the number of days ago
  const daysAgo = moment(date).fromNow();
  if(daysAgo.includes('minutes') || daysAgo.includes('seconds')) {
    return moment(date).format('h:mm a');
  } else if (daysAgo.includes('hour')) {
    return daysAgo.split('hours ago').join('h');
  }
  else if (daysAgo.includes('a day')) {
    return '1d';
  } else if (daysAgo.includes('days')) {
    let days = daysAgo.split('days');
    return days[0] + 'd';
  }
};

export default getDaysAgo;
