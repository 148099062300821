import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={24}
    height={24}
    {...props}
  >
    <Path
      d="M419.62 171.45 273.81 69.99c-14.25-9.92-33.37-9.92-47.62 0L80.38 171.45a41.683 41.683 0 0 0-17.85 34.2v190.14c0 22.98 18.68 41.66 41.66 41.66h41.35c34.45 0 62.49-28.04 62.49-62.49l-.02-83.36h83.97l.02 83.36c0 34.45 28.04 62.49 62.49 62.49h41.33c22.98 0 41.66-18.68 41.66-41.66V205.65c0-13.62-6.67-26.41-17.85-34.2Z"
      style={{
        fill: "#fff",
      }}
    />
  </Svg>
)

export default SvgComponent
