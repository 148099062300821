import * as React from "react"
import Svg, { Defs, LinearGradient, Stop, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      id="uuid-545e1e42-888c-4294-a9d6-33a1c3792663"
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="-5 -5 30 30"
      preserveAspectRatio="xMinYMin meet"
      {...props}
    >
      <Defs>
        <LinearGradient
          id="uuid-2ffe1743-7a27-4465-8160-e7f1457ab697"
          x1={-299.84}
          y1={410.71}
          x2={-299.13}
          y2={409.84}
          gradientTransform="matrix(19.94 0 0 -19.94 5976.83 8187.35)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0} stopColor="#5aedcc" />
          <Stop offset={1} stopColor="#4d53e2" />
        </LinearGradient>
      </Defs>
      <Path
        d="M19.93 9.41c0 1.46-.34 2.9-1 4.21a9.399 9.399 0 01-12.63 4.2L0 19.94l2.1-6.31c-.66-1.3-1-2.75-1-4.21 0-3.57 2.02-6.82 5.21-8.41A9.23 9.23 0 0110.52 0h.55c4.78.26 8.6 4.08 8.86 8.86v.55z"
        id="uuid-7fa8732d-2439-48f1-9897-47968da89287"
        fill="url(#uuid-2ffe1743-7a27-4465-8160-e7f1457ab697)"
      />
    </Svg>
  )
}

export default SvgComponent
