import { TouchableOpacity, Text, View } from "react-native";
import { useDispatch, useSelector, } from 'react-redux';
import styles from "@stylesheet";
import Modal from 'react-native-modal';
import { HIDE_COMMUNITY_MODAL } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import { Title } from "@constants/Themed";
import React from "react";

const ErrorModal = () => {
  const visibleState = useSelector((state: DefaultRootState) => state.modals?.communityModal.modalDisplayed);
const dispatch = useDispatch();
  return (
      <Modal
        animationIn={'bounceInDown'}
        animationInTiming={1000}
        animationOut={'slideOutUp'}
        isVisible={visibleState}
        coverScreen={false}
        hasBackdrop={true}
        backdropOpacity={0}
      onBackdropPress={() => { dispatch({ type: HIDE_COMMUNITY_MODAL }) }}
        style={{ flex: 1 }}
      >
        <View style={styles.modalContainerLanguage}>
          <View style={{ justifyContent: 'center', alignSelf: 'stretch', alignContent: 'center', alignItems: 'center', padding: 15 }}>
            <Title style={{ textAlign: 'center' }}>Community Coming Soon!</Title>
          </View>
          <View style={{ height: 10 }} />
          <View style={{ flex: 1 }}>
          <TouchableOpacity onPress={() => { dispatch({ type: HIDE_COMMUNITY_MODAL, payload: null }) }} style={[styles.Button]}>
            <Text adjustsFontSizeToFit >Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal >
  );
};

export default ErrorModal