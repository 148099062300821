const circleOptions = {
	strokeColor: '#5ac0eab3',
	strokeOpacity: 0.8,
	strokeWeight: 50,
	fillColor: '#5ac0ea',
	fillOpacity: 0.35,
	clickable: false,
	draggable: false,
	editable: false,
	visible: true,
	radius: 300,
	zIndex: 1,
};

export default circleOptions;