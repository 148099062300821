import { useEffect, useState, memo } from 'react';
import { Platform, TextProps } from 'react-native';
import { Text } from '@constants/Themed';
import React from 'react';
import * as Animatable from 'react-native-animatable';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import { rules } from "@utils/stringUtils/markDownRules";
import Markdown, { MarkdownIt } from 'react-native-markdown-display';

interface AnimatedTextProps extends TextProps {
	value: string;
	speed?: number;
	delay?: number;
	fontSize?: number;
	fontColor?: string;
	onAnimationComplete?: Function;
	center?: boolean;
	retain?: number;
	disabledAnimation?: boolean;
}

function AnimatedTextFunction({
	value,
	speed = 200,
	delay,
	fontSize = 12,
	fontColor,
	onAnimationComplete,
	center,
	disabledAnimation = false,
	retain,
	...props
}: AnimatedTextProps) {
	const [wordIndex, setWordIndex] = useState(0);
	const [opacity, setOpacity] = useState(true);

	useEffect(() => {
		if (!value) return;
		if (disabledAnimation) {
			setWordIndex(value.split(' ').length);
			return;
		}
		const words = value.split(' ');

		const displayNextWord = () => {
			if (wordIndex < words.length) {
				setWordIndex((index) => index + 1);
			} else {
				setOpacity(false);
				setTimeout(() => onAnimationComplete && onAnimationComplete(), retain || 1000);
			}
		};

		const timeoutId = setTimeout(displayNextWord, speed);

		return () => clearTimeout(timeoutId);
	}, [wordIndex, onAnimationComplete, value, speed, retain]);

	return (
		<Markdown
			rules={rules}
		>
			{value && value.split(' ').slice(0, wordIndex).join(' ')}
			{/* {Platform.OS === 'web' && (
				<Animatable.View animation="pulse" style={{ opacity: opacity ? 1 : 0, minWidth: 15, flex: 1 }}>
					<Animatable.Text animation="fadeIn" style={{ color: theme ? 'black' : 'white', flex: 1, lineHeight: fontSize * 1.5 }}>
						_
					</Animatable.Text>
				</Animatable.View>
			)} */}
		</Markdown>
	);
}

export default memo(AnimatedTextFunction, (prevProps, nextProps) => {
	// Memoization based on props
	return (
		prevProps.value === nextProps.value &&
		prevProps.speed === nextProps.speed &&
		prevProps.delay === nextProps.delay &&
		prevProps.fontSize === nextProps.fontSize &&
		prevProps.fontColor === nextProps.fontColor &&
		prevProps.center === nextProps.center &&
		prevProps.retain === nextProps.retain
	);
});
