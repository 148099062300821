import { Trash as MobileTrash, HomeIcon as MobileHomeIcon, DataLaptop as MobileDataLaptop, MobileUsersIcon, SearchIcon as MobileSearchIcon, MobileProjectIcon, MobileCalendarIcon, MobileReportIcon, MobileSettingsIcon, MobileIntegrationIcon } from '../../../../../assets/--native/index';
import { Entypo } from '@expo/vector-icons';
import styles from '../../../../../stylesheet';

const topics = [
  { id: 37, name: 'welcome', icon: <MobileHomeIcon style={[styles.centerEverything, { width: 50, height: 50, padding: 10 }]} /> },
  { id: 38, name: 'data', icon: <MobileDataLaptop style={[styles.centerEverything, { width: 50, height: 50, padding: 10 }]} /> },
  { id: 39, name: 'projects', icon: <MobileProjectIcon style={[styles.centerEverything, { width: 50, height: 50, padding: 10 }]} /> },
  { id: 40, name: 'organiser', icon: <MobileCalendarIcon style={[styles.centerEverything, { width: 50, height: 50, padding: 10 }]} /> },
  { id: 41, name: 'reports', icon: <MobileReportIcon style={[styles.centerEverything, { width: 50, height: 50, padding: 10 }]} /> },
  { id: 42, name: 'users', icon: <MobileUsersIcon style={[styles.centerEverything, { width: 50, height: 50, padding: 10 }]} /> },
  { id: 43, name: 'integrations', icon: <MobileIntegrationIcon style={[styles.centerEverything, { width: 50, height: 50, padding: 10 }]} /> },
  { id: 44, name: 'networking', icon: <Entypo name="globe" size={24} color="white" style={[styles.centerEverything]} /> },
  { id: 45, name: 'settings', icon: <MobileSettingsIcon style={[styles.centerEverything, { width: 50, height: 50, padding: 10 }]} /> },
]
export default topics