import React, { useState } from 'react';
import { Platform, StyleSheet, View, Dimensions, Pressable, TextStyle } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { Text } from '@constants/Themed';
import ButtonStyles from './ButtonStyles';
import styles from '@stylesheet';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import { Button as DefaultButton, ButtonProps } from 'tamagui';

interface DefaultButtonProps {
  onPress: () => void;
  buttonText?: JSX.Element | string;
  disabled?: boolean;
  icon?: JSX.Element | string;
  defaultColor?: string;
  onPressColor?: string;
  onHoverColor?: string;
  portrait?: boolean;
  outline?: boolean;
  customStyle?: ButtonProps,
  iconColor?: string,
  textColor?: string,
  badge?: number | string,
  isClose?: boolean,
  iconOnly?: boolean,
  inverse?: boolean,
  noShadow?: boolean,
  iconStyle?: TextStyle,
  iconSize?: number,
  textStyle?: TextStyle,
}

const Button: React.FC<DefaultButtonProps> = ({
  onPress,
  buttonText,
  disabled = false,
  icon,
  isClose = false,
  iconColor,
  defaultColor = '#58BFEA',
  onPressColor = '#99DBF7',
  portrait = false,
  outline = false,
  customStyle,
  textColor = "white",
  badge,
  iconOnly,
  inverse,
  iconSize = 24,
  noShadow = false,
  iconStyle,
  textStyle,
}) => {
  const theme = themeSelector();
    const [width, setWidth] = useState(0);

    function determineDirection() {
      if (inverse) {
        return 'row-reverse'
      } else {
        return 'row'
      }
    }
    return (
      <DefaultButton
        style={[styles.centerEverything, { flex: 1, alignSelf: "center", minWidth: !iconOnly && 70, maxWidth: iconOnly ? 60 : undefined, overflow: 'visible', maxHeight: portrait ? 80 : 40, marginBottom: 10, ...customStyle }]}
        onPress={onPress}
        backgroundColor={customStyle?.backgroundColor || defaultColor}
        disabled={disabled}
        icon={typeof icon === 'string' ? (
          <AntDesign name={icon} size={iconSize} color={iconColor ? iconColor : 'black'} style={[{ alignSelf: 'center', textAlign: 'center', left: Platform.OS === 'web' ? 30 : undefined }, iconStyle]} />
        ) : (
          icon
        )}
      >
        <View
          style={{ flexDirection: portrait ? 'column' : determineDirection(), flex: 1, justifyContent: 'center', alignItems: 'center', alignContent: 'center', alignSelf: 'center', gap: 15 }}
        >
          {
            badge != null &&
            <View style={{ backgroundColor: 'red', borderRadius: 100, minHeight: 20, minWidth: 20, position: 'absolute', right: -50, top: -10 }}>
              <Text style={{ textAlign: 'right', backgroundColor: 'red', color: 'white' }}>{badge}</Text>
            </View>
          }
          {
            buttonText && typeof buttonText === 'string' ?
              <Text adjustsFontSizeToFit numberOfLines={1} style={[ButtonStyles.buttonText, { color: textColor || (theme ? 'black' : 'white'), textAlign: 'center' }, textStyle]}>{buttonText}</Text>
              :
              (buttonText)
          }
        </View>
      </DefaultButton>

    );
  };


export default Button;
