import {
	UPDATE_NOTIFICATIONS,
	CLEAR_NOTIFICATIONS,
	FILTER_NOTIFICATIONS,
	NEW_NOTIFICATION,
} from '../actions/types';
import { updateObject } from './utils/globalStateFunctions';

const INITIAL_STATE = {
	lastNotification: {},
	notificationList: [],
	filteredNotifications: [],
	count: 0,
};

const notificationReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case NEW_NOTIFICATION:
			return updateObject(state, {
				lastNotification: action.payload,
				notificationList: [action.payload, ...state.notificationList],
				count: state.count + 1,
			});
		case UPDATE_NOTIFICATIONS:
			return updateObject(state, {
				notificationList: action.payload,
				count: action.payload.length,
			});
		case CLEAR_NOTIFICATIONS:
			return updateObject(state, {
				count: 0,
			});
		case FILTER_NOTIFICATIONS:
			return updateObject(state, {
				filteredNotifications: action.payload,
			});
		default:
			return state;
	}
};

export default notificationReducer;
