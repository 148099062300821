import React from "react"
import LottieView from "lottie-react-native"

export default function HandleIcon(index: number) {
  if (index === 0) {
    return <LottieView source={require('@assets/animations/LordIcons/63-home-gradient-edited.json')} style={[lottieStyle, { flex: 1 }]} autoPlay loop />
  } else if (index === 1) {
    return <LottieView source={require('@assets/animations/LordIcons/neighbourhood.json')} style={[lottieStyle, { flex: 1 }]} autoPlay loop />
  } else if (index === 2) {
    return <LottieView source={require('@assets/animations/LordIcons/building.json')} style={[lottieStyle, { flex: 1 }]} autoPlay loop />
  } else if (index === 3) {
    return <LottieView source={require('@assets/animations/LordIcons/manyBuildings.json')} style={[lottieStyle, { flex: 1 }]} autoPlay loop />
  } else if (index === 4) {
    return <LottieView source={require('@assets/animations/LordIcons/beta-test.json')} style={[lottieStyle, { flex: 1 }]} autoPlay loop />
  }
}

const lottieStyle = {
  flex: 1,
  width: 150,
  height: 150
}