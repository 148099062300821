import React from 'react';
import { Pressable, Platform } from 'react-native';
import { Text, View } from '@constants/Themed';
import { Avatar } from '@mui/material';
import Button from '@components/UI/Button/Button';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import styles from '@stylesheet';

const RequestItem = ({ request, onAccept, onReject }) => {
  const theme = themeSelector();
  //Convert this to a React Native component
  return (
    <View style={[styles.noticeCardContainer, { backgroundColor: theme ? undefined : "black" }]} >
      <Pressable style={{ flex: 1, width: 50 }}>
        <Avatar src={request.avatar} />
      </Pressable>
      <View style={{ flex: 5, gap: 20 }}>
        <Text><Text style={{ fontWeight: "bold" }}>{request.user}</Text> sent you a friend request</Text>
        <Text>{request.time} ago</Text>
        <View style={{ flexDirection: "row", gap: 10 }}>
          <Button customStyle={{ borderRadius: 10 }} buttonText={"Accept"} onPress={onAccept} />
          <Button customStyle={{ borderRadius: 10 }} buttonText={"Decline"} onPress={onReject} />
        </View>
      </View>
    </View>
  );
}
export default RequestItem;