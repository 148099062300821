import * as React from "react"
import Svg, { SvgProps, Defs, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props: SvgProps) => (
  <Svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={24}
    height={24}
    {...props}
  >
    <Defs></Defs>
    <Path
      className="cls-1"
      d="M395.81 93.78h-41.66V72.95c0-11.5-9.31-20.83-20.83-20.83s-20.83 9.33-20.83 20.83v20.83H187.51V72.95c0-11.5-9.31-20.83-20.83-20.83s-20.83 9.33-20.83 20.83v20.83h-41.66c-22.91 0-41.66 18.75-41.66 41.66v42.49h374.94v-42.49c0-22.91-18.75-41.66-41.66-41.66ZM395.81 209.17H62.53v197.05c0 22.91 18.75 41.66 41.66 41.66h291.62c22.91 0 41.66-18.75 41.66-41.66V209.17h-41.66Zm-62.7 62.28h.21c11.46 0 20.83 9.17 20.83 20.83s-9.37 20.83-20.83 20.83-20.83-9.37-20.83-20.83 9.17-20.83 20.62-20.83Zm-83.11 0c11.66 0 20.83 9.17 20.83 20.83s-9.17 20.83-20.83 20.83-20.83-9.37-20.83-20.83 9.17-20.83 20.83-20.83Zm0 72.9c11.66 0 20.83 9.37 20.83 20.83s-9.17 20.83-20.83 20.83-20.83-9.37-20.83-20.83 9.17-20.83 20.83-20.83Zm-83.32-72.9c11.46 0 20.83 9.17 20.83 20.83s-9.17 20.83-20.62 20.83h-.21c-11.46 0-20.83-9.37-20.83-20.83s9.37-20.83 20.83-20.83Zm0 72.9c11.46 0 20.83 9.37 20.83 20.83s-9.17 20.83-20.62 20.83h-.21c-11.46 0-20.83-9.37-20.83-20.83s9.37-20.83 20.83-20.83Z"
      style={{ fill: '#fff' }}
    />
  </Svg>
)

export default SvgComponent
