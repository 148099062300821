import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';

const handleImageUpload = async () => {
  const { status } = await ImagePicker.requestCameraPermissionsAsync();

  if (status !== 'granted') {
    alert('Sorry, we need camera roll permissions to make this work!');
    return;
  }

  const result = await ImagePicker.launchCameraAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: true,
    aspect: [4, 3],
  });
  if (result.canceled) {
    alert('No image selected')
    return;
  }

  const manipResult = await manipulateAsync(
    result.assets[0].uri,
    [{ resize: { width: 1000 } }],
    { compress: 0.7, format: SaveFormat.JPEG, base64: true }
  );
  let img = manipResult.base64;
  return img;
};
export default handleImageUpload;
