import React, { useMemo, useState } from 'react';
import { CalendarProvider, ExpandableCalendar } from 'react-native-calendars';
import { View } from 'react-native';
import moment from 'moment';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const today = new Date();
const getDate = (offset = 0) => moment(today).add(offset, 'days').format('YYYY-MM-DD');

const StoreCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(getDate());
  const [month, setMonth] = useState<DateData>(generateInitialMonth);

  const generateInitialMonth = useMemo(
    () => ({
      year: moment().year(),
      month: moment().month(),
      day: moment().day(),
      timestamp: new Date(),
      dateString: getDate(),
    }),
    []
  );

  const Arrow = ({ direction }) => {
    return (
      <View style={{ flex: 1 }}>
        <MaterialCommunityIcons
          name={direction === 'left' ? 'arrow-left-bold-circle' : 'arrow-right-bold-circle'}
          size={50}
          color="rgb(90,192,234)"
        />
      </View>
    );
  };

  return (
    <View style={{flex: 1}}>
      <CalendarProvider date={selectedDate} disabledOpacity={0.6} style={{ borderRadius: 20, flex: 1 }}>
        <ExpandableCalendar
          onMonthChange={(date) => setMonth(date)}
          firstDay={1}
          theme={{ selectedDayBackgroundColor: 'rgb(90,192,234)' }}
          renderArrow={(direction) => <Arrow direction={direction} />}
          showWeekNumbers={false}
          hideExtraDays
          snapToAlignment='end'
          onDayPress={(date) => {
            // handleMarkSelection(date.dateString);
          }}
          style={{ flex: 1, borderRadius: 20, shadowRadius: 20, shadowOpacity: 0.2 }}
          markingType='period'
          marking={{
            color: 'black',
            selectedTextColor: 'white',
            selectedColor: 'rgba(90,192,234,0.5)',
            customContainerStyle: { borderRadius: 100, backgroundColor: '#ececec' },
          }}
        />
      </CalendarProvider>
    </View>
  );
};

export default StoreCalendar;
