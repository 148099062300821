import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 977.58 604.3"
    {...props}
  >
    <defs>
      <linearGradient
        id="e"
        x1={649.71}
        y1={462.52}
        x2={649.71}
        y2={0}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#e0e0e0" />
        <stop offset={1} stopColor="#f5f5f5" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={649.71}
        y1={551.88}
        x2={649.71}
        y2={455.11}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#bdbdbc" />
        <stop offset={0.03} stopColor="#cbcbca" />
        <stop offset={0.08} stopColor="#dbdbdb" />
        <stop offset={0.14} stopColor="#e7e7e7" />
        <stop offset={0.22} stopColor="#eee" />
        <stop offset={0.45} stopColor="#f0f0f0" />
        <stop offset={0.53} stopColor="#e9e9e9" />
        <stop offset={0.64} stopColor="#d7d7d7" />
        <stop offset={0.77} stopColor="#bdbdbc" />
        <stop offset={1} stopColor="#858584" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={649.71}
        y1={564.23}
        x2={649.71}
        y2={548.87}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#919191" />
        <stop offset={0.01} stopColor="#9d9d9d" />
        <stop offset={0.04} stopColor="#aeaeae" />
        <stop offset={0.06} stopColor="#bababa" />
        <stop offset={0.1} stopColor="#c2c2c2" />
        <stop offset={0.2} stopColor="#c4c4c4" />
        <stop offset={0.48} stopColor="#dadada" />
        <stop offset={0.77} stopColor="#eaeaea" />
        <stop offset={1} stopColor="#f0f0f0" />
      </linearGradient>
      <linearGradient
        id="h"
        x1={524.55}
        y1={-55.18}
        x2={775.79}
        y2={-55.18}
        gradientTransform="matrix(1 0 0 -1 0 501.37)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#eee" />
        <stop offset={0.03} stopColor="#f6f6f6" />
        <stop offset={0.07} stopColor="#fdfdfd" />
        <stop offset={0.18} stopColor="#fff" />
        <stop offset={0.74} stopColor="#fff" />
        <stop offset={0.77} stopColor="#fff" />
        <stop offset={0.91} stopColor="#fdfdfd" />
        <stop offset={0.96} stopColor="#f6f6f6" />
        <stop offset={0.99} stopColor="#eee" />
      </linearGradient>
      <linearGradient
        id="i"
        x1={770.9}
        y1={372.46}
        x2={770.9}
        y2={462.52}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7f7f7" />
        <stop offset={1} stopColor="#d9d9d9" />
      </linearGradient>
      <linearGradient
        id="j"
        x1={649.71}
        y1={381.55}
        x2={649.71}
        y2={0}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#cfcfcf" />
        <stop offset={0.03} stopColor="#cfcfcf" />
        <stop offset={1} stopColor="#ebebeb" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={372.2}
        y1={277.81}
        x2={372.2}
        y2={545.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f5f5f5" />
        <stop offset={1} stopColor="#e0e0e0" />
      </linearGradient>
      <linearGradient
        id="k"
        x1={372.2}
        y1={277.81}
        x2={372.2}
        y2={545.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ebebeb" />
        <stop offset={0.97} stopColor="#cfcfcf" />
        <stop offset={1} stopColor="#cfcfcf" />
      </linearGradient>
      <linearGradient
        id="l"
        x1={372.2}
        y1={563.65}
        x2={372.2}
        y2={549.48}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#c9c9c9" />
        <stop offset={0.2} stopColor="#818181" />
        <stop offset={0.25} stopColor="#737373" />
        <stop offset={0.33} stopColor="#7a7a7a" />
        <stop offset={0.45} stopColor="#8f8f8f" />
        <stop offset={0.6} stopColor="#b0b0b0" />
        <stop offset={0.76} stopColor="#dfdfdf" />
        <stop offset={0.76} stopColor="#e0e0e0" />
        <stop offset={1} stopColor="#d6d6d6" />
      </linearGradient>
      <linearGradient
        id="m"
        x1={128.71}
        y1={556.57}
        x2={617.46}
        y2={556.57}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ccc" />
        <stop offset={0.18} stopColor="#fff" />
        <stop offset={0.74} stopColor="#fff" />
        <stop offset={0.77} stopColor="#fff" />
        <stop offset={0.89} stopColor="#fdfdfd" />
        <stop offset={0.93} stopColor="#f6f6f6" />
        <stop offset={0.95} stopColor="#eaeaea" />
        <stop offset={0.98} stopColor="#d9d9d9" />
        <stop offset={0.99} stopColor="#ccc" />
      </linearGradient>
      <linearGradient
        id="n"
        x1={372.2}
        y1={549.67}
        x2={372.2}
        y2={545.97}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#bababa" />
        <stop offset={0.31} stopColor="#f0f0f0" />
        <stop offset={0.52} stopColor="#f4f4f4" />
        <stop offset={0.72} stopColor="#fff" />
        <stop offset={1} stopColor="#c9c9c9" />
      </linearGradient>
      <linearGradient
        id="o"
        x1={307.11}
        y1={547.86}
        x2={437.28}
        y2={547.86}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#919191" />
        <stop offset={0.02} stopColor="#acacac" />
        <stop offset={0.05} stopColor="#c6c6c6" />
        <stop offset={0.07} stopColor="#dadada" />
        <stop offset={0.1} stopColor="#e9e9e9" />
        <stop offset={0.14} stopColor="#f1f1f1" />
        <stop offset={0.2} stopColor="#f4f4f4" />
        <stop offset={0.72} stopColor="#f4f4f4" />
        <stop offset={0.8} stopColor="#f4f4f4" />
        <stop offset={0.86} stopColor="#f1f1f1" />
        <stop offset={0.9} stopColor="#e9e9e9" />
        <stop offset={0.93} stopColor="#dadada" />
        <stop offset={0.96} stopColor="#c6c6c6" />
        <stop offset={0.98} stopColor="#acacac" />
        <stop offset={1} stopColor="#919191" />
      </linearGradient>
      <linearGradient
        id="p"
        x1={81.57}
        y1={-16.71}
        x2={81.57}
        y2={218.51}
        gradientTransform="translate(48.52 346.44)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#c4c4c4" />
        <stop offset={0.17} stopColor="#b3b3b3" />
        <stop offset={0.39} stopColor="#a2a2a2" />
        <stop offset={0.55} stopColor="#9c9c9c" />
        <stop offset={0.98} stopColor="#8c8c8b" />
        <stop offset={0.98} stopColor="#8f8f8e" />
        <stop offset={0.99} stopColor="#9b9b9b" />
        <stop offset={0.99} stopColor="#afafaf" />
        <stop offset={1} stopColor="#cbcbcb" />
        <stop offset={1} stopColor="#efefee" />
        <stop offset={1} stopColor="#f2f2f2" />
      </linearGradient>
      <linearGradient
        id="q"
        x1={130.09}
        y1={328.31}
        x2={130.09}
        y2={332.04}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#484846" />
        <stop offset={0.04} stopColor="#4c4c4a" />
        <stop offset={0.08} stopColor="#585856" />
        <stop offset={0.11} stopColor="#6c6c6a" />
        <stop offset={0.15} stopColor="#888887" />
        <stop offset={0.19} stopColor="#adadac" />
        <stop offset={0.23} stopColor="#d9d9d9" />
        <stop offset={0.26} stopColor="#fff" />
        <stop offset={0.32} stopColor="#f5f5f5" />
        <stop offset={0.43} stopColor="#dbdbdb" />
        <stop offset={0.56} stopColor="#b0b0b0" />
        <stop offset={0.72} stopColor="#757575" />
        <stop offset={0.9} stopColor="#2b2b2b" />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="a"
        x1={73.97}
        y1={562.21}
        x2={59.52}
        y2={527.91}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} />
        <stop offset={0.43} />
        <stop offset={0.45} stopColor="#010101" />
        <stop offset={0.46} stopColor="#080808" />
        <stop offset={0.47} stopColor="#141414" />
        <stop offset={0.47} stopColor="#252525" />
        <stop offset={0.48} stopColor="#3b3b3b" />
        <stop offset={0.48} stopColor="#565656" />
        <stop offset={0.49} stopColor="#777" />
        <stop offset={0.49} stopColor="#9c9c9c" />
        <stop offset={0.49} stopColor="#c5c5c5" />
        <stop offset={0.5} stopColor="#f4f4f4" />
        <stop offset={0.76} />
      </linearGradient>
      <linearGradient
        id="r"
        x1={186.5}
        y1={563.26}
        x2={204.72}
        y2={544.92}
        xlinkHref="#a"
      />
      <linearGradient
        id="s"
        x1={130.09}
        y1={545.43}
        x2={130.09}
        y2={328.84}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#3b3b3b" />
        <stop offset={1} stopColor="#616161" />
      </linearGradient>
      <linearGradient
        id="t"
        x1={130.09}
        y1={347.96}
        x2={130.09}
        y2={561.41}
        xlinkHref="#b"
      />
      <linearGradient
        id="z"
        x1={81.57}
        y1={207.31}
        x2={81.57}
        y2={213.53}
        gradientTransform="translate(48.52 346.44)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#e8e8e8" />
        <stop offset={1} stopColor="#c9c9c9" />
      </linearGradient>
      <linearGradient
        id="A"
        x1={81.57}
        y1={214.33}
        x2={81.57}
        y2={206.71}
        gradientTransform="translate(48.52 346.44)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.26} stopColor="#fafafa" />
        <stop offset={0.3} stopColor="#f2f2f2" />
        <stop offset={0.36} stopColor="#ddd" />
        <stop offset={0.43} stopColor="#bbb" />
        <stop offset={0.46} stopColor="#a8a8a8" />
        <stop offset={0.68} stopColor="#b0b0b0" />
        <stop offset={0.79} stopColor="#b7b7b7" />
        <stop offset={1} stopColor="#a3a3a3" />
      </linearGradient>
      <linearGradient
        id="B"
        x1={40.41}
        y1={399.44}
        x2={40.41}
        y2={564.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#a6a6a6" />
        <stop offset={0.05} stopColor="#9e9e9e" />
        <stop offset={0.25} stopColor="#828282" />
        <stop offset={0.43} stopColor="#717171" />
        <stop offset={0.56} stopColor="#6b6b6b" />
        <stop offset={0.78} stopColor="#6c6c6c" />
        <stop offset={0.87} stopColor="#737373" />
        <stop offset={0.92} stopColor="#7f7f7e" />
        <stop offset={0.96} stopColor="#8c8c8b" />
        <stop offset={0.97} stopColor="#8f8f8e" />
        <stop offset={0.97} stopColor="#9b9b9b" />
        <stop offset={0.98} stopColor="#afafaf" />
        <stop offset={0.98} stopColor="#cbcbcb" />
        <stop offset={0.99} stopColor="#efefee" />
        <stop offset={0.99} stopColor="#f2f2f2" />
      </linearGradient>
      <linearGradient
        id="C"
        x1={40.41}
        y1={400.4}
        x2={40.41}
        y2={410.22}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#484846" />
        <stop offset={0.02} stopColor="#6b6b6a" />
        <stop offset={0.05} stopColor="#aaaaa9" />
        <stop offset={0.08} stopColor="#d8d8d7" />
        <stop offset={0.1} stopColor="#f4f4f4" />
        <stop offset={0.12} stopColor="#fff" />
        <stop offset={0.16} stopColor="#f0f0f0" />
        <stop offset={0.58} stopColor="#707070" />
        <stop offset={0.87} stopColor="#1f1f1f" />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="D"
        x1={20.31}
        y1={564.17}
        x2={-1.25}
        y2={533.3}
        xlinkHref="#a"
      />
      <linearGradient
        id="E"
        x1={62.29}
        y1={563.69}
        x2={79.62}
        y2={531.2}
        xlinkHref="#a"
      />
      <linearGradient
        id="F"
        x1={40.41}
        y1={398.43}
        x2={40.41}
        y2={563.08}
        xlinkHref="#b"
      />
      <linearGradient
        id="c"
        x1={80.54}
        y1={440.66}
        x2={81.77}
        y2={440.66}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#b0b0b0" />
        <stop offset={0.34} stopColor="#e2e2e2" />
        <stop offset={0.46} stopColor="#f6f6f6" />
        <stop offset={0.58} stopColor="#f1f1f1" />
        <stop offset={0.72} stopColor="#e3e3e3" />
        <stop offset={0.82} stopColor="#d4d4d4" />
        <stop offset={1} stopColor="#f7f7f7" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={81.15}
        y1={450.36}
        x2={81.15}
        y2={431.02}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f1f1f1" />
        <stop offset={0.02} stopColor="#fff" />
        <stop offset={0.15} stopColor="#fff" />
        <stop offset={0.97} stopColor="#fff" />
        <stop offset={0.99} stopColor="#f9f9f9" />
        <stop offset={1} stopColor="#f6f6f6" />
      </linearGradient>
      <linearGradient
        id="G"
        x1={1207.49}
        y1={474.38}
        x2={1208.71}
        y2={474.38}
        gradientTransform="translate(-1126.95)"
        xlinkHref="#c"
      />
      <linearGradient
        id="H"
        x1={1208.1}
        y1={477.89}
        x2={1208.1}
        y2={470.89}
        gradientTransform="translate(-1126.95)"
        xlinkHref="#d"
      />
      <linearGradient
        id="I"
        x1={1207.49}
        y1={461.9}
        x2={1208.71}
        y2={461.9}
        gradientTransform="translate(-1126.95)"
        xlinkHref="#c"
      />
      <linearGradient
        id="J"
        x1={1208.1}
        y1={465.41}
        x2={1208.1}
        y2={458.41}
        gradientTransform="translate(-1126.95)"
        xlinkHref="#d"
      />
      <radialGradient
        id="u"
        cx={82.75}
        cy={-8.58}
        fx={82.75}
        fy={-8.58}
        r={2.28}
        gradientTransform="translate(48.52 346.44)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#c9c9c9" />
      </radialGradient>
      <radialGradient
        id="v"
        cx={82.31}
        cy={-9.02}
        fx={82.31}
        fy={-9.02}
        r={1.6}
        gradientTransform="translate(48.52 346.44)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fcfcfc" />
        <stop offset={0.47} stopColor="#f1f1f1" />
        <stop offset={1} stopColor="#ededed" />
      </radialGradient>
      <radialGradient
        id="w"
        cx={81.73}
        cy={5.88}
        fx={81.73}
        fy={5.88}
        r={0.7}
        gradientTransform="rotate(2.83 -6599.138 1149.99) scale(1 .95)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f8f8f8" />
        <stop offset={0.77} stopColor="#f9f9f9" />
        <stop offset={1} stopColor="#fff" />
      </radialGradient>
      <radialGradient
        id="x"
        cx={81.44}
        cy={7.14}
        fx={81.44}
        fy={7.14}
        r={0.49}
        gradientTransform="rotate(2.83 -6598.293 1151.387) scale(1 .71)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={0.08} stopColor="#e2e2e2" />
        <stop offset={0.27} stopColor="#9f9f9f" />
        <stop offset={0.46} stopColor="#666" />
        <stop offset={0.63} stopColor="#3a3a3a" />
        <stop offset={0.78} stopColor="#1a1a1a" />
        <stop offset={0.91} stopColor="#060606" />
        <stop offset={1} />
      </radialGradient>
      <radialGradient
        id="y"
        cx={123.32}
        cy={-202}
        fx={123.32}
        fy={-202}
        r={0.33}
        gradientTransform="rotate(-45 764.594 30.285) scale(.8 1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.29} stopColor="#fff" />
        <stop offset={0.38} stopColor="#ccc" />
        <stop offset={0.51} stopColor="#848484" />
        <stop offset={0.63} stopColor="#4b4b4b" />
        <stop offset={0.74} stopColor="#222" />
        <stop offset={0.82} stopColor="#090909" />
        <stop offset={0.87} />
      </radialGradient>
    </defs>
    <g
      style={{
        isolation: "isolate",
      }}
    >
      <path
        d="M964.73 0H334.69c-7.09 0-12.85 5.75-12.85 12.85v427.21c0 12.41 10.06 22.46 22.46 22.46h610.81c12.41 0 22.46-10.06 22.46-22.46V12.85c0-7.1-5.75-12.85-12.85-12.85Zm-3.55 361.71c0 5.68-4.61 10.29-10.29 10.29H348.53c-5.68 0-10.29-4.61-10.29-10.29V22.42c0-7.12 5.77-12.88 12.88-12.88H948.3c7.12 0 12.88 5.77 12.88 12.88v339.29Z"
        style={{
          fill: "url(#e)",
        }}
      />
      <path
        d="M730.05 444.61H699v43.26c0 19.87-30.04 25.46-49.29 25.46s-49.29-5.59-49.29-25.46v-43.26h-31.05l-23.36 106.75h207.38l-23.36-106.75Z"
        style={{
          fill: "url(#f)",
        }}
      />
      <path
        d="M749.66 548.92H547.19c-24.12 0-23.93 15.26-23.93 15.26h252.9s-2.68-15.26-26.5-15.26Z"
        style={{
          fill: "url(#g)",
        }}
      />
      <path
        d="M749.66 548.92H547.19c-24.12 0-23.93 15.26-23.93 15.26h252.9s-2.68-15.26-26.5-15.26Z"
        style={{
          fill: "url(#h)",
          mixBlendMode: "multiply",
        }}
      />
      <path
        d="M564.21 372.46v90.06h390.91c12.41 0 22.46-8.7 22.46-19.42v-70.64H564.21Z"
        style={{
          fill: "url(#i)",
        }}
      />
      <path
        d="M964.7 0H334.73c-7.12 0-12.88 5.77-12.88 12.88v273.57h16.39V22.42c0-7.12 5.77-12.88 12.88-12.88H948.3c7.12 0 12.88 5.77 12.88 12.88v339.29c0 5.68-4.61 10.29-10.29 10.29H564.21v9.54h403.08c5.68 0 10.29-4.61 10.29-10.29V12.88C977.58 5.76 971.81 0 964.7 0Z"
        style={{
          fill: "url(#j)",
        }}
      />
      <path
        d="M566.23 279.46H178.17a6.59 6.59 0 0 0-6.59 6.59v263.52h401.25V286.05a6.59 6.59 0 0 0-6.59-6.59Zm-3.44 263.36H181.6V292.81a6.59 6.59 0 0 1 6.59-6.59h368a6.59 6.59 0 0 1 6.59 6.59v250.01Z"
        style={{
          fill: "url(#b)",
        }}
      />
      <path
        d="M566.23 279.46H178.17a6.59 6.59 0 0 0-6.59 6.59v263.52h401.25V286.05a6.59 6.59 0 0 0-6.59-6.59Zm-3.44 263.36H181.6V292.81a6.59 6.59 0 0 1 6.59-6.59h368a6.59 6.59 0 0 1 6.59 6.59v250.01Z"
        style={{
          fill: "url(#k)",
        }}
      />
      <path
        d="M593.73 563.61H148.3c-22.26 0-22.08-14.08-22.08-14.08h491.97s-2.47 14.08-24.45 14.08Z"
        style={{
          fill: "url(#l)",
        }}
      />
      <path
        d="M593.73 563.61H148.3c-22.26 0-22.08-14.08-22.08-14.08h491.97s-2.47 14.08-24.45 14.08Z"
        style={{
          fill: "url(#m)",
          mixBlendMode: "multiply",
        }}
      />
      <path
        style={{
          fill: "url(#n)",
          mixBlendMode: "multiply",
        }}
        d="M618.14 549.66H126.25l-.04-.17v-3.33l.04-.17h491.89l.05.17v3.33l-.05.17z"
      />
      <path
        style={{
          fill: "url(#o)",
        }}
        d="M307.52 545.99h129.31v3.75H307.52z"
      />
      <path
        d="M192.08 328.46H68.11c-8.43 0-15.27 6.84-15.27 15.27v205.78c0 8.43 6.84 15.27 15.27 15.27h123.97c8.43 0 15.27-6.84 15.27-15.27V343.73c0-8.43-6.84-15.27-15.27-15.27Zm13.98 221.05c0 7.72-6.26 13.98-13.98 13.98H68.11c-7.72 0-13.98-6.26-13.98-13.98V343.73c0-7.72 6.26-13.98 13.98-13.98h123.97c7.72 0 13.98 6.26 13.98 13.98v205.78Z"
        style={{
          fill: "url(#p)",
        }}
      />
      <path
        d="M55.93 336.87c2.4-4.25 6.95-7.13 12.18-7.13h123.97c5.23 0 9.78 2.88 12.18 7.13h1.45c-2.51-4.99-7.67-8.41-13.63-8.41H68.11c-5.96 0-11.12 3.43-13.63 8.41h1.45Z"
        style={{
          fill: "url(#q)",
          mixBlendMode: "screen",
        }}
      />
      <path
        d="M91.59 563.49H68.1c-7.72 0-13.98-6.26-13.98-13.98v-19.46h-1.28v19.46c0 8.43 6.84 15.27 15.27 15.27h23.43c.02-.43.03-.86.05-1.28Z"
        style={{
          fill: "url(#a)",
          mixBlendMode: "screen",
        }}
      />
      <path
        d="M206.06 530.04v19.46c0 7.72-6.26 13.98-13.98 13.98h-19.21v1.28h19.21c8.43 0 15.27-6.84 15.27-15.27v-19.46h-1.29Z"
        style={{
          fill: "url(#r)",
          mixBlendMode: "screen",
        }}
      />
      <path
        d="M192.08 329.55H68.11c-7.83 0-14.17 6.34-14.17 14.17V549.5c0 7.83 6.34 14.17 14.17 14.17h123.97c7.83 0 14.17-6.34 14.17-14.17V343.72c0-7.83-6.34-14.17-14.17-14.17Zm10.19 219.38c0 4.81-3.9 8.71-8.71 8.71H66.64a8.71 8.71 0 0 1-8.71-8.71V344.29c0-4.81 3.9-8.71 8.71-8.71h126.92c4.81 0 8.71 3.9 8.71 8.71v204.64Z"
        style={{
          fill: "url(#s)",
        }}
      />
      <path
        d="M197.35 329.74H62.84a8.71 8.71 0 0 0-8.71 8.71v216.33c0 4.81 3.9 8.71 8.71 8.71h134.52c4.81 0 8.71-3.9 8.71-8.71V338.45c0-4.81-3.9-8.71-8.71-8.71Zm4.91 211.19c0 4.81-3.9 8.71-8.71 8.71H66.63a8.71 8.71 0 0 1-8.71-8.71V347.29c0-4.81 3.9-8.71 8.71-8.71h126.92c4.81 0 8.71 3.9 8.71 8.71v193.64Z"
        style={{
          fill: "url(#t)",
        }}
      />
      <path
        d="M131.73 336.84c0 .91-.73 1.64-1.64 1.64s-1.64-.73-1.64-1.64.73-1.64 1.64-1.64 1.64.73 1.64 1.64Z"
        style={{
          fill: "url(#u)",
        }}
      />
      <path
        d="M131.27 336.84c0 .65-.53 1.18-1.18 1.18s-1.18-.53-1.18-1.18.53-1.18 1.18-1.18 1.18.53 1.18 1.18Z"
        style={{
          fill: "#949494",
        }}
      />
      <path
        d="M131.13 336.84c0 .57-.47 1.04-1.04 1.04s-1.04-.47-1.04-1.04.46-1.04 1.04-1.04 1.04.46 1.04 1.04Z"
        style={{
          fill: "url(#v)",
        }}
      />
      <path
        d="M130.81 336.88c-.02.37-.35.66-.74.64-.39-.02-.7-.34-.68-.71.02-.37.35-.66.74-.64.39.02.7.34.68.71Z"
        style={{
          fill: "url(#w)",
          mixBlendMode: "multiply",
        }}
      />
      <path
        d="M130.38 336.32c0 .19-.24.34-.51.33-.27-.01-.48-.18-.48-.38 0-.19.24-.34.51-.33.27.01.49.18.48.38Z"
        style={{
          fill: "url(#x)",
          mixBlendMode: "screen",
        }}
      />
      <path
        d="M129.63 336.77c.13.13.15.32.05.42-.1.1-.29.08-.42-.05s-.15-.32-.05-.42c.1-.1.29-.08.42.05Z"
        style={{
          fill: "url(#y)",
          mixBlendMode: "screen",
        }}
      />
      <rect
        x={126.62}
        y={553.48}
        width={6.95}
        height={6.95}
        rx={1.44}
        ry={1.44}
        style={{
          fill: "url(#z)",
        }}
      />
      <path
        d="M130.7 560.76h-1.22a3.21 3.21 0 0 1-3.2-3.2v-1.22c0-1.76 1.43-3.2 3.2-3.2h1.22c1.76 0 3.2 1.44 3.2 3.2v1.22c0 1.76-1.43 3.2-3.2 3.2Zm-1.22-6.95c-1.4 0-2.54 1.14-2.54 2.53v1.22c0 1.4 1.14 2.54 2.54 2.54h1.22c1.4 0 2.54-1.14 2.54-2.54v-1.22c0-1.4-1.14-2.53-2.54-2.53h-1.22Z"
        style={{
          fill: "url(#A)",
        }}
      />
      <path
        d="M66.84 400.46H13.98C6.26 400.46 0 406.72 0 414.44v135.49c0 7.7 6.3 14 14 14h52.82c7.73 0 14-6.27 14-14V414.44c0-7.69-6.29-13.99-13.98-13.99Zm13.1 149.46c0 7.24-5.87 13.12-13.12 13.12H14c-7.24 0-13.11-5.87-13.11-13.11V414.45c0-7.23 5.87-13.1 13.1-13.1h52.86c7.23 0 13.09 5.86 13.09 13.09v135.48Z"
        style={{
          fill: "url(#B)",
        }}
      />
      <path
        d="M1.03 412.59c.9-6.35 6.35-11.24 12.95-11.24h52.86c6.6 0 12.04 4.89 12.94 11.24h.89c-.92-6.82-6.78-12.13-13.85-12.13H13.98c-7.09 0-12.94 5.29-13.84 12.13h.9Z"
        style={{
          fill: "url(#C)",
          mixBlendMode: "screen",
        }}
      />
      <path
        d="M32.93 563.03H14c-7.24 0-13.11-5.87-13.11-13.11v-18.53H0v18.52c0 7.7 6.3 14 14 14h18.94c0-.09-.02-.16-.02-.25 0-.21.01-.42.02-.64Z"
        style={{
          fill: "url(#D)",
          mixBlendMode: "screen",
        }}
      />
      <path
        d="M79.93 531.39v18.52c0 7.24-5.87 13.12-13.12 13.12H49.57c0 .21-.01.42-.01.64 0 .09-.02.16-.03.25h17.28c7.73 0 14-6.27 14-14v-18.53h-.89Z"
        style={{
          mixBlendMode: "screen",
          fill: "url(#E)",
        }}
      />
      <path
        d="M69.88 401.35H10.94c-5.55 0-10.05 4.5-10.05 10.05v141.57c0 5.56 4.51 10.06 10.06 10.06h58.92c5.56 0 10.06-4.51 10.06-10.06V411.4c0-5.55-4.5-10.05-10.05-10.05Zm8.08 147.58c0 5.56-4.51 10.06-10.06 10.06H12.93c-5.56 0-10.06-4.51-10.06-10.06V415.44c0-5.55 4.5-10.05 10.05-10.05h54.99c5.55 0 10.05 4.5 10.05 10.05v133.49Z"
        style={{
          fill: "url(#F)",
        }}
      />
      <rect
        x={80.54}
        y={430.92}
        width={1.23}
        height={19.47}
        rx={0.12}
        ry={0.12}
        style={{
          fill: "url(#c)",
        }}
      />
      <rect
        x={80.54}
        y={430.92}
        width={1.23}
        height={19.47}
        rx={0.12}
        ry={0.12}
        style={{
          fill: "url(#d)",
          mixBlendMode: "multiply",
        }}
      />
      <rect
        x={80.54}
        y={470.86}
        width={1.23}
        height={7.05}
        rx={0.12}
        ry={0.12}
        style={{
          fill: "url(#G)",
        }}
      />
      <rect
        x={80.54}
        y={470.86}
        width={1.23}
        height={7.05}
        rx={0.12}
        ry={0.12}
        style={{
          fill: "url(#H)",
          mixBlendMode: "multiply",
        }}
      />
      <rect
        x={80.54}
        y={458.37}
        width={1.23}
        height={7.05}
        rx={0.12}
        ry={0.12}
        style={{
          fill: "url(#I)",
        }}
      />
      <rect
        x={80.54}
        y={458.37}
        width={1.23}
        height={7.05}
        rx={0.12}
        ry={0.12}
        style={{
          mixBlendMode: "multiply",
          fill: "url(#J)",
        }}
      />
    </g>
  </svg>
)

export default SvgComponent
