import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
import { memo } from "react"
const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 471.25 461.57"
    {...props}
  >
    <Path
      d="M427.55 216.2v30.68c-1.59 2.41-1.02 5.25-1.34 7.85-3.57 29.07-13.37 55.85-29.23 80.4-8.87 13.74-19.14 26.34-31.54 37.11-.52-5.69-.65-11.41-1.41-17.1-4.04-30.21-17.29-55.68-39.11-76.81-12.58-12.18-27.34-20.94-43.46-27.53.33-.36.63-.78 1.01-1.08 24.66-19.06 35.8-44.03 32.01-75.06-2.56-20.97-12.78-37.99-29.15-51.21-14.34-11.59-30.95-17.09-49.34-17.39 0-22.1.02-44.21.03-66.31 12.14-.6 24.16.75 35.99 3.12 47.51 9.54 86.32 33.43 116.02 71.74 20.04 25.84 32.4 55.08 37.41 87.44.73 4.72.57 9.57 2.11 14.16Z"
      style={{
        fill: "#fe8900",
      }}
    />
    <Path
      d="M281.46 250.79c16.12 6.59 30.88 15.35 43.46 27.53 21.83 21.13 35.07 46.61 39.11 76.81.76 5.69.89 11.4 1.41 17.1-.15.2-.27.43-.45.6-28.24 25.62-61.18 41.44-98.79 47.66-5.07.84-10.28.68-15.24 2.22H236l.06-157.83c8.44-.26 16.7-1.53 24.77-4.1 7.37-2.34 13.94-6.28 20.63-10Z"
      style={{
        fill: "#4b5be5",
      }}
    />
    <Path
      d="M236.06 264.89 236 422.72h-14.96c-6.21-1.72-12.67-1.79-18.98-2.96-35.85-6.65-67.32-22.12-94.45-46.46-.27-.24-.43-.6-.64-.9.38-55.02 32.17-100.95 84.01-121.42 1.31.71 2.65 1.36 3.92 2.12 12.67 7.59 26.34 11.54 41.14 11.48l.02.32Z"
      style={{
        fill: "#7985ea",
      }}
    />
    <Path
      d="M190.98 250.96c-51.84 20.47-83.63 66.41-84.01 121.42-12.49-10.72-22.8-23.34-31.73-37.08-57.79-88.89-31.87-208.37 57.53-265.45 30.7-19.6 64.27-29.56 100.65-30.21.87-.02 1.74.06 2.61.1 0 22.1-.02 44.21-.03 66.31v.36c-3.89-.47-7.74.19-11.53.74-58.67 8.47-87.4 77.01-52.45 124.88 5.36 7.34 12.09 13.18 18.97 18.94Z"
      style={{
        fill: "#fea920",
      }}
    />
    <Path
      d="M281.46 250.79c-6.69 3.72-13.26 7.65-20.63 10-8.07 2.57-16.33 3.84-24.77 4.1l-.02-.32c-.02-52.72-.03-105.44-.05-158.16v-.36c18.38.3 35 5.8 49.34 17.39 16.36 13.22 26.59 30.25 29.15 51.21 3.78 31.03-7.36 56-32.01 75.06-.39.3-.68.72-1.01 1.08Z"
      style={{
        fill: "#fec373",
      }}
    />
    <Path
      d="M235.99 106.41c.02 52.72.03 105.44.05 158.16-14.8.06-28.48-3.9-41.14-11.48-1.27-.76-2.62-1.41-3.92-2.12-6.87-5.76-13.61-11.61-18.96-18.94-34.95-47.87-6.22-116.41 52.45-124.88 3.8-.55 7.64-1.21 11.53-.74Z"
      style={{
        fill: "#fedaa8",
      }}
    />
  </Svg>
)
const Memo = memo(SvgComponent)
export default Memo
