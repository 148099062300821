export default function decreaseBrightness(color) {
  // Parse the color string to get the RGB components
  let rgbValues;

  if (color.startsWith("#")) {
    // Convert HEX color to RGB
    const hex = color.replace("#", "");
    rgbValues = {
      r: parseInt(hex.substr(0, 2), 16),
      g: parseInt(hex.substr(2, 2), 16),
      b: parseInt(hex.substr(4, 2), 16),
    };
  } else if (color.startsWith("rgb(") && color.endsWith(")")) {
    // Extract RGB values from "rgb(r, g, b)" string
    const rgb = color
      .replace("rgb(", "")
      .replace(")", "")
      .split(",")
      .map((val) => parseInt(val.trim(), 10));

    rgbValues = {
      r: rgb[0],
      g: rgb[1],
      b: rgb[2],
    };
  } else {
    throw new Error("Invalid color format. Please use HEX or RGB.");
  }

  // Decrease brightness by 10%
  const decreaseFactor = 0.9;
  rgbValues.r = Math.max(0, Math.floor(rgbValues.r * decreaseFactor));
  rgbValues.g = Math.max(0, Math.floor(rgbValues.g * decreaseFactor));
  rgbValues.b = Math.max(0, Math.floor(rgbValues.b * decreaseFactor));

  // Convert back to HEX color
  const hexColor =
    "#" +
    ((1 << 24) + (rgbValues.r << 16) + (rgbValues.g << 8) + rgbValues.b)
      .toString(16)
      .slice(1);

  return hexColor;
}