import * as React from "react"
import Svg, {
  SvgProps,
  Defs,
  LinearGradient,
  Stop,
  Path,
} from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    {...props}
    viewBox="0 0 1091.01 1091.01"
  >
    <Defs>
      <LinearGradient
        id="b"
        x1={892.19}
        y1={-54.98}
        x2={198.82}
        y2={1145.98}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#d5d5d5" />
        <Stop offset={0.01} stopColor="#d7d7d7" />
        <Stop offset={0.15} stopColor="#e9e9e9" />
        <Stop offset={0.3} stopColor="#f5f5f5" />
        <Stop offset={0.51} stopColor="#fcfcfc" />
        <Stop offset={1} stopColor="#fff" />
      </LinearGradient>
      <LinearGradient
        id="a"
        x1={545.5}
        y1={37.92}
        x2={545.5}
        y2={0}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#1b1b1b" />
        <Stop offset={0.91} stopColor="#575757" />
      </LinearGradient>
      <LinearGradient id="c" y1={1091.01} y2={1053.09} xlinkHref="#a" />
      <LinearGradient
        id="d"
        x1={1053.09}
        y1={545.5}
        x2={1091.01}
        y2={545.5}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#2e2e2e" />
        <Stop offset={0.3} stopColor="#333" />
        <Stop offset={0.69} stopColor="#444" />
        <Stop offset={1} stopColor="#575757" />
      </LinearGradient>
      <LinearGradient
        id="e"
        x1={0}
        y1={545.5}
        x2={37.92}
        y2={545.5}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#1b1b1b" />
        <Stop offset={0.33} stopColor="#2a2a2a" />
        <Stop offset={0.96} stopColor="#535353" />
        <Stop offset={1} stopColor="#575757" />
      </LinearGradient>
    </Defs>
    <Path
      d="M37.92 37.92v1015.17h1015.17V37.92H37.92Zm933.92 933.92H119.17V119.17h852.67v852.67Z"
      style={{
        fill: "url(#b)",
        filter: "url(#drop-shadow-1)",
      }}
    />
    <Path
      style={{
        fill: "url(#a)",
      }}
      d="M37.92 37.92h1015.17L1091.01 0H0l37.92 37.92z"
    />
    <Path
      style={{
        fill: "url(#c)",
      }}
      d="M37.92 1053.09h1015.17l37.92 37.92H0l37.92-37.92z"
    />
    <Path
      style={{
        fill: "url(#d)",
      }}
      d="M1053.09 37.92v1015.17l37.92 37.92V0l-37.92 37.92z"
    />
    <Path
      style={{
        fill: "url(#e)",
      }}
      d="M37.92 37.92v1015.17L0 1091.01V0l37.92 37.92z"
    />
  </Svg>
)

export default SvgComponent
