import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import Modal from 'react-native-modal';
import { BlurView, } from 'expo-blur';
import { useSelector } from 'react-redux';
import { DefaultRootState } from '@reduxLocal/persistState';
import styles from '@stylesheet';

const MessageModal = () => {
  const [visibleState, setVisibleState] = useState(false);
  const theme = useSelector((state: DefaultRootState) => state.app.theme.lightTheme);
  const { message } = useSelector((state: DefaultRootState) => state.promiseData.message);
  const [refresh, setRefresh] = useState(false)

  // if (messageStatus) {
  //   setVisibleState(true)
  // }

  // useEffect(() => {
  //   setRefresh(!refresh)
  // }, [])

  // const Backdrop = () => {
  //   return (
  //     <BlurView intensity={70} tint={theme ? 'light' : 'dark'}>
  //       <View style={{ flex: 1 }}></View>
  //     </BlurView>
  //   )
  // }
  return (
    <Modal style={{ flex: 1 }}
      animationIn={'rubberBand'}
      animationOut={'fadeOut'}
      hasBackdrop={true}
      coverScreen={true}
      // customBackdrop={Backdrop()}
      isVisible={visibleState}>
      <View style={[styles.modalContainerAuth, { backgroundColor: theme ? 'white' : 'grey' }]}>
        <View style={{ flex: 3 }}>
          <Text adjustsFontSizeToFit >
            {message}
          </Text>
        </View>
        <TouchableOpacity onPress={() => { setVisibleState(false) }} style={styles.Button}>
          <Text adjustsFontSizeToFit >Close</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  )
}

export default MessageModal;