import { useEffect, useState, memo } from 'react';
import { Platform, TextProps, Text } from 'react-native';
import React from 'react';
import * as Animatable from 'react-native-animatable';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';

interface AnimatedTextProps extends TextProps {
	value: string;
	speed?: number;
	delay?: number;
	fontSize?: number;
	fontColor?: string;
	onAnimationComplete?: Function;
	center?: boolean;
	lineHeight?: number;
	retain?: number;
}

function AnimatedTextFunction({
	value,
	speed = 200,
	delay,
	fontSize = 12,
	fontColor,
	onAnimationComplete,
	lineHeight,
	center,
	retain,
	...props
}: AnimatedTextProps) {
	const [wordIndex, setWordIndex] = useState(0);
	const [opacity, setOpacity] = useState(true);
	const theme = themeSelector();

	useEffect(() => {
		if (!value) return;
		
		const words = value.split(' ');

		const displayNextWord = () => {
			if (wordIndex < words.length) {
				setWordIndex((index) => index + 1);
			} else {
				setOpacity(false);
				setTimeout(() => onAnimationComplete && onAnimationComplete(), retain || 1000);
			}
		};

		const timeoutId = setTimeout(displayNextWord, speed);

		return () => clearTimeout(timeoutId);
	}, [wordIndex, onAnimationComplete, value, speed, retain]);

	return (
		<Text
			adjustsFontSizeToFit={Platform.OS === 'android' ? false : true}
			style={{
				flexWrap: 'wrap',
				fontSize: fontSize,
				fontWeight: '400',
				justifyContent: 'space-evenly',
				textAlign: center ? 'center' : 'justify',
				color: theme && !fontColor ? fontColor || 'black' : fontColor || 'white',
				lineHeight:  lineHeight || (fontSize || 24) * 1.5,
			}}
			{...props}
		>
			{value && value.split(' ').slice(0, wordIndex).join(' ')}
			{Platform.OS === 'web' && (
				<Animatable.View animation="pulse" style={{ opacity: opacity ? 1 : 0, minWidth: 15, flex: 1 }}>
					<Animatable.Text animation="fadeIn" style={{ color: theme ? 'black' : 'white', flex: 1, lineHeight: fontSize * 1.5 }}>
						_
					</Animatable.Text>
				</Animatable.View>
			)}
		</Text>
	);
}

export default memo(AnimatedTextFunction)
