import { View, Text } from 'react-native';
import * as SVG from 'react-native-svg';

export const rules = {
  "heading1": (node, children, parent, styles) => (
    <Text key={node.key} style={[styles.heading, styles.heading1, { marginVertical: 10 }]}>
      {children}
    </Text>
  ),
  "heading2": (node, children, parent, styles) => (
    <Text adjustsFontSizeToFit numberOfLines={1} key={node.key} style={[styles.heading, { marginVertical: 10, marginBottom: 20 }, styles.heading2]}>
      {children}
    </Text>
  ),
  "heading3": (node, children, parent, styles) => (
    <Text key={node.key} style={[styles.heading, styles.heading3]}>
      {children}
    </Text>
  ),
  "heading4": (node, children, parent, styles) => (
    <View style={{ flexDirection: 'row', marginTop: 10 }}>
      <SVG.Svg width={30} height={30} style={{ flex: 1 }}>
        <SVG.Circle cx={10} cy={10} r={5} scale={1.5} stroke={'rgba(90,192,234,33)'} strokeWidth={2} fill={'white'} />
      </SVG.Svg>
      <Text key={node.key} style={[styles.heading, { flex: 10, justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontWeight: 'bold' }, styles.heading4]}>
        {children}
      </Text>
    </View>
  ),
  "heading5": (node, children, parent, styles) => (
    <Text key={node.key} style={[styles.heading, { left: 25, fontWeight: 600 }, styles.heading5]}>
      {children}
    </Text>
  ),
  "code_inline": (node, children, parent, styles) => (
    <Text key={node.key} style={[styles.code, { lineHeight: 24 }]}>
      {children}
    </Text>
  ),
  "inline": (node, children, parent, styles) => (
    <Text key={node.key} style={[styles.inline, {lineHeight: 35}]}>
      {children}
    </Text>
  ),
  "paragraph": (node, children, parent, styles) => (
    <Text key={node.key} style={[styles.paragraph, { lineHeight: 25 }]}>
      {children}
    </Text>
  ),
};
