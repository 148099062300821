import * as Yup from 'yup';

// This function will take an array of schema and generate a yup validation schema for each of them
export default function generateYupValidation(schemaArray: any[]) {
  return Yup.object().shape(
		Object.fromEntries(
			schemaArray.map((item) => {
				const { title, type, content } = item;
				if (content?.type === 'number') {
					if (content?.required) {
						return [title, Yup.number().required(`is required`)];
					} else {
						return [title, Yup.number()];
					}
				}
				if (content?.type == 'dropDown') {
					return [title, Yup.string().required(`is required`)];
				}
				if (content && content?.required && !content?.validation) {
					if (type.includes('image')) {
						return [
							type,
							Yup.lazy((value) =>
								/^data/.test(value)
									? Yup.string()
											.trim()
											.matches(
												/^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)$/i,
												'Must be a valid data URI'
											)
											.required()
									: Yup.string()
											.trim()
											.url('Must be a valid URL')
											.required()
							),
						];
					}
					if (type.includes('date' || 'dateTime')) {
						return [
							type,
							Yup.date()
								.nullable()
								.required(`is required`)
								.min('1969-11-13', 'Date is too early'),
						];
					} else {
						return [title, Yup.string().required(`is required`)];
					}
				} else if (content && content?.validation) {
					// Start building the validation for the current item
					let validation = Yup.string();

					// Apply validations based on 'content'
					Object.entries(content?.validation).forEach(
						([key, value]) => {
							switch (key) {
								case 'minCharacters':
									validation = validation.min(
										value,
										`Too Short! Minimum ${value} characters.`
									);
									break;
								case 'maxCharacters':
									validation = validation.max(
										value,
										`Too Long! Maximum ${value} characters.`
									);
									break;
								case 'email':
									validation = validation.email(
										'Invalid email address'
									);
									break;
								case 'fieldType':
									if (value == 'email') {
										validation = validation.email(
											'Invalid email address'
										);
									}
									if (value == 'password') {
										validation = validation.min(
											8,
											`Too Short! Minimum ${8} characters.`
										);
									}
									if (value == 'text') {
										validation = validation.min(
											1,
											`Too Short! Minimum ${1} characters.`
										);
									}
									break;
								case 'url':
									validation = validation.url('Invalid URL');
									break;
								case 'matches':
									// For matching a regular expression
									validation = validation.matches(
										value,
										'Invalid format'
									);
									break;
								case 'required':
									validation =
										validation.required('Required');
									break;
								case 'numeric':
									validation = validation.matches(
										/^[0-9]+$/,
										'Must be a numeric value'
									);
									break;
								case 'alpha':
									validation = validation.matches(
										/^[a-zA-Z]+$/,
										'Must contain only letters'
									);
									break;
								case 'alphanumeric':
									validation = validation.matches(
										/^[a-zA-Z0-9]+$/,
										'Must be alphanumeric'
									);
									break;
								case 'uppercase':
									validation = validation.matches(
										/^[A-Z]+$/,
										'Must be uppercase'
									);
									break;
								case 'lowercase':
									validation = validation.matches(
										/^[a-z]+$/,
										'Must be lowercase'
									);
									break;
								case 'date':
									validation = validation.date(
										'Invalid date format'
									);
									break;
								case 'oneOf':
									validation = validation.oneOf(
										value,
										`Must be one of: ${value.join(', ')}`
									);
									break;
								case 'positive':
									validation = validation.moreThan(
										0,
										'Must be a positive number'
									);
									break;
								case 'negative':
									validation = validation.lessThan(
										0,
										'Must be a negative number'
									);
									break;
								case 'integer':
									validation =
										validation.integer(
											'Must be an integer'
										);
									break;
								case 'startsWith':
									validation = validation.matches(
										new RegExp(`^${value}`),
										`Must start with: ${value}`
									);
									break;
								case 'endsWith':
									validation = validation.matches(
										new RegExp(`${value}$`),
										`Must end with: ${value}`
									);
									break;
								// Add more cases for other validation types
								default:
									break;
							}
						}
					);
					return [title, validation];
				}
				return [title, undefined];
			})
		)
  );
}
