import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Pressable } from 'react-native';
import { GiftedChat } from 'react-native-gifted-chat';
import { Title } from '@constants/Themed';
import striptags from 'striptags';
import { AntDesign } from '@expo/vector-icons';
import styles from '@stylesheet';

interface HelpChatProps {
  selection: any;
  flip: () => void;
  defaultContent?: boolean;
}

const HelpChat = ({ selection, flip, defaultContent }: HelpChatProps) => {
  const [messageArray, setMessageArray] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const timeoutRef = useRef(null);

  useEffect(() => {
    // Clear the timeout when flip is called to prevent further message pushing
    const clearTimeoutOnFlip = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };

    // Call the cleanup function when flip is called
    flip && clearTimeoutOnFlip();

    if (selection) {
      if (defaultContent) {

      }
      const htmlContent = selection?.content?.rendered;
      const paragraphsArray = splitHTMLIntoParagraphs(htmlContent, defaultContent);

      // Start pushing messages with the first message's timeout
      pushNextMessage(paragraphsArray);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [selection, currentMessageIndex, flip]);

  const pushNextMessage = async (paragraphsArray) => {

    if (currentMessageIndex < paragraphsArray.length) {
      const nextMessage = paragraphsArray[currentMessageIndex];

      const textLength = currentMessageIndex > 0 ? paragraphsArray[currentMessageIndex - 1].text.length : 10;
      const timeoutDuration = textLength * 20 + 700;

      // Wrap setTimeout in a Promise
      await new Promise((resolve) => {
        // Set a timeout and resolve the Promise after the duration
        timeoutRef.current = setTimeout(() => {
          resolve();
        }, timeoutDuration);
      });

      setMessageArray((prevMessages) =>{
        const text = nextMessage.text.replace(/^\s+/g, '');

        return GiftedChat.append(prevMessages, {
          _id: Math.round(Math.random() * 1000000).toString(),
          text: striptags(text),
          createdAt: new Date(nextMessage.sentAt),
          user: {
            _id: 0,
            name: 'System',
            avatar: 'https://storage.googleapis.com/smartsaas-avatar-store/smartsaas-logo-complete.png',
          },
        })
      }
      );
      setCurrentMessageIndex(currentMessageIndex + 1);
    }
  };


  return (
    <View style={{ flex: 1, gap: 5 }}>
      <View style={{ flexDirection: 'row' }}>
        <Pressable style={[styles.centerEverything, { flex: 1, maxWidth: 40 }]} onPress={() => flip()}>
          <AntDesign name="back" size={24} color="black" />
        </Pressable>
        <View style={{ flex: 4, padding: 10 }}>
          <Title>{selection?.title?.rendered}</Title>
          <Text numberOfLines={3}>{striptags(selection.excerpt?.rendered)}</Text>
        </View>
      </View>
      <GiftedChat
        messages={messageArray}
        renderInputToolbar={() => null}
        user={{
          _id: 1,
        }}
      />
    </View>
  )
};

function splitHTMLIntoParagraphs(html, knowledge = false) {
  let result = [];
  if (!html) return [{ text: 'No content, add some content to preview your help article', sentAt: Date.now(), sentBy: 'system' }, { link: 'https://www.smartsaas.co.uk', text: 'Learn more about SmartSaaS', sentAt: Date.now(), sentBy: 'system' }];
  if (knowledge) {
    const divs = html.split('&lt;div&gt;').map((div) => div.trim());

    divs.forEach((text) => {
      text = text.replace(/&lt;\/div&gt;$/, '');
      // Remove leading spaces or indentation
      if (text.length > 0) {
        result.push({
          text: text,
          sentAt: Date.now(),
          sentBy: "system",
        });
      }
    });
  } else {
    const paragraphs = html.split('<p>').map((paragraph) => paragraph.trim());

    paragraphs.forEach((text) => {
      // Remove leading spaces or indentation
      if (text.length > 0) {
        result.push({
          text: text,
          sentAt: Date.now(),
          sentBy: "system",
        });
      }
    });
  }
  if(knowledge){
    result.shift();
    result.pop();
  }
  return result;
}


export default HelpChat;
