import * as React from "react"

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.52 37.53" {...props}>
    <path
      d="M21.26 15.86 39.85 1c1.28 0 2.08 1.39 1.44 2.5l-9.3 16.1-9.3 16.1a1.662 1.662 0 0 1-2.88 0l-9.29-16.1L1.23 3.5C.59 2.39 1.39 1 2.67 1l18.59 14.86Z"
      style={{
        fill: "#c66",
        stroke: "#ae2f30",
        strokeMiterlimit: 10,
        strokeWidth: 2,
      }}
    />
    <path
      d="M21.1 17.26 39.58 1.51c1.17 0 1.9 1.27 1.32 2.28l-9.58 16.59-8.38 13.67c-.59 1.01-.74 1.58-1.77 1.58"
      style={{
        fill: "#cc769a",
      }}
    />
  </svg>
)

export default SvgComponent
