const MenuType = [
  { label: 'Management', value: 'default' },
  { label: 'Admin/Ops', value: 'adminOps' },
  // { label: 'Research & Development', value: 'r&d' },
  { label: 'Customer Service', value: 'customerServices' },
  { label: 'Accounting & Finance', value: 'accountingFinance' },
  { label: 'Inventory', value: 'inventory' },
  { label: 'Marketing & Sales', value: 'marketingSales' },
  { label: "Learning", valkue: 'learning' }
];

export default MenuType;