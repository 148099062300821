import { Modal } from '@constants/Themed';
import { Platform, ScrollView, Text, View, useWindowDimensions } from 'react-native'
import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../UI/Button/Button';
import WebView from 'react-native-webview';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultRootState } from '@reduxLocal/persistState';
import { ACCEPT_TERMS, HIDE_LOADING_MODAL, HIDE_TC_MODAL } from '@reduxLocal/actions/types';
import styles from '@stylesheet';
import apis from '@api/api';
import Checkbox from 'expo-checkbox';
import { Title } from '@constants/Themed';
import moment from 'moment';
import { XStack } from 'tamagui';

interface TCModalTypes {
  countryCode?: string,
  userId?: string
}

const TCModal = () => {
  const visible = useSelector((state: DefaultRootState) => state.modals.tcModal?.modalDisplayed);
  // const visible = true;
  const userId = useSelector((state: DefaultRootState) => state.modals.tcModal?.userId); 
  const [checked, setChecked] = useState(false);
  const [tcHtml, setTcHtml] = useState();
  const [loading, setLoading] = useState(false);
  const [tcVersion, setTCVersion] = useState('');
  const [activeCheck, setActiveCheck] = useState(false);
  const { width, height } = useWindowDimensions();
  const [date, setDate] = useState();
  const [error, setError] = useState(false);
const dispatch = useDispatch();

  const handleGetTerms = async () => {
    setLoading(true);
    await fetch('https://www.smartsaas.co.uk/wp-json/wp/v2/pages/2754', {
      method: 'GET',
    }).then(async res => {
      await res.json().then(data => {
        setTCVersion(data.title.rendered);
        setTcHtml(data.content.rendered);
        setDate(data.content.modified);
        //TODO Implement function for applying the correct terms and conditions version
        setLoading(false);
      })
    })
      .catch(e => {
        setError(true)
      })
  }


  const activateCheck = useCallback(() => {
    setActiveCheck(true);
  }, [loading])


  const setTC = async () => {
    await apis.updateTC(userId, tcVersion).then(res => {
      if (res.status === 200) {
        dispatch({ type: ACCEPT_TERMS });
        setTimeout(() => dispatch({ type: HIDE_TC_MODAL }), 100);
      }
    })
  }


  useEffect(() => {
    handleGetTerms();
    setTimeout(() => activateCheck(), 2000)
  }, [])

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={() => dispatch({ type: HIDE_TC_MODAL })}
      style={{ flex: 1, minWidth: Platform.OS === 'web' ? "80%" : undefined, minHeight: "60vh", paddingVertical: '5%' }}
      deviceWidth={width}
      onModalWillShow={() => dispatch({ type: HIDE_LOADING_MODAL })}
      deviceHeight={height}
      extraChildren={(
        <XStack style={[styles.centerEverything, { flex: 1, minHeight: 50, bottom: '-10vw' }]}>
          <View style={{ flexDirection: 'row', gap: 30, flex: 1, minHeight: 50 }}>
            <Button customStyle={{ minWidth: 120 }} onPress={async () => {
              await apis.cancelUserCreation(userId).finally(() => {
                dispatch({ type: HIDE_TC_MODAL })
              })
            }} defaultColor='red' buttonText={'Cancel'} />
            <Button customStyle={{ backgroundColor: !checked ? 'grey' : 'rgb(90,192,234)', minWidth: 120 }} onPress={() => setTC()} buttonText={'Proceed'} disabled={checked ? false : true} />
          </View>
        </XStack>
      )}
    >
      <View style={{ gap: 20, flex: 1, alignSelf: "stretch",minHeight:"80vh" }}>
        <View style={{ flex: 10, backgroundColor: '#ececec', borderRadius: 25, padding: 30, minHeight: '100%', marginHorizontal: -15 }}>
          <Title style={{ top: -10, color:"black", textAlign: "center" }}>Please accept our Terms and Conditions to proceed</Title>
          {
            !error ?
              <WebView source={{ html: tcHtml }} style={{ flex: 1, backgroundColor: 'white', padding: Platform.OS != 'web' ? 7 : 5, }} containerStyle={{ flex: 1, padding: 3, marginHorizontal: -15 }} />
              :
              <View style={{ flex: 1, paddingVertical: 100, gap: 50 }}>
                <Text style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: 24 }}>Could not connect to server to download terms and conditions, please check your network connection</Text>
                <Button onPress={() => dispatch({ type: HIDE_TC_MODAL })} buttonText={"Return to login"} />
              </View>
          }
          <View style={{ flex: 0.1, flexDirection: 'row', gap: 10, minHeight: 70, justifyContent: "center", alignContent: 'space-around', paddingVertical: 20 }}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ padding: 10, flex: 4, gap: 15 }}>
                <View style={{ flexDirection: "row", borderBottomWidth: 1, paddingBottom: 10 }}>
                  <Text style={[styles.centerEverything, { verticalAlign: 'middle', textAlign: 'left', flex: 2, flexWrap: 'wrap' }]}>Check this box to agree to our terms and conditions</Text>
                  <Checkbox style={{ alignSelf: "flex-end" }} value={checked} disabled={!activateCheck} onValueChange={setChecked} />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontStyle: 'italic', textAlign: 'center', fontSize: 12, flex: 1 }}>Version: {tcVersion}</Text>
                  <Text style={{ fontStyle: 'italic', textAlign: 'center', fontSize: 12, flex: 1 }}>Date: {date || moment().format("ddd DD MMMM YYYY")}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  )
}
export default TCModal; 
