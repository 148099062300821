import * as React from "react"
import Svg, { Defs, LinearGradient, Stop, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      id="uuid-05d3b3b8-8ab4-479a-9aa9-399fe7119509"
      xmlns="http://www.w3.org/2000/svg"
      width={19.4}
      height={19.93}
      viewBox="0 0 19.4 19.93"
      {...props}
    >
      <Defs>
        <LinearGradient
          id="uuid-cdebc478-ef1a-4e40-9357-6dfed9f445b0"
          x1={-324.61}
          y1={411.04}
          x2={-323.85}
          y2={410.1}
          gradientTransform="matrix(19.4 0 0 -19.93 6296.35 8191.06)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0} stopColor="#5aedcc" />
          <Stop offset={1} stopColor="#4d53e2" />
        </LinearGradient>
      </Defs>
      <Path
        d="M17.1 10.95c.09-.65.09-1.31 0-1.95l2.1-1.65c.19-.15.24-.42.12-.64l-1.99-3.45a.51.51 0 00-.61-.22l-2.48 1c-.52-.4-1.08-.73-1.68-.98L12.18.42a.488.488 0 00-.49-.42H7.7c-.25 0-.45.18-.49.42l-.38 2.64c-.6.25-1.17.58-1.68.98l-2.48-1c-.23-.09-.49 0-.61.22L.07 6.71c-.13.21-.07.49.12.64L2.29 9c-.04.32-.07.65-.07.98 0 .33.03.65.07.98l-2.1 1.63c-.19.15-.24.42-.12.64l1.99 3.45c.12.21.38.3.61.22l2.48-1c.52.4 1.08.73 1.68.98l.38 2.64c.03.24.24.42.49.42h3.99c.25 0 .45-.18.49-.42l.38-2.64c.6-.25 1.17-.58 1.69-.98l2.48 1c.23.09.49 0 .61-.22l1.99-3.45a.51.51 0 00-.12-.64l-2.1-1.65zm-7.41 2.51c-1.93 0-3.49-1.56-3.49-3.49 0-1.93 1.56-3.49 3.49-3.49 1.93 0 3.49 1.56 3.49 3.49 0 1.93-1.56 3.49-3.49 3.49z"
        id="uuid-9463b18b-1f7c-4953-b385-2c7520974d98"
        fill="url(#uuid-cdebc478-ef1a-4e40-9357-6dfed9f445b0)"
      />
    </Svg>
  )
}

export default SvgComponent
