import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface TutorialState {
	activeTutorials: { id: number; title: string }[];
}

const initialState = { activeTutorials: [] } as TutorialState;

const tutorialSlice = createSlice({
	name: 'tutorials',
	initialState,
	reducers: {
		addTutorial(state, action) {
			state.activeTutorials = [action.payload];
		},
	},
});

export const { addTutorial } = tutorialSlice.actions;

const fetchTutorials = () => async dispatch => {
  
}

export default tutorialSlice.reducer;
