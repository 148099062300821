import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => {
  return (
    <Svg
      id="uuid-d2973ed1-c70e-47c6-b14e-b4e5a0391461"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 401.53 175"
      {...props}
    >
      <G id="uuid-dfa41ea5-492e-4dd2-a1bb-ffb971d88d44">
        <Path
          d="M392.83 62.78C264.79 103.34 136.74 13.32 8.7 53.88l17.4-72.91c116.44-26.9 232.89 64.96 349.33 38.06 5.8 14.58 11.6 29.16 17.4 43.75z"
          fill="#010101"
        />
        <Path
          d="M410.23 106.53C270.59 160.76 130.94 72.58-8.7 126.81L8.7 53.88c128.04-40.56 256.09 49.46 384.13 8.9 5.8 14.59 11.6 29.17 17.4 43.76z"
          fill="#dd0c15"
        />
        <Path
          d="M427.63 150.28c-151.24 67.89-302.49-18.46-453.73 49.44l17.4-72.91c139.64-54.23 279.29 33.96 418.93-20.27 5.8 14.58 11.6 29.17 17.4 43.75z"
          fill="#ffce05"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent;
