import { TouchableOpacity, Text, View, Image, Pressable } from "react-native";
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-native-modal';
import { HIDE_ACHIEVEMENT_MODAL } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "@stylesheet";
import LottieView from 'lottie-react-native';
import * as Animatable from 'react-native-animatable';
import { useNavigation } from "@react-navigation/native";
import React from 'react';
import { use } from "i18next";
import { PanGestureHandler, State } from "react-native-gesture-handler";
import Animated, {
  useSharedValue,
  withSpring,
  useAnimatedGestureHandler,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { BlurView } from "expo-blur";

const AchievementModal = () => {
  const visible = useSelector((state: DefaultRootState) => state.modals.achievementModal.modalDisplayed)
  const achievement = useSelector((state: DefaultRootState) => state.modals.achievementModal.modalContent);
const navigation = useNavigation();
  const panRef = useRef();
  const [xyValue, setXYValue] = useState(0);
  const translateY = useSharedValue(xyValue);
  const dispatch = useDispatch();

  const hideAchievementModal = () => {
    dispatch({ type: HIDE_ACHIEVEMENT_MODAL })
  }

  useEffect(() => {
    if (visible) {
      translateY.value = 0;
      setTimeout(hideAchievementModal, 10000)
    }
    return () => {
      panRef.current = null;
    }
  }, [visible])


  const handleNavigation = useCallback(() => {
    navigation.navigate('Settings', { screen: 'Achievements' });
    dispatch({ type: HIDE_ACHIEVEMENT_MODAL })
  }, []);

  const handlePanGesture = ({ nativeEvent }) => {
    const { translationY, state } = nativeEvent;

    if (state === State.ACTIVE) {
      translateY.value = translationY;
    }

    if (state === State.END) {
      if (translationY < -5) {
        translateY.value = withSpring(-400);
        setTimeout(hideAchievementModal, 200);
      } else if (translationY > 1) {
        translateY.value = withSpring(0);
      }
    }
  };

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translateY.value }],
    };
  });

  return (
    <Modal
      isVisible={visible}
      animationIn={'slideInDown'}
      coverScreen={false}
      hasBackdrop={false}
      animationOut={'slideOutUp'}
      style={{ alignSelf: 'flex-end', position: 'absolute', justifyContent: 'flex-start' }}
    >
      <PanGestureHandler ref={panRef} onGestureEvent={handlePanGesture} onHandlerStateChange={handlePanGesture}>
        <Pressable onPress={handleNavigation} style={{ flex: 1 }}>
          <Animated.View style={[animatedStyle, { flexDirection: 'row', maxHeight: 300, overflow: 'hidden', padding: 20, borderRadius: 20, margin: 20, alignSelf: 'flex-end', width: 300 }]} >
            <BlurView style={{ zIndex: -1, position: 'relative', alignSelf: 'stretch', minWidth: '100%', borderRadius: 20, }} blurType="light" blurAmount={10} />
            <View style={{ flex: 1, zIndex: 2 }}>
              <LottieView source={require('@assets/animations/achievementTrophy.json')} style={{ overflow: 'hidden' }} autoPlay />
            </View>
            <View style={{ flex: 3 }}>
              <Text adjustsFontSizeToFit style={{ fontWeight: 'bold' }}>{achievement ? achievement.description?.toString() : null}</Text>
              <Text adjustsFontSizeToFit style={{ fontWeight: 'bold' }}>{achievement ? achievement.date?.toString() : null}</Text>
            </View>
            <Image source={{ uri: `https://storage.googleapis.com/smartsaas-achievement-image-store/${achievement?.id}.png` }} style={{ width: 60, height: 60, borderRadius: 40 }} />
          </Animated.View>
          <LottieView source={require('@assets/animations/celebration.json')} style={{ position: 'absolute', zIndex: -3, top: -80, left: -20 }} autoPlay />
        </Pressable>
      </PanGestureHandler>
    </Modal>
  )
}

export default AchievementModal;