import React, { useState, useRef, useEffect } from 'react';
import { Pressable, Platform, View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import AnimatedLottieView from 'lottie-react-native';
import styles from '@stylesheet';
import { DefaultRootState } from '@reduxLocal/persistState';
import { useTranslation } from 'react-i18next';
import LottieView from 'lottie-react-native';
import * as Animatable from 'react-native-animatable';

// ErrorBoundary component
const SecondaryMenu = ({ item, index }) => {
  const page = useSelector((state: DefaultRootState) => state.navigation.page);
  const { t } = useTranslation();
  const [buttonState, setButtonState] = useState(false);
  const navigation = useNavigation();
  const hoveredStyle = styles.menuHovered;
  const unMarkedStyle = styles.menuItemSelected;
  const markedStyle = styles.menuItem;
  const secondaryItemRef = useRef<LottieView | null>(null);
  const [iconSource, setIconSource] = useState(null);

  useEffect(()=>{
    return () => {
      secondaryItemRef.current = null;
    }
  },[])

  const confirmedScreen = () => {
    try {
      if (page.params?.screen === item.screen && item.parent === page.name) {
        return [markedStyle, { top: 30 }];
      } else {
        return unMarkedStyle;
      }
    } catch (e) {
      return unMarkedStyle;
    }
  };

  const handlePress = () => {
    if (item.parent) {
      navigation.replace(item.parent, { screen: item.screen });
    } else {
      navigation.replace(item.primaryScreen, { screen: item.screen });
    }
  };

  useEffect(() => {
    async function importIcon() {
      // Dynamically import the icon
      import(`@assets/animations/${item.path}`)
        .then((module) => {
          // Set the imported icon as the icon source
          setIconSource(module.default);
        })
        .catch((error) => {
          console.error('Failed to import icon:', error);
        });
    }
    importIcon();
  }, [item]);

  const calculateFontSize = (tabName) => {
    // Adjust this multiplier for your font and screen size
    const baseFontSize = 12;
    const maxLength = 7; // Maximum characters allowed before scaling
    const length = tabName.length;

    if (length <= maxLength) {
      return baseFontSize;
    } else {
      return baseFontSize * (maxLength / length);
    }
  };

  return (
    <Animatable.View animation={'slideInLeft'} duration={400} delay={index * 50} style={{ flex: 1, overflow:"visible", justifyContent: "center", alignContent: "center", alignItems: "center", left: -4 }}>
      <Pressable
        style={[{ flex: 1, overflow:"visible", alignSelf: 'stretch', minWidth: 60, paddingTop: 5, paddingVertical: 2 }]}
        hitSlop={10}
        onPress={handlePress}
        onHoverIn={()=>secondaryItemRef.current?.play()}
      >
        {({ hovered }) => (
          <Animatable.View duration={20} transition={'backgroundColor'} style={[hovered ? hoveredStyle : confirmedScreen(item), { flexDirection: 'column', flex: 1, alignSelf: 'stretch', overflow: 'visible', paddingVertical: 5, top: -10, gap: 2 }]}>
          <View style={{ flex: 1, backgroundColor: 'transparent', alignItems: 'center', overflow: 'visible', minHeight: 50 }}>
            {/* Render the Lottie animation */}
            <AnimatedLottieView
              source={iconSource}
              ref={secondaryItemRef}
                style={{ flex: 1, width: 40, height: 40, top: 5, transform: [{ scale: item.scale ? item.scale : 1 }] }}
              speed={0.5}
              autoPlay={false}
              loop={false}
              key={iconSource}
            />
          </View>
            <Text adjustsFontSizeToFit numberOfLines={2} selectable={false} style={[styles.menuItemText, { maxWidth: 60, flexWrap: "wrap", userSelect: "none", pointerEvents: "none", minWidth:80, fontSize: 12, paddingHorizontal: 5, textAlign: 'center', flex: 1, overflow:"visible" }]}>
            {t(item.header).toString()}
          </Text>
          </Animatable.View>
        )}
      </Pressable>
    </Animatable.View>
  );
};

export default SecondaryMenu;
