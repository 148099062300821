import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={24}
    height={24}
    {...props}
  >
    <Path
      d="M437.5 270.42h-20.83l-.02-145.27c0-23.05-18.7-41.81-41.67-41.81H125c-22.97 0-41.67 18.76-41.67 41.81v145.27H62.5c-11.52 0-20.83 9.33-20.83 20.84v62.91c0 34.47 28.03 62.5 62.5 62.5h291.66c34.47 0 62.5-28.03 62.5-62.5v-62.91c0-11.51-9.31-20.84-20.83-20.84ZM83.33 354.17v-42.08h16.64c1.35.27 2.76.42 4.2.42s2.84-.15 4.2-.42h66.6l20.34 16.28a20.87 20.87 0 0 0 13.02 4.56h83.34c4.74 0 9.34-1.61 13.02-4.57l20.32-16.27h66.62c1.36.27 2.76.42 4.2.42s2.85-.15 4.2-.42h16.64v42.08c0 11.49-9.34 20.84-20.84 20.84H104.17c-11.5 0-20.84-9.35-20.84-20.84Z"
      style={{
        fill: "#fff",
      }}
    />
  </Svg>
)

export default SvgComponent
