import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => {
  return (
    <Svg
      id="uuid-93dc1921-82f4-4c17-8c81-f7e8884df0cd"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 411.28 199.17"
      {...props}
    >
      <G id="uuid-a1c47159-b9b2-4a44-a86b-fb0ea80a265a">
        <G>
          <Path
            d="M26.73-21.66C20.79,6,14.85,33.66,8.91,61.32,2.97,88.99-2.97,116.65-8.91,144.32c-5.94,27.66-11.88,55.32-17.82,82.98,154.92-77.27,309.83,21.01,464.75-56.26-5.94-16.6-11.88-33.19-17.82-49.79-5.94-16.6-11.88-33.2-17.82-49.8-5.94-16.6-11.88-33.19-17.82-49.79C265.28,52.27,146.01-52.27,26.73-21.66Z"
            fill="#fff"
          />
          <Path
            d="M287.52,111.31c4.17,29.88-32.49,53.09-81.88,47.75-49.4-5.33-86.05-35.39-81.88-71.2,4.17-36.36,40.82-59.24,81.88-47.75,41.05,11.48,77.71,41.88,81.88,71.2Z"
            fill="#bc1730"
          />
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent;
