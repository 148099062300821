import currencyData from './currencies.json';

class CurrencyExtractor {
  static extract() {
    return currencyData.map(({ "Currency Name": name, Symbol: symbol, "Currency Code": code }) => ({
      label: symbol + '-' + name,
      value: code,
      name: name
    }));
  }
}

export default CurrencyExtractor;