import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import Svg, { Circle, Line } from 'react-native-svg';

const AnalogClock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const hours = time.getHours() % 12; // Convert to 12-hour format
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();

  // Calculate rotation angles for clock hands
  const hourRotation = 360 * (hours / 12) + 360 * (minutes / 60 / 12);
  const minuteRotation = 360 * (minutes / 60);
  const secondRotation = 360 * (seconds / 60);

  // Function to generate markings on the edge
  const renderEdgeMarkings = () => {
    const markings = [];
    for (let i = 0; i < 60; i++) {
      const angle = (360 / 60) * i;
      const length = i % 5 === 0 ? 8 : 4; // Longer markings for every 5th minute
      markings.push(
        <Line
          key={i}
          x1="100"
          y1="10"
          x2="100"
          y2={`10 + ${length}`}
          stroke="white"
          strokeWidth="1"
          strokeLinecap="round"
          transform={`rotate(${angle}, 100, 100)`}
        />
      );
    }
    return markings;
  };

  return (
    <Svg width="200" height="200" viewBox="0 0 200 200" style={{top: '5%'}}>
      {/* Clock Face */}
      <Circle cx="100" cy="100" r="90" fill="grey" stroke="white" />

      {/* Edge Markings */}
      {renderEdgeMarkings()}

      {/* Hour Hand */}
      <Line
        x1="100"
        y1="100"
        x2="100"
        y2="60"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        transform={`rotate(${hourRotation}, 100, 100)`}
      />

      {/* Minute Hand */}
      <Line
        x1="100"
        y1="100"
        x2="100"
        y2="40"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        transform={`rotate(${minuteRotation}, 100, 100)`}
      />

      {/* Second Hand */}
      <Line
        x1="100"
        y1="100"
        x2="100"
        y2="30"
        stroke="red"
        strokeWidth="1"
        strokeLinecap="round"
        transform={`rotate(${secondRotation}, 100, 100)`}
      />
    </Svg>
  );
};

export default AnalogClock;
