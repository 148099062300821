import { StyleSheet } from "react-native";

const searchStyles = StyleSheet.create({
  containerStyle: {
    overflow: "hidden", justifyContent: 'center', alignContent: 'center', alignItems: 'center', flexDirection: 'row', backgroundColor: 'white', borderRadius: 40, minHeight: 65, margin: 15, paddingHorizontal: 20, zIndex: 2, shadowRadius: 20, shadowOpacity: .1
  },
  inputStyle: {
    flex: 5, flexGrow: 7, backgroundColor: 'white', minHeight: 40, borderRadius: 30, paddingHorizontal: 10
  },
  iconStyle: {
    maxWidth: 50, flex: 1, alignSelf: 'center', alignContent: 'center', alignItems: 'center', minWidth: 50
  },
  searchIconStyle: {
    flex: 1, minWidth: 35, overflow: 'visible', zIndex: 1
  },
  iconArrayStyle: {
    flexDirection: 'row',
    gap: 10,
  }
})

export default searchStyles;