import { useSelector } from "react-redux";
import { DefaultRootState } from "@reduxLocal/persistState";
import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";

const useGlobalUser = () => {
  const userData = useSelector((state: DefaultRootState) => state.promiseData.data?.userData);
  const companyData = useSelector((state: DefaultRootState) => state.promiseData.data?.companyData);
  const currentCompany = useSelector((state: DefaultRootState) => state.app.selectedProfile);


  if (currentCompany) {
    return currentCompany.id;
  } else if (companyData?.id) {
    return companyData.id;
  } else {
    // Handle the case when neither userData nor companyData has an 'id' property.
    return userData.id; // You can return a default value or handle this case as needed.
  }
};

export default useGlobalUser;
