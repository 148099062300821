import { View } from 'react-native';
import lang from '@components/modals/LanguageModal/constant/languageList';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DefaultRootState } from '@reduxLocal/persistState';

export const FlagHandler = () => {
  const activeLanguage = useSelector((state: DefaultRootState) => state.app.language.value)

  const langList = lang;

  const ActiveFlag = useCallback(() => {
    const flag = langList.map((langItem) => {
      if (langItem.value === activeLanguage) {
        return (
          <View style={{ flex: 1 }} key={langItem.languageCode}>
            {langItem.source}
          </View>
        )
      } else return null
    })
    return flag
  }, [activeLanguage, langList])

  return (
    <View style={{ flex: 1, justifyContent: 'center' }}>
      <ActiveFlag />
    </View>
  )
}

export default FlagHandler;
