import * as React from "react"
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    id="uuid-b1fbf0b1-5839-4ea3-9b4f-d44b09c4a945"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 411.28 199.15"
    {...props}
  >
    <G id="uuid-f22e79e9-7316-4dee-97db-7f3986422034">
      <G>
        <Path
          d="m26.73-21.66c-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22,123.23-35.79,246.47,68.04,369.7,32.25-.66-1.84-1.32-3.69-1.98-5.53-.66-1.84-1.32-3.69-1.98-5.53-.66-1.84-1.32-3.69-1.98-5.53C265.28,52.27,146.01-52.27,26.73-21.66Z"
          style={{
            fill: "#185faa",
          }}
        />
        <Path
          d="m20.79,6c-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22,127.19-40.97,254.39,62.16,381.58,21.19-.66-1.84-1.32-3.69-1.98-5.53-.66-1.84-1.32-3.69-1.98-5.53-.66-1.84-1.32-3.69-1.98-5.53C267.26,74.04,144.03-29.79,20.79,6Z"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="m14.85,33.65c-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22,131.15-46.16,262.31,56.29,393.46,10.13-.66-1.84-1.32-3.69-1.98-5.53-.66-1.84-1.32-3.69-1.98-5.53-.66-1.84-1.32-3.69-1.98-5.53C269.24,95.82,142.05-7.32,14.85,33.65Z"
          style={{
            fill: "#185faa",
          }}
        />
        <Path
          d="m8.91,61.31c-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22-.66,3.08-1.32,6.16-1.98,9.23,135.11-51.34,270.23,50.4,405.34-.94-.66-1.85-1.32-3.69-1.98-5.54-.66-1.84-1.32-3.69-1.98-5.53-.66-1.84-1.32-3.69-1.98-5.53C271.22,117.6,140.07,15.15,8.91,61.31Z"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="m2.97,88.98c-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22,139.07-56.53,278.15,44.52,417.22-12-.66-1.84-1.32-3.69-1.98-5.53s-1.32-3.69-1.98-5.53c-.66-1.84-1.32-3.69-1.98-5.53-135.11,51.34-270.23-50.4-405.34.94Z"
          style={{
            fill: "#185faa",
          }}
        />
        <Path
          d="m-2.97,116.64c-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22,143.03-61.71,286.07,38.64,429.1-23.07-.66-1.84-1.32-3.69-1.98-5.53-.66-1.84-1.32-3.69-1.98-5.53-.66-1.84-1.32-3.69-1.98-5.53C275.18,161.16,136.1,60.11-2.97,116.64Z"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="m-8.91,144.29c-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22,146.99-66.89,293.99,32.76,440.98-34.13-.66-1.84-1.32-3.69-1.98-5.53-.66-1.84-1.32-3.69-1.98-5.53-.66-1.84-1.32-3.69-1.98-5.53-143.03,61.71-286.07-38.64-429.1,23.07Z"
          style={{
            fill: "#185faa",
          }}
        />
        <Path
          d="m-14.85,171.95c-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22-.66,3.08-1.32,6.16-1.98,9.23,150.96-72.08,301.91,26.88,452.87-45.2-.66-1.85-1.32-3.69-1.98-5.54-.66-1.84-1.32-3.69-1.98-5.53-.66-1.84-1.32-3.69-1.98-5.53C279.14,204.71,132.14,105.06-14.85,171.95Z"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="m-20.79,199.62c-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22-.66,3.07-1.32,6.15-1.98,9.22,154.92-77.26,309.83,21,464.75-56.26-.66-1.84-1.32-3.69-1.98-5.53-.66-1.84-1.32-3.69-1.98-5.53s-1.32-3.69-1.98-5.53c-150.96,72.08-301.91-26.88-452.87,45.2Z"
          style={{
            fill: "#185faa",
          }}
        />
        <Path
          d="m151.56,102.66c-51.51-6.28-103.02-6.96-154.53,13.97C6.93,70.54,16.83,24.44,26.73-21.66c44.17-11.34,88.35-4.14,132.52,7.87-2.57,38.82-5.13,77.63-7.7,116.45Z"
          style={{
            fill: "#185faa",
          }}
        />
        <Path
          d="m8.91,61.31c48.57-17.1,97.15-13.81,145.72-5.23.51-7.76,1.03-15.52,1.54-23.29-47.11-9.72-94.22-14.31-141.32.87-1.98,9.22-3.96,18.44-5.94,27.66Z"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="m79.74-27.24c-6.97,42.7-13.93,85.4-20.9,128.11,10.3-1.24,20.6-1.87,30.9-2.03,5.5-41.28,11-82.56,16.5-123.85-8.83-1.19-17.67-1.97-26.5-2.23Z"
          style={{
            fill: "#fff",
          }}
        />
      </G>
    </G>
  </Svg>
)

export default SvgComponent
