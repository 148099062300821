import React, { useState } from 'react';
import { Modal as SmallModal, View } from "react-native";
import LanguagePressable from './Languages';
import lang from './constant/languageList';

const Languages = (props) => {

  return (
    <View style={{ flexDirection: 'column' }}>
      {
        lang.map((lang, i) => {
          return (
            <LanguagePressable {...lang} key={i} />
          )
        })
      }

    </View>
  );
}

export default Languages