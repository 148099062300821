import * as React from "react"
import Svg, { SvgProps, Defs, ClipPath, Path, Mask, G } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={24}
    height={24}
    preserveAspectRatio="xMinYMin meet"
    {...props}
  >
    <Defs>
      <ClipPath id="a">
        <Path
          style={{
            fill: "none",
          }}
          d="M0 0h500v500H0z"
        />
      </ClipPath>
      <ClipPath id="b">
        <Path
          style={{
            fill: "none",
          }}
          d="M0 0h500v500H0z"
        />
      </ClipPath>
      <ClipPath id="c">
        <Path
          style={{
            fill: "none",
          }}
          d="M0 0h500v500H0z"
        />
      </ClipPath>
      <ClipPath id="e">
        <Path
          style={{
            fill: "none",
          }}
          d="M0 0h500v500H0z"
        />
      </ClipPath>
      <ClipPath id="f">
        <Path
          style={{
            fill: "none",
          }}
          d="M0 0h500v500H0z"
        />
      </ClipPath>
      <ClipPath id="h">
        <Path
          style={{
            fill: "none",
          }}
          d="M0 0h500v500H0z"
        />
      </ClipPath>
      <ClipPath id="i">
        <Path
          style={{
            fill: "none",
          }}
          d="M0 0h500v500H0z"
        />
      </ClipPath>
      <ClipPath id="j">
        <Path
          style={{
            fill: "none",
          }}
          d="M0 0h500v500H0z"
        />
      </ClipPath>
      <Mask
        id="d"
        x={0}
        y={-376}
        width={500}
        height={876}
        maskUnits="userSpaceOnUse"
      >
        <Path
          d="M500-376v500H0v-500h500Z"
          style={{
            fill: "#fff",
          }}
        />
        <Path d="M500-376v500H0v-500h500Z" />
      </Mask>
      <Mask
        id="g"
        x={0}
        y={-376}
        width={500}
        height={876}
        maskUnits="userSpaceOnUse"
      >
        <Path
          d="M500-376v500H0v-500h500Z"
          style={{
            fill: "#fff",
          }}
        />
        <Path d="M500-376v500H0v-500h500Z" />
      </Mask>
    </Defs>
    <G
      style={{
        clipPath: "url(#a)",
      }}
    >
      <G
        style={{
          clipPath: "url(#i)",
        }}
      >
        <Path
          d="M388.74 155.02H92.95l27.91 251.63c3.12 28.75 27.7 50.41 56.87 50.41h142.06c29.16 0 53.74-21.66 56.87-50.41l27.91-251.63h-15.83Zm-119.15 93.73c0-11.46 9.37-20.83 20.83-20.83s20.83 9.37 20.83 20.83v124.98c0 11.46-9.37 20.83-20.83 20.83s-20.83-9.37-20.83-20.83V248.75Zm-83.32 0c0-11.46 9.37-20.83 20.83-20.83s20.83 9.37 20.83 20.83v124.98c0 11.46-9.37 20.83-20.83 20.83s-20.83-9.37-20.83-20.83V248.75ZM413.25 81.79l-145.8 1.53-.22-20.83c-.12-11.5-9.53-20.73-21.05-20.61s-20.73 9.55-20.61 21.05l.22 20.83-145.8 1.53c-11.52.12-20.73 9.55-20.61 21.05s9.53 20.73 21.05 20.61l333.26-3.49c11.52-.12 20.73-9.55 20.61-21.05-.12-11.5-9.53-20.73-21.05-20.61Z"
          style={{
            fill: "#fff",
          }}
        />
      </G>
    </G>
  </Svg>
)

export default SvgComponent
