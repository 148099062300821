import { TouchableOpacity, Text, ScrollView, View } from "react-native";
import { useDispatch, useSelector } from 'react-redux';
import styles from "@stylesheet";
import Modal from 'react-native-modal';
import { HIDE_LANGUAGE_MODAL } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import Languages from './LangContent';
import { useTranslation } from "react-i18next";
import React from 'react';

const LangModal = () => {
  const visibleState = useSelector((state: DefaultRootState) => state.modals?.languageModal.modalDisplayed);
const dispatch = useDispatch();
  return (
    <View >
      <Modal
        animationIn={'bounceInDown'}
        animationInTiming={1000}
        animationOut={'slideOutUp'}
        isVisible={visibleState}
        coverScreen={false}
        hasBackdrop={true}
        backdropOpacity={0}
        onBackdropPress={() => { dispatch({ type: HIDE_LANGUAGE_MODAL, payload: null }) }}
        style={{ alignSelf: "center" }}
      >
        <View style={styles.modalContainerLanguage}>
          <ScrollView showsVerticalScrollIndicator={true}>
            <Languages style={{ flex: 1 }} />
          </ScrollView>
          <View style={{ flex: 0.5 }} />
          <TouchableOpacity onPress={() => { dispatch({ type: HIDE_LANGUAGE_MODAL, payload: null }) }} style={styles.Button}>
            <Text adjustsFontSizeToFit >Close</Text>
          </TouchableOpacity>
        </View>
      </Modal >
    </View >
  );
};

export default LangModal