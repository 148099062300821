import React, { useCallback, useMemo, useState, useLayoutEffect, useRef } from "react";
import { Platform, Pressable, View, Image, useWindowDimensions } from "react-native";
import { Text } from "@constants/Themed";
import * as Animatable from 'react-native-animatable';
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_DRAGGED_USER, FLIP_MESSAGE_MODAL, GET_MESSAGE_CONTENT, SET_DRAGGED_USER, SHOW_MESSAGE_MODAL, SHOW_USER_CONTEXT_MODAL } from "@reduxLocal/actions/types";
import apis from "@api/api";
import getId from "@api/getId";
import { themeSelector } from "@reduxLocal/selectors/themeSelector";
import { Feather } from "@expo/vector-icons";
import styles from "@stylesheet";
import { DefaultRootState, store } from "@reduxLocal/persistState";
import { Badge, Skeleton } from "@mui/material";
import AvatarHandler from "@components/Global/AvatarHandler/AvatarHandler";

const MessageUserListItem = ({ layoutWidth, messageViewRef, item, badge, index, clearBadge, setIdCallback }) => {
  const visibleState = useSelector((state: DefaultRootState) => state.modals.messageModal.modalDisplayed);
  const dispatch = useDispatch();
  const [hoveredState, setHoveredState] = useState(false);
  const userId = getId();
  const theme = themeSelector();
  const listItemRef = useRef(null);
  let listItemWidth;
  const nameCapitalised = useMemo(() => item.profile_information.name?.charAt(0).toUpperCase() + item.profile_information.name?.slice(1), [item.profile_information.name]);
  const surnameCapitalised = useMemo(() => item.profile_information.surname?.charAt(0).toUpperCase() + item.profile_information.surname?.slice(1), [item.profile_information.surname]);
  const departmentCapitalised = useMemo(() => item.department?.charAt(0).toUpperCase() + item.department?.slice(1), [item.department]);

  const returnAvailability = () => {
    switch (item.status) {
      case 'available':
        return '#03AC13';
      case 'busy':
        return '#DC143C';
      case 'away':
        return '#FF7F50';
      case 'offline':
        return '#A9A9A9';
      default:
        return 'white';
    }
  };

  const handlePress = useCallback(async () => {
    if (!visibleState) {
      dispatch({ type: SHOW_MESSAGE_MODAL })
    }
    //Dispatch flip without conversation ID at first, then login from messages awaits conversations id, loads conversation else creates new conversation. Otherwise on failure flips back to previous state.
    dispatch({
      type: FLIP_MESSAGE_MODAL,
      payload: {
        id: item._id,
        name: nameCapitalised,
        surname: surnameCapitalised,
        avatar: item.profile_information.avatar?.img || "https://storage.googleapis.com/smartsaas-avatar-store/Asset%202.png",
        department: item.profile_information.department,
        conversationId: undefined
      }
    });
    try {
      const res = await apis.getConversation(item.conversationId ? { conversationId: item.conversationId } : { userId: userId, participantId: item._id });
      if (res.status != 203) {
        setIdCallback?.(res.data?.data?._id, res.data.data.messages || []);
        dispatch({ type: GET_MESSAGE_CONTENT, data: res.data.data });
      } else if (res.status === 203) {
        setIdCallback?.('blank', []);
        dispatch({ type: GET_MESSAGE_CONTENT, data: { _id: '' } });
      }
      if (!visibleState) {
        clearBadge?.(item._id);
        // if (visibleStateFunction) visibleStateFunction(true);
        // dispatch({ type: SHOW_MESSAGE_MODAL })
      }
    } catch (error) {
      console.error(error);
    }
  }, [userId, item, visibleState, clearBadge]);

  useLayoutEffect(() => {
    if (listItemRef.current) {
      listItemWidth = listItemRef.current.offsetWidth;
      // Perform any actions based on the width, if needed
    }
  }, [listItemRef]);

  const _handleDrag = (e) => {
    dispatch({ type: SET_DRAGGED_USER, payload: item });
  }

  const _handleDragEnd = (e) => {
    dispatch({ type: SET_DRAGGED_USER, payload: null });
  }


  return useMemo(() => (
    <div style={{ flex: 1 }} draggable onDragStartCapture={_handleDrag} onDragEnd={_handleDragEnd}>
      <Animatable.View ref={listItemRef} key={item.key} duration={50} direction={hoveredState ? 'normal' : 'reverse'} transition="backgroundColor" style={{ backgroundColor: hoveredState ? 'rgba(0,0,0,0.2)' : 'transparent', marginVertical: 5, borderBottomWidth: 0.1, borderBottomColor: 'rgba(0,0,0,0.1)', flex: 1, minHeight: 80, maxHeight: 80, overflow: 'visible', shadowRadius: 10, shadowOpacity: .2 }}>
        <Pressable
          style={[styles.userMessageListItem, { paddingVertical: 10 }]}
          onPress={handlePress}
          onHoverIn={() => { setHoveredState(true) }}
          onHoverOut={() => { setHoveredState(false) }}
          onPressIn={() => { setHoveredState(true) }}
          onPressOut={() => { setHoveredState(false) }}
        >
          <Badge overlap="circular" anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }} color="error" max={999} style={{ userSelect: "none" }} badgeContent={badge} >
            <View style={{ overflow: 'visible', borderRadius: 20, alignItems: 'flex-start', alignContent: 'flex-start', justifyContent: 'flex-start', elevation: 1 }} >
              {
                item.profile_information.avatar || item.profile_information.avatar?.img ?
                  <AvatarHandler replacementImageStyle={{ left: -30, maxHeight: 40 }} avatarImageStyle={{ borderWidth: 0 }} avatar={item.profile_information.avatar?.img} />
                  :
                  <Skeleton variant="circular" width={40} height={40} />
              }
            </View>
          </Badge>
          {
            layoutWidth &&
            <Animatable.View animation={'fadeIn'} style={{ flex: 1, flexDirection: 'row', borderBottomColor: returnAvailability(), borderBottomWidth: 1, paddingHorizontal: 3, gap: 10, paddingVertical: 5 }}>
              <View style={{ flexDirection: 'column', minHeight: 20, justifyContent: 'flex-start', alignItems: 'flex-start', alignContent: 'flex-start', flex: 5 }}>
                <Text selectable={false} style={{ color: 'white', textAlign: 'left', fontWeight: '600' }}>{nameCapitalised} {surnameCapitalised}</Text>
                <Text selectable={false} >{departmentCapitalised}</Text>
                {__DEV__ && <Text selectable={true} style={{ color: 'white', fontSize: 10 }}>{item._id}</Text>}
              </View>
              <Pressable onPress={() => { dispatch({ type: SHOW_USER_CONTEXT_MODAL, payload: { id: item._id, name: nameCapitalised, surname: surnameCapitalised, department: departmentCapitalised, avatar: item.profile_information.avatar?.img } }) }}>
                <Feather name={"more-horizontal"} size={15} color={'white'} style={{ justifyContent: 'center', alignContent: 'center', alignSelf: 'center', flex: 1, marginTop: 8, marginRight: 10 }} />
              </Pressable>
            </Animatable.View>
          }
        </Pressable>
      </Animatable.View>
    </div>
  ), [item, badge, layoutWidth, hoveredState]);
};

export default MessageUserListItem;
