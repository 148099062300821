import React from 'react'
import { AnimatePresence } from '@tamagui/animate-presence'

import { Button, Image, XStack, YStack, styled } from 'tamagui'
import { AntDesign } from '@expo/vector-icons'

const GalleryItem = styled(YStack, {
  zIndex: 1,
  x: 0,
  opacity: 1,
  fullscreen: true,

  variants: {
    // 1 = right, 0 = nowhere, -1 = left
    going: {
      ':number': (going) => ({
        enterStyle: {
          x: going > 0 ? 1000 : -1000,
          opacity: 0,
        },
        exitStyle: {
          zIndex: 0,
          x: going < 0 ? 1000 : -1000,
          opacity: 0,
        },
      }),
    },
  } as const,
})

const wrap = (min: number, max: number, v: number) => {
  const rangeSize = max - min
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min
}

export default function HorizontalCarousel({ images, height }: { images: string[], height: number }) {
  const [[page, going], setPage] = React.useState([0, 0])
  const [galleryImages, setGalleryImages] = React.useState(images)

  const imageIndex = wrap(0, images.length, page)
  const paginate = (going: number) => {
    setPage([page + going, going])
  }

  return (
    <XStack
      overflow="hidden"
      backgroundColor="$colorTransparent"
      position="relative"
      height={height || 300}
      width="100%"
      alignItems="center"
    >
      <AnimatePresence initial={false} custom={{ going }}>
        <GalleryItem key={page} animation="slow" going={going}>
          <Image flex={1} backgroundColor={"$colorTransparent"} resizeMethod='resize' source={{ uri: images[imageIndex], height: height | 100 }} />
        </GalleryItem>
      </AnimatePresence>
      {
        images.length > 1 && (
          <>
            <Button
              accessibilityLabel="Carousel left"
              icon={<AntDesign name="arrowleft" size={24} color="white" />}
              size="$3"
              position="absolute"
              left="$4"
              circular
              elevate
              onPress={() => paginate(-1)}
              zi={100}
            />

            <Button
              accessibilityLabel="Carousel right"
              icon={<AntDesign name="arrowright" size={24} color="white" />}
              size="$3"
              position="absolute"
              right="$4"
              circular
              elevate
              onPress={() => paginate(1)}
              zi={100}
            />
          </>)
      }
    </XStack>
  )
}