import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={24}
    height={24}
    {...props}
  >
    <Path
      d="M380.19 62.53h-26.04c0-11.46-9.37-20.83-20.83-20.83H166.68c-11.46 0-20.83 9.37-20.83 20.83h-26.04c-20 0-36.45 16.46-36.45 36.45v322.86c0 20.21 16.46 36.45 36.45 36.45h260.37c20 0 36.45-16.25 36.45-36.45V98.98c0-20-16.46-36.45-36.45-36.45ZM187.51 93.78V83.37h124.98v41.66H187.51V93.79Zm-20.83 237.55c-11.46 0-20.83-9.37-20.83-20.83s9.37-20.83 20.83-20.83h166.64c11.46 0 20.83 9.37 20.83 20.83s-9.37 20.83-20.83 20.83H166.68Zm104.15 62.49c0 11.46-9.37 20.83-20.83 20.83h-83.32c-11.46 0-20.83-9.37-20.83-20.83s9.37-20.83 20.83-20.83H250c11.46 0 20.83 9.37 20.83 20.83Zm62.49-145.81H166.68c-11.46 0-20.83-9.37-20.83-20.83s9.37-20.83 20.83-20.83h166.64c11.46 0 20.83 9.37 20.83 20.83s-9.37 20.83-20.83 20.83Z"
      style={{
        fill: "#fff",
      }}
    />
  </Svg>
)

export default SvgComponent
