function toTitleCase(phrase: string) {
  if (phrase) {
    return phrase
      .toLowerCase()
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  } else return;
}

export default toTitleCase;
