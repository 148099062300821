import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: title */

const SvgComponent = (props: SvgProps) => (
  <Svg style={[{top: 5}, ...(Array.isArray(props.style) ? props.style : [props.style])]} xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <Path fill="#f25022" d="M1 1h9v9H1z" />
    <Path fill="#00a4ef" d="M1 11h9v9H1z" />
    <Path fill="#7fba00" d="M11 1h9v9h-9z" />
    <Path fill="#ffb900" d="M11 11h9v9h-9z" />
  </Svg>
)

export default SvgComponent
