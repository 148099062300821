import { updateObject } from './utils/globalStateFunctions';
import {
	GOOGLE_CALENDARS_ADDED,
	DATE_SELECTED,
	CLEAR_CALENDARS,
	MAKE_CALENDAR_VISIBLE,
	HIDE_CALENDAR,
	ADD_LOCAL_CALENDARS,
} from '../actions/types';

const initialState = {
	dateSelected: new Date().toISOString().split('T')[0],
	activeCalendars: [],
	availableCalendars: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ADD_LOCAL_CALENDARS:
			return updateObject(state, {
				...state,
				availableCalendars: action.payload,
			});
		case DATE_SELECTED:
			return updateObject(state, {
				...state,
				dateSelected: action.payload.date,
				eventList: action.payload.events,
			});
		case HIDE_CALENDAR:
			const calendarIdToRemove = action.payload;
			const updatedActiveCalendars = state.activeCalendars.filter(
				(calendar) => calendar.id !== calendarIdToRemove
			);

			return updateObject(state, {
				...state,
				activeCalendars: updatedActiveCalendars,
			});
		case MAKE_CALENDAR_VISIBLE: {
			const calendarToAdd = action.payload;
			// Check if the calendar is already in activeCalendars
			if (
				state.activeCalendars.some(
					(calendar) => calendar.id === calendarToAdd.id
				)
			) {
				return state; // Return the current state if the calendar is already active
			}
			return updateObject(state, {
				...state,
				activeCalendars: [...state.activeCalendars, calendarToAdd],
			});
		}
		case GOOGLE_CALENDARS_ADDED:
			return updateObject(state, {
				...state,
				availableCalendars: [
					...state.availableCalendars,
					...action.payload,
				],
				activeCalendars: [...state.activeCalendars, ...action.payload],
			});
		case CLEAR_CALENDARS:
			return updateObject(state, {
				...state,
				availableCalendars: [],
				activeCalendars: [],
			});
		default:
			return state;
	}
}
