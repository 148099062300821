export { default as en } from './en.json';
export { default as de } from './de.json';
export { default as fr } from './fr.json';
export { default as es } from './es.json';
export { default as ja } from './ja.json';
export { default as ar } from './ar.json';
export { default as tk } from './tk.json';
export { default as el } from './el.json';
export { default as zh } from './zh.json';
export { default as it } from './it.json';
export {default as ms} from './ms.json';