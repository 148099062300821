import { useState } from 'react';
import { View, Text, Image } from 'react-native';
import Modal from 'react-native-modal';
import { Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { HIDE_USER_CONTEXT_MODAL } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import styles from '@stylesheet';
import React from 'react';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import { Button, H2, Paragraph, YStack } from 'tamagui';
import AvatarHandler from '@components/Global/AvatarHandler/AvatarHandler';

const MessageSettings = ({ ...props }) => {
  const isVisible = useSelector((state: DefaultRootState) => state.modals.messageModal.userSettingsModal);
  const selectedProfile = useSelector((state: DefaultRootState) => state.modals.messageModal.userSettingsContent);
  const messageOpenState = useSelector((state: DefaultRootState) => state.modals.messageModal.modalDisplayed);
  const [buttonHovered, setButtonHovered] = useState(false);
  const dispatch = useDispatch();
  const theme = themeSelector();

  return (
    <Modal
      animationIn={'bounceInDown'}
      animationInTiming={1000}
      animationOut={'slideOutUp'}
      isVisible={isVisible}
      coverScreen={false}
      hasBackdrop={true}
      backdropOpacity={.6}
      style={{ flex: 1, flexDirection: 'row' }} >
      <YStack style={{ backgroundColor: '#ECECEC', borderRadius: 20, flexBasis: 'auto', elevation: 1, shadowRadius: 10, flex: 1, flexDirection: 'column', padding: 15 }}>
        <View style={{ flex: 1.5 }}>
          <AvatarHandler avatar={selectedProfile?.avatar} avatarImageStyle={{ height: 300, width: 300 }} />
        </View>
        <YStack style={{ flex: 4 }} />
        <H2 textAlign='center' adjustsFontSizeToFit style={{ color: 'black', fontSize: 24 }}>{selectedProfile ? selectedProfile.name + ' ' + selectedProfile.surname : null}</H2>
        <Paragraph textAlign='center' adjustsFontSizeToFit style={{ color: 'black', fontSize: 24 }}>{selectedProfile ? selectedProfile.department : null}</Paragraph>
        <Paragraph textAlign='center' adjustsFontSizeToFit style={{ color: 'black', fontSize: 24 }}>{selectedProfile ? selectedProfile.role : null}</Paragraph>
        <Button
          onPress={() => { dispatch({ type: HIDE_USER_CONTEXT_MODAL }) }}
        >
          Back
        </Button>
      </YStack>
    </Modal>
  )
}

export default MessageSettings; 