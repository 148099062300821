import React from 'react';
import { Pressable, Platform } from 'react-native';
import { Text, View } from '@constants/Themed';
import { Avatar } from '@mui/material';
import Button from '@components/UI/Button/Button';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import styles from '@stylesheet';
import getDaysAgo from '@utils/getDaysAgo';
import toTitleCase from '@hooks/toTitleCase';
import moment from 'moment';
import { rejectInvitation, acceptCompanyInvitation, acceptInvitation } from '@api/api';

const InviteItem = ({ request, onAccept, onReject }) => {
  const theme = themeSelector();
  //Convert this to a React Native component
  console.log(request, "request");
  return (
    <View style={[styles.noticeCardContainer, { backgroundColor: theme ? undefined : "black" }]} >
      <Pressable style={{ flex: 1, width: 50 }}>
        <Avatar src={request.from.profile_information.avatar.img} />
      </Pressable>
      <View style={{ flex: 5, gap: 20 }}>
        <Text><Text style={{ fontWeight: "bold" }}>{toTitleCase(request.from.profile_information.name) + ' ' + toTitleCase(request.from.profile_information.surname)}</Text> invited you to join their {toTitleCase(request.type)}</Text>
        {
          request.type === 'data' &&
          <Text style={{ fontSize: 16, marginBottom: 10 }} numberOfLines={1}>Entries: {request.entries}</Text>
        }
        {
          request.type === 'project' &&
          <>
            <Text style={{fontSize: 16, marginBottom: 10 }} numberOfLines={1}>Participants: {request.participants}</Text>
            <Text style={{fontSize: 16, marginBottom: 10 }} numberOfLines={1}>Start: {moment(request.startDate).format("ddd DD MM YYYY")}</Text>
            <Text style={{fontSize: 16, marginBottom: 10 }} numberOfLines={1}>End: {moment(request.endDate).format("ddd DD MM YYYY")}</Text>
          </>
        }
        {
          request.type === 'company' && request.users &&
          <>
            <Text style={{ fontSize: 16, marginBottom: 10 }} numberOfLines={1}>{request.users}</Text>
          </>
        }
        <Text>{getDaysAgo(request.createdAt)}</Text>
        <View style={{ flexDirection: "row", gap: 10 }}>
          <Button customStyle={{ borderRadius: 10 }} buttonText={"Accept"} onPress={async () => {
            if (request.type === 'data') {
              await acceptInvitation(request._id).then(() => onAccept)
            } else if (request.type === 'company') {
              await acceptCompanyInvitation({ id: request._id }).then(() => onAccept)
            }
          }} />
          <Button customStyle={{ borderRadius: 10 }} buttonText={"Decline"} onPress={async () => { await rejectInvitation(request._id).then(() => onReject) }} />
        </View>
      </View>
    </View>
  );
}
export default InviteItem;