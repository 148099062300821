import { StyleSheet, FlatList, Dimensions, Animated, useWindowDimensions } from "react-native";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Text, View } from "@constants/Themed";
import Modal from 'react-native-modal';
import { HIDE_NOTIFICATIONS_PANEL } from '@reduxLocal/actions/types';
import { DefaultRootState, store } from '@reduxLocal/persistState';
import { useState, useRef } from "react";
import SocketManager from "@utils/socket";
import NotificationItem from "./Components/NotificationItem";
import { useNavigation } from "@react-navigation/native";
import { themeSelector } from "@reduxLocal/selectors/themeSelector";
import { Sound } from "expo-av/build/Audio/Sound";
import { Tab, Tabs } from "@mui/material";
import RequestItem from "./Components/RequestItem";
import InviteItem from "./Components/InviteItem";
import ErrorBoundary from "@utils/ErrorBoundary";
import NoticeItem from "./Components/NoticeItem";
import { useQuery, useQueryClient } from "react-query";
import * as Animatable from 'react-native-animatable';
import apis from "@api/api";

const socketManager = new SocketManager('notifications');

const NotificationModal = () => {
  const visibleState = useSelector((state: DefaultRootState) => state.modals.notificationsPanel?.modalDisplayed);
  const notifications = useSelector((state: DefaultRootState) => state.notifications);
  const contextAvailable = useSelector((state: DefaultRootState) => state.UI.contextAvailable);
  const theme = themeSelector();
  const [notificationsArray, setNotificationsArray] = useState<any[]>([]);
  const [event, setEvent] = useState(notifications?.notificationList);
  const dimensionHeight = useWindowDimensions().height - 70;
  const [text, setText] = useState("");
  const [fullView, setFullView] = useState(false);
  const userId = useSelector((state: DefaultRootState) => state.promiseData.data.userData.id);
  const [tempList, setTempList] = useState([]);
  const [loading, setLoading] = useState(false);
  const socket = socketManager.getSocket(userId);
  const [sound, setSound] = React.useState<Sound>();
  const [selectedTab, setSelectedTab] = useState(0);
  const panRef = useRef();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const data = queryClient.getQueriesData('invitations');

  useEffect(() => {
    if (userId) {
      socket.connect();
    }
  }, [userId])

  if (!data) {
    useQuery('invitations', () => apis.getInvitations(), {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });
    return <Text>Loading...</Text>
  }

  const handleEventListUpdate = useCallback((newEvents) => {
    //Ensure no duplicates
    const filteredEvents = newEvents.filter((newEvent) => {
      return !event.find((oldEvent) => oldEvent._id === newEvent._id);
    });
    setEvent((prevEvents) => [...prevEvents, ...filteredEvents]);
  }, []);

  const _onAccept = useCallback((id) => {
    //Remove the event from the list
    setEvent((prevEvents) => prevEvents.filter((event) => event._id !== id));
  }, [event]);

  const _onReject = useCallback((id) => {
    //Remove the event from the list
    setEvent((prevEvents) => prevEvents.filter((event) => event._id !== id));
  }, [event]);

  useEffect(() => {
    if (data && data[0]) {
    if (data[0][1]) {
      const newData = data[0][1]?.map((item: any) => {
        // if(item.to?._id !== userId) return;
        item.noticeType = 'invitation';
        return item;
      });
      handleEventListUpdate(newData);
    } else {
      //Remove all invitation type events
      setEvent((prevEvents) => prevEvents.filter((event) => event.noticeType !== 'invitation'));
    }
    } else {
      setEvent((prevEvents) => prevEvents.filter((event) => event.noticeType !== 'invitation'));
    }
  }, [])

  //Add listener for redux NEW_NOTIFICATION
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const lastAction = store.getState().lastAction;
      if (lastAction.type === 'NEW NOTIFICATION') {
        const newEvent = lastAction.payload;
        newEvent.noticeType = 'notification';
        setEvent((prevEvents) => [newEvent, ...prevEvents]);
      }
    });
    return () => unsubscribe();
  }, [])

  // const { status, data, error, isFetching, isLoading, refetch } = useQuery({
  //   queryKey: ['activityLog'],
  //   queryFn: async () => {
  //     const data = await apis.getActivityLog(userId)
  //       .then(res => {
  //         return res.data.data;
  //       });
  //     setEvent(data);
  //   },
  //
  // });


  return (
    <Modal
      animationIn={'slideInRight'}
      animationOut={'slideOutRight'}
      isVisible={visibleState}
      coverScreen={false}
      deviceWidth={Dimensions.get('window').width}
      deviceHeight={Dimensions.get('window').height-70}
      backdropOpacity={0}
      onBackdropPress={() => dispatch({ type: HIDE_NOTIFICATIONS_PANEL })}
      style={{ flex: 1, justifyContent: 'flex-end', alignContent: "flex-start", flexDirection: 'row', zIndex: 10 }}
    >
      <Animatable.View transition={'paddingRight'} style={{ elevation: 10, paddingHorizontal: 20, minHeight: Dimensions.get('window').height, paddingBottom: 70, paddingTop: 30, top: -60, minWidth: 600, right: -200, paddingRight: 200, shadowRadius: 5, shadowOffset: { width: -20, height: 20 }, maxWidth: 450, shadowOpacity: .2, width: "40%", alignSelf: "stretch", flex: 1, backgroundColor: theme ? 'white' : '#333333' }}>
        <Text selectable={false} style={{ paddingVertical: 20, fontSize: 24, borderBottomWidth: 1, borderColor: "#00000060", textAlign: "right" }}>Notifications</Text>
        <Tabs value={selectedTab} onChange={({ eventPhase }) => setSelectedTab(eventPhase)}>
          <Tab onClick={() => setSelectedTab(0)} style={{ color: theme ? "black" : "white" }} label="All" value={0} />
          <Tab onClick={() => setSelectedTab(1)} style={{ color: theme ? "black" : "white" }} label="Invitations" value={1} />
          <Tab onClick={() => setSelectedTab(2)} style={{ color: theme ? "black" : "white" }} label="Requests" value={2} />
        </Tabs>
        <Animated.View style={{ flexWrap: 'wrap', flex: 4, alignSelf: 'stretch' }}>
          <FlatList
          extraData={event}
            contentContainerStyle={{ gap: 5, paddingVertical: 10 }}
            style={{ flex: 3, marginHorizontal: -15, paddingHorizontal: 15, minHeight: 200, alignSelf: 'stretch', }}
            windowSize={10}
            data={event?.filter((item: any) => {
              switch (selectedTab) {
                case 0:
                  return true;
                case 1:
                  return item.noticeType === 'invitation';
                case 2:
                  return item.noticeType === 'request';
              }
            }) || []}
            renderItem={({ item }) => {
              switch (item?.noticeType) {
                case 'notification':
                  return (
                    <ErrorBoundary>
                      <NoticeItem item={item}  />
                    </ErrorBoundary>
                  )
                case 'request':
                  return (
                    <ErrorBoundary>
                      <RequestItem request={item} onAccept={_onAccept} onReject={_onReject} />
                    </ErrorBoundary>
                  )
                case 'invitation':
                  return data && (
                    <ErrorBoundary>
                      <InviteItem request={item} onAccept={_onAccept} onReject={_onReject} />
                    </ErrorBoundary>
                  )
              }
            }}
          />
        </Animated.View>
      </Animatable.View>
    </Modal>
  );
};

export default NotificationModal;