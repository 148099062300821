import * as React from "react"

const SvgComponent = (props) => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 63.12 55.64"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#fff}.cls-2{opacity:.15}"}</style>
    </defs>
    <g id="Layer_1-2">
      <path
        className="cls-1"
        d="M19.55 34.94c-.24 1.42-.49 2.68-.65 3.96-.24 1.82-.72 3.69-.53 5.48.21 2.01.93 3.99 1.65 5.9.85 2.23 1.4 4.86 4.49 5.18.92.1 1.85.25 2.76.16 2.93-.29 4.23-1.8 4.23-4.74 0-2-.02-3.99.02-5.99 0-.48.1-1.03.35-1.43 2.24-3.75 4.49-7.5 6.8-11.2.68-1.09 1.54-1.73 3.14-1.68 6.11.17 12.23.07 18.35.07 1.5 0 2.47-.65 2.69-2.12.23-1.57.26-3.18.27-4.78.03-6.8.02-13.59 0-20.39 0-1.98-1.06-3.34-2.64-3.34-5.61-.02-11.21 0-16.82 0-1.3 0-1.99.79-2.43 1.86-.19.45-.26.95-.4 1.51-.39-.16-.69-.3-1-.42-1.01-.38-2.02-1.07-3.03-1.08-8.88-.09-17.76-.17-26.63 0-4.46.09-7.22 3.68-6.27 8.22.12.59.18 1.08-.29 1.71-1.27 1.71-1.45 3.7-.95 5.78.09.37-.05.88-.27 1.21-1.15 1.7-1.49 3.5-1 5.5.11.44-.01 1.03-.24 1.43C-.1 28-.49 30.31.83 32.64 2 34.7 4.06 34.96 6.2 34.95c4.37-.02 8.74 0 13.36 0Z"
      />
      <path d="M19.55 34.94c-4.62 0-8.99-.01-13.36 0-2.14 0-4.2-.25-5.37-2.31-1.32-2.32-.93-4.64.32-6.9.22-.4.34-.99.24-1.43-.49-2-.16-3.8 1-5.5.22-.32.36-.84.27-1.21-.5-2.08-.32-4.07.95-5.78.47-.63.42-1.12.29-1.71-.96-4.54 1.81-8.13 6.27-8.22 8.87-.17 17.75-.09 26.63 0 1.01 0 2.02.7 3.03 1.08.31.12.61.25 1 .42.15-.56.22-1.06.4-1.51C41.66.79 42.35 0 43.65 0c5.61 0 11.21-.01 16.82 0 1.58 0 2.64 1.36 2.64 3.34 0 6.8.02 13.59 0 20.39 0 1.59-.04 3.21-.27 4.78-.22 1.47-1.18 2.12-2.69 2.12-6.12 0-12.23.1-18.35-.07-1.59-.05-2.45.59-3.14 1.68-2.32 3.7-4.56 7.45-6.8 11.2-.24.41-.34.95-.35 1.43-.04 2-.02 3.99-.02 5.99 0 2.94-1.3 4.45-4.23 4.74-.91.09-1.84-.06-2.76-.16-3.09-.32-3.64-2.95-4.49-5.18-.73-1.91-1.44-3.89-1.65-5.9-.19-1.79.29-3.65.53-5.48.16-1.27.42-2.53.65-3.96ZM41.92 7.08c-1.27-.55-2.31-.86-3.2-1.43-1.15-.74-2.34-1.01-3.69-1.01-7.9.03-15.8 0-23.7.04-.84 0-1.76.24-2.52.63C6.54 6.46 6.36 9 8.39 10.46c.68.49.47.76-.1 1.09-.62.35-1.3.64-1.81 1.11-1.31 1.22-1.66 3.54-.02 4.41.43.23.76.64 1.13.97-.42.3-.84.6-1.27.9-.21.14-.45.23-.65.38-2.18 1.69-2.01 3.85.4 5.21.17.09.29.27.6.56-.5.27-.86.51-1.25.68-2.02.88-3.14 3.53-2.02 5.08.8 1.11 1.89 1.23 3 1.24 5.05.04 10.11.02 15.16.02.48 0 .97.04 1.48.07-.51 2.75-1.02 5.33-1.44 7.92-.2 1.22-.52 2.53-.27 3.68.54 2.43 1.34 4.82 2.18 7.17.67 1.88 2.88 2.48 4.52 1.35.28-.19.6-.58.61-.89.05-1.95-.04-3.91.02-5.86.03-1.04-.05-2.25.43-3.1 2.23-3.91 4.67-7.69 6.96-11.56 1.17-1.98 2.97-3.19 4.84-4.32.76-.46 1.04-.95 1.03-1.82-.04-5.27-.02-10.53-.02-15.8V7.08Zm18.11-4.16H44.59v24.8h15.44V2.92Z" />
      <path
        className="cls-1"
        d="M41.92 7.08v1.87c0 5.27-.02 10.53.02 15.8 0 .87-.27 1.35-1.03 1.82-1.87 1.13-3.67 2.34-4.84 4.32-2.29 3.87-4.73 7.66-6.96 11.56-.48.84-.4 2.05-.43 3.1-.05 1.95.03 3.91-.02 5.86 0 .3-.33.69-.61.89-1.64 1.13-3.85.53-4.52-1.35-.84-2.35-1.65-4.74-2.18-7.17-.25-1.15.07-2.46.27-3.68.42-2.59.94-5.17 1.44-7.92-.52-.02-1-.07-1.48-.07-5.05 0-10.11.02-15.16-.02-1.12 0-2.2-.12-3-1.24-1.11-1.55 0-4.2 2.02-5.08.39-.17.76-.41 1.25-.68-.3-.29-.43-.46-.6-.56-2.42-1.35-2.59-3.52-.4-5.21.2-.15.44-.24.65-.38.43-.29.84-.6 1.27-.9-.37-.33-.7-.74-1.13-.97-1.64-.86-1.3-3.18.02-4.41.51-.47 1.19-.76 1.81-1.11.57-.32.79-.6.1-1.09-2.03-1.47-1.85-4 .42-5.15.76-.38 1.67-.62 2.52-.63 7.9-.05 15.8-.01 23.7-.04 1.35 0 2.53.27 3.69 1.01.88.56 1.93.88 3.2 1.43ZM60.03 2.92v24.8H44.59V2.92h15.44Z"
      />
      <path
        className="cls-2"
        d="m44.74 3.04 15.44-.01v24.74l-4.61-17.14-10.83-7.59zM41.99 25.98s-4.36-9.23-14.03-12.47S7.37 8.86 7.15 8.34s-.07-1.77.37-2.07 2.14-1.85 7.09-1.7 21.26.07 21.55.07.66.15.66.15l1.77.89s1.77.81 2.07.89 1.41.64 1.41.64.51 17.74-.08 18.78Z"
      />
    </g>
  </svg>
)

export default SvgComponent
