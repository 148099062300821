import AnimatedLottieView from "lottie-react-native"
import React from 'react'

export default function returnIcon (type) {
	switch (type) {
		case 'update':
			return (
				<AnimatedLottieView
					style={{ flex: 1, height: 30, width: 30 }}
					source={require('../../../../../assets/animations/111742-diamond-upgrade-reward-animation.json')}
					autoPlay
					autoSize
				/>
			);
		case 'insert':
			return (
				<AnimatedLottieView
					style={{ flex: 1, height: 30, width: 30 }}
					source={require('../../../../../assets/animations/newfile.json')}
					autoPlay
					autoSize
				/>
			);
		default:
			return (
				<AnimatedLottieView
					style={{ flex: 1, height: 30, width: 30 }}
					source={require('../../../../../assets/animations/88006-warning-yellow.json')}
					autoPlay
					autoSize
				/>
			);
	}
};
