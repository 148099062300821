import * as React from "react"
import Svg, { SvgProps, Defs, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props: SvgProps) => (
  <Svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={24}
    height={24}
    {...props}
  >
    <Defs></Defs>
    <Path
      id="_Path3_"
      className="cls-1"
      d="M395.25 156.27h-40.54c-11.81 0-21.39 9.58-21.39 21.39v228.01c0 11.81 9.58 21.39 21.39 21.39h40.54c11.81 0 21.39-9.58 21.39-21.39V177.66c0-11.81-9.58-21.39-21.39-21.39Z"
      style={{ fill: '#fff' }}
    />
    <Path
      id="_Path2_"
      className="cls-1"
      d="M267.21 72.94H232.8c-13.5 0-24.45 10.94-24.45 24.45v305.2c0 13.5 10.96 24.45 24.45 24.45h34.41c13.5 0 24.45-10.96 24.45-24.45V97.4c0-13.52-10.96-24.45-24.45-24.45Z"
      style={{ fill: '#fff' }}

    />
    <Path
      id="_Path1_"
      className="cls-1"
      d="M148.89 239.58h-47.74c-9.83 0-17.79 7.96-17.79 17.79v151.89c0 9.83 7.96 17.79 17.79 17.79h47.74c9.83 0 17.79-7.96 17.79-17.79V257.37c0-9.83-7.96-17.79-17.79-17.79Z"
      style={{ fill: '#fff' }}

    />
  </Svg>
)

export default SvgComponent
