import FontAwesome from '@expo/vector-icons/FontAwesome';
import { useEffect, useState } from 'react';
import { View, Pressable, Text } from 'react-native';
import * as WebBrowser from "expo-web-browser";
import { makeRedirectUri, useAuthRequest, useAutoDiscovery } from 'expo-auth-session';
import { SHOW_AUTH_MODAL } from '@reduxLocal/actions/types';
import { store } from '@reduxLocal/persistState';
import { createURL } from 'expo-linking';
import { MicrosoftIcon } from '@assets/--native';


WebBrowser.maybeCompleteAuthSession();

const MicrosoftButton = () => {

  const discovery = useAutoDiscovery('https://login.microsoftonline.com/cbf48daa-58ec-49a9-9032-8b7077454562/v2.0');

  const [request, response, promptAsync] = useAuthRequest(
    {
      clientId: 'd9317384-abec-4b01-bcdd-db0da0803901',
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      redirectUri: 'msauth.com.anonymous.smartsaas://auth',
    },
    discovery
  );


  return (
    <Pressable onPress={() => {
      promptAsync().then(res => {
        console.log(res);
      })
    }} style={{ elevation: 1, shadowRadius: 10, shadowOpacity: .2, flexDirection: 'row', justifyContent: 'center', alignContent: 'space-around', alignItems: 'stretch', marginVertical: 5, backgroundColor: 'white', minHeight: 45, borderRadius: 20, minWidth: 270 }}>
      <MicrosoftIcon style={{ justifyContent: 'center', alignSelf: 'center', top: 3.5 }} />
      <View style={{ justifyContent: 'center', alignItems: 'center', minWidth: 220 }}><Text adjustsFontSizeToFit style={{ color: 'black', opacity: .7, fontWeight: '600' }}>Sign in with Microsoft</Text></View>
    </Pressable>
  );
}

export default MicrosoftButton;