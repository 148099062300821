import * as React from "react"
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    id="uuid-c6e1d303-bdd1-45f6-b958-4b2e3d03bd05"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 411.28 199.17"
    {...props}
  >
    <G id="uuid-bf4180f4-b208-4a15-9892-edc7cf21b9e4">
      <G>
        <Path
          d="m26.73-21.66C20.79,6,14.85,33.66,8.91,61.32c-5.94,27.67-11.88,55.33-17.82,83-5.94,27.66-11.88,55.32-17.82,82.98,154.92-77.27,309.83,21.01,464.75-56.26-5.94-16.6-11.88-33.19-17.82-49.79-5.94-16.6-11.88-33.2-17.82-49.8-5.94-16.6-11.88-33.19-17.82-49.79C265.28,52.27,146.01-52.27,26.73-21.66Z"
          style={{
            fill: "#e3131b",
          }}
        />
        <Path
          d="m160.32,133.91c-32.09-3.25-55.59-22.73-52.49-47.09,3.1-24.46,28.6-41.9,56.97-35.78,15.91,3.19,29.97,12.1,39.7,22.74-11.64-16.72-31.6-31.72-54.8-36.23-34.86-7.44-67.23,17.51-72.29,49.24-5.05,31.53,23.84,53.19,64.53,56,26.6,1.83,50.18-5.14,62.49-17.83-10.36,7.19-26.38,10.76-44.09,8.95Z"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="m223.39,79.99c4.74,5.59,9.58,11.1,14.5,16.52,7.55-.47,15.04-.98,22.48-1.57-4.33,3.81-8.75,7.64-13.26,11.5,5.03,5.29,10.15,10.47,15.36,15.53-8-2.62-15.94-5.42-23.82-8.34-4.62,3.9-9.33,7.84-14.13,11.83-.14-5.79-.28-11.58-.42-17.37-7.82-3.05-15.58-6.18-23.29-9.34,7.73-.5,15.41-.95,23.03-1.4-.15-5.79-.29-11.58-.44-17.36Z"
          style={{
            fill: "#fff",
          }}
        />
      </G>
    </G>
  </Svg>
)

export default SvgComponent
