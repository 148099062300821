const addCompanyInfo = [
  {
    fieldName: 'organizationName',
    placeHolderValue: 'Company / Organisation Name',
    fieldState: 'companyName',
    type: 'organisation',
    furtherInformation: false,
  },
  //{
   // fieldName: 'organizationNumber',
   // placeHolderValue: 'Company Number',
   // fieldState: 'companyNumber',
   // type: 'organisation',
   // furtherInformation: false,
  //},
  {
    fieldName: 'industryType',
    placeHolderValue: 'Industry Type',
    fieldState: 'industryType',
    type: 'all',
    furtherInformation: false,
  },
  {
    fieldName: 'jobTitle',
    placeHolderValue: 'Role at Company',
    fieldState: 'role',
    type: 'organisation',
    furtherInformation: false,
  },
  {
    fieldName: 'interests',
    placeHolderValue: 'Interests',
    fieldState: 'interests',
    type: 'individual',
    furtherInformation: false,
  },
];
export default addCompanyInfo;
