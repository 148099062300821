import React, { useEffect, useRef } from 'react';
import { View, Animated, ViewStyle, Platform } from 'react-native';
import Svg, { Defs, LinearGradient, Stop, Path } from 'react-native-svg';
import * as Animatable from 'react-native-animatable';
import { isTablet } from 'react-native-device-info';
import { uniqueId } from 'lodash';

interface BackgroundProps {
  green?: boolean;
  purple?: boolean;
  style?: any;
  id?: string;
  /**
   * Tint color for the background in rgba format
   * @example 'rgba(255,255,255,0.5)'
   * 
   */
  tintColor?: string;
  children?: JSX.Element | JSX.Element[];
  containerStyle?: ViewStyle;
}

const SvgComponent = ({ green, purple, tintColor, ...props }: BackgroundProps) => {
  const { id } = props
  const slideAnim1 = useRef(new Animated.Value(0)).current;
  const slideAnim2 = useRef(new Animated.Value(0)).current;
  const newKey = id + uniqueId();

  const calculateDarkerColor = (color: string) => {
    if(!color) return 'rgb(90,192,234)';
    //Calculate a slightly darker color
    const colorArr = color.split(',').map((e) => parseInt(e));
    const r = colorArr[0] - 10;
    const g = colorArr[1] - 10;
    const b = colorArr[2] - 10;
    return `rgb(${r},${g},${b})`;
  }

  const tintColorCalc = () => {
    //User tint color to generate two stop colors
    //If tintColor is not provided, use the default colors
    if ((!green || !purple) && tintColor) {
      //Calculate two stop colors based on the tint color
      const tintColor1 = tintColor;
      const tintColor2 = calculateDarkerColor(tintColor); //Calculate slightly different color
      return { tintColor1, tintColor2 };
    } else {
      //Use default colors
      const tintColor1 = green ? '#30BAFF' : purple ? '#ce2aff' : 'rgb(90,192,234)';
      const tintColor2 = green ? '#06E925' : purple ? '#ce2aff' : '#0400e9';
      return {
        tintColor1, tintColor
      }
    }
  }
  let { tintColor1, tintColor2 } = tintColorCalc();


  const stopColor1 = tintColor1
  const stopColor2 = tintColor2

  const tintStopColor1 = stopColor1; // Use tintColor or default
  const tintStopColor2 = stopColor2; // Use tintColor or default

  useEffect(() => {
    Animated.timing(slideAnim1, {
      toValue: 1,
      duration: 1000, // Adjust the duration as needed
      useNativeDriver: true,
    }).start();

    setTimeout(() => {
      Animated.timing(slideAnim2, {
        toValue: 1,
        duration: 1000, // Adjust the duration as needed
        useNativeDriver: true,
      }).start();
    }, 500); // Delay the second animation

    return () => {
      //Destroy the component
      slideAnim1.stopAnimation();
      slideAnim2.stopAnimation();
    }

  }, []);

  const gradientId1 = `gradient1-${newKey}`;
  const gradientId2 = `gradient2-${newKey}`;
  const gradientId3 = `gradient3-${newKey}`;
  const gradientId4 = `gradient4-${newKey}`;
  const gradientId5 = `gradient5-${newKey}`;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1125 2436"
      id={newKey+'bg'}
      // key={props.key}
      style={{objectFit:'cover'}}
      opacity={props.style?.opacity ? `${props.style.opacity}` : "0.8"}
      {...props}
    >
      <Defs>
        <LinearGradient
          id={gradientId1}
          x1={254.32}
          x2={1218.15}
          y1={448.47}
          y2={2859.1}
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0} stopColor={stopColor1} />
          <Stop offset={0.73} stopColor={tintStopColor2} />
        </LinearGradient>
        <LinearGradient
          id={gradientId2}
          x1={596.84}
          x2={962.28}
          y1={480.73}
          y2={1394.73}
        >
          <Stop offset={0} stopColor="#ce2aff" />
          <Stop offset={0.73} stopColor="#0400e9" />
        </LinearGradient>
        <LinearGradient
          id={gradientId3}
          x1={401.25}
          x2={617.08}
          y1={1327}
          y2={2677.23}
        >
          <Stop offset={0} stopColor="#ce2aff" />
          <Stop offset={0.73} stopColor="#0400e9" />
        </LinearGradient>
        <LinearGradient
          id={gradientId4}
          x1={279.84}
          x2={645.28}
          y1={1161.73}
          y2={2075.73}
        >
          <Stop offset={0} stopColor="#ce2aff" />
          <Stop offset={0.73} stopColor="#0400e9" />
        </LinearGradient>
        <LinearGradient
          id={gradientId5}
          x1={576.33}
          x2={703.95}
          y1={119.94}
          y2={918.3}
        >
          <Stop offset={0} stopColor="#ce2aff" />
          <Stop offset={0.73} stopColor="#0400e9" />
        </LinearGradient>
      </Defs>
      <Path
        d="M-.5.5h1125v2435H-.5z"
        style={{
          fill: `url(#${gradientId1})`,
          strokeWidth: 0,
        }}
      />
      <Animatable.View
        animation={'slideInDown'}
      >
        <Path
          d="m200.5 1524.5 1026-1504"
          style={{
            fill: `url(#${gradientId2})`,
            opacity: 0.4,
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 10,
            strokeWidth: 27,
          }}
        />
      </Animatable.View>
      <Path
        d="M1124 2230.28c-164.6-59.41-273.53-141.97-348.5-221.78C501.56 1716.88 566.1 1339.69 222 1128c-32.82-20.19-125.31-77.09-222-95.67V2435h1124v-204.72Z"
        style={{
          fill: `url(#${gradientId3})`,
          opacity: 0.47,
          strokeWidth: 0,
        }}
      />
      <Animated.View
        style={{
          transform: [
            {
              translateX: slideAnim2.interpolate({
                inputRange: [0, 1],
                outputRange: [-200, 0], // Adjust the initial X position
              }),
            },
          ],
        }}
      >
        <Path
          d="m-116.5 2205.5 1026-1504"
          style={{
            fill: `url(#${gradientId4})`,
            opacity: 0.6,
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 10,
            strokeWidth: 27,
          }}
        />
      </Animated.View>
      <Path
        d="M-.5 0v63.68A839.597 839.597 0 0 0 44 79c297.56 93.12 488.97-29.45 719.59 119.5 285.09 184.13 293.93 566.07 350 559 3.89-.49 7.72-2.82 11.41-7.49V0H-.5Z"
        style={{
          fill: `url(#${gradientId5})`,
          opacity: 0.47,
          strokeWidth: 0,
        }}
      />
    </Svg>
  );
};

const returnBackground = (props: BackgroundProps) => {
  useEffect(() => {
    return () => {
      //Destroy the component

    }
  }, [])
  return (
    <SvgComponent id={props?.id + 'bg'} {...props}>
      <View style={[{flex: 1, padding: 20, zIndex: 10, position:"relative"}, props.containerStyle]}>
        {props.children}
      </View>
    </SvgComponent>
  )
}

export default returnBackground;
