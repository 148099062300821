import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, de, es, ja, ar, el, tk, fr, zh, it } from './i18n/languages';
import 'intl-pluralrules';

//empty for now
const resources = {
	en: {
		translation: en,
	},
	es: {
		translation: es,
	},
	de: {
		translation: de,
	},
	el: {
		translation: el,
	},
	ja: {
		translation: ja,
	},
	ar: {
		translation: ar,
	},
	tk: {
		translation: tk,
	},
	fr: {
		translation: fr,
	},
	zh: {
		translation: zh,
	},
	it: {
		translation: it,
	},
};

i18n.use(initReactI18next).init({
	resources,
	//language to use if translations in user language are not available
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false, // not needed for react!!
	},
});

export default i18n;
