import React, { useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { Platform } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import Button from '../UI/Button/Button';

type ImagePickerComponentProps = {
  onImageSelected: (image: string) => void;
};

export type ImagePickerHandle = {
  pickImage: () => void;
}

const ImagePickerComponent = forwardRef<ImagePickerHandle, ImagePickerComponentProps>((props, ref) => {
  const { onImageSelected } = props;

  useImperativeHandle(ref, () => ({
    pickImage: () => handleImagePick(),
  }));

  useEffect(() => {
    if (Platform.OS !== 'web') {
      (async () => {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert('Camera roll permissions required');
        }
      })();
    }
  }, []);

  const pickImage = async () => {
    const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      alert("You've refused to allow this app to access your photos!");
      return null;
    }

    return await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });
  };

  const handleImagePick = async () => {
    const result = await pickImage();

    if (result && result.assets && result.assets.length > 0) {
      const uri = result.assets[0].uri;
      try {
        const manipResultImg = await manipulateAsync(uri, [{ resize: { height: 500 } }], {
          format: SaveFormat.PNG,
          compress: 1,
          base64: true,
        });

        if (typeof onImageSelected === 'function') {
          onImageSelected(manipResultImg);
        }
      } catch (error) {
        console.error('Error during image manipulation:', error);
        // Handle the error as needed
      }
    } else {
      alert('Upload canceled or no image selected');
    }
  }

  return <Button icon="picture" buttonText="Select Image" onPress={handleImagePick} customStyle={{ minHeight: 40, minWidth: 150, borderRadius: 5 }} />;
});

export default ImagePickerComponent;
