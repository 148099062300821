import { View, Image } from "react-native";
import styles from "../../../stylesheet";
import React from 'react';
import { StoreStackParamList } from "../types";
import StoreLoading from "../screens/TopLevel/StoreScreen/StoreLoading";
import { createStackNavigator } from "@react-navigation/stack";
import Store from "../screens/TopLevel/StoreScreen/Store";

const StoreStack = createStackNavigator<StoreStackParamList>();

const StoreStackNavigator = () => {
  return (
    <View style={[styles.loginContainer]}>
      <StoreStack.Navigator screenOptions={{ headerShown: false }} initialRouteName="Store">
        <StoreStack.Screen name="StoreLoading" component={StoreLoading} />
        <StoreStack.Screen name='StoreList' component={Store} />
      </StoreStack.Navigator>
    </View>
  )
}
export { StoreStack, StoreStackNavigator };
