import {
  ADD_USER_IN_HIERARCHY,
  REMOVE_USER_FROM_HIERARCHY,
  CONNECT,
  DISCONNECT,
  APPEND_USER_LIST
} from '../actions/types';

const initialState = {
	usersInRoom: [],
	connected: false,
};

export default function (state = initialState, action){
  switch (action.type) {
    case APPEND_USER_LIST:
      return {
        ...state,
        usersInRoom: action.payload,
      };
    case ADD_USER_IN_HIERARCHY:
      return {
        ...state,
        usersInRoom: [...state.usersInRoom, action.payload],
      };
    case REMOVE_USER_FROM_HIERARCHY:
      return {
        ...state,
        usersInRoom: state.usersInRoom.filter(user => user._id !== action.payload),
      };
    case CONNECT:
      return {
        ...state,
        connected: true,
      };
    case DISCONNECT:
      return {
        ...state,
        connected: false,
      };
    default:
      return state;
  }
}
