import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface AchievementState {
	achievementMultiplier: number;
}

const initialState = {
	achievementMultiplier: 0,
} as AchievementState;

const achievementSlice = createSlice({
	name: 'achievements',
	initialState,
	reducers: {
		updateMultiplier(state, action) {
			state.achievementMultiplier = action.payload;
		},
	},
});

export const { updateMultiplier } = achievementSlice.actions;

const fetchTutorials = () => async (dispatch) => {};

export default achievementSlice.reducer;
