import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface WidgetState {
	activeWidget: { id: number };
	state: boolean;
	availableWidgets: [];
	userWidgets: [];
}

const initialState = {
	activeWidget: { id: 0 },
	state: true,
	availableWidgets: [],
	userWidgets: [],
} as WidgetState;

const widgetSlice = createSlice({
	name: 'homeWidgets',
	initialState,
	reducers: {
		changeWidget(state, action) {
			state.activeWidget = {
				id: action.payload.id,
			};
		},
	},
});

export const { changeWidget } = widgetSlice.actions;

export default widgetSlice.reducer;
