import { DefaultRootState, store } from "@reduxLocal/persistState";
import { View, Text, Pressable, FlatList, Platform, ScrollView } from "react-native";
import * as Animatable from 'react-native-animatable';
import { FontAwesome5, FontAwesome, AntDesign } from '@expo/vector-icons';
import { LinearGradient } from "expo-linear-gradient";
import { saveData, uploadData } from "@api/api";
import getId from '@api/getId';
import { connect } from 'react-redux'
import { useEffect, useState, useRef, useContext } from "react";
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { ADDED_DATA_TO_STORE, ADD_DATA_ITEM, HIDE_ALL_MODALS, HIDE_TOOLBAR, SHOW_AUTH_MODAL, SHOW_LOADING_MODAL, TOOLBAR_ITEM_DRAGGED, TOOLBAR_ITEM_DROPPED } from "@reduxLocal/actions/types";
import React from "react";
import { navigationRef } from "@navigation/rootNavigation";
import styles from "@stylesheet";
import handleSaveData from "./utils/handleSaveData";
import MemoizedUploadModal from "@components/modals/UploadModal/UploadModal";
import EditorToolbar from "../../../../components/Global/EditorToolbar/EditorToolbar";
import { ToolbarContext } from "@hooks/toolbarContext";
import { themeSelector } from "@reduxLocal/selectors/themeSelector";
import { useDispatch } from "react-redux";
import ErrorModal from "@components/modals/CommunityModal/CommModal";

const mapStateToProps = state => {
  return {
    dataTracker: state.uploadPreview.present,
    dataHistory: state.uploadPreview.past,
    toolbarContext: state.modals.toolbar.toolbarContext,
    itemDragged: state.toolbar.toolbarItemDragged,
    onUndo: () => store.dispatch(UndoActionCreators.undo()),
    onRedo: () => store.dispatch(UndoActionCreators.redo()),
    onReset: () => store.dispatch(UndoActionCreators.clearHistory()),
    currentPage: state.navigation.page.params?.screen,
    currentCompany: state.app.selectedProfile
  }
}

interface ToolbarProps {
  dataTracker?: any,
  dataHistory?: any,
  toolbarContext?: any,
  itemDragged?: any,
  onUndo?: Function,
  onRedo?: Function,
  onReset?: Function,
  currentPage?: string,
  currentCompany?: string
}

let ToolBar = ({ dataTracker, dataHistory, toolbarContext, itemDragged, onUndo, onRedo, onReset, currentPage, currentCompany }: ToolbarProps) => {
  //TODO check if current page does not require toolbar to be visible and remove toolbar if necesssary
  const [loading, setLoading] = useState(true);
  const [uploadModal, setUploadModal] = useState(false);
  const { ref, setRef, optionsRef, setOptionsRef } = useContext(ToolbarContext);
  const theme = themeSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading) {
      if (onReset) {
        onReset();
      }
      setLoading(false)
    }
  }, [toolbarContext, ref, optionsRef])

  const showErrorModal = (message) => {
    console.log('calling show error modal')
    dispatch({type: HIDE_ALL_MODALS});
    dispatch({ type: SHOW_AUTH_MODAL, data: { content: message } })
  }


  useEffect(() => {
    return () => {
      setRef(null);
    }
  }, [currentPage, ref, optionsRef]);

  return (
    <Animatable.View style={{ backgroundColor: theme ? '#58BFEA' : '#3a8099', alignSelf: "stretch", height: Platform.OS === 'web' ? '100%' : '100%', maxWidth: Platform.OS === 'web' ? 60 : '100%',  }} animation={'slideInRight'}>
      <LinearGradient colors={theme ? ['#1E90FF', 'transparent'] : ['#333333', 'black']} style={{ flex: 1, paddingBottom: 30, alignSelf: 'stretch' }}>
        <Animatable.View animation={'fadeIn'} style={{ flex: 5 }}>
          <View style={{ padding: 5, alignSelf: 'center', minHeight: 50, minWidth: "100%", backgroundColor: 'grey', shadowRadius: 20, shadowOpacity: .4, marginBottom: 20 }}>
            {
              toolbarContext.historyTracked ?
                <FontAwesome5 hitSlop={10} onPress={() => dispatch({ type: HIDE_TOOLBAR })} name="history" style={{ textAlign: 'center', paddingTop: 7 }} size={24} color="white" />
                :
                <FontAwesome5 hitSlop={10} onPress={() => dispatch({ type: HIDE_TOOLBAR })} name="tools" style={{ borderRadius: 20, textAlign: 'center', paddingTop: 7 }} size={24} color="white" />
            }
          </View>
          {
            toolbarContext.historyTracked ?
              <FlatList data={dataHistory} contentContainerStyle={{ gap: 10, maxHeight: 600, padding: 5 }} renderItem={() => { return (<View style={{ borderRadius: 5, backgroundColor: 'white', paddingVertical: 10 }}><Text adjustsFontSizeToFit style={{ fontSize: 11, textAlign: 'center' }} >History State</Text></View>) }} />
              :
              <>
                {
                  toolbarContext.items ?
                    <View style={{ gap: 5, flex: 1, backgroundColor: 'transparent' }}>
                      {
                        toolbarContext.items.map((item, index) => {
                          return (
                            <View key={item.toolName + index} style={{ flex: 1, maxHeight: item.id === 'break' ? undefined : 60 }}>
                              <Animatable.View animation={'fadeIn'} delay={index * 100} style={{ flex: 1 }}>
                                <View style={{ flexDirection: 'row', left: -3, flex: 1, minWidth: 60, marginVertical: 2, borderRadius: 15, maxHeight: 50, alignContent: 'center' }}>
                                  <View style={{ flex: 1, gap: 3 }}>
                                    {
                                      item.icon === 'slider' ?
                                        <FontAwesome onPress={() => { if (item.function?.type == 'navigation') { navigationRef.navigate(item.function.screen) } else { item.function() } }} name="sliders" size={24} color="black" style={{ alignSelf: 'center', overflow: 'visible', padding: 3, top: 3 }} />
                                        :
                                        <AntDesign onPress={() => { if (item.function?.type == 'navigation') { navigationRef.navigate(item.function.screen) } else { item.function() } }} name={item.icon} size={24} color="white" style={{ alignSelf: 'center', overflow: 'visible', padding: 3, top: 3 }} />
                                    }
                                    <Text selectable={false} adjustsFontSizeToFit numberOfLines={1} style={{ color: 'white', flex: 1, flexWrap: 'wrap', fontSize: 10, alignSelf: 'center', textAlign: 'center' }}>{item.toolName}</Text>
                                  </View>
                                </View>
                              </Animatable.View>
                            </View>
                          )
                        })
                      }
                    </View>
                    :
                    <>
                      {
                        ref &&
                        <View style={{ flex: 4, minHeight: 600 }}>
                          <EditorToolbar ref={ref} vertical={true} />
                        </View>
                      }
                    </>
                }
              </>
          }
        </Animatable.View>
        <View style={{ flex: toolbarContext.historyTracked ? 2.5 : 0.0001, zIndex: 1, minHeight: 50 }}>
          {
            toolbarContext.historyTracked
              ?
              <View style={{ left:5, flex: 2.5, paddingBottom: 30, marginLeft: 5, zIndex: 5 }}>
                <Pressable style={{ flex: 1, alignSelf: 'flex-end' }} onPress={onUndo} >
                  <View style={{ flex: 1, flexWrap: 'wrap', minWidth: 60, marginVertical: 2, padding: 1, borderRadius: 15, maxHeight: 50, alignContent: 'center', maxWidth: '100%' }}>
                    <AntDesign name={'arrowleft'} size={24} color="white" style={{ alignSelf: 'center', overflow: 'visible', padding: 3 }} />
                    <Text adjustsFontSizeToFit numberOfLines={2} style={{ flex: 1, flexWrap: 'wrap', fontSize: 12, color: 'white', alignSelf: 'center', textAlign: 'center' }}>{'Undo'}</Text>
                  </View>
                </Pressable>
                <Pressable style={{ flex: 1, alignSelf: 'flex-end' }} onPress={onRedo} >
                  <View style={{ flex: 1, flexWrap: 'wrap', minWidth: 60, marginVertical: 2, padding: 1, borderRadius: 15, maxHeight: 50, alignContent: 'center', maxWidth: '100%' }}>
                    <AntDesign name={'arrowright'} size={24} color="white" style={{ alignSelf: 'center', overflow: 'visible', padding: 3 }} />
                    <Text adjustsFontSizeToFit numberOfLines={2} style={{ flex: 1, flexWrap: 'wrap', fontSize: 12, color: 'white', alignSelf: 'center', textAlign: 'center' }}>{'Redo'}</Text>
                  </View>
                </Pressable>
                <Pressable style={{ flex: 1, alignSelf: 'flex-end' }} onPress={onReset} >
                  <View style={{ flex: 1, flexWrap: 'wrap', minWidth: 60, marginVertical: 2, padding: 1, borderRadius: 15, maxHeight: 50, alignContent: 'center', maxWidth: '100%' }}>
                    <AntDesign name={'retweet'} size={24} color="white" style={{ alignSelf: 'center', overflow: 'visible', padding: 3 }} />
                    <Text adjustsFontSizeToFit numberOfLines={2} style={{ flex: 1, flexWrap: 'wrap', fontSize: 12, color: 'white', alignSelf: 'center', textAlign: 'center' }}>{'Restart'}</Text>
                  </View>
                </Pressable>
                <Pressable style={{ flex: 1 }}
                  onPress={async () => {
                    dispatch({type: SHOW_LOADING_MODAL})
                    console.log('calling save')
                    if (optionsRef?.current) {
                      console.log('found options ref');
                      await optionsRef?.current?.validateForm?.().then(async (r) => {
                        if(r && r.content){
                          dispatch({type: HIDE_ALL_MODALS});
                          showErrorModal('Please fill in all required fields')
                        }
                        else if (!r || !r.content) {
                          await optionsRef?.current?.handleSubmit?.()
                        }
                      }).catch((e) => {
                        console.log('error', e);
                        dispatch({type: HIDE_ALL_MODALS});
                        showErrorModal('Please fill in all required fields')
                      });
                    }
                  }
                  }
                >
                  <View style={{ flex: 1, minWidth: 60, marginVertical: 2, padding: 1, borderRadius: 15, maxHeight: 50, alignContent: 'center', maxWidth: '100%', marginLeft:-14 }}>
                    <AntDesign name={'save'} size={30} color="white" style={{ alignSelf: 'center', overflow: 'visible', padding: 3 }} />
                    <Text style={{ flex: 1, flexWrap: 'wrap', fontSize: 12, color: 'white', alignSelf: 'center', textAlign: 'center' }}>{'Save'}</Text>
                  </View>
                </Pressable>
              </View>
              :
              null
          }
        </View>
      </LinearGradient>
    </Animatable.View>
  )
}

ToolBar = connect(mapStateToProps)(ToolBar);

export default ToolBar;