import * as React from "react"
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    id="uuid-86534ce9-140d-4904-81b7-1c93f3dd5ac3"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 454.21 225.41"
    {...props}
  >
    <G id="uuid-5283580a-21ee-4662-ba6d-5a832b112d09">
      <G>
        <Path
          d="m113.35,198.81c-37.12,2.48-74.23,9.62-111.37,26.1-1.95.87-2.24.67-1.79-1.22C17.33,151.52,34.39,79.39,51.42,7.25c.45-1.89.83-2.34,2.33-2.67,28.56-6.28,57.15-5.54,85.74-1.18-.99,7.38-1.98,14.76-2.96,22.15-1.89,14.28-3.77,28.56-5.65,42.83-.02.16-.04.33-.07.49-.29,1.94-.6,3.89-.86,5.83-2.59,19.55-5.18,39.09-7.76,58.64-.02.16-.04.33-.06.49-.16.97-.34,1.94-.47,2.92-2.77,20.69-5.54,41.37-8.3,62.06Z"
          style={{
            fill: "#e52320",
          }}
        />
        <Path
          d="m130.87,68.39c1.88-14.28,3.76-28.55,5.65-42.83.98-7.38,1.97-14.76,2.96-22.15,86.85,13.26,173.72,59.74,260.55,40.47,1.73-.39,2.12-.08,2.58,1.11,5.53,14.2,11.15,28.37,16.74,42.55-96.16,30.67-192.32-12.75-288.48-19.16Z"
          style={{
            fill: "#007433",
          }}
        />
        <Path
          d="m113.35,198.81c2.77-20.69,5.53-41.37,8.3-62.06.13-.97.31-1.94.47-2.92,103.43-.6,206.86,38.05,310.29-.2,1.13-.42,2.28-.81,3.42-1.23.12.31.25.63.37.94,5.42,13.84,10.84,27.67,16.25,41.51.2.46.29.99,1.09,1.07.04.11.09.22.13.33-113.44,51.02-226.88,14.96-340.33,22.55Z"
          style={{
            fill: "#010000",
          }}
        />
        <Path
          d="m122.19,133.35c2.58-19.55,5.17-39.09,7.76-58.64.26-1.95.57-3.89.86-5.83,96.23,6.35,192.45,49.75,288.68,19,5.75,14.58,11.5,29.16,17.25,43.73-104.85,40.99-209.7,1.18-314.54,1.74Z"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="m419.48,87.88c-96.23,30.75-192.45-12.64-288.68-19,.02-.16.04-.33.07-.49,96.16,6.41,192.32,49.83,288.48,19.16.04.11.09.22.13.33Z"
          style={{
            fill: "#abd1bb",
          }}
        />
        <Path
          d="m122.19,133.35c104.85-.55,209.7,39.25,314.54-1.74.05.11.09.22.13.33-.34.16-.69.31-1.03.46-1.14.42-2.29.81-3.42,1.23-103.43,38.25-206.86-.4-310.29.2.02-.17.04-.33.06-.49Z"
          style={{
            fill: "#a9a9a9",
          }}
        />
        <Path
          d="m435.84,132.4c.34-.15.69-.31,1.03-.46,5.75,14.58,11.5,29.16,17.25,43.73-.19.08-.38.17-.57.26-.8-.08-.89-.62-1.09-1.07.59-.94.06-1.51-.19-2.16-4.92-12.55-9.85-25.1-14.79-37.64-.25-.65-.29-1.43-1.27-1.71-.12-.31-.25-.63-.37-.94Z"
          style={{
            fill: "#080800",
          }}
        />
        <Path
          d="m453.54,175.93c.19-.08.38-.17.57-.26.22.24.08.43-.44.59-.04-.11-.09-.22-.13-.33Z"
          style={{
            fill: "#080800",
          }}
        />
        <Path
          d="m436.2,133.35c.98.28,1.01,1.06,1.27,1.71,4.94,12.54,9.87,25.09,14.79,37.64.25.65.79,1.22.19,2.16-5.42-13.84-10.84-27.68-16.25-41.51Z"
          style={{
            fill: "#040300",
          }}
        />
      </G>
    </G>
  </Svg>
)

export default SvgComponent
