import { useDispatch } from "react-redux";
import addCompanyInfo from "../../screens/TopLevel/Components/AddCompanyInfo";
import styles from "@stylesheet";
import { Platform, View, Text, TextInput, Pressable, ScrollView, KeyboardAvoidingView, ActivityIndicator } from 'react-native';
import { Image } from 'expo-image';
import { addCompany } from "@api/api";
import { forwardRef, useEffect, useState } from "react";
import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import { HIDE_ADD_COMPANY_MODAL } from "@reduxLocal/actions/types";
import React from "react";
import { Formik } from "formik";
import Button from "../../../components/UI/Button/Button";
import { FontAwesome } from "@expo/vector-icons";
import * as Animatable from 'react-native-animatable';
import useGlobalUser from "@hooks/getGlobalUser";

const AddCompanyForm = forwardRef((_, ref) => {
  const userID = useGlobalUser();
  const dispatch = useDispatch();
  const [image, setImage] = useState('null');
  const [hovered, setHovered] = useState(false);
  const [imageData, setImageData] = useState({});
  const formRef = React.useRef(null);
  const [loading, setLoading] = useState(false);

    useEffect(() => {
      (async () => {
        if (Platform.OS != 'web') {
          const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
          if (status != 'granted') {
            alert('Camera roll permissions required');
          }
        }
      })();
    }, []);

    const _resizeImage = async () => {
      const pickImage = async () => {
        let result: ImagePicker.ImagePickerResult;
        const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();

        if (permissionResult.granted === false) {
          alert("You've refused to allow this app to access your photos!");
          return;
        }

        result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          base64: true,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });


        if (!result.canceled) {
          return result;
        }
        if (result.canceled) {
          alert("Upload canceled");
          return;
        }
        return result;
      };

      pickImage().then(async result => {
        const { assets } = result;
        const uri = assets[0].uri;
        setImage(uri)
        const manipResultImg = await manipulateAsync(uri, [
          {
            resize: { height: 200 }
          }
        ], { format: SaveFormat.PNG, compress: 1, base64: true }
        );
        let img = manipResultImg.base64;
        setImageData({ img })
        formRef.current?.setFieldValue('logo', img);
      });
    }


    return (
      <View style={{ flex: 1 }}>
        <KeyboardAvoidingView behavior="padding" keyboardVerticalOffset={50} style={{ flex: 10, paddingHorizontal: 10 }}>
          <Formik
            innerRef={formRef}
            initialValues={{
              companyName: null,
              // companyNumber: null,
              interests: null,
              industryType: null,
              userId: userID,
              logo: imageData
            }}
            onSubmit={async values => {
              setLoading(true)
              await dispatch(addCompany(values)).then(res => {
                if (res) {
                  ref.current?.closeModal();
                }
              })
              setLoading(false);

            }}>{({ handleChange, handleBlur, handleSubmit, setFieldValue, values }) => (
              <View style={{ flex: 1 }}>
                {loading ?
                  <ActivityIndicator />
                  :
                  <View style={{ overflow: "scroll", flex: 10, alignSelf: 'center' }}>
                    <View style={{ flex: 3, marginVertical: 40 }}>
                      <Pressable onPress={_resizeImage} onHoverIn={() => { setHovered(true) }} onHoverOut={() => { setHovered(false) }}>
                        <View style={{ borderRadius: 20, borderWidth: image != 'null' && imageData ? 0 : 3, justifyContent: 'center', padding: 20, borderStyle: 'dashed', alignContent: 'center', alignSelf: 'stretch', alignItems: 'center', backgroundColor: hovered ? 'grey' : 'white' }}>
                          {
                            image != 'null' && imageData && values.logo ?
                              <Animatable.View animation={'bounceIn'} style={{ flex: 1, top: -20 }}>
                                <Image source={{ uri: image }} style={{ height: 150, width: 150 }} />
                              </Animatable.View>
                              :
                              <View>
                                <FontAwesome style={styles.menuItemIcon} size={25} name={'plus'} color={'#00000081'} />
                                <Text adjustsFontSizeToFit >Add Logo</Text>
                              </View>
                          }
                        </View>
                      </Pressable>
                    </View>
                    <View style={[styles.credentialInputContainer, { alignContent: 'flex-start', flex: 10, gap: 25 }]}>
                      {addCompanyInfo.map((registrationInfo, n) => {
                        return (
                          <View style={{ flex: 1 }} key={n + 'addcompany'}>
                            <Text adjustsFontSizeToFit style={{ textAlign: 'center', justifyContent: 'flex-start', flex: 1, fontWeight: '600' }}>{registrationInfo.placeHolderValue.toString()}</Text>
                            <TextInput
                              key={n}
                              textContentType={registrationInfo.fieldName.toString()}
                              placeholder={registrationInfo.placeHolderValue.toString()}
                              style={[styles.inputOutline, { margin: 5, minWidth: Platform.OS != 'web' ? '100%' : 350 }]}
                              onChangeText={handleChange(registrationInfo.fieldName.toString())} />
                          </View>
                        );
                      })}
                    </View>
                    <View style={{ flexDirection: 'row', flex: 1, marginVertical: 30, gap: 30, minHeight: 50, paddingHorizontal: 20 }}>
                      <Button
                        onPress={() => ref.current?.closeModal()}
                        buttonText="Close"
                        customStyle={{ flex: 1, maxWidth: 140 }}
                      />
                      <Button buttonText="Submit" onPress={() => handleSubmit()} customStyle={{ flex: 1, maxWidth: 140 }} />
                    </View>
                  </View>
                }
              </View>
            )}
          </Formik>
        </KeyboardAvoidingView>
      </View>
    )
});

export default AddCompanyForm;