import { Animated, Pressable, Platform } from "react-native";
import { useEffect, useRef, useState } from "react";
import { Text, View } from "@constants/Themed";
import { useDispatch, useSelector } from 'react-redux';
import styles from "@stylesheet";
import Modal from 'react-native-modal';
import LottieView from "lottie-react-native";
import { useLottieAnim } from '@hooks/lottieHook';
import { DefaultRootState } from '@reduxLocal/persistState';
import React from "react";
import { HIDE_AUTH_MODAL, HIDE_LOADING_MODAL, PENDING_REFRESH, REFRESHED, SHOW_AUTH_MODAL, UPDATED_CALENDAR_ENTRIES } from "@reduxLocal/actions/types";
import { BlurView } from 'expo-blur';
import { useWindowDimensions } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { CountUp } from "use-count-up";

const LoadingModal = () => {
  const visibleState = useSelector((state: DefaultRootState) => state.modals.loadingModal);
  const messageCount = useSelector((state: DefaultRootState) => state.UI.counts?.messageCount);
  const invitationCount = useSelector((state: DefaultRootState) => state.UI.counts?.invitationCount);
  const calendarCount = useSelector((state: DefaultRootState) => state.UI.counts?.calendarCount);
  const [loaded, setLoaded] = useState(false);
  const [waitMessage, setWaitMessage] = useState(false);
  const windowDimensions = useWindowDimensions();
const dispatch = useDispatch();

  useEffect(() => {
    setWaitMessage(false)
    if (!loaded) {
      setLoaded(true)
    }

    return (() => {

    })
  }, [])

  return (
    <Modal
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      isVisible={visibleState}
      hasBackdrop
      deviceHeight={windowDimensions.height*2}
      deviceWidth={windowDimensions.width*2}
      onModalWillShow={() => dispatch({ type: HIDE_AUTH_MODAL })}
      backdropOpacity={.5}
      style={{ justifyContent: "center", alignSelf: 'center', gap: 50, zIndex: 1, opacity: 1 }}
    >
      <Animatable.View transition={'width'} style={[styles.modalContainerAuth, { alignSelf: 'center', flex: 1, width: 350, maxWidth: '100%', maxHeight: '60%', opacity: 1, padding: 0 }]}>
        <View style={{ flexDirection: 'column', flex: 1, padding: 50 }}>
          <LottieView style={{ flex: 1, width: 150, height: 150 }} speed={1} source={require('@assets/animations/spin-leaf-loader.json')} autoPlay loop />
          <Text style={{ textAlign: "center" }}>Loading...</Text>
        </View>
      </Animatable.View>
      {/* <View style={[styles.centerEverything, { flexDirection: 'row', gap: 30 }]}>
        {renderCounterView({ count: invitationCount, index: 0 })}
        {renderCounterView({ count: messageCount, index: 1 })}
        {renderCounterView({ count: calendarCount, index: 2 })}
      </View> */}
    </Modal>
  );
};

const renderCounterView = ({ count, index }) => {
  if (count >= 0) {
    return (
      <Animatable.View delay={index * 400} animation={'slideInUp'} style={[styles.centerEverything, { flex: 1, backgroundColor: 'white', width: 80, height: 80, borderRadius: 50 }]}>
        <Text style={styles.headerText}>{count}</Text>
      </Animatable.View>
    );
  }
  return null;
};

export default LoadingModal;