import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { View, Text, Pressable, ScrollView, Platform, Image } from 'react-native';
import styles from '@stylesheet';
import SearchComponent from '@components/Global/SearchComponent/SearchBar';
import ProductList from './Components/ProductList';
import { FontAwesome, SimpleLineIcons } from '@expo/vector-icons';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import ConnectModal from './Components/ConnectModal';
import Basket from './Components/Basket';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import Animated, {
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import * as LocalAuthentication from 'expo-local-authentication';
import { filter } from 'lodash';
import BottomSheet, {
  BottomSheetModalProvider,
  BottomSheetModal
} from '@gorhom/bottom-sheet';
import Button from '@components/UI/Button/Button';
import { Title } from '@constants/Themed';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import { ListHandle } from './Components/ProductList';
// import { useStripeTerminal } from '@stripe/stripe-terminal-react-native';
//TODO ADD LANDING PAGE CAPABILITIES TO THE STORE, i.e. if product has url, use webview to display product url overlayed on basket


const Store = ({ navigation }) => {
  const [columns, setColumns] = useState(1);
  const [basketOpen, setBasketOpen] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [searchState, setSearchState] = useState('');
  const [widthReqMet, setWidth] = useState(0);
  const [basketModalVisible, setBasketModalVisible] = useState(false);
  const [connectModal, setConnectModal] = useState(false);
  const [switchState, setSwitchState] = useState(false);
  const [type, setType] = useState('products');
  const [showActions, setShowActions] = useState(false);
  const [listHorizontal, setListHorizontal] = useState(false);
  const listRef = useRef<ListHandle>(null);

  const theme = themeSelector();

  //TODO Create a keypress to escape the store

  const _handleSearch = useCallback(() => {

  }, [])

  const _handleSwitchType = useCallback(() => {
    setType(type === 'products' ? 'services' : 'products');
  }, [type])

  const wrapperSetRefresh = useCallback((val) => {
    setRefresh(true);
  }, [setRefresh]);

  const openConnect = () => {
    setConnectModal(!connectModal);
  }

  const handleStatsSwitch = useCallback((value) => {
    setShowActions(value);
  }, []);

  const handleSearch = useCallback((text = "") => {
    const formattedQuery = text.toLowerCase();
    const retrievedData = [];
    const filteredData = filter(retrievedData, data => {
      return contains(data, formattedQuery);
    });
    // setRenderedData(filteredData);
    // setQuery(text);
  }, []);

  const contains = (data, query) => {
    query = query.replace(/ /g, '_').toLowerCase(); // Replace spaces with underscores and convert to lowercase

    const searchNestedObject = (obj) => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];

          if (value !== null && typeof value === 'object') {
            // Recursively search nested objects
            if (searchNestedObject(value)) {
              return true;
            }
          } else {
            // Check if the string representation of the value includes the query
            if (value && value.toString().toLowerCase().includes(query)) {
              return true;
            }
          }
        }
      }

      return false;
    };

    return searchNestedObject(data);
  };



  const SwipeSearch = ({ events }) => {
    const [xyValue, setXYValue] = useState(0);
    const translateX = useSharedValue(xyValue);
    const panRef = useRef();

    const handlePanGesture = ({ nativeEvent }) => {
      const { translationX, translationY, state, absoluteX, absoluteY } = nativeEvent;
      if (state === State.ACTIVE) {
        translateX.value = translationX;

      }
      if (state === State.END) {
        if (translateX.value < -1) {
          translateX.value = withSpring(0);
          if (Platform.OS === 'web') {
            handleStatsSwitch(true);
          } else {
            if (!showActions) {
              LocalAuthentication.authenticateAsync()
                .then(res => {
                  if (res.success) {
                    handleStatsSwitch(true);
                  }
                })
                .catch(err => console.log(err))
            }
          }
          // Handle your logic when translationX is less than -1
        } else if (translateX.value > 1) {
          translateX.value = withSpring(0);
          //TODO add local authentication
          handleStatsSwitch(false);

          // Handle your logic when translationX is greater than 1
        }
      }
    };

    const changeListView = () => {
      if (listHorizontal) {
        listRef.current?.setColumns(1);
      }
      listRef.current?.setColumns(3);
    };

    return (
      <PanGestureHandler ref={panRef} onGestureEvent={handlePanGesture} onHandlerStateChange={handlePanGesture}>
        <View style={{ borderBottomStartRadius: 20, borderBottomEndRadius: 20, maxHeight: 80, alignSelf: 'stretch', flex: 1 }} >
          {
            showActions ?
              <Animated.View style={[{ borderRadius:20,backgroundColor:'white', maxHeight: 80, flexDirection: 'row', shadowRadius: 20, shadowOpacity: .2, gap: 10, paddingVertical: 15, alignContent: 'space-around', maxWidth:"95%", paddingHorizontal: '7%', transform: [{ translateX: translateX }] }]}>
                <Pressable onPress={() => navigation.replace('Login')} style={{ flex: 1, top: Platform.OS === 'web' ? undefined : '5%', maxWidth: 40, alignContent: 'center', alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }}>
                  <AntDesign name="closecircle" size={24} color="red" style={[styles.centerEverything, { flex: 1 }]} />
                </Pressable>
                <ScrollView horizontal style={{ flex: 1 }} contentContainerStyle={{ gap: 10 }} showsHorizontalScrollIndicator={false}>
                  <Pressable onPress={()=> setRefresh(!refresh)} style={styles.searchSwipeIcons}>
                    <FontAwesome name="refresh" size={24} color="black" />
                    <Text>Refresh</Text>
                  </Pressable>
                  <Pressable onPress={openConnect} style={styles.searchSwipeIcons}>
                    <MaterialCommunityIcons name="connection" size={24} color="black" />
                    <Text>Connect</Text>
                </Pressable>
                  <Pressable onPress={openConnect} style={styles.searchSwipeIcons}>
                    <AntDesign name="setting" size={24} color="black" />
                    <Text>Settings</Text>
                </Pressable>
                  <View style={styles.searchSwipeIcons}>

                  </View>
                </ScrollView>
              </Animated.View>
              :
              <Animated.View style={{ transform: [{ translateX: translateX }] }}>
                <SearchComponent
                  searchState={searchState}
                  setSearchState={setSearchState}
                  callback={_handleSearch}
                  customIcons={Platform.OS === 'web' && [{
                    icon: listHorizontal ? <FontAwesome name="th-list" size={18} color="white" style={{ pointerEvents: "none" }} /> : <SimpleLineIcons name="grid" size={18} color="white" style={{ pointerEvents: "none" }} />,
                    function: changeListView,
                    functionName: 'Change View',
                  }]}
                />
              </Animated.View>
          }
        </View>
      </PanGestureHandler>
    )
  }


  return (
    <View style={{ flex: 1, flexDirection: 'row', paddingTop: Platform.OS != 'web' ? 50 : 0 }} onLayout={e => setWidth(e.nativeEvent.layout.width)}>
      <Image source={require('@assets/images/background/AdobeStock_462293980.jpg')} style={{ height: '140%', width: '120%', zIndex: -10, position: 'absolute', opacity: .7, }} />
      {
        Platform.OS != 'web' &&
        <ConnectModal modalOpen={connectModal} setModalOpen={() => setConnectModal(!connectModal)} />
      }
      <View style={{ flex: 4 }}>
        <View style={{ alignSelf: 'stretch', minWidth: '100%', flexDirection: 'row', flex: 1, paddingHorizontal: 10, maxHeight: 80, zIndex: 2, }}>
          <SwipeSearch events={3} />
        </View>
        <View style={{ flex: 10 }}>
          <ProductList ref={listRef} refresh={refresh} setRefresh={wrapperSetRefresh} type={type} />
        </View>
      </View>
      <Basket currentWidth={widthReqMet} callBack={_handleSwitchType} type={type} />
    </View>
  )
}
export default Store;