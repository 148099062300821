import { View, Pressable, TextInput, Platform, Text, useWindowDimensions } from 'react-native';
import styles from '@stylesheet';
import React, { useEffect, useState } from 'react';
import LottieView from 'lottie-react-native';
import Modal from 'react-native-modal';
import { DefaultRootState } from '@reduxLocal/persistState';
import { HIDE_PASSWORD_RESET_MODAL, LOGIN_FLIP, SHOW_AUTH_MODAL } from '@reduxLocal/actions/types';
import * as Animatable from 'react-native-animatable';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { BlurView } from 'expo-blur';
import apis from '@api/api';
import Button from '@components/UI/Button/Button';

const PasswordResetModal = ({ emailForward }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(emailForward);
  const [awaitingPasscode, setAwaitingPasscode] = useState(false);
  const [registerHovered, setRegisterHovered] = useState(false);
  const [loginHovered, setLoginHovered] = useState(false);
  const visible = useSelector((state: DefaultRootState) => state.modals.passwordModalDisplayed);
  const [passcode, setPassCode] = useState('');
  const [heldId, setHeldId] = useState('');
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  
  const _handleResetRequest = async (values) => {
    console.log(values.email)
    await apis.passwordReset(values.email).then(res => {
      let success = res;
      if (success.success) {
        setAwaitingPasscode(true);
        console.log(awaitingPasscode);
      }
    })
  }

  const _handleResetCode = async (values) => {
    console.log(values)
    if (values.newPassword != values.repeatNewPassword) {
      dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Please ensure the passwords match', type: 'error' } })
      return;
    }
    if (values.code.length < 5) {
      dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Code must be 6 characters and is case-sensitive', type: 'error' } })
      return;
    }
    else await apis.validatePasswordReset(values)
  }

  const PasswordResetRequest = () => (
    <View style={{ flex: 1 }}>
    <Formik
      initialValues={{ email: '' }}
      onSubmit={values => _handleResetRequest(values)}
    >{({ handleChange, handleBlur, handleSubmit, values }) => (
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>
            <Text adjustsFontSizeToFit style={{ fontSize: 16, fontWeight: 'bold', maxHeight: 60, textAlign: 'center' }}>Please enter your email address</Text>
          <TextInput textContentType={'emailAddress'} value={values.email} onBlur={handleBlur('email')} style={[styles.inputOutline, { minWidth: '90%' }]} onChangeText={handleChange('email')} ></TextInput>
        </View>
          <Text style={{ padding: 20, lineHeight: 25 }}>If you originally logged in through Google, Apple or Microsoft, just log in again or get in touch with our support team if you are having trouble.</Text>
            <View style={{ flex: 2, flexDirection: 'row', paddingHorizontal: 20, gap: 30 }}>
              <Button
                onPress={() => { dispatch({ type: HIDE_PASSWORD_RESET_MODAL }) }}
                buttonText={t('backButton')}
              />
              <Button
                disabled={values.email ? false : true}
            onPress={handleSubmit}
                buttonText={t('resetButton')}
              />
        </View>
      </View>
    )
      }
    </Formik >
    </View>
  );

  return (
    <Modal
      isVisible={visible}
      backdropOpacity={0.5}
      hasBackdrop={true}
      onBackdropPress={() => { dispatch({ type: HIDE_PASSWORD_RESET_MODAL }) }}
      coverScreen={true}
      deviceHeight={windowDimensions.height}
      deviceWidth={windowDimensions.width}
      style={{ padding: 20, alignContent: 'center', alignItems: 'center', flex: 1 }}
      animationIn={'slideInUp'}
      animationOut={'slideOutDown'}
    >
      <BlurView
        style={styles.blurViewStyle}
        blurAmount={10}
        pointerEvents='none'
        reducedTransparencyFallbackColor="white"
      />
      <View style={[styles.modalContainerAuth, { maxWidth: '100%', maxHeight: '90%', padding: Platform.OS === 'web' ? 30 : 30, elevation: 20, flex: 1 }]}>
        <Text adjustsFontSizeToFit style={{ fontSize: 20, fontWeight: 'bold', flex: 1, maxHeight: 60 }}>Password Reset</Text>
        <View style={{ flex: 1, alignSelf: 'center', margin: 10 }}>
          <LottieView style={{ flex: 1, alignSelf: 'center' }} source={require('@assets/animations/lockAnimation.json')} autoPlay ></LottieView>
        </View>
        <View style={{ flex: 2 }}>
          {
            awaitingPasscode ?
              <Animatable.View animation={'fadeIn'} style={{ flex: 5, alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                <Text adjustsFontSizeToFit style={{ fontSize: 16, fontWeight: 'bold', maxHeight: 60, textAlign: 'center' }}>Verification Code</Text>
                <View style={{ flex: 5 }}>
                  <View style={[styles.inputContainer, { flex: 1 }]}>
                    <Formik initialValues={{ code: '', newPassword: '', repeatNewPassword: '' }} onSubmit={values => _handleResetCode(values)}>{({ handleChange, handleBlur, handleSubmit, values }) => (
                      <View style={[styles.inputContainer, { flex: 1, paddingHorizontal: 100 }]}>
                        <TextInput value={values.code} placeholderTextColor={'grey'} placeholder='Enter code from email' onChangeText={handleChange('code')} onBlur={handleBlur('code')} style={styles.inputOutline} />
                        <TextInput value={values.newPassword} placeholderTextColor={'grey'} placeholder='Enter New Password' secureTextEntry={true} onChangeText={handleChange('newPassword')} textContentType='password' onBlur={handleBlur('newPassword')} style={styles.inputOutline} />
                        <TextInput value={values.repeatNewPassword} placeholderTextColor={'grey'} placeholder='Repeat New Password' secureTextEntry={true} onChangeText={handleChange('repeatNewPassword')} textContentType='password' onBlur={handleBlur('repeatNewPassword')} style={styles.inputOutline} />
                        <View style={{ flex: 3 }}>
                          <Text adjustsFontSizeToFit style={{ flex: 1, lineHeight: 20 }}>A One Time Passcode hass been emailed to you. This code will expire in 10 minutes so it's a good idea to hurry.</Text>
                        </View>
                        <View style={{ flex: 3 }}>
                          <Pressable
                            style={registerHovered ? styles.LoginButtonHovered : styles.LoginButton}
                            onHoverIn={() => setRegisterHovered(true)}
                            onHoverOut={() => setRegisterHovered(false)}
                            onPress={handleSubmit}
                          >
                            <Text adjustsFontSizeToFit style={[styles.appButtonText, { color: 'black', flex: 1 }]}>Validate</Text>
                          </Pressable >
                        </View>
                      </View>
                    )}
                    </Formik>
                  </View>
                </View>
              </Animatable.View>
              :
              <Animatable.View animation={'fadeIn'} style={{ flex: 1, maxWidth:'100%' }}>
                <PasswordResetRequest />
              </Animatable.View>
          }
        </View>
      </View>
    </Modal >
  )
}
export default PasswordResetModal;