const registrationInfo = [
	{
		fieldName: 'givenName',
		placeHolderValue: 'Name',
		fieldState: 'name',
		type: 'all',
		furtherInformation: false,
	},
	{
		fieldName: 'familyName',
		placeHolderValue: 'Surname',
		fieldState: 'surname',
		type: 'all',
		furtherInformation: false,
	},
	{
		fieldName: 'organizationName',
		placeHolderValue: 'Company / Organisation Name',
		fieldState: 'companyName',
		type: 'organisation',
		furtherInformation: false,
	},
	{
		fieldName: 'organizationNumber',
		placeHolderValue: 'Company Number',
		fieldState: 'companyNumber',
		type: 'organisation',
		furtherInformation: false,
	},
	{
		fieldName: 'city',
		placeHolderValue: 'City',
		fieldState: 'city',
		type: 'all',
		furtherInformation: false,
	},
	{
		fieldName: 'industryType',
		placeHolderValue: 'Industry Type',
		fieldState: 'industryType',
		type: 'all',
		furtherInformation: false,
	},
	{
		fieldName: 'jobTitle',
		placeHolderValue: 'Role at Company',
		fieldState: 'role',
		type: 'organisation',
		furtherInformation: false,
	},
	{
		fieldName: 'interests',
		placeHolderValue: 'Interests',
		fieldState: 'interests',
		type: 'individual',
		furtherInformation: false,
	},
	{
		fieldName: 'email',
		placeHolderValue: 'Email Address',
		fieldState: 'email',
		type: 'all',
		furtherInformation: false,
	},
];
export default registrationInfo;
