import { TextInput } from 'react-native';
import { useState } from 'react';

export type FormConfig<TValues> = {
	initialValues: TValues;
	onSubmit: (values: TValues) => Promise<void>;
};

function useForm<TValues>({ initialValues, onSubmit }: FormConfig<TValues>) {
	const [values, setValues] = useState<TValues>(initialValues);

	const [errors, setErrors] = useState({});

	const handleChangeByEvent =
		(name: string, key: string) => (event: TextInput) => {
			const { value } = event.target;
			setValues({ ...values, [name]: value });
		};

	const handleChangeByText = (name: string) => (value: string) => {
		setValues({ ...values, [name]: value });
	};

	const handleChangeBySelect = (name: string) => (value: string) => {
		setValues({ ...values, [name]: value?.value });
	};

	const handleSubmit = (event: any) => {
		setErrors({ ...errors });
		onSubmit(values);
	};

	return {
		values,
		handleChange: handleChangeByEvent,
		handleChangeByText,
		handleChangeBySelect,
		handleSubmit,
	};
}

export default useForm;
