import {
	TOOLBAR_ITEM_DRAGGED,
	TOOLBAR_ITEM_DROPPED,
	DATA_CREATION_SUCCEEDED,
	TOOLBAR_ITEM_PLACED,
	SIM_GESTURES,
	CLEAR_SIM_GESTURE,
	CHANGE_SELECTED_ITEM,
	INITIALISE_MENU,
} from '../actions/types';
import { updateItemInArray, updateObject } from './utils/globalStateFunctions';

const INITIAL_STATE = {
	toolbarItemDragged: false,
	toolbarItemContext: 2,
	simGestures: null,
	selectedItem: 0,
};

const toolbarReducer = (state = INITIAL_STATE || null, action) => {
	switch (action.type) {
		case TOOLBAR_ITEM_DRAGGED:
			return updateObject(state, {
				toolbarItemDragged: true,
				toolbarItemContext: action.payload,
			});
		case TOOLBAR_ITEM_DROPPED:
			return updateObject(state, {
				toolbarItemDragged: false,
				toolbarItemContext: 2,
			});
		case TOOLBAR_ITEM_PLACED:
			return updateObject(state, {
				toolbarItemDragged: false,
				toolbarItemContext: null,
			});
		case CHANGE_SELECTED_ITEM: {
			return updateObject(state, {
				...state,
				selectedItem: action.payload,
			});
		}
		case SIM_GESTURES:
			updateObject(state, {
				simGestures: action.payload,
			});
		case CLEAR_SIM_GESTURE:
			updateObject(state, {
				simGestures: null,
			});
		default:
			return state;
	}
};

export default toolbarReducer;
