import * as React from "react"
import Svg, {
  SvgProps,
  Defs,
  LinearGradient,
  Stop,
  Path,
} from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" id="uuid-2419925a-7c93-4159-a326-c0d8537966ef" 
    width={250} 
    height={1080} 
    {...props}
    viewBox="35 0 199 945" 
    preserveAspectRatio="xMinYMid meet" 
    pointerEvents={'none'}>
      <Defs>
        <LinearGradient
          id="a"
          x1={105.43}
          y1={923.87}
          x2={105.43}
          y2={8.96}
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0} stopColor="#8bbedc" />
          <Stop offset={1} stopColor="#58bfea" />
        </LinearGradient>
      </Defs>
      <Path
        d="M18.56 643.45v-.12c-.72-1.54-1.14-3.25-1.14-5.06V336.89c0-94.79 77.73-180.46 122.19-211.47C203.76 80.66 201.98 8.96 201.98 8.96L8.87 12.12v911.75c19.92 0 36.06-11.79 36.06-26.33l7.76-217.37s-34.13-29.71-34.13-36.73Z"
        style={{
          fill: "url(#a)",
          filter: "url(#uuid-0ef322c2-4415-4240-a44b-1bd3ba8153dd)",
          opacity: 0.7,
        }}
      />
    </Svg>
    )

    export default SvgComponent
