import { useSelector } from 'react-redux';
import { DefaultRootState, store } from '@reduxLocal/persistState';

function handleGetID(data: any) {
 return data?.promiseData?.data?.userData?.id;
}

const getId = () => {
	const data = store.getState();
	const id = handleGetID(data);
	return id;
};
export default getId;
