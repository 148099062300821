import React, { useMemo } from 'react';
import { View, Pressable, Keyboard, Platform } from 'react-native';
import { FastField, FieldProps } from 'formik';
import AnimatedLottieView from 'lottie-react-native';
import HandleFieldType from './handleFieldType';
import ErrorBoundary from '@utils/ErrorBoundary';

const GetField = ({ otherVals, item, field, allItems, index, nextFieldFunction, setInputRef }) => {
  return (
    <HandleFieldType
      setRef={(fieldName, ref) => setInputRef?.(fieldName, ref)}
      nextFieldFunction={(index, length) => { nextFieldFunction(allItems[index + 1]?.type, index, length) }}
      otherVals={otherVals}
      index={index}
      fieldProps={field}
      schemaItem={item}
      length={allItems?.length}
    />
  )
}

const Row = ({ otherVals, items, allItems, index, nextFieldFunction, setInputRef, column }) => {
  return (
    <View style={{ flexDirection: column ? 'column' : 'row', minWidth: "100%", alignSelf: "stretch", gap: 15, padding: 5, backgroundColor: "transparent", zIndex: index * -1 }}>
      {items.map((item, index) => (
        <FastField key={index} id={item.title} name={item.title}>
          {(fieldProps: FieldProps) => {
            return (
              <Pressable style={{ alignSelf: "stretch", flexDirection: "row", flex: 1, gap: 10 }} disabled={Platform.OS === 'web'} onPress={() => Keyboard.dismiss()} hitSlop={10}>
                <View style={{ position: "absolute", backgroundColor: "transparent", minWidth: "10%", maxWidth: 60, left: -10, top: 50 }}>
                  {
                    item.content?.validation?.required &&
                    <View style={{ maxWidth: 40, backgroundColor: "transparent" }}>
                      {
                        fieldProps.meta.touched && !fieldProps.meta.error ?
                          <AnimatedLottieView source={require("@assets/animations/registerSuccess.json")} style={{ flex: 1 }} autoPlay />
                          :
                          <AnimatedLottieView colorFilters={[{
                            keypath: "layers.1.shapes.0.it.1.c.k", color: "red"
                          }]} source={require("@assets/animations/exclamation-mark.json")} style={{ flex: 1 }} autoPlay />
                      }
                    </View>
                  }
                </View>
                <View style={{ flex: 1, paddingLeft: 20, backgroundColor: "transparent" }}>
                  <ErrorBoundary>
                    <GetField otherVals={otherVals} item={item} field={fieldProps.field} allItems={allItems} index={index} nextFieldFunction={nextFieldFunction} setInputRef={setInputRef} />
                  </ErrorBoundary>
                </View>
              </Pressable>
            )
          }}
        </FastField>
      ))}
    </View>
  )
};

export default Row;