import { useDispatch, useSelector } from "react-redux"
import Modal from 'react-native-modal';
import { DefaultRootState } from "@reduxLocal/persistState"
import { FlatList, ScrollView, Text, View, useWindowDimensions } from "react-native";
import { Pressable } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import styles from "@stylesheet";
import React, { useState } from "react";
import RenderSubscriptionItems from "./Components/SubscriptionItems";
import { Ionicons } from "@expo/vector-icons";
import { HIDE_REGISTER_MODAL } from "@reduxLocal/actions/types";
import { Title } from "@constants/Themed";
// import { BlurView } from 'expo-blur';
import { useEffect } from "react";
import apis, { registerUser } from "@api/api";
import { useQuery } from "react-query";
import { Loading } from "../../Global/Loading";

const RegisterModal = () => {
  const { products, selectedPlan, heldValues, modalDisplayed } = useSelector((state: DefaultRootState) => state.modals.registerModal);
  const [submitHovered, setSubmitHovered] = useState(false);
  const [productInfo, setProductInfo] = useState([]);
  const dispatch = useDispatch();
  const dimensions = useWindowDimensions();

  const _renderSubscripItems = ({ item, index }) => {
    return (
      <RenderSubscriptionItems {...{ item, index }} />
    )
  }

  return (
    <Modal
      isVisible={modalDisplayed}
      coverScreen={true}
      style={{ flex: 1, width: 500, padding: 30, paddingTop: 30, alignSelf: 'flex-start' }}
      hasBackdrop={true}
      deviceWidth={dimensions.width}
      deviceHeight={dimensions.height}
      animationIn={'bounceInDown'}
      backdropOpacity={.2}
      onBackdropPress={() => { { dispatch({ type: HIDE_REGISTER_MODAL }) } }}
      animationInTiming={1000}
      animationOut={'slideOutUp'}
      animationOutTiming={1000}
    >
      <View
        // Background Linear Gradient
        style={[styles.gradientBackground, { zIndex: -100, backgroundColor: 'white', paddingTop: 30, minHeight: 740, alignSelf: 'stretch', flex: 1, borderRadius: 20, flexDirection: 'column', elevation: 1, shadowRadius: 50, shadowOffset: { width: 20, height: -30 }, shadowOpacity: .1 }]}
      >
        <View style={{ flex: 1, alignSelf: 'stretch' }}>
          <View style={{ flex: 1 }}>
            <Title style={{ textAlign: 'center' }}>Plan Selection</Title>
          </View>
          <View style={{ flex: 15, flexDirection: 'column', flexWrap: 'wrap', }}>
            <View style={{ flex: 1, alignSelf: 'center' }}>
                  <FlatList
                data={products.data}
                    renderItem={(items: { item: any; index: any; }) => _renderSubscripItems(items)}
                    numColumns={2}
                    showsVerticalScrollIndicator={false}
                contentContainerStyle={{ flex: 1 }}
                    style={{ flex: 1 }}
              />
            </View>
          </View>
          {
            selectedPlan >= 0 ?
              <View style={{ flex: 1, padding: 5 }}>
                <Text adjustsFontSizeToFit >This product is currently in testing, rest assured you will not be billed. Any and all feedback is appreciated so that we can build the best software for your business. If you require any further help, please do not hesitate to get in touch.</Text>
              </View>
              :
              null
          }
          <View style={{ flex: 2 }} />
          <View style={{ flexDirection: 'row', alignSelf: 'stretch', alignContent: 'space-between', flex: 2, marginVertical: 10 }}>
            <Pressable
              onPress={() => {
                dispatch({ type: HIDE_REGISTER_MODAL })
              }}
              style={[styles.BackToLogin, { flexDirection: 'row', flex: 1, justifyContent: 'center' }]}
            >
              <View />
              <Ionicons name={"arrow-back-outline"} size={20} color={'white'} ></Ionicons>
              <Text adjustsFontSizeToFit style={{ color: 'white' }}>Back</Text>
              <View />
            </Pressable>
            <Pressable onPress={async () => {
              let currentValues = heldValues;
              await dispatch(registerUser(currentValues))
            }} disabled={selectedPlan < 0 ? true : false}
              style={[styles.BackToLogin, { flexDirection: 'row', flex: 1, justifyContent: 'center' }]}
            >
              <Text adjustsFontSizeToFit style={{ color: selectedPlan < 0 ? 'grey' : 'white', fontWeight: '600' }}>Register</Text>
            </Pressable>
          </View>
        </View >
      </View>
    </Modal>
  )
}

export default RegisterModal;