import {
  GET_ERRORS
} from "../actions/types";

const initialState = {
  data: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return action.payload.data;
    default:
      return state;
  }
}